<ngx-json-ld [json]="schema"></ngx-json-ld>
   <div class="publisher-type">
        <div class="page-select-buttons">
                <a  routerLink="publishers" routerLinkActive="active" mat-button>{{ 'PUBLISHERS' | translate }}</a>
                <a  routerLink="creators" routerLinkActive="active" mat-button>{{ 'CREATORS' | translate }}</a>
                <a  routerLink="upload" routerLinkActive="active" mat-button>{{ 'UPLOAD' | translate }}</a>
        </div>
   </div>

<div class="publisher-titles">
    <div class="main-container">

<router-outlet></router-outlet>

    </div>
</div>
