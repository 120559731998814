<div class="comic-viewer container-fluid vh-100" *ngIf="isLoaded">
    <div id="top-nav" class="overlay fixed-top col-12 d-flex justify-content-center justify-content-md-end">
        <button class="x-circle" type="button" (click)="close()" mat-fab><mat-icon svgIcon="x-circle"></mat-icon></button>
    </div>
    <div class="d-flex flex-column h-100">
        <div class="row flex-grow-1 h-100 image-container">
            <swiper-container
                *ngIf="issue"
                appSwiper
                #swiper
                [config]="config"
                init="false"
                (slidechange)="slideChange($event)"
                class="comicViewer"
                [initialSlide]="index"
                [slidesPerView]="showPages"
            >
                <swiper-slide *ngFor="let page of issue.pages; let i=index">
                    <div class="slide" [ngStyle]="{backgroundImage: 'url(' + getPageImage( page )+')'}"></div>
                </swiper-slide>
            </swiper-container>
        </div>
    </div>
    <div id="controls" class="overlay fixed-bottom col-12 d-flex justify-content-center">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item"> <button type="button" (click)="prev()" mat-fab> <mat-icon svgIcon="arrow-left-circle"></mat-icon></button> </li>
                    <li class="nav-item"> <button type="button" (click)="zoom()" mat-fab> <mat-icon svgIcon="zoom-in"></mat-icon></button></li>
                    <li class="nav-item"> <button type="button" (click)="view('single')" mat-fab> <mat-icon svgIcon="single-page"></mat-icon> </button> </li>
                    <li class="nav-item"> <button type="button" (click)="view('double')" mat-fab> <mat-icon svgIcon="facing-pages"></mat-icon> </button> </li>
                    <li class="nav-item"> <button type="button" (click)="next()" mat-fab> <mat-icon svgIcon="arrow-right-circle"></mat-icon> </button> </li>
                </ul>
            </div>
        </nav>
    </div>
</div>
<div class="comic-viewer container-fluid vh-100" *ngIf="!isLoaded">
    <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
</div>