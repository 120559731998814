import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import {AuthenticationService} from '../../_services/authentication.service';
declare const postscribe;

@Component({
  selector: 'app-ownads',
  templateUrl: './ownads.component.html',
  styleUrls: ['./ownads.component.scss']
})
export class OwnadsComponent implements OnInit, AfterViewInit {



  constructor(private authService: AuthenticationService) { 

  }

  ads = [ 'amazon', 'google', 'own'];
  activeAd;
  isBrowser;

  @Input() vertical:Boolean;
  @Input() adId:String;
  @Input() which:String;


  ngOnInit() {
    this.isBrowser = this.authService.isWindow();
    if(this.which){
      this.activeAd=this.which;
      return;
    }
    this.activeAd = this.ads
    .map((a) => ({sort: Math.random(), value: a}))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value)[0];
    this.activeAd='amazon';
    this.isBrowser = this.authService.isWindow();
  }

  ngAfterViewInit(){
    if(this.isBrowser){
      if(this.vertical===true){
        this.loadScriptsVertial()
      }
      else{
        this.loadScripts();
      }
        
    }

  }

  loadScripts(){
    if(!this.adId){
      this.adId="amazonContainer";
    }
    if(this.authService.isWindow()){
        if(this.activeAd === 'amazon'){
          postscribe('#'+this.adId, `<div id="amzn-assoc-ad-2a5b653c-e692-44a7-866c-4302e163b23e"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=2a5b653c-e692-44a7-866c-4302e163b23e"></script>
          `);

      }
    }
  }


  loadScriptsVertial(){
    if(!this.adId){
      this.adId="amazonContainer";
    }
    if(this.authService.isWindow()){
          postscribe('#'+this.adId, `<div id="amzn-assoc-ad-dce8ec42-4a7c-4c05-ae27-212f630cd433" style="background:white;"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=dce8ec42-4a7c-4c05-ae27-212f630cd433"></script>
        `);
      
  }
}
}
