

<ng-container *ngIf="isPlaceholder!==true">
    <ng-container class="desktop-only">
      <app-topslider-desktop [isPlaceholder]="isPlaceholder" [genre]="genre" [genreId]="genre?.objectId" [slides]="gSlides" ></app-topslider-desktop>
    </ng-container>
    <ng-container class="mobile-only">
      <app-topslider-mobile [isPlaceholder]="isPlaceholder" [genre]="genre"  [genreId]="genre?.objectId" [slides]="slides" ></app-topslider-mobile>
    </ng-container>  
</ng-container>

  
<div *ngIf="isPlaceholder===true" style="max-height:484.5px; width:100%; background-color:silver;position:relative; text-align:center;opacity:.5;">
  <svg viewBox="0 0 800 600" preserveAspectRatio="xMidYMin slice" style="opacity:0">
    <rect width="100%" height="100%" fill="silver" />
  </svg>
    <div style="
    max-width:1100px; 
    position:absolute; 
    height:100%; width:100%; top:0;
    margin:auto; background-color:rgba(0, 0, 0, 0.2);
    display: flex; flex-direction: column;        
    justify-content: flex-end; padding-top:10px;  box-sizing: border-box; text-align:left;
    left: 50%;
    transform: translate(-50%);
    ">

        <div style="background-color:rgba(0,0,0,.2); padding:15px 10px;max-width:400px;">
          <div style="height:20px; width:250px; border-radius:5px;; margin-bottom:10px;"></div>
          <div style="height:12px; width:50px; bborder-radius:5px;"></div>
        </div>           

    </div>
</div>