import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-feed-recommendation',
  templateUrl: './feed-recommendation.component.html',
  styleUrls: ['./feed-recommendation.component.scss']
})
export class FeedRecommendationComponent implements OnInit {
    @Input() user: any;
    @Input() layout: string = 'vertical';
    defaultImage: String='assets/images/graphy.png';
    username: string;
    name: string;
    profile: any;
    photo: string;
    permalink: string;

    constructor() { }

    ngOnInit() {

        this.username = this.user.username;
        this.profile  = this.user.profile ? this.user.profile : {};
        this.name     = this.profile.name ? this.profile.name : this.username;
        this.photo    = this.profile.photo ? this.profile.photo.url : this.defaultImage;
        this.permalink = this.profile.permalink ? this.profile.permalink : '/feed/' + this.username;
    }

    gotoProfile() {

    }

    follow() {
        console.log('follow', this.username);
    }
}
