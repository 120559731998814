<div style="width:1px; min-height:450px;">

</div>
<div style="top:0;width:100%;min-height:100%; position:fixed;background-image:url(./assets/images/loading_bg.png); background-repeat: no-repeat; background-position: center;background-size:contain;">
  
      <div class="login-container" *ngIf="!loading">
      <mat-card class="login-card" *ngIf="isValid">
            <mat-card-header>
                    <div>
                          <div class="slogan">Reset password?</div>
                          <div class="sub-slogan">Enter the new password</div>   
                    </div>      
            </mat-card-header>
            <mat-card-content>
                  <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()" class="login-form">
                        <div class="form-group">
                                <mat-form-field  appearance="outline">
                                        <mat-icon matPrefix>lock</mat-icon>
                                        <mat-label>Password</mat-label>
                                        <input  autocomplete="off" id="ugcPassword" name="ugcPassword" matInput placeholder="Password" formControlName="password" [type]="hide ? 'password' : 'text'" autocomplete="new-password">
                                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        <mat-hint>Minimum of 8 characters and contains UPPER, lower case and numbers.</mat-hint>
                                        <mat-error *ngIf="f.password.errors && f.password.errors.pattern">Your password must be a minimum of 8 characters and contains UPPER, lower case and numbers.</mat-error>
                                </mat-form-field>
                        </div>
                        <div class="form-group" style="margin-top:20px;">
                                <mat-form-field  appearance="outline">
                                        <mat-icon matPrefix>lock</mat-icon>
                                        <mat-label>Retype password</mat-label>
                                        <input  autocomplete="off" id="ugcPassword2" name="ugcPassword2" matInput placeholder="Type your password" formControlName="passwordAgain" [type]="hide ? 'password' : 'text'" autocomplete="new-password">
                                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        <mat-error *ngIf="f.passwordAgain.errors && f.passwordAgain.errors.mustMatch">Passwords must match</mat-error>
                                </mat-form-field>
                        </div>

                      <mat-card-actions>
                                      <button [disabled]="loading" mat-button  class="pink-mat">Reset</button>
                      </mat-card-actions>
                  </form>
          </mat-card-content>
          <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>

          <div style="text-align:center;">
                 <a routerLink="/login" class="btn btn-link">Return to login?</a>
          </div>


</mat-card>
<mat-card class="login-card" *ngIf="!isValid">
            <mat-card-header>
                    <div style="color:red;">
                          <div class="slogan">error</div>
                          <div class="sub-slogan">The link is invalid. Please go to Forgot Pasword and reset again.</div>   
                    </div>      
            </mat-card-header>
            <mat-card-content>

          </mat-card-content>


          <div style="text-align:center;">
                 <a routerLink="/forgotpassword" class="btn btn-link">Forgot Password?</a>
                 <br>
                 <br>
                 <a routerLink="/login" class="btn btn-link">Login?</a>
          </div>


</mat-card>

         

    </div>
    <div class="login-container" *ngIf="loading">
    <mat-card class="login-card" *ngIf="didReset">
        <mat-card-header>
                <div>
                      <div class="slogan">Success</div>
                      <div class="sub-slogan">The password was changed.</div>   
                </div>      
        </mat-card-header>
        <mat-card-content>

      </mat-card-content>


      <div style="text-align:center;">
             <a routerLink="/login" class="btn btn-link">Go to Login</a>
      </div>


</mat-card>   
</div>
</div>
