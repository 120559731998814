import { Injectable , Inject, PLATFORM_ID} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {HttpParams} from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Account, User } from '../_models';
import { WINDOW } from './window.service';
import { LocalService } from './local.service';


import { isPlatformBrowser } from '@angular/common';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(WINDOW) private window: Window,
        private localStorage: LocalService,
        private http: HttpClient) {
        if (isPlatformBrowser(this.platformId)) {
            this.currentUserSubject = new BehaviorSubject<User>(this.parseJSON(this.localStorage.getItem('currentUser')));
            this.currentUser = this.currentUserSubject.asObservable();
        } else {
            this.currentUserSubject = new BehaviorSubject<User>(new User);
            // this.currentUser = this.currentUserSubject.asObservable();
            this.currentUser = of(null);
        }
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    update(user=null) {
        if(user===null){
            user=JSON.parse(this.localStorage.getItem('currentUser'));
        }
        let currentuser=JSON.parse(this.localStorage.getItem('currentUser'));
        user['sessionToken']=currentuser.sessionToken;
        this.localStorage.setItem('currentUser', JSON.stringify(user));
        if (isPlatformBrowser(this.platformId)) {
            this.currentUserSubject.next(this.parseJSON(this.localStorage.getItem('currentUser')));
        } else {
            this.currentUserSubject = new BehaviorSubject<User>(new User);
            this.currentUser = of(null);
        }
    }
    refresh(): any {
        //reload the current user, to refresh subscription and other changes
        const refreshObs = new Observable(observer => {
            if(this.currentUserValue && this.currentUserValue.sessionToken){
                this.getUser().subscribe(result=>{
                    let user = result;
                    this.update(user);
                    observer.next(true);
                });
            }
            else{
                observer.next(false);
            }  
        });
        return refreshObs;
    }



    login(username: string, password: string) {

        const  params = new  HttpParams().set('username', username).set('password', password);
        return this.http.post<any>(`${environment.apiUrl}/user/login`, params)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.sessionToken) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    isWindow() {
        if (isPlatformBrowser(this.platformId)) {
            return true;
        }
        return false;
    }

    logout(reload=false) {
         this.http.get(`${environment.apiUrl}/user/logout`,{})
         .subscribe((res) => {
                if(reload===true){
                    location.reload();
                }
        });
        // remove user from local storage to log user out
        if (isPlatformBrowser(this.platformId)) {
                this.localStorage.removeItem('currentUser');
                this.currentUserSubject.next(null);
        }

    }

    parseJSON(str) {
        if (this.isJSON(str)) {
            return JSON.parse(str);
        }
        return null;
    }

    isJSON(str) {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    }


    getUser() {
        return this.http.get<Account>(`${environment.apiUrl}/user/current`);
    }


    getMobileOperatingSystem() {
        const userAgent = this.window.navigator.userAgent || this.window.navigator.vendor || this.window['opera'];
    
            // Windows Phone must come first because its UA also contains "Android"
          if (/windows phone/i.test(userAgent)) {
              return 'Windows Phone';
          }
    
          if (/android/i.test(userAgent)) {
              return 'Android';
          }
    
          // iOS detection from: http://stackoverflow.com/a/9039885/177710
          if (/iPad|iPhone|iPod/.test(userAgent) && !this.window['MSStream']) {
              return 'iOS';
          }
    
          return 'unknown';
      }
    
}
