import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
// import { SwiperConfigInterface} from 'ngx-swiper-wrapper';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UserService, AuthenticationService } from '../../_services';
import { DeviceDetectorService } from 'ngx-device-detector';
// import SwiperCore, { Keyboard, Pagination, Navigation, Virtual } from 'swiper';
import { Location } from '@angular/common';

// SwiperCore.use([Keyboard, Pagination, Navigation, Virtual]);

@Component({
  selector: 'app-graphite-viewer',
  templateUrl: './graphite-viewer.component.html',
  styleUrls: ['./graphite-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class GraphiteViewerComponent implements OnInit {
    issueId: string;
    issue: any;
    index: number=0;
    pageCount: number=0;
    currentUser:any;
    deviceInfo: any = null;
    isMobile = false;
    isiOS=false;

    public config = {
        direction: 'horizontal',
        slidesPerView: 1,
        keyboard: true,
        mousewheel: true,
        autoplay:false,
        navigation: true,
    };

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private route: ActivatedRoute,
        private userService: UserService,
        private titleService: Title,
        private metaTagService: Meta,
        private authenticationService: AuthenticationService,
        private deviceService: DeviceDetectorService,
        private location: Location

    ) {
        this.authenticationService.currentUser.subscribe((value) => {
            this.currentUser = value;
        });
        this.deviceInfo = this.deviceService.getDeviceInfo();
        if (this.deviceService.isMobile()) {
            this.isMobile = true;
            this.isiOS=true
        } else if (this.deviceInfo.os === 'IOS') {
            this.isMobile = true;
            this.isiOS=true;
        } else if (this.deviceInfo.os === 'ANDROID') {
            this.isMobile = true;
            this.isiOS=true
        } else {

        }
    }

    public onIndexChange(index: number) {
        let page = this.issue.pages[index];
        this.userService.pageWasDisplayed(page.objectId, this.issue.objectId, this.issue.title.objectId, this.issue.readerType, this.userService.isSubscribed(this.currentUser)).subscribe(result => {
            // NOTHING
        });

        this.index=index;
    }

    ngOnInit() {
        this.issueId = this.route.snapshot.paramMap.get("issueId");
        if ( this.issueId ) {
            this.userService.getIssue(this.issueId).subscribe(result => {
                this.issue=result;
                this.pageCount=result.pages.length;
                this.userService.issueWasOpened(this.issueId, this.issue.title.objectId, this.issue.readerType, false).subscribe(result => {
                    // NOTHING
                });

            });
        }
    }

    getPageImage(page) {
        let url= this.userService.getPageImage(page.objectId).replace('.jpf','.jpg');
        return url;
    }

    close() {
        let url = "/title/" + this.issue.title.publisher_slug + "/" + this.issue.title.slug;
        console.log('close',url);
        this.userService.issueWasClosed(this.issueId, this.issue.title.objectId, this.issue.readerType, false).subscribe(result => {
            // NOTHING
        });
        this.document.location.href = url;
    }
}
