import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-publishers-grid',
  templateUrl: './publishers-grid.component.html',
  styleUrls: ['./publishers-grid.component.scss']
})
export class PublishersGridComponent implements OnInit {
  defaultImage: String='./assets/images/preloading_image_placeholder.png';
  isBrowser = false;
  @Input() publishers: any;
  @Input() title: any;
  @Input() isPlaceholder: any;

  constructor(
    ) { }

  ngOnInit() {
        
  }


}
