import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from '../../_services';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-popular-titles',
  templateUrl: './popular-titles.component.html',
  styleUrls: ['./popular-titles.component.scss']
})
export class PopularTitlesComponent implements OnInit {
  isBrowser = false;
  isPlaceholder = true;

  titles: any;
  slides: any;
  schema;
  constructor(
      private authService: AuthenticationService,
      private userService: UserService,
      private titleService: Title,
      private metaTagService: Meta
  ) {

      
  }

  ngOnInit() {
    this.isBrowser = this.authService.isWindow();

    this.titleService.setTitle('Comics, Manga, Webtoon - Popular Titles');
    this.metaTagService.updateTag({name: 'description', content: 'Read the latest of over 30,000 FREE to read comics from top shelf publishers and creators.'});

    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://graphitecomics.com' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Comics, Manga, Webtoon - Popular Titles' });
    this.metaTagService.updateTag({ property: 'og:description',
    content: 'Read the latest of over 30,000 FREE to read comics from top shelf publishers and creators.' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.graphitecomics.com/assets/images/site_icon_512.jpg' });

// Twitter metadata
this.metaTagService.addTag({name: 'twitter:card', content: 'summary'});
this.metaTagService.addTag({name: 'twitter:site', content: 'GraphiteComics'});
this.metaTagService.addTag({name: 'twitter:title', content:  'Comics, Manga, Webtoon - Popular Titles'});
this.metaTagService.addTag({name: 'twitter:description',
content: 'Read the latest of over 30,000 FREE to read comics from top shelf publishers and creators.' });
this.metaTagService.addTag({name: 'twitter:text:description',
content: 'Read the latest of over 30,000 FREE to read comics from top shelf publishers and creators.'});
this.metaTagService.addTag({name: 'twitter:image', content: 'https://www.graphitecomics.com/assets/images/site_icon_512.jpg'});
    if(this.isBrowser){
      this.loadTitles();
    }
    
  }


  loadTitles(){
    this.titles=[];
    this.slides=[];
    this.userService.popular(100).subscribe(result=>{
        this.titles=result.data.popularTitles;
        this.isPlaceholder = false;
    });
    this.populateSchema();
  }


  populateSchema() {
    const schema =       {
      "@context": "http://schema.org",
      "@type": "WebPage",
      "name": "Comics, Manga, Webtoon - Popular Titles",
      "description": "Read the latest of over 30,000 FREE to read comics from top shelf publishers and creators.",
      "publisher": {
          "@type": "Organization",
          "name": "GraphiteComics"
      }
  };
  this.schema = schema;

  }





}
