import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import {Router} from '@angular/router';

@Component({
  selector: 'app-appstore-redirect',
  templateUrl: './appstore-redirect.component.html',
  styleUrls: ['./appstore-redirect.component.scss']
})
export class AppStoreRedirectComponent implements OnInit {
  deviceInfo = null;

  constructor(private deviceService: DeviceDetectorService, private router: Router,) {
    this.deviceInfo = this.deviceService.getDeviceInfo();

    this.doRedirect();
  }

  ngOnInit() {

    if (this.deviceInfo.os == "iOS") {
      document.location.href = "https://apps.apple.com/us/app/graphite-comics/id1367730938";
    } else if (this.deviceInfo.os == "Android") {
      document.location.href = "https://play.google.com/store/apps/details?id=com.graphite.graphitecomics&hl=en";
    } else {
      this.router.navigate(['/']);
      //document.location.href = "https://apps.apple.com/us/app/graphite-comics/id1367730938";
    }

  }

  doRedirect() {
    /*
    if (this.deviceInfo.os == "iOS") {
      document.location.href = "https://apps.apple.com/us/app/graphite-comics/id1367730938";
    } else if (this.deviceInfo.os == "Android") {
      document.location.href = "https://play.google.com/store/apps/details?id=com.graphite.graphitecomics&hl=en_US";
    } else {
      this.router.navigate(['/']);
      //document.location.href = "https://apps.apple.com/us/app/graphite-comics/id1367730938";
    }
    */
  }
}
