import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { COPYRIGHT, BASE_URL, FACEBOOK, TWITTER, INSTAGRAM } from '../../../environments/environment';
import packageInfo  from '../../../../package.json';
import { UserService, AuthenticationService, WINDOW } from '../../_services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public isBrowser: boolean = false;
    version: string = packageInfo.version;
    copyright:string   = COPYRIGHT;
    baseUrl:string    = BASE_URL;
    currentYear = new Date().getFullYear();
    facebook:string   = FACEBOOK;
    twitter:string     = TWITTER;
    instagram:string   = INSTAGRAM;
    mentions!: string;
    currentUser!: any;
    username!: string;

    constructor(
      @Inject(PLATFORM_ID) private platformId: any,
      @Inject(WINDOW) private window: Window,
      private authService: AuthenticationService,
    ) {
      this.isBrowser = isPlatformBrowser(this.platformId);
      if (this.isBrowser) {
        this.authService.currentUser.subscribe((value) => {
          this.currentUser = value;
          this.username = this.currentUser.username;
          this.mentions = `mentions/${this.username}`;
        });
      }

    }

    ngOnInit() { }

}
