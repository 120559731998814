
<ngx-json-ld [json]="schema"></ngx-json-ld>
<app-topslider [isPlaceholder]="isPlaceholder" ></app-topslider>
<div style="width:100%; height:10px; background:white;max-width:1100px; margin:auto;"></div>
<div *ngIf="isPlaceholder===false" class="main-container home-page"       infiniteScroll
[infiniteScrollDistance]="20"
[infiniteScrollThrottle]="20"
[scrollWindow] = "true"
(scrolled)="onScroll()">
    <div *ngIf="genres && genres.length>0">
        <div style="height:0;width:0; display:none;" *ngIf="genres && genres.length>0">
            <app-titlesrow [slider]="true"  [rowTitle]="''" [titles]="[]" [genreId]="genres[0].objectId"></app-titlesrow>
        </div>
        <app-titlesrow *ngIf="latest" [slider]="true" [rowTitle]="'LATEST CONTENT'" [titles]="latest" [link]="'/hotnew/new'"></app-titlesrow>
        <app-titlesrow *ngIf="titles" [slider]="true" [rowTitle]="'Trending Titles'" [titles]="titles"></app-titlesrow>
    </div>

    <span  *ngIf="genres">
        <div *ngFor="let genre of genres">
            <app-titlesrow *ngIf="genre"
                [slider]="true"
                [rowTitle]="genre.genreName"
                [titles]="genre.titles"
                [genreId]="genre.objectId"
                [genreName]="genre.genreName"
            ></app-titlesrow>
        </div>
        <mat-progress-bar *ngIf="loading===true" mode="indeterminate"></mat-progress-bar>
    </span>
    <div class="mobile-app">
        <div style="text-align: center; color: #646464; font-size: 16px; font-family: HelveticaNeueBdCn;">
            <span>{{ 'GET THE APP FOR' | translate }}</span>
            <br>
            <span>{{ 'THE LATEST COMICS!' | translate }}</span>
        </div>
        <a href="https://apps.apple.com/us/app/graphite-comics-get-drawn-in/id1367730938" target="_blank" style="margin-left:25px; margin-right:10px;"  (click)="bannerClick('iphone')">
            <img  src="./assets/images/appleappstore.png" style="max-width: 110px;">
        </a>
        <a (click)="bannerClick('android')" href="https://play.google.com/store/apps/details?id=com.graphite.graphitecomics&hl=en" target="_blank">
            <img  src="./assets/images/googleplay.png" style="max-width: 110px;">
        </a>
    </div>
</div>

<div *ngIf="isPlaceholder===true" class="main-container home-page">
    <div>
        <app-titlesrow [slider]="true" [isPlaceholder]="isPlaceholder" [rowTitle]="'LATEST CONTENT'" [titles]="titles"></app-titlesrow>
        <app-titlesrow [slider]="true" [isPlaceholder]="isPlaceholder" [rowTitle]="'Trending Titles'" [titles]="titles"></app-titlesrow>
    </div>
    <span>
        <div *ngFor="let dummy of ' '.repeat(7).split(''), let x = index">
            <app-titlesrow [slider]="true" [isPlaceholder]="isPlaceholder"></app-titlesrow>
        </div>
    </span>
    <div class="mobile-app">
        <div style="text-align: center; color: #646464; font-size: 16px; font-family: HelveticaNeueBdCn; ">
            <span>{{ 'GET THE APP FOR' | translate }}</span>
            <br>
            <span>{{ 'THE LATEST COMICS!' | translate }}</span>
        </div>
        <a href="https://apps.apple.com/us/app/graphite-comics-get-drawn-in/id1367730938" target="_blank" style="margin-left:25px; margin-right:10px;"  (click)="bannerClick('iphone')">
            <img  src="./assets/images/appleappstore.png" style="max-width: 110px;">
        </a>
        <a (click)="bannerClick('android')" href="https://play.google.com/store/apps/details?id=com.graphite.graphitecomics&hl=en" target="_blank">
            <img  src="./assets/images/googleplay.png" style="max-width: 110px;">
        </a>
    </div>
</div>
