import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noheader-layout',
  templateUrl: './noheader-layout.component.html',
  styleUrls: ['./noheader-layout.component.scss']
})
export class NoheaderLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
