<app-topslider *ngIf="genre" [isPlaceholder]="isPlaceholder" [genreId]="genre?.objectId"></app-topslider>
<app-topslider *ngIf="!genre" [isPlaceholder]="isPlaceholder" [genreId]="genre?.objectId"></app-topslider>
<div class="genre-titles">
    <div class="main-container">
      <div class="genre-description-mobile">
          <h1>{{genre?.genreName}} Comics</h1>
          <p style="padding: 0 15pX;">
            {{genre?.description}}
          </p>
      </div>
      <div class="sorting-titles">
        <div (click)="sort('trending')" [ngClass]="{'selected': sortBy==='trending'}">{{ 'Trending' | translate }}</div>
        <div (click)="sort('a-z')" [ngClass]="{'selected': sortBy==='a-z'}">{{ 'A-Z' | translate }}</div>
        <div (click)="sort('latest')" [ngClass]="{'selected': sortBy==='latest'}">{{ 'Latest' | translate }}</div>
      </div>
      
            <app-titlesrow  [slider]="false" [isPlaceholder] = "isPlaceholder || isTitlePlaceholder" [rowTitle]="'Trending Titles'" [titles]="titles" [all]="true"></app-titlesrow>
    </div>
</div>
