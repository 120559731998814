import {Component, OnInit} from '@angular/core';
import { User } from '../../_models/index';
import {  UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, ValidationErrors} from '@angular/forms';
import { UserService, AuthenticationService } from '../../_services';;
import { ImageSelectorComponent } from '../image-selector/image-selector.component';
import { MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogPlanComponent } from '../dialog-plan/dialog-plan.component';


@Component({
  selector: 'app-profile-page',
  templateUrl: 'profile-page.component.html',
  styleUrls: ['profile-page.component.scss'],
})

export class ProfilePageComponent implements OnInit{
  listCountry: any;


  sliderValue = 0;
  selected: string[];
  showPassword = false;
  selectedFile: File;
  user: any;
  languagesList: string[];
  minPw = 8;
  pwChangeForm: UntypedFormGroup;
  userUpdate: UntypedFormGroup;
  validationMessage: '';
  errorMessage: '';
  subscription: any;
  listAge;
  cropped=true;
  changePassword=false;

    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        private router: Router,
    ) {
        this.listCountry= this.userService.listCountry;
    }


    ngOnInit() {
        if( !this.authenticationService.currentUserValue ){
            this.router.navigate(['/']);
            return;
        }
        this.userUpdate = this.formBuilder.group({
            email: [{value: null, disabled: true}],
            ageRating: [this.sliderValue],
            languages: ['']
        });
        this.listAge=this.userService.listAge;
        this.userService.getUser().subscribe(user=>{
            this.user = user;
            if ( user && user['subscription'] && user['subscription']['source']==='stripe'){
                this.userService.getUserSubscription().subscribe(
                subscription => {
                this.subscription=subscription;

            }
        );
    }
    this.updateUserData();
    })
    this.pwChangeForm = this.formBuilder.group({
    current: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(this.minPw)]],
    password2: ['', Validators.required],
    }, {
    validator: passwordMatchValidator
    });




    }

updateUserData(){
  this.authenticationService.update(this.user);
  this.sliderValue = this.userService.ageToNumber(this.user.max_age_rating);
  const list = [];
  this.user.languages.forEach(function(value) {
    list.push(value.language);
  });
  this.languagesList = list;
  this.userUpdate.patchValue(
    {
      ageRating: [this.sliderValue]
    }
  );


}

onInputChange(ev){
  this.updateAgeSettings(ev.value);
}

  changeSliderValue(value) {
    this.sliderValue = value;
    this.updateAgeSettings(value);
  }


  updateAgeSettings(value){
        let newAgeRating=this.userService.listAge[value].value;
        let oldAgeRating=this.user.max_age_rating;
        this.user['max_age_rating']=newAgeRating;
        this.authenticationService.update(this.user);
        if(newAgeRating!==oldAgeRating){
              this.userService.updateUser(newAgeRating,this.languagesList).subscribe(result=>{
                    this.user=result;             
                    this.authenticationService.update(this.user);      
              });
        }
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  // Uploading picture
  uploadPic() {

  }

  getProfileImage(){
    return this.user.profileImage;
  }
  imageLoaded(ev){
    if(Number(ev.path[0].width) !== Number(ev.path[0].height)){
      this.cropped=false;
    } else {
      this.cropped=true;
    }
  }
  openDialog(showCrop= false, canclose = false, noImage=false): void {
    const dialogRef = this.dialog.open(ImageSelectorComponent, {
        data: {
          image: noImage===false? this.getProfileImage(): null,
          place: 'profileImage',
          publisher: true,
          // publisherId: this.publisherId,
          dialogTitle: 'Select a profile image',
          save: this.userService.uploadProfileImage(),
          showCrop: showCrop,
          canclose: canclose,
          maintainAspect: true,
        },
        width: '90%',
        maxWidth: '1100px',
        maxHeight:'100%',

        disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.profileImage){
              this.user.profileImage = result.profileImage;
              this.authenticationService.update(this.user);;
      }
      

    });
  }




  uploadProfile(form) {
    const age = this.userService.numberToAge(form.ageRating);
    const langues = form.languages.join();
    this.userService.updateUser(age, langues).subscribe(
      (result: User) => {
        this.user=result;
        this.authenticationService.update(this.user);
        this.authenticationService.currentUserSubject.next(result);
        
      },
      error => {

      }
    );
  }

  
  langSelected( $event ){
    let newAgeRating= this.user.max_age_rating;
    this.userService.updateUser(newAgeRating,this.languagesList).subscribe(result=>{
          this.user=result;
    });
  }
  changePasswordSubmit(form) {
    this.userService.updatingPassword(form.current, form.password, this.user.username).subscribe(
      result => {

        // this.localStorage.setItem('currentUser', JSON.stringify(result));
      }
      ,
      error => {

      }
    );
  }

  get password() { return this.pwChangeForm.get('password'); }
  get password2() { return this.pwChangeForm.get('password2'); }

  onPasswordInput() {
    if (this.pwChangeForm.hasError('passwordMismatch')) {
      this.password2.setErrors([{'passwordMismatch': true}]);
    } else {
      this.password2.setErrors(null);
    }
  }


  doLogout(){
    this.authenticationService.logout();
    location.reload();
  }


  getTotal(){
    return 1000;
  }
  createOrder(id){
  
  }
    checkout() {

        const dialogRef = this.dialog.open(DialogPlanComponent, {
          // opening dialog here which will give us back stripeToken
          width: '100%',
          height: '100%',
          panelClass: 'payment-modalbox',
    
      });
        
    
    
        dialogRef.afterClosed().subscribe(result => {
          this.authenticationService.refresh().subscribe(r=>{
                this.ngOnInit();
          })
          
    
        });
      
  }

  cancelSubscription(){
    console.log('cancel')
    this.userService.cancelSubscription().subscribe(result=>{
      this.authenticationService.update();
      this.userService.getUserSubscription().subscribe(
        subscription => {
          this.subscription=subscription;
        }
      );
    });
  }

  reactivateSubscription(){
    this.userService.reactivateSubscription().subscribe(result=>{
      this.authenticationService.update();
      this.userService.getUserSubscription().subscribe(
        subscription => {
          this.subscription=subscription;
        }
      );
    });
  }

}

export const passwordMatchValidator: ValidatorFn = (pwChangeForm: UntypedFormGroup): ValidationErrors | null => {
  if (pwChangeForm.get('password').value === pwChangeForm.get('password2').value) {
    return null;
  } else {
    return {passwordMismatch: true};
  }
};
