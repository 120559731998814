import { isPlatformBrowser } from '@angular/common';
import { Component, AfterViewInit, ElementRef, Input, OnInit, PLATFORM_ID, Inject, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../_services';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types/swiper-options';


// https://medium.com/@fbanyai/swiper-10-angular-16-uncovered-1721bf32dfd6

@Component({
    selector: 'app-titlesrow',
    templateUrl: './titlesrow.component.html',
    styleUrls: ['./titlesrow.component.scss']
})
export class TitlesrowComponent implements OnInit, AfterViewInit {
    @ViewChild('swiper') swiperContainer!: ElementRef<SwiperContainer>;
    isBrowser;
    @Input() rowTitle: string;
    @Input() titles: any;
    @Input() all: Boolean;
    @Input() genreId: string;
    @Input() genreName: string;
    @Input() link: string;
    @Input() slider:  true;
    @Input() isPlaceholder: boolean;

    isWindow = true;
    afterLoaded = false;
    index = 0;
    isLoaded = false;
    public config: SwiperOptions = {

        initialSlide: 0,
        direction: 'horizontal',
        slidesPerView: 'auto',
        //slidesPerGroupAuto: true,
        keyboard: false,
        mousewheel: false,
        autoplay: false,
        centeredSlides: false,
        zoom: false,
        observer: true,
        autoHeight: true,
        setWrapperSize: true,
        watchSlidesProgress: true,
        virtual: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        observeParents: true,
        parallax:true,
    }
    /*
                    (indexChange)="indexChange($event)"

                [(index)]="index"
                (imagesReady)="imagesReady()"
                (lazyImageReady)="lazyImageReady()"

    */

    defaultImage: String = './assets/images/preloading_image_placeholder.png';
    constructor(
        private authenticationService: AuthenticationService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);

    }

    ngAfterViewInit() {
        this.isWindow = this.authenticationService.isWindow();
        this.isLoaded = true;
    }

    ngOnInit() {

    }

    getLink(title) {
        return '/title/'  + title.publisher_slug + '/' + title.slug;
    }

    indexChange(ev) {
        if (this.afterLoaded !== true) {

            // this.index=0;
            // this.afterLoaded=true;
        }
    }

    lazyImageReady() {}

    imagesReady() {
        console.log('imagesReady');
    }

    getExploreLink(){
        if(this.link){
            return this.link;
        }
        if(this.genreName){
            return this.genreName?'/genre/'+this.genreName:'/titles/popular'
        } else {
            return this.genreId?'/t/g/'+this.genreId:'/titles/popular'
        }
    }
}

