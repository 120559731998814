import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../_services';
import { UserService } from '../../_services';

@Component({
  selector: 'app-hottitles',
  templateUrl: './hottitles.component.html',
  styleUrls: ['./hottitles.component.scss']
})
export class HottitlesComponent implements OnInit {
  itemAgeRating: any;
  loading;
  defaultImage: String = './assets/images/preloading_image_placeholder.png';
  private ratings = this.userService.ratings();

  constructor(private authenticationService: AuthenticationService,
    private userService: UserService) { }
  @Input() titles: any;
  @Input() nopublisher: false;
  @Input() isPlaceholder: true;
  public isWindow = true;
  ngOnInit() {
    this.isWindow = this.authenticationService.isWindow();

  }

  

  getAgeRating(title){
    for (let r of this.ratings){
      if (r.id === title.age_rating){
            return r.image;
      }
    }
    return '';
  }


}
