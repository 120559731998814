import { Component, OnInit, EventEmitter } from '@angular/core';
import {Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploader } from 'ng2-file-upload';
import { UserService, AuthenticationService, AlertService, LocalService  } from '../../_services';

import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConfirmationPublishComponent } from '../confirmation-publish/confirmation-publish.component';


@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent implements OnInit {
  isCrop = false;
  cropping = false;
  cropconf: any;
  imageBase64: any;
  image: string;
  objectImage: string;
  issues: any;
  titleId: string;
  issueId: string;
  dialogReturn: any;
  place: string;
  saveUrl: string;
  public uploader: FileUploader ;
  public imgProgress: number;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  public hasDropZoneOver = false;
  public base64loaded = true;
  public dialogTitle = 'Select Image';
  public isPublisher = false;
  public publisherId: string;
  maintainAspect = false;
  base64: any;
  canclose = false;

  reader: FileReader = new FileReader();


  public fileOver(e: any): void {
    this.hasDropZoneOver = e;
  }


//   dragFileAccepted(event: any): void 
// {
//   console.log('dragFileAccepted: ',event);
//     this.imageChangedEvent = event;
//     this.isCrop=true;
// }


   dragFilesDropped(event: any): void 
{
  //  console.log('dragFilesDropped: ',arguments);
//     this.imageChangedEvent = event[0];
//     this.isCrop=true;
 }



fileChangeEvent(event: any): void 
{
  // console.log('fileChangeEvent: ',event);
  //   this.imageChangedEvent = event;
  //   this.isCrop=true;
}
imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
}
imageLoaded() {
    // show cropper
}
cropperReady() {
    // cropper ready
}
loadImageFailed() {
    // show message
}

doCrop() {
    this.cropping = true;
    this.canclose = true;
    this.dialogRef.disableClose = !this.canclose;
        this.userService.cropProfileImage(this.croppedImage).subscribe(result => {
          this.cropping = false;
          //this.authenticationService.update();
          this.dialogRef.close(result);
        });

  

}

  public close() {

  }

  public getEditedFile(file: File) {

  }
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    public dialogRef: MatDialogRef<ImageSelectorComponent>,
    public dialog: MatDialog,
    private localStorage: LocalService,
    @Inject(MAT_DIALOG_DATA) public data: any ) {
            this.objectImage = data.image;
            // this.issues=data.issue;
            this.base64 = data.base64;
            this.titleId = data.titleId;
            this.issueId = data.issueId;
            this.publisherId = data.publisherId;
            this.place = data.place;
            this.maintainAspect = data.maintainAspect;
            this.saveUrl = data.save;
            if (data.publisher && data.publisher === true) {
              this.isPublisher = true;
            }
            if (data.dialogTitle) {
              this.dialogTitle = data.dialogTitle;
            }
            if (data.showCrop === true) {
              this.activateCrop();
            }
            this.canclose = data.canclose;
            this.dialogRef.disableClose = !this.canclose;
            
    }
  ngOnInit() {
    this.isCrop = false;
      this.uploader = new FileUploader({
        url: this.saveUrl,
        disableMultipart : false,
        autoUpload: false,
        method: 'post',
        itemAlias: this.place,
        allowedFileType: ['image'],
        authToken: this.authenticationService.currentUserValue.sessionToken,
        headers: [{
          name: 'Session-Token', value: this.authenticationService.currentUserValue.sessionToken,
        }],
        additionalParameter: {
          profile: 'profile'
        }
      });
    
      this.reader.onload = (ev: any) => {

        this.imageBase64 = ev.target.result;
        this.isCrop=true;
      };
    this.uploader.onCompleteItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => this.onCompleteAll();
    this.uploader.onProgressItem = (item, progress) => this.onProgressItem(item, progress);
    this.uploader.onAfterAddingFile=(item)=>{
      this.reader.readAsDataURL(item._file);
    }





    //this.getBase64(this.objectImage);

    this.cropconf = {
      aspect: '1',
      minwidth: 330,
      minheight: 330,
      resizeToWidth: 330,
      quality: 70,
      maintainAspect: this.maintainAspect,
    };
  

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  cancelCrop() {  
    
    if(this.canclose === true){
      this.isCrop = false;
    }
    else {
      
      this.confirm('Cancel Cropping',  'You can\'t cancel cropping because the image will be deleted by a cron job!', 'OK' ).afterClosed().subscribe(result => {

      });          
    }

  }
  closeDialog() {

  if(this.uploader.isUploading===true){
      this.confirm('Cancel Image Upload',  'Upload in progress. Are you sure that you want to stop the upload?', 'Yes', 'No' ).afterClosed().subscribe(result => {
        if(result){
          this.dialogRef.close(this.dialogReturn);
        }
      });
    }
    else {
      if(this.isCrop===false){
        this.dialogRef.close(this.dialogReturn);
      } else{
        if(this.canclose === true){
          this.dialogRef.close(this.dialogReturn);
        }
        else {
          this.confirm('Cancel Cropping',  'You can\'t cancel cropping because the image will be deleted by a cron job!', 'OK' ).afterClosed().subscribe(result => {
  
          });          
        }
      }

      
  }
    



  }
  fileChange(event){

  }

  stopAll(){
    this.uploader.clearQueue();
    this.uploader.cancelAll();
    this.dialogRef.disableClose = false;
  }

  stopAllConfirm(){
    this.confirm('Cancel upload',  'Upload still in progress. Are you sure that you want to stop the upload?', 'Yes', 'No' ).afterClosed().subscribe(result => {
      if(result){
          this.stopAll();
      }
    });
  }


  onProgressItem(item, progress) {
    this.dialogRef.disableClose = true;
    this.imgProgress = progress;
  }

  onCompleteItem(item, response, status, headers) {
    this.dialogRef.disableClose = true;
    if(response){
          response = JSON.parse(response);
          const profile = response.profileImage;
          this.objectImage = profile;
          this.dialogReturn = profile;
      //   if (!response.error) {
      //     this.getBase64(this.objectImage);
      //     this.activateCrop();
      //     this.canclose = false;
      //     this.dialogRef.disableClose = true;
      //     item.remove();
      // }
    }

    this.stopAll();

  }
  onCompleteAll() {

  }

  fetchCover(issue) {
  //   this.objectImage = issue.cover_image;
  //   this.getBase64(issue.cover_image);
  }


hasImage() {
  if (this.objectImage) {
    return true;
  }
  return false;

}


getImage() {
  if (this.objectImage) {
    return this.objectImage;
  }
  this.isCrop=false;
  return './assets/images/no-image.png';

}


  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];


  }

activateCrop() {

  if (!this.objectImage) {
    return;
  }
  if (this.objectImage.indexOf('_placeholder') >= 0) {
    return;
  }

  this.isCrop = true;
}

getBase64(imgUrl) {

  if (!imgUrl) {
    return;
  }
  if (imgUrl.indexOf('_placeholder') >= 0) {
    return;
  }
  if (imgUrl.indexOf('publisher_bg_default') >= 0) {
    return;
  }
  this.base64loaded = false;

  if(this.base64 && this.base64 === true){
        this.userService.getBase64(imgUrl).subscribe(result => {
          this.objectImage = result.image;
          this.imageBase64 = result.image;
          this.base64loaded = true;
        });
  } else {
        this.userService.getBase64(imgUrl).subscribe(result => {
          this.imageBase64 = result.image;
          this.base64loaded = true;
        });
  }



}



confirm(title, question, ok = 'YES', cancel = 'Cancel') {
  const dialogRef = this.dialog.open(ConfirmationPublishComponent, {
    minWidth: '450px',
    maxWidth: '700px',
    width:"100%",
    data: {question: question, title: title, ok: ok, cancel: cancel}
  });
  return dialogRef;
}

}
