import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';

import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { TruncateModule } from 'ng2-truncate';
import { FileUploadModule } from 'ng2-file-upload';
import { AppComponent } from './app.component';
import { NgxJsonLdModule } from './_components/ngx-lite/json-ld';
import { routing } from './app.routing';
import { DatePipe } from '@angular/common';

import { AlertComponent } from './_components';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './_components/footer/footer.component';
import { HeaderComponent } from './_components/header/header.component';
import { MenuComponent } from './_components/menu/menu.component';
import { TopbannerComponent } from './_components/topbanner/topbanner.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TitlesrowComponent } from './_components/titlesrow/titlesrow.component';
import { PopularTitlesComponent } from './_components/popular-titles/popular-titles.component';
import { TitleDetailsComponent } from './_components/title-details/title-details.component';
import { ExternalLinkComponent } from './_components/external-link/external-link.component';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { TopsliderComponent } from './_components/topslider/topslider.component';
import { TitleGenresComponent } from './_components/title-genres/title-genres.component';
import { GraphiteViewerComponent } from './_components/graphite-viewer/graphite-viewer.component';
import { FeedLayoutComponent } from './layouts/feed-layout/feed-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { NoheaderLayoutComponent } from './layouts/noheader-layout/noheader-layout.component';
import { PublishersComponent } from './_components/publishers/publishers.component';
import { ReaderComponent } from './_components/reader/reader.component';
import { ViewerLayoutComponent } from './layouts/viewer-layout/viewer-layout.component';
import { GoTopButtonComponent } from './_components/go-top-button/go-top-button.component';
import { PublisherPipePipe } from './pipes/publisher-pipe.pipe';
import { HotnewComponent } from './_components/hotnew/hotnew.component';
import { CreatorsComponent } from './_components/creators/creators.component';
import { PublisherslistComponent } from './_components/publisherslist/publisherslist.component';
import { HottitlesComponent } from './_components/hottitles/hottitles.component';
import { PublisherPageComponent } from './_components/publisher-page/publisher-page.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SearchComponent } from './_components/search/search.component';
import { ShortlinksComponent } from './_components/shortlinks/shortlinks.component';
import { AppStoreRedirectComponent } from './_components/appstore-redirect/appstore-redirect.component';
import { MatureComponent } from './_components/mature/mature.component';

import { TransferHttpCacheModule } from '@nguniversal/common';
import { AdsenseModule } from 'ng2-adsense';
import { OwnadsComponent } from './_components/ownads/ownads.component';
import { ViewsFormatPipe } from './_pipes/views-format.pipe';
import { FeedFormatPipe } from './_pipes/feed-format.pipe';
import { TruncatePipe } from './_components/truncate/truncate.pipe';
import { TimeAgoPipe } from './_pipes/time-ago.pipe';
import { PaginatorComponent } from './_components/paginator/paginator.component';
import { MaturePopupComponent } from './_components/mature-popup/mature-popup.component';
import { CommentsComponent } from './_components/comments/comments.component';

import { RecommendedComponent } from './_components/recommended/recommended.component';
import { ForgotpasswordComponent } from './_components/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './_components/reset-password/reset-password.component';
import { UploadComponent } from './_components/upload/upload.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { StarRatingComponent } from './_components/star-rating/star-rating.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RegisterUserComponent } from './_components/register/register.component';
import { LoginUserComponent } from './_components/login/login.component';
import { LoginPageComponent } from './_components/login-page/login-page.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// Dialogs
import { ProfileDialog } from './dialogs/profile/profile-dialog';
import { ProfilePageComponent } from './_components/profile-page/profile-page.component';
import { IssueDetailsComponent } from './_components/issue-details/issue-details.component';
import { WebtoonReaderComponent } from './_components/reader/webtoon-reader/webtoon-reader.component';
import { ComicReaderComponent } from './_components/reader/comic-reader/comic-reader.component';

import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageSelectorComponent } from './_components/image-selector/image-selector.component';
import { UploaderComponent } from './_components/uploader/uploader.component';
import { StripePaymentComponent } from './_components/stripe-payment/stripe-payment.component'
import { ConfirmationPublishComponent } from './_components/confirmation-publish/confirmation-publish.component';
import { PublishersGridComponent } from './_components/publishers-grid/publishers-grid.component';
import { TopsliderMobileComponent } from './_components/topslider-mobile/topslider-mobile.component';
import { TopsliderDesktopComponent } from './_components/topslider-desktop/topslider-desktop.component';
import { IssueSummaryComponent } from './_components/issue-summary/issue-summary.component';
import { SelectPlanComponent } from './_components/select-plan/select-plan.component';
import { DialogPlanComponent } from './_components/dialog-plan/dialog-plan.component'
import { AgeSettingsComponent } from './_components/age-settings/age-settings.component';
import { PrivacyComponent } from './_components/privacy/privacy.component';
import { TermsComponent } from './_components/terms/terms.component';
import { CommentComponent } from './_components/comment/comment.component';
import { CommentReplyComponent } from './_components/comment-reply/comment-reply.component';
import { ComicsComponent } from './_components/comics/comics.component';
import { ComicDetailComponent } from './_components/comic-detail/comic-detail.component';
import { ComicElementComponent } from './_components/elements/comic-element/comic-element.component';
import { SeriesElementComponent } from './_components/elements/series-element/series-element.component';
import { PublisherElementComponent } from './_components/elements/publisher-element/publisher-element.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ComicViewerComponent } from './_components/comic-viewer/comic-viewer.component';
import { FeedComponent } from './_components/feed/feed.component';
import { FeedPageComponent } from './_components/feed-page/feed-page.component';
import { FeedProfileComponent } from './_components/feed-profile/feed-profile.component';
import { FeedCardComponent } from './_components/feed-modules/feed-card/feed-card.component';
import { FeedNavComponent } from './_components/feed-modules/feed-nav/feed-nav.component';
import { FeedDropdownComponent } from './_components/feed-modules/feed-dropdown/feed-dropdown.component';
import { FeedPostComponent } from './_components/feed-modules/feed-post/feed-post.component';
import { FeedImagePreviewComponent } from './_components/feed-modules/feed-image-preview/feed-image-preview.component';
import { BootstrapComponent } from './_components/_utils/bootstrap/bootstrap.component';
import { FeedRecommendationComponent } from './_components/feed-modules/feed-recommendation/feed-recommendation.component';
import { FeedGalleryComponent } from './_components/feed-modules/feed-gallery/feed-gallery.component';
import { ImageViewerComponent } from './_components/image-viewer/image-viewer.component';
import { UniversalDeviceDetectorService } from './_services';
import { translateBrowserLoaderFactory } from './_loaders/translate-browser.loader';
import { WebtoonsViewerComponent } from './_components/webtoons-viewer/webtoons-viewer.component';
import { ImagesLazyloadModule } from './_components/_utils/lazyload/lazyload.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TransferState } from '@angular/platform-browser';
import { register } from 'swiper/element/bundle';
register();
import { SwiperDirective } from "./_directives/swiper.directive";
import { FeedBookmarksComponent } from './_components/feed-bookmarks/feed-bookmarks.component';
import { DiscoverComponent } from './_components/discover/discover.component';
import { FeedNotFoundComponent } from './_components/feed-not-found/feed-not-found.component';


registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        CommonModule,
        InfiniteScrollModule,
        NgxJsonLdModule,
        ReactiveFormsModule,
        HttpClientModule,
        routing,
        FileUploadModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatIconModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatTabsModule,
        MatDividerModule,
        MatSidenavModule,
        MatSliderModule,
        MatMenuModule,
        MatDialogModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatStepperModule,
        BrowserAnimationsModule,
        NgxHmCarouselModule,
        AdsenseModule.forRoot({
            adClient: 'ca-pub-5874405567752781',
            adSlot: 9334598887,
            adtest: 'off',
        }),
        LazyLoadImageModule,
        TransferHttpCacheModule,
        ImageCropperModule,
        DragDropModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateBrowserLoaderFactory,
                deps: [HttpClient, TransferState]
            },
            defaultLanguage: 'en'
        }),
        OverlayModule,
        ImagesLazyloadModule,
        FontAwesomeModule,

    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        TopbannerComponent,
        TitlesrowComponent,
        PopularTitlesComponent,
        TitleDetailsComponent,
        ExternalLinkComponent,
        TopsliderComponent,
        TitleGenresComponent,
        GraphiteViewerComponent,
        FeedLayoutComponent,
        MainLayoutComponent,
        NoheaderLayoutComponent,
        PublishersComponent,
        ReaderComponent,
        ViewerLayoutComponent,
        PublisherPipePipe,
        HotnewComponent,
        FeedComponent,
        FeedCardComponent,
        PaginatorComponent,
        CreatorsComponent,
        PublisherslistComponent,
        HottitlesComponent,
        LoginUserComponent,
        LoginPageComponent,
        RegisterUserComponent,
        ForgotpasswordComponent,
        ResetPasswordComponent,
        PublisherPageComponent,
        SearchComponent,
        ShortlinksComponent,
        AppStoreRedirectComponent,
        MatureComponent,
        TimeAgoPipe,
        TruncatePipe,
        FeedFormatPipe,
        ViewsFormatPipe,
        OwnadsComponent,
        MaturePopupComponent,
        CommentsComponent,
        RecommendedComponent,
        UploadComponent,
        ProfileDialog,
        ProfilePageComponent,
        IssueDetailsComponent,
        WebtoonReaderComponent,
        ComicReaderComponent,
        UploaderComponent,
        ImageSelectorComponent,
        ConfirmationPublishComponent,
        StripePaymentComponent,
        PublishersGridComponent,
        TopsliderMobileComponent,
        TopsliderDesktopComponent,
        IssueSummaryComponent,
        SelectPlanComponent,
        DialogPlanComponent,
        AgeSettingsComponent,
        PrivacyComponent,
        TermsComponent,
        CommentComponent,
        CommentReplyComponent,
        ComicsComponent,
        ComicDetailComponent,
        ComicElementComponent,
        SeriesElementComponent,
        PublisherElementComponent,
        FeedPageComponent,
        FeedProfileComponent,
        ComicViewerComponent,
        FeedNavComponent,
        FeedDropdownComponent,
        FeedPostComponent,
        FeedImagePreviewComponent,
        BootstrapComponent,
        FeedRecommendationComponent,
        FeedGalleryComponent,
        ImageViewerComponent,
        GoTopButtonComponent,
        StarRatingComponent,
        SwiperDirective,
        WebtoonsViewerComponent,
        FeedBookmarksComponent,
        DiscoverComponent,
        FeedNotFoundComponent,

    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        provideClientHydration(),
        DatePipe,
        TimeAgoPipe,
        TruncatePipe,
        { provide: DeviceDetectorService, useClass: UniversalDeviceDetectorService },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})

export class AppModule { }
