import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../_services';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  term: String;
  termResults: String;
  titles: any;
  loading: Boolean;
  schema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    'url': 'https://graphitecomics.com',
    'potentialAction': {
      '@type': 'SearchAction',
      'target': 'https://graphitecomics.com/s/{query}',
      'query': 'required',
      'query-input': 'name=query'
    }
  };
  holdSearch = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {

    this.titleService.setTitle('Graphite Comics - Search');
    this.metaTagService.updateTag({name: 'description', content: 'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators'});

    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://graphitecomics.com/s' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Graphite Comics: Free manga, webtoons, graphic novels and webcomics.' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.graphitecomics.com/assets/images/site_icon_512.jpg' });

    this.route.queryParams.subscribe(params => {
      const param = params['redirected'];
      if (param) {
        this.holdSearch = true;
      }
    });
    this.titles = [];
    this.route.params.subscribe(routeParams => {
      this.term = routeParams.term;
      if (this.holdSearch !== true) { this.doSearch(this.term); }

      this.titleService.setTitle('Graphite Comics - Search');
      this.metaTagService.updateTag({name: 'description', content: 'Graphite Comics - Search results for ' + this.term});

      this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
      this.metaTagService.updateTag({ property: 'og:url', content: 'https://graphitecomics.com/s/' + this.term });
      this.metaTagService.updateTag({ property: 'og:title', content: 'Graphite Comics: ' + this.term });
      this.metaTagService.updateTag({ property: 'og:description', content: 'Graphite Comics - Search results for ' + this.term });
      this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.graphitecomics.com/assets/images/site_icon_512.jpg' });
    });
    // if(this.holdSearch!==true) this.doSearch(this.term);


  }

  termChanged() {

  }

  doSearch(term) {
    this.holdSearch = false;
    if (!term) { return; }



    this.loading = true;
    this.titles = [];
    this.router.navigate(['/s', term]);
    this.termResults = term;
    this.userService.searchTitle(this.term).subscribe(
      result => {
        this.titles = result;
        this.loading = false;
      },

      error => {
          this.loading = false;
      }
    );


  }
}
