import { Component, OnInit, AfterViewInit, Input, HostListener, ElementRef,
  ViewChild, Renderer2, ViewChildren, QueryList, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, AuthenticationService, WINDOW } from '../../_services';

@Component({
  selector: 'app-topslider',
  templateUrl: './topslider.component.html',
  styleUrls: ['./topslider.component.scss']
})
export class TopsliderComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private userService: UserService,
    private authService: AuthenticationService,
    private renderer: Renderer2) { 
      this.authService.currentUser.subscribe((value) => {
        this.currentUser = value;
      });

    }
  slides: Array<any>;
  gSlides: Array<any>;
  maxheight = 548;
  margintop = 0;
  initial = false;
  isBrowser = false;
  genre;
  currentUser;

  @Input() isPlaceholder: Boolean;
  @Input() genreId: String;
  @ViewChildren('imgslide') imgslides: QueryList<any>;
  @ViewChild('slidesection', { static: true }) container: ElementRef;

  index = 0;
  speed = 8000;
  infinite = true;
  direction = 'right';
  directionToggle = true;
  autoplay = true;

  ngAfterViewInit() {
        const innerHeight = (this.window.innerHeight);
        //this.maxheight = innerHeight / 2;
        this.initial = false;
  }

  ngOnInit() {
    this.isBrowser = this.authService.isWindow();
    if(this.isBrowser){
      this.initializeComponent();
    }
  }

  initializeComponent(){
    const innerHeight = (this.window.innerHeight);
    // let innerWidth = (window.screen.width);
    // this.maxheight = innerHeight / 2;
    this.slides = [];
    this.gSlides = [];
    if (this.genreId) {
      this.userService.featuredTitlesGenre(10, this.genreId).subscribe(result => {
        this.genre=result.data.genre;
        let group=[];
        for (const title of result.data.featuredTitlesGenre) {
          if(title.featuredBanner && title.featuredBanner.url){
            const slide = {
              url: title.featuredBanner.url,
              title: title.name,
              publisher: title.publisher.name,
              link: title.objectId,
              action: title.featuredAction,
              special: title.featuredBannerSpecial,
              wide: title.featuredBannerWide,
              publisherId: title.publisher.objectId,
              featuredActionValue: title.featuredActionValue,
              slug: title.slug,
              age_rating: title.age_rating,
              publisher_slug: title.publisher_slug
            };
            this.userService.titleCanView(slide, this.currentUser);
            group.push(slide);
            this.slides.push(slide);
            if(group.length===2){
                this.gSlides.push(group);
                group=[];
            }
            
          }
      }
      if(group.length>0){
          this.gSlides.push(group);
          group=[];
      }
      this.initial = false;
    });
    } else {
            this.userService.featuredTitles(9).subscribe(result => {
                let group=[];
                for (const title of result.data.featuredTitles) {
                  if(title.featuredBanner && title.featuredBanner.url){
                    const slide = {
                      url: title.featuredBanner.url,
                      title: title.name,
                      publisher: title.publisher.name,
                      link: title.objectId,
                      action: title.featuredAction,
                      special: title.featuredBannerSpecial,
                      wide: title.featuredBannerWide,
                      publisherId: title.publisher.objectId,
                      featuredActionValue: title.featuredActionValue,
                      slug: title.slug,
                      age_rating: title.age_rating,
                      publisher_slug: title.publisher_slug
                    };
                    this.userService.titleCanView(slide, this.currentUser);
                    group.push(slide);
                    this.slides.push(slide);
                    if(group.length===3){
                        this.gSlides.push(group);
                        group=[];
                    }
                  }
              }
              this.initial = false;
              if(group.length>0){
                this.gSlides.push(group);
                group=[];
              }
            });
    }
  }
  initializeComponentOld(){
    const innerHeight = (this.window.innerHeight);
    // let innerWidth = (window.screen.width);
    // this.maxheight = innerHeight / 2;
    this.slides = [];
    if (this.genreId) {
      this.userService.featuredTitlesGenre(9, this.genreId).subscribe(result => {
        this.genre=result.data.genre;
        for (const title of result.data.featuredTitlesGenre) {
          if(title.featuredBanner && title.featuredBanner.url){

            const slide = {
              url: title.featuredBanner.url,
              title: title.name,
              publisher: title.publisher.name,
              link: title.objectId,
              action: title.featuredAction,
              special: title.featuredBannerSpecial,
              wide: title.featuredBannerWide,
              publisherId: title.publisher.objectId,
              featuredActionValue: title.featuredActionValue,
              slug: title.slug,
              age_rating: title.age_rating,
              publisher_slug: title.publisher_slug
            };
            this.userService.titleCanView(slide, this.currentUser);
            // if(slide['hideAge']!==true){
                this.slides.push(slide);
            // }
          }
      }
      this.initial = false;
    });
    } else {
            this.userService.featuredTitles(9).subscribe(result => {
                for (const title of result.data.featuredTitles) {
                  if(title.featuredBanner && title.featuredBanner.url){
                    const slide = {
                      url: title.featuredBanner.url,
                      title: title.name,
                      publisher: title.publisher.name,
                      link: title.objectId,
                      action: title.featuredAction,
                      special: title.featuredBannerSpecial,
                      wide: title.featuredBannerWide,
                      publisherId: title.publisher.objectId,
                      featuredActionValue: title.featuredActionValue,
                      slug: title.slug,
                      age_rating: title.age_rating,
                      publisher_slug: title.publisher_slug
                    };
                     this.userService.titleCanView(slide, this.currentUser);
                    // if(slide['hideAge']!==true){
                      this.slides.push(slide);
                    // }
                  }
              }
              this.initial = false;
            });
    }
  }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {
      const innerHeight = (this.window.innerHeight);
      // this.maxheight = innerHeight / 2;
    }
    @HostListener('window:resize', ['$event'])
    onResize() {
          this.centerImage();
    }


    centerImage() {
      if (!this.imgslides) {
        // return;
      }
      const innerHeight = (this.window.innerHeight);
      // this.maxheight = innerHeight / 2;
      if (this.imgslides && this.imgslides.first && this.container && this.container.nativeElement) {
            const h = this.container.nativeElement.offsetHeight;
            const imgh = this.imgslides.first.nativeElement.offsetHeight;
            if (h < imgh) {
                // this.margintop=(h-imgh)+20;
                this.margintop = 0;
            } else {
              this.margintop = 0;
            }

            this.imgslides.forEach(imgel => {
              this.renderer.setStyle(imgel.nativeElement, 'margin-top', this.margintop + 'px');
            });
            this.isPlaceholder=false;
      }

    }



    getLinkG(title) { 
      if(!title){
        return '';
      }
      if (title && title.action && title.action!==null) {
        switch (title.action) {
          case 'goToPublisher': {
            return '/publisher/' + title.publisher_slug;
            break;
          }
          case 'openURL': {
            return title.featuredActionValue;
            break;
          }
          case 'doNothing': {
            return '/';
            break;
          }
          default: {
            if(title && title.publisher_slug){
              return '/title/' + title.publisher_slug + '/' + title.slug;
            }
            
            break;
          }
        }
      } else {
        return '/title/' + '/' + title.publisher_slug + '/' + title.slug;
      }
  
    }

getImageG(slide) {
    if (!slide) {
      return;
    }
    if (slide.special) {
        return slide.special.url;
    }
    return slide.url;
}
getTitleG(slide) {
    if(slide && slide!==false){
        return slide.title;
    }
    return'';
}
getPublisherG(slide) {
    if(slide && slide!==false){
        return slide.publisher;
    }
    return'';
}
getImage(avatar, index, next=0) {
    let slide=avatar;
    if(next===1){
        if((index+1)<this.slides.length){
             slide=this.slides[index+1];
        }
        else{
            slide=this.slides[0];
        }
    }
    if(next===2){
        if((index+2)<this.slides.length){
             slide=this.slides[index+2];
        }
        else{
            slide=this.slides[index+2-this.slides.length];
        }
    }
    if (!slide) {
      return;
    }
    if (slide.special) {
        return slide.special.url;
    }
    return slide.url;
}
getTitle(avatar, index, next=0) {
    let slide=this.getSlide(avatar, index, next);
    if(slide!==false){
        return slide.title;
    }
    return'';
}
getPublisher(avatar, index, next=0) {
    let slide=this.getSlide(avatar, index, next);
    if(slide!==false){
        return slide.publisher;
    }
    return'';
}
getSlide(avatar, index, next=0) {
    let slide=avatar;
    if(next===1){
        if((index+1)<this.slides.length){
             slide=this.slides[index+1];
        }
        else{
            slide=this.slides[0];
        }
    }
    if(next===2){
        if((index+2)<this.slides.length){
             slide=this.slides[index+2];
        }
        else{
            slide=this.slides[index+2-this.slides.length];
        }
    }
    if (!slide) {
      return false;
    }

    return slide;
}

getBgImage(slide) {
  if (!slide) {
    return;
  }
  if (slide.wide) {
      return slide.wide.url;
  }
  return slide.url;
}

  getLink(avatar, index, next=0) { 
    let title=this.getSlide(avatar,index,next);
    if (title.action) {
      switch (title.action) {
        case 'goToPublisher': {
          return '/publisher/' + title.publisher_slug;
          break;
        }
        case 'openURL': {
          return title.featuredActionValue;
          break;
        }
        case 'doNothing': {
          return '/';
          break;
        }
        default: {
          return '/title/' + title.publisher_slug + '/' + title.slug;
          break;
        }
      }
    } else {
      return '/title/' + '/' + title.publisher_slug + '/' + title.slug;
    }

  }


  goToTitle(title) {
    let link = '';
    if (title.action && title.action!==null) {
      switch (title.action) {
        case 'goToPublisher': {
          link = '/publisher/' + title.publisher_slug;
          break;
        }
        case 'openURL': {
          link = title.featuredActionValue;
          break;
        }
        case 'doNothing': {
          link = '/';
          break;
        }
        default: {
          link = '/title/'  + title.publisher_slug + '/' + title.slug;
          break;
        }
      }
    } else {
      link = '/title/' + title.publisher_slug + '/' + title.slug;
    }
    if (link && link.length > 0) {
      this.router.navigate([link]);
    }
    if (title) {
      if (title.link && title.link.length > 0) {
        this.router.navigate([link]);
      }
    }

  }


  indexChanged(index) {

  }


  indexChangedG(index) {

  }

  toggleDirection($event) {
    this.direction = this.directionToggle ? 'right' : 'left';
  }



}
