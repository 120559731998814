import { AfterViewInit, Component, ElementRef, OnInit, Inject, ViewChild, VERSION } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title, Meta } from '@angular/platform-browser';
import { UserService, AuthenticationService, WINDOW } from '../../_services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types/swiper-options';
import {trigger, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-comic-viewer',
  templateUrl: './comic-viewer.component.html',
  styleUrls: ['./comic-viewer.component.scss'],
  animations: [
    trigger('fade', [ 
      transition('void => *', [
        style({ opacity: 0 }), 
        animate(2000, style({opacity: 1}))
      ]) 
    ])
  ]

})
export class ComicViewerComponent implements OnInit, AfterViewInit {
    @ViewChild('swiper') swiperContainer!: ElementRef<SwiperContainer>;
    @ViewChild('swiperThumbs') swiperThumbs!: ElementRef<SwiperContainer>;

    issueId: string;
    issue: any;
    index: number=0;
    pageCount: number=0;
    currentUser:any;
    deviceInfo: any = null;
    isMobile = false;
    isiOS=false;
    showHud = false;
    isWindow=false;
    isLoaded = false;
    showPages = 1;

    public config: SwiperOptions = {
        direction: 'horizontal',
        keyboard: true,
        mousewheel: true,
        autoplay:false,
        navigation: false,
        centeredSlides: true,
        grabCursor: true,
        zoom: true,
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<ComicViewerComponent>,
        @Inject(WINDOW) private window: Window,
        private userService: UserService,
        private titleService: Title,
        private metaTagService: Meta,
        private authService: AuthenticationService,
        private deviceService: DeviceDetectorService,
        
    ) {
        if ( data ) {
            this.issueId = data;
        }
        this.dialogRef.updateSize('300vw','300vw');
        this.authService.currentUser.subscribe((value) => {
            this.currentUser = value;
        });
        this.deviceInfo = this.deviceService.getDeviceInfo();
        if (this.deviceService.isMobile()) {
            this.isMobile = true;
            this.isiOS=true
        } else if (this.deviceInfo.os === 'IOS') {
            this.isMobile = true;
            this.isiOS=true;
        } else if (this.deviceInfo.os === 'ANDROID') {
            this.isMobile = true;
            this.isiOS=true
        } else {

        }

    }

    public onIndexChange(index: number) {
        let page = this.issue.pages[index];
        this.userService.pageWasDisplayed(page.objectId, this.issue.objectId, this.issue.title.objectId, this.issue.readerType, this.userService.isSubscribed(this.currentUser)).subscribe(result => {
            // NOTHING
        });

        this.index=index;
    }

    ngOnInit() {
        if(this.authService.isWindow()){
            this.isWindow = true;
        }
        if ( this.issueId ) {
            this.userService.getIssue(this.issueId).subscribe(result => {
                this.issue=result;
                this.pageCount=result.pages.length;
                this.userService.issueWasOpened(this.issueId, this.issue.title.objectId, this.issue.readerType, false).subscribe(result => {
                    // NOTHING
                });
                this.isLoaded = true;
            });
        }

    }

    ngAfterViewInit() {
        console.log('ngAfterViewInit');
        if (this.issue) {        
            this.swiperContainer.nativeElement.swiper.activeIndex = this.index;
            this.swiperThumbs.nativeElement.swiper.activeIndex = this.index;
        }
    }

    getPageImage(page) {
        let url= this.userService.getPageImage(page.objectId).replace('.jpf','.jpg');
        return url;
    }

    close() {
        this.dialogRef.close();
    }
/*
    displayHud( $event ) {
        setTimeout(()=>{this.showHud = true;}, 2000);
        this.showHud = false;
    }

    hideHud( $event ) {
        console.log('hide Hud');
        setTimeout(()=>{this.showHud = false;}, 2000);
    }
*/
    next() {
        
        this.swiperContainer.nativeElement.swiper.slideNext();
    }

    prev() {
        this.swiperContainer.nativeElement.swiper.slidePrev();
    }

    zoom() {
        console.log('zoom');
        this.swiperContainer.nativeElement.swiper.zoom.in();
    }

    view( mode ) {
        console.log('view', mode);
       this.showPages = mode == 'single' ? 1 : 2;
    }

    slideChange(swiper: any) {
        this.index = swiper.detail[0].activeIndex;
    }

}
