<div style="width:1px; min-height:450px;">

</div>
<div style="top:0;width:100%;min-height:100%; position:fixed;background-image:url(./assets/images/loading_bg.png); background-repeat: no-repeat; background-position: center;background-size:contain;">
  <div class="login-container">
      <mat-card class="login-card" *ngIf="!submitted">
            <mat-card-header>
                    <div>
                          <div class="slogan">Forgot password?</div>
                          <div class="sub-slogan">Enter username or email address to reset it</div>   
                    </div>      
            </mat-card-header>
            <mat-card-content>
                  <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()" class="login-form">
                      <div class="form-group">
                              <mat-form-field  appearance="outline" class="bgwhite">
                                      <mat-icon matPrefix>person</mat-icon>
                                      <mat-label>Username or email</mat-label>
                                      <input matInput placeholder="Username or email" formControlName="UGCusername" id="UGCusername" name="UGCusername">
                                      <mat-error *ngIf="f.UGCusername.errors && f.UGCusername.errors.required">Username or email  required</mat-error>
                              </mat-form-field>
                      </div>

                      <mat-card-actions>
                                      <button [disabled]="loading" mat-button  class="pink-mat">Reset</button>
                      </mat-card-actions>
                  </form>
          </mat-card-content>
          <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>

          <div style="text-align:center;">
                 <a routerLink="/login" class="btn btn-link">Return to login?</a>
          </div>


</mat-card>
      <mat-card class="login-card" *ngIf="submitted">
            <mat-card-header>
                    <div>
                          <div class="slogan">Link sent</div>
                          <div class="sub-slogan">Check your email and click on link you received</div>   
                    </div>      
            </mat-card-header>

          <div style="text-align:center;">
                 <a routerLink="/login" class="btn btn-link">Return to login?</a>
          </div>


</mat-card>

         

    </div>
</div>
