
<div class="titlesrow"  *ngIf="titles">
  <ng-container *ngIf="vertical===true; else horizontal">
    <h4 class="row-title" >{{rowTitle | translate}}</h4>
    <div class="vertical">
          <div *ngFor="let title of titles; let i=index" [ngClass]="{'in-home':!all}" class="title-container" routerLink="{{getLink(title)}}">
              <div class="image-container" routerLink="{{getLink(title)}}">
                <img  [src]="title.logo?title.logo.url:''">
              </div>
              <div class="text-container">
                
                <h5 routerLink="{{getLink(title)}}">{{title.publisher.name}}</h5>
                <h3 routerLink="{{getLink(title)}}">{{title.name}}</h3>
                <div class="genres" routerLink="{{getLink(title)}}">
                      <div *ngFor="let genre of title.genres" class="genre-container">
                          {{genre.genreName}}
                      </div>
                </div>
                <div class="likes">

                          <img src="/assets/images/icon_eye_views_grey.png"><span>{{title.totalPageViews | viewsFormat}}</span>
                          <img src="/assets/images/icon_comments_small_grey.png"><span>{{title.totalComments | viewsFormat}}</span>
                          <img src="/assets/images/icon_like_small_grey.png"><span>{{title.titleFavoriteCount | viewsFormat}}</span>
                </div>
                <div class="age">
                  <img [src]="getAgeRating(title)">
                </div>
                <div class="read">
                  <img src="/assets/images/icon_button_readnow_pink@2x.png"><span>Read Now</span> 
                </div>
              </div>
          </div>

    </div>
  </ng-container>



  <ng-template #horizontal>
    <h4 class="row-title" >{{rowTitle | translate}}</h4>
    <div class="title-row inslider">
        <swiper-container
        (indexChange)="indexChange($event)"
        [config]="config"
        [(index)]="index"
        (imagesReady)="imagesReady()"
        (lazyImageReady)="lazyImageReady()"
        >
          <div *ngFor="let title of titles; let i=index" [ngClass]="{'in-home':!all}" class="title-container" routerLink="{{getLink(title)}}">
            <a routerLink="{{getLink(title)}}">
              <div class="image-container">
                <img  [src]="title.logo?title.logo.url:''">
              </div>
              <div class="text-container">
                <h5>{{title.publisher.name}}</h5>
                <h3>{{title.name}}</h3>
              </div>
            </a>
          </div>
      </swiper-container>
    </div>
  </ng-template>
</div>
