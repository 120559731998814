<div *ngIf="!isProcessing" style="text-align: right;"><span class="material-icons" (click)="closeDialog()" style="cursor: pointer;">
    close
    </span>
</div>
<div class="payment-container">


    <h3>Subscribe to Graphite Premium</h3>

<div class="login-register-container" *ngIf="loggedin===false">
    <div class="login-container" *ngIf="showLogin===true">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
            <h5>You have to be authentificated or to <a (click)="changeRegister()">create an account</a>  to subscribe</h5>
            <div class="form-group">
                    <mat-form-field  appearance="outline" style=" width: 100%;">
                            <mat-icon matPrefix>person</mat-icon>
                            <mat-label>Username</mat-label>
                            <input matInput placeholder="Username" formControlName="username">
                            <mat-error *ngIf="f.username.errors && f.username.errors.required">Username is required</mat-error>
                    </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field  appearance="outline" style=" width: 100%;">
                        <mat-icon matPrefix>lock</mat-icon>
                        <mat-label>Password</mat-label>
                        <input matInput placeholder="Password" formControlName="password" [type]="hide ? 'password' : 'text'">
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="f.password.errors && f.password.errors.required">Password is required</mat-error>
                </mat-form-field>

        </div>
        <div class="form-group">

                <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

        </div>

        <mat-card-actions>
                      <button [disabled]="loading" mat-raised-button color="primary">Login and continue with payment</button>
                      </mat-card-actions>
        </form>

        <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>

        <div style="text-align:center; margin-top:15px;">
        <a  class="btn btn-link" (click)="changeRegister()">No account? Signup.</a>
        </div>
</div>

<div class="register-component"  *ngIf="showRegister===true">
    <h5>You need an account to subscribe. please register one or <a (click)="changeLogin()">log into</a> an existing account.</h5>
          <form [formGroup]="registerForm" (ngSubmit)="onSubmitRegister()" class="register-form">
            <div class="form-group">
              <mat-form-field  appearance="outline"  style=" width: 100%;">
                      <mat-icon matPrefix>mail</mat-icon>
                      <mat-label>Email</mat-label>
                      <input matInput placeholder="Email" formControlName="email">
                      <mat-error *ngIf="fr.email.errors && fr.email.errors.required">Email is required</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field  appearance="outline"  style=" width: 100%;">
                      <mat-icon matPrefix>person</mat-icon>
                      <mat-label>Username</mat-label>
                      <input matInput placeholder="Username" formControlName="username">
                      <mat-error *ngIf="fr.username.errors && fr.username.errors.required">Username is required</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field  appearance="outline"  style=" width: 100%;">
                        <mat-icon matPrefix>lock</mat-icon>
                        <mat-label>Password</mat-label>
                        <input matInput placeholder="Password" formControlName="password" [type]="hide ? 'password' : 'text'">
                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-hint>Minimum of 8 characters and contains UPPER, lower case and numbers.</mat-hint>
                        <mat-error *ngIf="fr.password.errors && fr.password.errors.pattern">Your password must be a minimum of 8 characters and contains UPPER, lower case and numbers.</mat-error>
                </mat-form-field>
            </div>
            <div style="    padding-top: 20px;
            padding-bottom: 20px;
            font-size: 12px;
            text-align: left;
            width: 100%;
            padding-left: 5px;">
                <mat-checkbox class="check-label" formControlName="iAgree">I agree to the <b><a href="https://insider.graphitecomics.com/terms/" target="blank"> Terms of Use</a>,
                </b>  <b>Privacy <br><a href="https://insider.graphitecomics.com/privacy-policy/" target="blank">Policy</a></b></mat-checkbox>   
                <mat-error *ngIf="fr.iAgree.errors && submitted">You have to agree to te terms and privacy!</mat-error>
                </div>
        <div class="form-group">

                <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

        </div>



        <mat-card-actions>
                      <button [disabled]="loading"  mat-raised-button color="primary">{{ 'Register and continue with the payment' | translate}}</button>
              </mat-card-actions>
        </form>

        <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>

        <div style="text-align:center; margin-top:15px;">
        <a (click)="changeLogin()" style="font-weight: bold;" class="btn btn-link">{{ 'Already have an account? Login here.' | translate }}</a>
        </div>


</div>


</div>
<div [ngClass]="{'invizible':loggedin===false}">
  <div class="form-container" *ngIf="showThankYou!==true">
    
    <div class="user-section" *ngIf="currentUser">
        <div>
            <span class="material-icons">
                person
            </span>  
            <span class="user-text">{{currentUser.username}}</span>
        </div>
        <div>
            <span class="material-icons">
                email
                </span> <span  class="user-text">{{currentUser.email}}</span> 
        </div>
        <p (click)="changeUser()" style="font-weight: bold;margin-top:20px;text-align: center; cursor:pointer;">Subscribe with different account.</p>
    </div>
    <p style="font-weight:400; font-family:Roboto;">
        {{plan_name}}
    </p>
    
    <form (ngSubmit)="doSubscription()" class="checkout">
        <div class="payment-loading" *ngIf="isProcessing">
            <div>
                <div>
                    Payment is processing .....
                </div>
                <div>
                    Please wait
                </div>
                <mat-progress-bar  mode="query" ></mat-progress-bar>
            </div>
               
        </div>
            <div class="fields-container"  [ngClass]="{'form-error':cardError}">
                <div  id="card-info" #cardInfo></div>
                <div class="line-sep" ></div>
                <div style="display: flex;width:100%;">
                    <div  style="flex:1" id="card-expiry" #cardExpiry></div>
                    <div style="border-left:1px solid silver;width:70px;" id="card-cvc" #cardCvc></div>
                </div>                
            </div>
            <button id="submit-button" type="submit" mat-button class="pink-mat" style="margin-bottom: 20px;border-radius:10px;">
                START SUBSCRIPTION
            </button>
            <mat-error id="card-errors" role="alert" *ngIf="cardError" style="margin-top:10px; margin-bottom:10px;">
                <mat-icon style="color: #f44336">cancel</mat-icon>
                &nbsp;{{ cardError }}
            </mat-error>
            <div style="padding: 10px;display:none;">
                After 14-days you will be charged $4.99 renewed monthly. Subscription can be cancelled at any time.
            </div>
            <div style="margin-top:15px;  text-align:center;">
                <img src="./assets/images/powered-by-stripe.png" style="max-width: 140px;">
            </div>
            

    </form>
</div>
<div class="thank-you-container" *ngIf="showThankYou===true">
    <h3>Subscribed with succcess</h3>
    <p style="text-align: center;padding: 0 26px; color: dimgray; font-size: 15px;font-family: Roboto;font-weight: 400;">
        Welcome as a subscriber of Graphite Premium. Your subscription will be billed monthly and can be cancelled at any time. To make changes to your subscription go to your settings in Graphite.
    </p>
    <div style="    color: silver;
    font-size: 15px;
    font-weight: 400;
    font-family: Roboto;">
        <div>
          Username: {{currentUser.username}}
        </div>
        <div>
          Email: {{currentUser.email}}
        </div>
<div class="subscription-details" *ngIf="subscription">
        <div>Status: {{subscription.status}} </div>
        <!-- <div>Trial start: {{subscription.trial_start * 1000 | date:'MMM d, yyyy'}}</div>
        <div>Trial end: {{subscription.trial_end * 1000 | date:'MMM d, yyyy'}}</div> -->
        <div>First Billing date: {{subscription.billing_cycle_anchor * 1000 | date:'MMM d, yyyy'}}</div>
</div>
        <button (click)="closeDialogAndRead()" type="button" mat-button class="pink-mat" style="margin-top:20px;margin-bottom: 20px;border-radius:10px;">
            START READING
        </button>
        <p style="margin-top:15px">Your Graphite Premium subscription works across all platforms. Web, iOS and Android.</p>
    </div>
    
</div>
</div>
   
    
    
</div>
