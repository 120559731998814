<div *ngIf="images.length === 2 || images.length === 4">
    <div *ngFor="let image of images; let index = index; let isFirst = first;" class="row">
        <div class="col-6"><img (click)="launchImageViewer( index )" [src]="image.url" class="thumbnail"></div>
    </div>
</div>
<div *ngIf="images.length === 3" class="row">
    <div class="col-8">
        <img (click)="launchImageViewer( 0 )" [src]="images[0].url" class="thumbnail">
    </div>
    <div class="col-4">
        <div class="row">
            <img (click)="launchImageViewer( 1 )" [src]="images[1].url" class="img-fluid thumbnail">
        </div>
        <div class="row">
            <img (click)="launchImageViewer( 2 )" [src]="images[2].url" class="img-fluid thumbnail">
        </div>
    </div>
</div>

<div *ngIf="images.length === 1">
    <div><img (click)="launchImageViewer( 0 )" [src]="images[0].url" class="thumbnail"></div>
</div>
