import { isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AuthenticationService, UserService, WINDOW } from '../../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss']
})
export class DiscoverComponent {
  public isBrowser: boolean = false;
  public isWindow = true;
  isLoaded: boolean = false;
  currentUser!: any;
  error: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
    private authService: AuthenticationService,
    private userService: UserService,
    private titleService: Title,
    private metaTagService: Meta,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private dialog: MatDialog,

  ) {
    console.log( 'Discover' );
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.authService.currentUser.subscribe((value) => {
        this.currentUser = value;
      });
    }

  }
  ngOnInit() {
    this.route.url.subscribe(([url]) => {
      const { path, parameters } = url;
      console.log('path', '=>', path); // e.g. /products
      console.log('path', '=>', parameters); // e.g. { id: 'x8klP0' }
    });

    this.titleService.setTitle('Discover');
    this.isLoaded = true;
  }
}
