<ngx-json-ld [json]="schema"></ngx-json-ld>
<div class="publisher w-auto">
    <section *ngIf="!error">
        <div class="card publisher-header">
            <div class="background" [ngStyle]="{'background-image':'url(' + (publisher.coverImage?publisher.coverImage.url:'') + ')'}">

            </div>

            <div class="d-flex flex-row mx-1">
                <div class="avatar mx-2 bg-white"><img class="publisher-logo" *ngIf="publisher.logo?.url" [src]="publisher.logo.url" class="rounded-circle img-fluid"></div>
                <div class="mx-1 mt-2">
                    <span class="mx-1"><svg class="feather"><use href="/assets/images/tip-jar.svg" /></svg></span>
                    <span class="mx-1">Tip Jar</span>
                </div>
                <div class="ms-auto mt-2">
                    <span class="mx-1"><svg class="feather"><use href="/assets/images/feather-sprite.svg#twitter" /></svg></span>
                    <span class="mx-1"><svg class="feather"><use href="/assets/images/feather-sprite.svg#instagram" /></svg></span>
                    <span class="mx-1"><svg class="feather"><use href="/assets/images/feather-sprite.svg#facebook" /></svg></span>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex flex-row">
                    <div><h1>{{publisher.name}}</h1></div>
                    <div class="ms-auto my-auto">
                        <span id="views" class="ps-2">
                            <span><svg class="feather"><use href="/assets/images/feather-sprite.svg#eye" /></svg></span>
                            <span class="mx-1">X</span>
                        </span>
                        <span id="comments" class="ps-2">
                            <span><svg class="feather"><use href="/assets/images/feather-sprite.svg#message-circle" /></svg></span>
                            <span class="mx-1">Y</span>
                        </span>
                        <span id="likes" class="ps-2">
                            <span><svg class="feather"><use href="/assets/images/feather-sprite.svg#heart" /></svg></span>
                            <span class="mx-1">Z</span>
                        </span>
                    </div>
                </div>
                <div class="text-truncate">{{publisher.description}}</div>
                <div class="my-2">
                    <span class="feather-text me-2"><mat-icon>calendar_today</mat-icon></span>
                    <span class="feather-text me-2">Joined </span>
                    <span *ngIf="publisher.link">
                        <span class="feather-text me-2"><a href="{{publisher.link}}" target="_blank" class="publisher-link"><svg class="feather"><use href="/assets/images/feather-sprite.svg#external-link" /></svg></a></span>
                        <span class="feather-text me-2"><a href="{{publisher.link}}" target="_blank" class="publisher-link">WEBSITE</a></span>
                    </span>
                </div>
            </div>
        </div>

        <div  *ngIf="!error" class="main-container p-2">

            <div class="titles-list">
                <app-hottitles [isPlaceholder]="isPlaceholder" [titles]="titles" [nopublisher]="true"></app-hottitles>
            </div>
        </div>
    </section>

    <section *ngIf="error" class="error-found">
        <h2>{{ 'Publisher not found!' | translate }}</h2>
    </section>
</div>
