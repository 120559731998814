<ngx-json-ld [json]="schema"></ngx-json-ld>
<ng-container   *ngIf="issue">
  <app-webtoon-reader [prevIssue] = "prevIssue"  [nextIssue] = "nextIssue" [navprev] = "navprev"  [navnext] = "navnext" [neighbours] = "neighbours" [issue]="issue" *ngIf="isWebtoon()===true; else comicsreader"></app-webtoon-reader>

  <!-- Webtoon -->
  <ng-template #comicsreader>
    <app-comic-reader [prevIssue] = "prevIssue"  [nextIssue] = "nextIssue" [navprev] = "navprev"  [navnext] = "navnext" [neighbours] = "neighbours" [issue]="issue" *ngIf="isWebtoon()===false && isiOS===false; else comicsreaderold"></app-comic-reader>
  </ng-template>

<ng-template #comicsreaderold >
  <div  class="reader-container isIos">
    <!-- Comics & Manga -->
      <div class="graphite-viewer">
        <div class="swiper-viewer" *ngIf="issue">
          <swiper-container  #comicSwiper [config]="config" [(index)]="index" (imagesReady)="imagesReady()" (lazyImageReady)="lazyImageReady()" (indexChange)="onIndexChange($event)">
            <div *ngIf="readerType==='jmanga'"  >
              <ng-container *ngTemplateOutlet="extrapage;"></ng-container>
            </div>
            <div *ngFor="let slide of issue.pages; let i=index">
              <div class="swiper-zoom-container" (click)="zoomIn()">
                <img  [src]="getPageImage(slide,i)" class="slide-img" (onLoad)="slideLoaded(i, $event)" (load)="slideLoaded(i, $event)" [ngClass]="{'noblur':loadarr[i]}">
              </div>
            </div>
            
            <div *ngIf="readerType!=='jmanga'">
              <ng-container *ngTemplateOutlet="extrapage;"></ng-container>
            </div>           
          </swiper-container>
        </div>
        <ng-container *ngTemplateOutlet="navigation"></ng-container>
      </div>  









<!-- Last extra page at first for jmanga -->
<ng-template #extrapage>
  <div class="swiper-slide-wrapper" *ngIf="active_subscription_or_preview; else summary" >
    <div class="swiper-zoom-container" >
      <div class="scrollable-summary">
        <div class="like-container">
            <span>{{ 'Discover the rest of the story on Mobile with Graphite Premium' | translate }}</span>
        </div>
        <div class="mobile-apps-container">
          <div class="app-store">
              <a (click)="bannerClick('iphone')" href="https://apps.apple.com/us/app/graphite-comics-get-drawn-in/id1367730938" target="_blank">
                      <img  src="./assets/images/appstore_small.png">
              </a>
          </div>
          <div class="graphite-logo">
              <a routerLink="/" target="_blank">
                      <img  src="./assets/images/graphite_logo.png">
              </a>
          </div>
          <div class="google-play">
              <a (click)="bannerClick('android')"  href="https://play.google.com/store/apps/details?id=com.graphite.graphitecomics&hl=en" target="_blank">
                      <img  src="./assets/images/goolgeplay_small.png">
              </a>
          </div>
          <!-- <button mat-button class="pink-mat small-round menu-button-blue">
            {{ 'GET GRAPHITE PREMIUM' | translate }}
          </button> -->
      </div>
      <app-comments [issue]="issue.objectId"></app-comments>
      </div>
    </div>
  </div>
  <ng-template #summary>

    <div  class="summary-page">
      <div (click)="navigate(nextIssue)" class="next-issue">
          <div class="next-text">
            <div>NEXT ISSUE</div>
            <div>{{neighbours?.nextIssue?.name}}</div>
          </div>
          <div class="next-image-container">
            <img src="{{neighbours?.nextIssue?.thumb?.url}}" >
          </div>
      </div>
      <div (swipe)="onSwipe($event)" class="next-issue-hand">
            <div class="next-text">
              Next <br>
            Issue
            </div>
      </div>
      <div class="issue-card">
          <div class="issue-container">
              <div class="thumb">
                <img src="{{issue?.thumb?.url}}" >
              </div>
              <div class="title-details">
                  <h4>{{issue.title.name}}</h4>
                  <h5>{{issue.name}}</h5>
                  <p>{{issue.description}}</p>
              </div>              
          </div>
          <div class="like-band">
              <div class="icons-container">
                    <div class="icon-wrapper">
                      <div><img src="./assets/images/complete_comic_icon_comment@2x.png"></div>
                      <div>{{issue.commentCount}}</div>
                    </div>
                    <div class="icon-wrapper">
                      <div><img src="./assets/images/complete_comic_icon_like@2x.png"></div>
                      <div>{{issue.issueLikeCount}}</div>
                    </div>
                    <div class="icon-wrapper">
                      <div><img src="./assets/images/complete_comic_icon_share@2x.png"></div>
                      <div>share</div>
                    </div>
                  
                  
              </div>
                    <div class="subscribe-wrapper">
                      <div>SUBSCRIBE</div>
                    </div>
              
              
          </div>
      </div>
      <hr class="thick-line">
      <app-comments [issue]="issue.objectId"></app-comments>

      <hr class="thick-line">
      <!-- <app-external-link *ngIf="issue.title?.externalLink" class="mobile-only" [externalLink]="issue.title.externalLink"></app-external-link> -->
      <!-- <app-ownads style="max-width:100%;"  [adId]="'summary-ads'" [vertical]="true"></app-ownads> -->

      <div style="flex-direction: column;">

        <app-recommended [rowTitle]="'Recommended for you'" [titleId]="issue.title.objectId" [vertical]="true"></app-recommended>
        <div class="like-container">
            <div class="like-el">
              <img src="./assets/images/webtoon_read_like@2x.png">
            </div>

        </div>

        <div class="mobile-apps-container">
            <div style="max-width: 30%;">
                <a (click)="bannerClick('iphone')" href="https://apps.apple.com/us/app/graphite-comics-get-drawn-in/id1367730938" target="_blank">
                  <img  src="./assets/images/appstore_small.png">
                </a>
            </div>
            <div style="margin-left: 15px; margin-right: 15px; max-width: 33%;    height: 30px;">
                <a routerLink="/" target="_blank">
                  <img  src="./assets/images/graphite_logo.png">
                </a>
            </div>
            <div style="max-width: 30%;">
                <a (click)="bannerClick('android')" href="https://play.google.com/store/apps/details?id=com.graphite.graphitecomics&hl=en" target="_blank">
                  <img  src="./assets/images/goolgeplay_small.png">
                </a>
            </div>
        </div>
      </div>
    </div>

  </ng-template>
 
</ng-template>




<ng-template #navigation>
  <div  *ngIf="issue" #navContainers class="top-bar " [ngClass]="{'showHeader':showHeader, 'showFirst': showFirst}" (mouseover)="displayHeader()">
    <div [style.display]="showHeader?'block':'none'" class="top-bar-content">
        <div class="link-back" routerLink="/title/{{issue.title.publisher_slug}}/{{issue.title.slug}}">
          <span  class="webtoon-to-title">{{ issue.title.name }}</span>
        </div>
        <div class="vol-nav" title="Page {{ currentPage+1 }}">
          <div>
              <img style="max-height: 25px;" src="assets/images/button-previous-comic_2x.png" *ngIf="navprev" class="material-icons nav-read" (click)="navigate(prevIssue)">
          </div>
          <div class="issueName">
            #{{issue.number}} - {{issue.name}}
          </div>
          <div >
            <img style="max-height: 25px;" src="assets/images/button-next-comic_2x.png" *ngIf="navnext" class="material-icons nav-read" (click)="navigate(nextIssue)">
          </div>
        </div>

            <div class="page-number" style="display:none;" >
                 {{(pageCount>(index+1))?index+1:pageCount}}/{{pageCount}}
            </div>
              <div routerLink="/" class="reader-mobile-logo">
                <img src="./assets/images/graphy.png">
                </div>
                <div routerLink="/" class="reader-desktop-logo">
                  <img src="./assets/images/logo_graphite_comcis_small_2x.png">
                </div>

                <div class="page-slider-container" *ngIf="index<pageCount-1">
                  <div class="slider-number left">{{readerType==='jmanga'?pageCount:newIndex||sliderValue}}</div>
                  <mat-slider style="height:40px;"
                  class="page-slider"
                  (change)="movePage($event)"
                  (input)="moveSlide($event)"
                  [disabled]="disabled"
                  [invert]="invert"
                  [max]="max"
                  [min]="min"
                  [step]="step"
                  [thumbLabel]="thumbLabel"
                  [tickInterval]="tickInterval"
                  [(ngModel)]="sliderValue"
                  [vertical]="vertical">
                </mat-slider>
                <div class="slider-number right">{{readerType==='jmanga'?newIndex||sliderValue:pageCount}}</div>
              </div>
        </div>
    </div>
</ng-template>







<div *ngIf="!(currentUser?.subscription?.status==='active')"  [style.opacity]="showLeftAd===true?1:0" id="amznLeft" >
  <div style="text-align: center;">
    <span class="material-icons link-pointer closead" (click)="closeLeftAd()">
    highlight_off
    </span></div>
  </div>
  <div *ngIf="!(currentUser?.subscription?.status==='active')"  [style.opacity]="showRightAd===true?1:0" id="amznRight" >
    <div  style="text-align: center;">
      <span class="material-icons link-pointer closead"  (click)="closeRightAd()">
      highlight_off
      </span></div>
      <app-ownads *ngIf="!(currentUser?.subscription?.status==='active')" [which]="'google'"></app-ownads>
  </div>
  
  
  



</div>
<app-ownads *ngIf="!(currentUser?.subscription?.status==='active')" class="bottom"  style="text-align:center;"></app-ownads>
</ng-template>


<!-- <div *ngIf="isZoom===true" style="position: fixed; width:100%; height:100%; top:0; left:0;    z-index: 9990; background-color:white;">
      <img [src]="currentImage" *ngIf="currentImage" id="zoomedimage">
</div> -->


<!-- <div  class="zoomButtons">
  <img (click)="zoomIn()" src="/assets/images/icon_reader_zoomin_2x.png" >  
  <img (click)="zoomOut()" src="/assets/images/icon_reader_zoomout_2x.png" >  
</div>  -->

  







<!-- 
<ng-container *ngIf="!showHeader">
  <app-ownads [style.display]="!showHeader?'block':'none'"></app-ownads>
</ng-container>  -->

</ng-container>
