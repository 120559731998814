import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss']
})
export class ExternalLinkComponent implements OnInit {
  @Input() externalLink: any;
  isOnlyUrl = true;
  website = null;
  facebook = {
      'icon': '/assets/images/external_link/external_link_icon_facebook.png',
      'bgColor': 'rgb(69,97,157)',
      'textColor': 'white',
      'text': 'Visit us on Facebook'
    };
  twitter = {
      'icon': '/assets/images/external_link/external_link_icon_twitter.png',
      'bgColor': 'rgb(29,161,242)',
      'textColor': 'white',
      'text': 'Follow us on Twitter'
    };
  patreon = {
      'icon': '/assets/images/external_link/external_link_icon_patreon.png',
      'bgColor': 'rgb(249,104,84)',
      'textColor': 'white',
      'text': 'Support me on Patreon'
    };
  kickstarter = {
      'icon': '/assets/images/external_link/external_link_icon_kickstarter.png',
      'bgColor': 'rgb(90,207,95)',
      'textColor': 'white',
      'text': 'Support our Kickstarter'
    };
  instagram = {
      'icon': '/assets/images/external_link/external_link_icon_instagram.png',
      'bgColor': 'rgb(255,48,108)',
      'textColor': 'white',
      'text': 'Follow us on Instagram'
    };
  linktree = {
    'icon': '/assets/images/external_link/external_link_icon_linktree.png',
    'bgColor': 'rgb(55,217,151)',
    'textColor': 'white',
    'text': 'Discover more about this title'
  };
defaultlink = {
  'icon': '/assets/images/external_link/external_link_icon_chevron.png',
  'bgColor': 'rgb(0,116,255)',
  'textColor': 'white',
  'text': 'Discover more about this title'
};
  constructor(
  ) {


  }
  ngOnInit() {
    if (this.externalLink.displayText !== undefined) {
      this.isOnlyUrl = false;
    } else {
      this.website = this.detectWebsite(this.externalLink.url);
    }

  }

  protocolFix(url) {
    if (url.search(/^http[s]?\:\/\//) === -1) {
        return 'https://' + url;
    }
    return url;
  }

  detectWebsite(url) {
    if (url.includes('patreon')) { return this.patreon; }
    if (url.includes('facebook')) { return this.facebook; }
    if (url.includes('twitter')) { return this.twitter; }
    if (url.includes('instagram')) { return this.instagram; }
    if (url.includes('kickstarter')) { return this.patreon; }
    if (url.includes('linktr.ee')) { return this.linktree; }

    return this.defaultlink;
  }
}
