export class User {
    id: number;
    username: string;
    password: string;
  //  firstName: string;
  //  lastName: string;
    sessionToken: string;
    profileImage = './assets/images/avatar.png';
    languages: any;
    max_age_rating: string;
    subscription: any;

  //  constructor(){
    //  this.profileImage = User.
  //  }

//    get profileImage(){
//      return this.profileImage || "./assets/images/avatar.png";
//    }
}
