import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UserService, AuthenticationService, WINDOW } from '../../_services';
import { fromEvent } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-webtoons-viewer',
  templateUrl: './webtoons-viewer.component.html',
  styleUrls: ['./webtoons-viewer.component.css']
})
export class WebtoonsViewerComponent implements OnInit, AfterViewInit {
  @Input() issueId: string;
  @ViewChild('slides_container', {static: true}) container : ElementRef;
  winHeight: number;
  issue: any;
  showFirst:    boolean;
  countLoaded:  any;
  allpages:     any;
  defaultImage: any;
  index:        number;

  calculationsCompleted = false;
  lasti                 = 0;
  lastTop               = 0;
  currentPage           = 0;
  y                     = 0;
  firstLoaded           = 3;
  loadBefore            = 7;
  firstLoadedThumb      = 20;
  loadBeforeThumb       = 15;
  scroll                = false;

  max:             any;
  pageCount:       any;
  active_subscription_or_preview: boolean;
  pageWidth:number        = 800;
  pageHeight: number;
  isWindow: boolean        = false;
  currentUser:     any;
  isLoaded: boolean = false;

  constructor(
    @Inject(WINDOW) private window: Window,
    private userService: UserService,
    private authService: AuthenticationService,
  ) {
    if(this.authService.isWindow()){
      this.authService.currentUser.subscribe((value) => {
          this.currentUser = value;
      });
      fromEvent(this.window, 'scroll').pipe(
          tap(() => this.scroll = true),
          debounceTime(100)
      ).subscribe((event) => {
          this.scroll = false;
          this.y = this.window.scrollY;
          this.findImageByScroll();
      });
      fromEvent(this.window, 'resize').pipe(
          debounceTime(100)
      ).subscribe((event) => {
          this.reinitAll();
      });
    }
  }
  ngOnInit() {    
    if(this.authService.isWindow()){
      this.isWindow = true;
    }
    if ( this.issueId ) {
        this.userService.getIssue(this.issueId).subscribe(result => {
            this.issue=result;
            this.pageCount=result.pages.length;
            
            /*
            this.userService.issueWasOpened(this.issueId, this.issue.title.objectId, this.issue.readerType, false).subscribe(result => {
                // NOTHING
            });
            */
            this.initViewer();            
        });
    }

  }

  ngAfterViewInit() {
    
    //this.pageWidth=this.container.nativeElement.offsetWidth;
    //console.log(this.pageWidth);
  }

  pagePreLoaded(evt, index) {
    // Not currently in use, leaving for reference
    //console.log('pagePreLoaded', index);
    // const page = this.issue.pages[index];
    // page.height = evt.path[0].height;
    // page.height = evt.target.height;
    // this.calculateImages();
  }

  @HostListener('window:scroll', ['$event']) onScroll(e) {

    const doch = document.documentElement.scrollHeight;
    if (this.winHeight !== doch) {
        this.winHeight = doch;
        return;
    }

    this.showFirst = false;

  }


  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event) {
    this.reinitAll();
  }

  reinitAll(){
    this.calculationsCompleted=false;
    this.lasti= 0;
    this.initViewer();
  }

  pageLoaded(page, evt) {
    if (evt === true) {
        page.loaded = true;
    }
    if (page.displayed) { return; }

    this.userService.pageWasDisplayed(page.objectId, this.issue.objectId, this.issue.title.objectId, this.issue.readerType, this.userService.isSubscribed(this.currentUser), page.aspectRatio).subscribe(result => {
        page.displayed = true;
    });
  }

  initViewer() {
    this.pageWidth = this.container.nativeElement.offsetWidth;
    console.log('pageWidth', this.pageWidth);
    if(this.pageWidth>800){
      this.pageWidth=800;
    }
    for (let i = 0 ; i < this.issue.pages.length; i++) {
      this.issue.pages[i].height = Math.floor( (this.pageWidth) * this.issue.pages[i].aspectRatio);
    }
    this.calculateImages();
    for (let i = 0 ; i < this.issue.pages.length; i++) {
      this.issue.pages[i].src     = this.userService.getPageImageJPG(this.issue.pages[i].objectId);
      this.issue.pages[i].thumb   = this.userService.getThumbnail(this.issue.pages[i].objectId);
      this.issue.pages[i].isThumb = i <= this.firstLoaded ? false : true;
      this.issue.pages[i].full    = i <= this.firstLoaded ? true : false;
    }
    this.isLoaded = true;
  }

  calculateImages() {
    if (this.calculationsCompleted === true) {
      return;
    }
    if (this.lasti === 0) {
      this.issue.pages[0].top = 0;
      this.lasti = 1;
    }
    for (let i = this.lasti; i < this.issue.pages.length; i++) {
      this.issue.pages[i].top = this.issue.pages[i - 1].top + this.issue.pages[i - 1].height;
      this.lastTop = this.issue.pages[i].top;
      if (!this.issue.pages[i].height) {
        break;
      }
      this.lasti = i;
      if (i === (this.issue.pages.length - 1)) {
        this.calculationsCompleted = true;
        this.lastTop = this.issue.pages[this.lasti].top +  this.issue.pages[this.lasti].height;

        if (this.y === 0 && this.window.scrollY > 0) {
          this.y = this.window.scrollY;
        }
        this.findImageByScroll();
      }
    }
  }

  loadAround(index) {
    //console.log('loadAround', index);
  }

  findImageByScroll() {
    if (this.y < this.lastTop) {
        // search for image in that position and load x images after it
        // find position
        const index = 0;
        for (let i = 0; i < this.issue.pages.length; i++) {
          if (this.issue.pages[i].top < this.y && (this.issue.pages[i].top + this.issue.pages[i].height) > this.y) {
            this.currentPage = i;
            this.pageLoaded(this.issue.pages[this.currentPage], true);
            this.loadAround(this.currentPage);
            break;
          }

        }

    }
  }

  getPageImage(page) {
    let url = this.userService.getPageImage(page.objectId).replace('.jpf','.jpg');
    return url;
  }

}
