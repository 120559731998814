<div class="select-plan">

    <div class="header-plans">
        <h2>SELECT YOUR PLAN ON GRAPHITE PREMIUM</h2>
        <h3>All plans include ad-free full access to all exclusive, premium and age-restricted content on web , iOS and Android </h3>
    </div>

    <div class="plans">
            <div clasS="plan-left">
                <div class="plan-header">
                    <h2><span class="price">$2.99 </span><span class="permo">/mo</span></h2>
                    <h3>Billed monthly</h3>
                    <hr>                    
                <div class="plan-description">
                    <p>
                        Full access to all premium and exclusive content
                    </p>
                    <p>
                        One subscription to Graphite web, iOS and Android
                    </p>
                    <p>
                        No annoying ads
                    </p>       
                </div>
                 
                </div>
                <div class="plan-bottom">
                    <p>
                        <button  (click)="checkout(1, '$2.99 1mo Graphite Premium subscription renewed monthly')"  mat-button class="pink-mat">SELECT PLAN</button>
                    </p>
                    <div class="terms">TERMS OF SERVICE</div> 
                </div>

            </div>
            <div clasS="plan-right">
                <div class="plan-header">
                        <h2><span class="price">$1.67 </span><span  class="permo">/mo</span></h2>
                        <h3>19.99 billed annually</h3>
                        <hr>                    
                        <div class="plan-description">
                            <p>
                                <span class="save40">Save 40%</span><br>
                                <span>with an annuall plan</span>
                            </p>
                        </div>
                </div>
           
                <div class="plan-bottom">
                        <p>
                            <button (click)="checkout(2, '$19.99 12mo Graphite Premium subscription renewed annually')"  mat-button class="pink-mat">SELECT PLAN</button>
                            
                        </p>
                        <div class="terms">TERMS OF SERVICE</div>                    
                </div>

            </div>
    </div>
</div>
