<ngx-json-ld [json]="schema"></ngx-json-ld>
<div style="
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 789px;
    width: 100%;
    overflow: hidden;
    margin-top:69px;
    z-index:-1;
">
    <div class="bg-blur" *ngIf="isTitleLoaded===true" [style.backgroundImage]="'url('+(bannerImage)+')'"></div>
    <div class="bg-blur" *ngIf="isTitleLoaded===false" style="background-color: silver;"></div>
</div>
<div class="background-pic">
    <div class="gradient-back" style="max-width: 100%;"></div>
    <img  *ngIf="isTitleLoaded===true" [src]="(bannerImage?bannerImage:'')" style="width:1050px;" class="desktop-banner"/>
    <img  *ngIf="isTitleLoaded===true" [src]="mBannerImage" style="width:1050px;" class="mobile-banner"/>
    <svg  *ngIf="isTitleLoaded===false" viewBox="0 0 800 600" preserveAspectRatio="xMidYMin slice"  style="width:1050px;">
        <rect width="100%" height="100%" fill="gray" />
    </svg>
</div>
<app-topbanner [isPlaceholder]="isTitleLoaded===false" *ngIf="!error" [image]="bannerImage" [name]="name" [readerType]="readerType" [publisher]="publisher" [genres]="genres" [objTitle]="title"></app-topbanner>
    <div class="title-details">
        <div *ngIf="!error" class="main-container">
                    <!-- <app-volume-nav *ngIf="issues" [titleIssues]="issues" (onChangeVol)="volChanged($event)"></app-volume-nav> -->
            <!-- if webtoon or webcomics -->
            <app-external-link *ngIf="title?.externalLink" class="mobile-only" [externalLink]="title.externalLink"></app-external-link>
            <div  style="display:inline-block; width:100%;">
                <div class="left-side" *ngIf="isPlaceholder===false">
                    <!-- Top tabs -->
                    <div *ngIf="issues?.length===0" style="text-align: center;margin-top: 30px;">
                        No issues yet!
                    </div>
                    <div class="volume-nav">
                        <mat-tab-group (selectedTabChange)="volumeChanged($event)">
                            <mat-tab *ngFor="let volume of volumes">
                    <ng-template mat-tab-label>
                      <div class="volume-title">{{volume?.title}}</div>
                      <div class="issues-count" *ngIf="volume?.issues">{{volume.issues}} {{issueLabel | translate}}</div>
                    </ng-template>
                  </mat-tab>
                </mat-tab-group>
                <img (click)="changeSort(false)" *ngIf="sortasc===true && showInfo===false && !showPagination" class="sort-ico" src="assets/images/button_sort_ascending@3x.png">
                <img (click)="changeSort(true)" *ngIf="sortasc===false && showInfo===false  && !showPagination" class="sort-ico" src="assets/images/button_sort_descending@3x.png">
              </div>
              <div *ngIf="showInfo===false && isVolume===false" style="padding-bottom: 30px;">
                  <div class="issue-row" [class.webtoon-row]="isWebToonOrWebComic(issue.readerType)===true"  *ngFor="let issue of pageOfIssues">
                    <!--   ALL ISSUES -->
                    <div class="issue-item">
                              <a *ngIf="issue.lockSubscription===true; else nosubscription" class="image-wrapper pointer-link" (click)="showAlertIssue(issue)">
                                <img *ngIf="isWindow" [defaultImage]="defaultImage" [lazyLoad]="issue.thumb?issue.thumb.url:defaultImage" [offset]="'100'">
                                <img *ngIf="!isWindow" [src]="issue.thumb?issue.thumb.url:defaultImage" >
                                <img *ngIf="issue.lockedMature===true"  class="ma-overlay" src="./assets/images/icon_age_rating_MA@3x.png">
                            </a>
                            <ng-template #nosubscription>
                                <a  *ngIf="issue.lockedAge!==true; else ageLocked" class="image-wrapper pointer-link"  routerLink="/issue/{{issue.publisher_slug}}/{{issue.title_slug}}/{{issue.slug}}">
                                    <img *ngIf="isWindow" [defaultImage]="defaultImage" [lazyLoad]="issue.thumb?issue.thumb.url:defaultImage" [offset]="'100'">
                                    <img *ngIf="!isWindow" [src]="issue.thumb?issue.thumb.url:defaultImage" >
                                    <img *ngIf="issue.lockedMature===true"  class="ma-overlay" src="./assets/images/icon_age_rating_MA@3x.png">
                                </a>
                                <ng-template #ageLocked>
                                    <a  class="image-wrapper pointer-link"  (click)="showAlertIssue(issue)">
                                        <img *ngIf="isWindow" [defaultImage]="defaultImage" [lazyLoad]="issue.thumb?issue.thumb.url:defaultImage" [offset]="'100'">
                                        <img *ngIf="!isWindow" [src]="issue.thumb?issue.thumb.url:defaultImage" >
                                        <img *ngIf="issue.lockedMature===true"  class="ma-overlay" src="./assets/images/icon_age_rating_MA@3x.png">
                                    </a>                                
                                </ng-template>    
                            </ng-template>

                            <div  class="hot-right">
                              <div class="top-texts">
                                  <h2 class="name-row">
                                    <span *ngIf="issue.lockSubscription===true" class="locked"><img src="/assets/images/icon_locked_small_grey_2x.png"></span>
                                    <a *ngIf="issue.lockSubscription===true || issue.lockedAge===true"  (click)="showAlertIssue(issue)" class="issue-title">{{issue.number}}-{{issue.name}}</a>
                                    <a *ngIf="issue.lockSubscription!==true" routerLink="/issue/{{issue.publisher_slug}}/{{issue.title_slug}}/{{issue.slug}}" class="issue-title">{{issue.number}}-{{issue.name}}</a>
                                    <span style="display:none; float:right; color:#828282; font-size:15px; font-weight:400;">{{issue.createdAt | date}}</span>
                                  </h2>
                                  <div class="views">
                                    <!--<span>
                                      {{issue.pages.length}} pages
                                    </span>-->
                                    <div>
                                      
                                      <span>
                                        <img  class="icon" src="assets/images/icon_like_small_grey.png">
                                        
                                      </span>

                                      <span>
                                        {{issue.issueLikeCount | viewsFormat}}
                                      </span>
                                      <span style="margin-left: 30px;">
                                          <img class="icon" style="margin-left:-5px;" src="assets/images/icon_comments_small_grey.png">
                                      </span>

                                      <span>
                                        {{(issue.commentCount?issue.commentCount:0) | viewsFormat}}
                                      </span>
                                    </div>
                                    <div  *ngIf="issue.lockSubscription===true" class="premium" (click)="showAlertIssue(issue)" >
                                      <span class="text">
                                        <span  *ngIf="issue.lockSubscription===true && getUnlockPreview(issue)==='readwithpremium'">{{ 'Unlock With' | translate }}</span>
                                        <img src="assets/images/logo_graphite_premium_small_2x.png">
                                      </span>
                                    </div>

                                    <!-- <div  *ngIf="getUnlockPreview(issue)==='exclusivepreview'" class="premium">
                                      <span class="text">
                                        <span >{{ 'PREVIEW' | translate }}</span>
                                      </span>
                                    </div> -->


                                    <div *ngIf="issue.readerType === 'comic'; then comicView; else issueView"></div>
                                    <ng-template #comicView>
                                        <div  routerLink="/view/{{issue.objectId}}"  *ngIf="issue.lockSubscription!==true && issue.lockedAge!==true; else readAgeLocked" class="premium">
                                            <span class="text">
                                                <span >{{ 'READ NOW' | translate }}</span>
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template #issueView>
                                        <div  routerLink="/issue/{{issue.publisher_slug}}/{{issue.title_slug}}/{{issue.slug}}"  *ngIf="issue.lockSubscription!==true && issue.lockedAge!==true; else readAgeLocked" class="premium">
                                            <span class="text">
                                                <span >{{ 'READ NOW' | translate }}</span>
                                            </span>
                                        </div>
                                    </ng-template>
                                    <ng-template #readAgeLocked>
                                        <div  *ngIf="issue.lockedAge===true" routerLink="/register"   class="premium">
                                          <span class="text">
                                            <span >{{ 'SIGN IN' | translate }}</span>
                                          </span>
                                        </div>                                      
                                    </ng-template>

                                </div>
                                <h5  *ngIf="!issue.showMore&&issue.description" class="description">
                                  <span (click)="openIssue(issue)">{{issue.description | truncate:180}}</span>  <a (click)="issue.showMore=true" *ngIf="issue.description.length>=180">{{ 'read more' | translate }}</a>
                                </h5>
                                <h5 *ngIf="issue.showMore" class="description">
                                    <span  (click)="openIssue(issue)">{{issue.description| translate}}</span> <a (click)="issue.showMore=false">{{ 'read less' | translate }}</a>
                                </h5>
                              </div>
                            </div>
                          <div class="right-points">
                            <a routerLink="{{issueURL(issue)}}">
                              <mat-icon>more_vert</mat-icon>x`
                            </a> 
                          </div>
                        </div>
                      
                  </div> 
                  <app-paginator  *ngIf="issues?.length > 20 && showPagination===true" [items]="issues" [initialPage]="initialPage" (changePage)="onChangePage($event)"></app-paginator>
              </div>
              <div *ngIf="showInfo===false && isVolume===true"  style="padding-bottom: 30px;">
                <!--- ISSUES IN VOLUMES  -->
                      <div class="issue-row" *ngFor="let issue of volumeIssues" >
                        <div class="issue-item">
                        
                                <a *ngIf="issue.lockSubscription===true; else nosubscription" class="image-wrapper pointer-link" (click)="showAlertIssue(issue)">
                                  <img *ngIf="isWindow" [defaultImage]="defaultImage" [lazyLoad]="issue.thumb?issue.thumb.url:defaultImage" [offset]="'100'">
                                  <img *ngIf="!isWindow" [src]="issue.thumb?issue.thumb.url:defaultImage" >
                                  <img *ngIf="issue.lockedMature===true"  class="ma-overlay" src="./assets/images/icon_age_rating_MA@3x.png">
                              </a>
                              <ng-template #nosubscription>
                                  <a  *ngIf="issue.lockedAge!==true; else ageLocked" class="image-wrapper pointer-link"  routerLink="/issue/{{issue.publisher_slug}}/{{issue.title_slug}}/{{issue.slug}}">
                                      <img *ngIf="isWindow" [defaultImage]="defaultImage" [lazyLoad]="issue.thumb?issue.thumb.url:defaultImage" [offset]="'100'">
                                      <img *ngIf="!isWindow" [src]="issue.thumb?issue.thumb.url:defaultImage" >
                                      <img *ngIf="issue.lockedMature===true"  class="ma-overlay" src="./assets/images/icon_age_rating_MA@3x.png">
                                  </a>
                                  <ng-template #ageLocked>
                                      <a  class="image-wrapper pointer-link"  (click)="showAlertIssue(issue)">
                                          <img *ngIf="isWindow" [defaultImage]="defaultImage" [lazyLoad]="issue.thumb?issue.thumb.url:defaultImage" [offset]="'100'">
                                          <img *ngIf="!isWindow" [src]="issue.thumb?issue.thumb.url:defaultImage" >
                                          <img *ngIf="issue.lockedMature===true"  class="ma-overlay" src="./assets/images/icon_age_rating_MA@3x.png">
                                      </a>                                
                                  </ng-template>    
                              </ng-template>                        
                                
                              <div  class="hot-right" >
                                <div class="top-texts">
                                    <h4 *ngIf="isWebToonOrWebComic(issue.readerType)!==true">{{ 'volume' | translate }}  {{issue.volume_number}} {{ 'issue' | translate }}  {{issue.number}}</h4>
                                    <h4 *ngIf="isWebToonOrWebComic(issue.readerType)===true">{{ 'season' | translate }} {{issue.volume_number}} {{'episode' | translate }}  {{issue.number}}</h4>
                                    <h2 class="name-row">
                                      <span *ngIf="issue.lockSubscription===true" class="locked"><img src="/assets/images/icon_locked_small_grey_2x.png"></span>
                                      <a *ngIf="issue.lockSubscription===true || issue.lockedAge===true"  (click)="showAlertIssue(issue)" class="issue-title">{{issue.number}}-{{issue.name}}</a>
                                      <a *ngIf="issue.lockSubscription!==true" routerLink="/issue/{{issue.publisher_slug}}/{{issue.title_slug}}/{{issue.slug}}" class="issue-title">{{issue.number}}-{{issue.name}}</a>
                                      <span style="display:none; float:right; color:#828282; font-size:15px; font-weight:400;">{{issue.createdAt | date}}</span>
                                    </h2>
                                    <div class="views">
                                      <span style="    margin-right: 15px;">
                                        {{issue.pages.length}} pages
                                      </span>
                                      <div>
                                        <span>
                                          <img  class="icon" src="assets/images/icon_like_small_grey.png">
                                          
                                        </span>
          
                                        <span>
                                          {{issue.issueLikeCount | viewsFormat}}
                                        </span>
                                        <span style="margin-left: 30px;">
                                          <img class="icon" style="margin-left:-5px;" src="assets/images/icon_comments_small_grey.png">
                                        </span>
          
                                        <span>
                                          {{(issue.commentCount?issue.commentCount:0) | viewsFormat}}
                                        </span>
                                      </div>
                                      <div  *ngIf="issue.lockSubscription===true &&  getUnlockPreview(issue)==='readwithpremium'" class="premium" (click)="showAlertIssue(issue)" >
                                        <span class="text">
                                          <span  *ngIf="issue.lockSubscription===true && getUnlockPreview(issue)==='readwithpremium'">{{ 'Unlock With' | translate }}</span>
                                          <img src="assets/images/logo_graphite_premium_small_2x.png">
                                        </span>
                                      </div>
                                      <!-- <div  *ngIf="getUnlockPreview(issue)==='exclusivepreview'" class="premium">
                                        <span class="text">
                                          <span >{{ 'PREVIEW' | translate }}</span>
                                        </span>
                                      </div> -->
                                      <div  routerLink="/issue/{{issue.publisher_slug}}/{{issue.title_slug}}/{{issue.slug}}"  *ngIf="issue.lockSubscription!==true && issue.lockedAge!==true; else readAgeLocked" class="premium">
                                        <span class="text">
                                          <span >{{ 'READ NOW' | translate }}</span>
                                        </span>
                                      </div>
                                      <ng-template #readAgeLocked>
                                          <div  *ngIf="issue.lockedAge===true" routerLink="/register"   class="premium">
                                            <span class="text">
                                              <span >{{ 'SIGN IN' | translate }}</span>
                                            </span>
                                          </div>                                      
                                      </ng-template>
  
                                  </div>
                                  <h5  *ngIf="!issue.showMore&&issue.description" class="description">
                                    <span (click)="openIssue(issue)">{{issue.description | truncate:180}}</span>  <a (click)="issue.showMore=true" *ngIf="issue.description.length>=180">{{ 'read more' | translate }}</a>
                                  </h5>
                                  <h5 *ngIf="issue.showMore" class="description">
                                      <span  (click)="openIssue(issue)">{{issue.description| translate}}</span> <a (click)="issue.showMore=false">{{ 'read less' | translate }}</a>
                                  </h5>

                                </div>
                              </div>
                            <div class="right-points">
                            <a routerLink="{{issueURL(issue)}}">
                              <mat-icon>more_vert</mat-icon>
                            </a> 
                            </div>
                          </div>
                          

                      </div>
              </div>

              <div *ngIf="showInfo===true" class="info-div">
                <p>
                  {{title?.description}}
                </p>

                  <div class="info-row">
                  <div class="info-label">{{ 'created by' | translate }}</div>
                  <div  class="info-value">{{creators}}</div>
                  </div>
                  <div class="info-row">
                  <div class="info-label">{{ 'publisher' | translate }}</div>
                  <div  class="info-value">{{title?.publisher?.name}}</div>
                  </div>

              </div>


            </div>





            
            <div class="left-side" *ngIf="isPlaceholder===true">
              <!-- Top tabs -->

              <div>
                  <div class="issue-row"   *ngFor="let dummy of ' '.repeat(12).split(''), let i = index">
                     
                      <img src="./assets/images/title_page_issue_placeholder.png">

                  </div>
                 
              </div>
            </div>

            <div class="right-side">
              <h5 *ngIf="title?.description && isPlaceholder===false" class="encard-description">
                {{title?.description}}
              </h5>
              <h5 style="color:#eaeaea;" *ngIf="title?.description && isPlaceholder===true" class="encard-description">
                &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;
                &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;
              </h5>
              <app-external-link *ngIf="title?.externalLink" class="desktop-only" style="margin:10px 0 0 20px; " [externalLink]="title.externalLink"></app-external-link>
           
           

              <div *ngIf="!(currentUser?.subscription?.status==='active')" id="amzntitle" style="padding-left: 20px; padding-top: 10px; box-sizing: border-box;"></div>
            </div>
            </div>
           
</div>
    <div *ngIf="error" class="error-found">
        <h2>Title not found!</h2>
    </div>

</div>
<app-mature-popup  (onclose)="onclose()" *ngIf="displayAlert" [type]="alertType"></app-mature-popup>
