<div class="image-viewer container-fluid vh-100">
    <div class="ui">
        <button class="x-circle" type="button" (click)="close()" mat-mini-fab><mat-icon svgIcon="x-circle"></mat-icon></button>
    </div>
    <div class="d-flex flex-column h-100">
        <div class="row flex-grow-1 h-100 image-container">
            <div class="swiper-button-next"><mat-icon svgIcon="arrow-right-circle"></mat-icon></div>
            <div class="swiper-button-prev"><mat-icon svgIcon="arrow-left-circle"></mat-icon></div>
            <swiper-container
                appSwiper
                #swiper
                [config]="imagesConfig"
                init="false"
                thumbs-swiper=".thumbnails" 
                (slidechange)="slideChange($event)"
                class="imageViewer"
                [initialSlide]="index"
            >
                <swiper-slide *ngFor="let image of images; let i=index">
                    <div class="slide" [ngStyle]="{backgroundImage: 'url(' + image.url +')'}"></div>
                </swiper-slide>
            </swiper-container>
        </div>
        <div class="row thumbnails-container" *ngIf="images.length > 1">
            <swiper-container
                appSwiper
                #swiperThumbs
                [config]="thumbsConfig"
                class="thumbnails"
                init="false"
                [slidesPerView]="images.length"
            >
                <swiper-slide *ngFor="let image of images">
                    <div class="thumbnail" role="button"><img [src]="image.url"></div>
                </swiper-slide>
            </swiper-container>
        </div>
    </div>
</div>