import { Component, ComponentRef, OnInit, Inject, ViewChild, ViewContainerRef, VERSION } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AuthenticationService, UserService } from '../../../_services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FileUploader } from 'ng2-file-upload';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FeedImagePreviewComponent } from '../../feed-modules/feed-image-preview/feed-image-preview.component';

@Component({
  selector: 'app-feed-post',
  templateUrl: './feed-post.component.html',
  styleUrls: ['./feed-post.component.scss']
})
export class FeedPostComponent implements OnInit {
    @ViewChild('statusImages', { read: ViewContainerRef }) target;
    statusForm: UntypedFormGroup;
    currentUser: any;
    username: any;
    error: String;
    disabled = true;
    thumbnail: String='assets/images/graphy.png';
    files: any;
    uploadProgress: any;
    loading: Boolean;
    submitted: Boolean;
    posting: Boolean = false;
    componentRef: ComponentRef<any>;

    public imageUploader: FileUploader ;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<FeedPostComponent>,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private formBuilder: UntypedFormBuilder,
        private alert: AlertService,
    ) {
        this.authenticationService.currentUser.subscribe((value) => {
            this.currentUser = value;
            console.log(this.currentUser);

            if ( this.currentUser.profileImage ) {
                this.thumbnail = this.currentUser.profileImage;
            }
        });
        if ( data ) {
            console.log(data);
        }
    }

    get f() { return this.statusForm.controls; }

    ngOnInit() {
        this.statusForm = this.formBuilder.group({
            status: [this.data ? this.data : '', Validators.required],
            images: [null],
        });
    }

    onFileSelected( event ) {
        console.log('onFileSelected');
        this.files = Array.from(event.target.files);

        if ( this.files && this.files.length <= 4 ) {
            console.log(this.files.length);
            this.files.forEach(file=> {
                this.componentRef = this.target.createComponent( FeedImagePreviewComponent );
                let instance = this.componentRef.instance;
                    instance.file = file;
            });

        }

    }

    close() {
        this.dialogRef.close();
    }

    onKey( key ) {
        this.disabled = false;
    }

    postStatus() {
        console.log('Post Status');
        if (this.statusForm.invalid) {
            return;
        }
        const headers = [{
            name: 'Session-Token', value: this.authenticationService.currentUserValue.sessionToken,
        }];

        var result = Object.assign({}, this.statusForm.value);

        const formData = new FormData();
              formData.append('username', this.currentUser.username);
              formData.append('status', result.status);
              if ( this.files ) {
                  this.files.forEach( image => {
                    formData.append('images', image, image.name);
                  });
              }
            this.userService.postStatus( formData, headers ).subscribe( response=> {
                if ( response ) {
                    console.log( response );
                    this.alert.success('Update successful', true);
                    this.close();
                }
            });
/*
        if ( this.files ) {

            this.imageUploader = new FileUploader({
                url: this.userService.postStatus(),
                disableMultipart : false,
                autoUpload: false,
                method: 'post',
                itemAlias: 'images',
                allowedFileType: ['image'],
                authToken: this.authenticationService.currentUserValue.sessionToken,
                headers: [{
                    name: 'Session-Token', value: this.authenticationService.currentUserValue.sessionToken,
                }],
                additionalParameter: result
            });
            this.imageUploader.onCompleteItem = (item, response, status, headers) => this.onImageCompleteItem(item, response, status, headers);
            this.imageUploader.onProgressItem = (item, progress) => this.onImageProgressItem(item, progress);
            this.imageUploader.onAfterAddingFile = (item) => this.onAfterAddingImageFiles(item);
        } else {
            this.userService.postStatus( result ).subscribe(response=> {
                if ( response ) {
                    console.log( response );
                }
            });

        }
        */
    }
      onImageProgressItem(item, progress) {
        this.uploadProgress = progress;


    }

      onImageCompleteItem(item, response, status, headers) {
        if (response) {
          //this.publisher.logo = JSON.parse(response).logo;
          //this.openDialog(true, '', false);
          //item.remove();
        }
        this.stopAll();
      }

    onAfterAddingImageFiles(item) {
        if (this.imageUploader.isUploading === true) {

        }
        if (item.file.size > 5 * 1024 * 1024) {
            //this.alert.snackerror(item.file.name + ' file is too big! Max file size is 5MB.');
            this.stopAll();
        }

    }
    stopAll() {
        this.imageUploader.clearQueue();
        this.imageUploader.cancelAll();
    }


}
