import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Location } from '@angular/common';
import { AlertService, AuthenticationService, UserService } from '../../_services';
import { Title, Meta } from '@angular/platform-browser';

import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComicViewerComponent } from '../comic-viewer/comic-viewer.component';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';


@Component({
  selector: 'app-feed-bookmarks',
  templateUrl: './feed-bookmarks.component.html',
  styleUrls: ['./feed-bookmarks.component.scss', '../feed/feed.component.scss']
})
export class FeedBookmarksComponent {
  feed;
  schema;
  isPlaceholder:boolean = true;
  isBrowser!: boolean;
  username!: string;
  error!: any;

  constructor(
    private alert: AlertService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

    ngOnInit() {
        this.isBrowser = isPlatformBrowser( this.platformId );
        if (this.authenticationService.currentUserValue) {
          this.loadFeed();
        } else {
          console.log('User not logged in');
        }
        this.titleService.setTitle('Bookmarks');
    }

    loadFeed() {
        this.userService.loadBookmarks().subscribe(result => {
            if ( result.error ) {
                this.error = result.error;
            } else {

                this.feed = result;
                this.isPlaceholder = false;
            }
        });
    }

    receiveMessage( $event ) {
        const status = JSON.parse( $event );
        switch ( status.event ) {
          case 'launchImageViewer':
            this.launchImageViewer( status );
            break;
          case 'removeBookmark':
            this.feed = this.feed.filter( card => card.objectId !== status.statusId );
            //this.alert.snacksuccess('Bookmark removed');
            break;
          case 'openContent':
            this.openContent( status );
            break;
        }
    }

    openContent( status ) {
        const dialogRef = this.dialog.open( ComicViewerComponent, {
            panelClass : 'feed-overlay',
            autoFocus: true,
            hasBackdrop: true,
            backdropClass: 'backdropBackground',
            width:'100%',
            height:'100%',
            //ariaModal: true,
            position: { 'top': '0' },
            maxWidth: '100vw',
            maxHeight: '100vw',
            data: status.issueId,
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    launchImageViewer( status ) {
        const dialogConfig = new MatDialogConfig();
            dialogConfig.panelClass = 'overlay';
            dialogConfig.autoFocus = true;
            dialogConfig.hasBackdrop = true;
            dialogConfig.backdropClass = 'backdropBackground';
            dialogConfig.position = { 'top': '0' };
            dialogConfig.width = 'calc(100%)';
            dialogConfig.height = 'calc(100%)';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
            dialogConfig.data = { images: status.images, index: status.index };

        const dialogRef = this.dialog.open( ImageViewerComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');

        });
    }
}