<div class="main-container mature-page">

<h1>{{ 'This content is not available on the web!' | translate }}</h1>

<div style="text-align:center;">
<div style="display:inline-block;">
<p>{{ 'However, you can read it in one of our Graphite Comics mobile apps if you have an active subscription!' | translate }}</p>

<p>{{ 'Subscribe to Graphite Comics on iOS or Android!' | translate }}</p>
</div>
</div>
