import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-publish',
  templateUrl: './confirmation-publish.component.html',
  styleUrls: ['./confirmation-publish.component.scss']
})
export class ConfirmationPublishComponent implements OnInit {
  loading: boolean;
  title = 'Confirmation';
  question = 'Are you sure?';
  ok = 'YES';
  cancel = 'NO';
  showOk = true;
  showCancel = true;
  ngOnInit() {

  }

  constructor(
    public dialogRef: MatDialogRef<ConfirmationPublishComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data.title;
        this.question = data.question;
        if (data.ok === false){
            this.showOk = false;
        } else {
          this.ok = data.ok;
          this.showOk = true;
        }
        if (data.cancel === false){
            this.showCancel = false;
        } else {
          this.cancel = data.cancel;
          this.showCancel = true;
        }

    }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(): void {
        this.dialogRef.close('yes');
  }
}
