<div  class="reader-container isIos">
  <div class="scroller-viewer" >
    <div class="top-bar " [ngClass]="{'showHeader':showHeader, 'showFirst': showFirst}" (mouseover)="displayHeader()"> 
      <div  class="top-bar-content" id="header" >
        <div class="link-back" routerLink="/title/{{issue.title.publisher_slug}}/{{issue.title.slug}}">
          <span  class="webtoon-to-title">{{ issue.title.name }}</span>
        </div>
        <div class="vol-nav" title="Page {{currentPage+1}}">
          <div>
            <img style="max-height: 25px;" src="assets/images/button-previous-comic_2x.png" *ngIf="prevIssue" class="material-icons nav-read" (click)="navigate(prevIssue)">
          </div>
          <div class="issueName">
            #{{issue.number}} - {{issue.name}}
          </div>
          <div >
            <img style="max-height: 25px;" src="assets/images/button-next-comic_2x.png" *ngIf="nextIssue" class="material-icons nav-read" (click)="navigate(nextIssue)">
          </div>
        </div>
        <div routerLink="/" class="reader-mobile-logo">
          <img src="./assets/images/graphy.png">
        </div>
        <div routerLink="/" class="reader-desktop-logo">
          <img src="./assets/images/logo_graphite_comcis_small_2x.png">
        </div>
     </div>
  </div>
    
    <div  class="slides-container">
      <div #slides_container style="width:100%;"></div>
      <div *ngFor="let page of issue.pages; let i=index">
        <div class="div-page" [style.width.px]="pageWidth"  [style.height.px]="page.height">
          <img *ngIf="page.src"  (load)="pagePreLoaded($event, i)" class="page" [src]="page?.src" [ngClass]="{'blur-page':page?.isThumb===true}">
        </div>
      </div>


      <!-- Last page if subscription -->
      <div *ngIf="active_subscription_or_preview; else summary"  class="summary-page">
        <div style="flex-direction: column;">
            <div class="like-container">
                <span style="font-family: HelveticaNeueMdCn; width:100%; text-align:center; text-transform: uppercase; font-size: 18px;"> {{ 'Discover the rest of the story on Mobile with Graphite Premium' | translate }}</span>
            </div>
            <div class="mobile-apps-container">
              <div style="max-width: 30%;">
                  <a (click)="bannerClick('iphone')" href="https://apps.apple.com/us/app/graphite-comics-get-drawn-in/id1367730938" target="_blank">
                          <img  src="./assets/images/appstore_small.png">
                  </a>
              </div>
              <div style="margin-left: 15px; margin-right: 15px; max-width: 33%;    height: 30px;">
                  <a routerLink="/" target="_blank">
                          <img  src="./assets/images/graphite_logo.png">
                  </a>
              </div>
              <div style="max-width: 30%;">
                  <a (click)="bannerClick('android')"  href="https://play.google.com/store/apps/details?id=com.graphite.graphitecomics&hl=en" target="_blank">
                          <img  src="./assets/images/goolgeplay_small.png">
                  </a>
              </div>

          </div>
          <!-- <button mat-button class="pink-mat small-round menu-button-blue">
            {{ 'GET GRAPHITE PREMIUM' | translate }}
          </button> -->
          <div *ngIf="issue.allowComments !== 'no'">
            <app-comments [issue]="issue.objectId"></app-comments>
          </div>
        </div>
      </div>
      <!-- Last page -->
      
      <ng-template #summary>
      <div  class="summary-page">
        <div (click)="navigate(nextIssue)" class="next-issue">
            <div class="next-text">
              <div>NEXT ISSUE</div>
              <div>{{neighbours?.nextIssue?.name}}</div>
            </div>
            <div class="next-image-container">
              <img src="{{neighbours?.nextIssue?.thumb?.url}}" >
            </div>
        </div>
        <div (swipe)="onSwipe($event)" class="next-issue-hand">
              <div class="next-text">
                Next <br>
              Issue
              </div>
        </div>
        <div class="issue-card">
            <div class="issue-container">
                <div class="thumb">
                  <img src="{{issue?.thumb?.url}}" >
                </div>
                <div class="title-details">
                    <h4>{{issue.title.name}}</h4>
                    <h5>{{issue.name}}</h5>
                    <p>{{issue.description}}</p>
                </div>              
            </div>
            <div class="like-band">
                <div class="icons-container">
                      <div class="icon-wrapper">
                        <div><img src="./assets/images/complete_comic_icon_comment@2x.png"></div>
                        <div>{{issue.commentCount}}</div>
                      </div>
                      <div class="icon-wrapper">
                        <div><img src="./assets/images/complete_comic_icon_like@2x.png"></div>
                        <div>{{issue.issueLikeCount}}</div>
                      </div>
                      <div class="icon-wrapper">
                        <div><img src="./assets/images/complete_comic_icon_share@2x.png"></div>
                        <div>share</div>
                      </div>
                    
                    
                </div>
                      <div class="subscribe-wrapper">
                        <div>SUBSCRIBE</div>
                      </div>
                
                
            </div>
        </div>
        <hr class="thick-line">
        <app-comments [issue]="issue.objectId"></app-comments>

        <hr class="thick-line">
        <!-- <app-external-link *ngIf="issue.title?.externalLink" class="mobile-only" [externalLink]="issue.title.externalLink"></app-external-link> -->
        <app-ownads *ngIf="!(currentUser?.subscription?.status==='active')" [adId]="'summary-ads'" [vertical]="true"></app-ownads>

        <div style="flex-direction: column;">

          <app-recommended [rowTitle]="'Recommended for you'" [titleId]="issue.title.objectId" [vertical]="true"></app-recommended>
          <div class="like-container">
              <div class="like-el">
                <img src="./assets/images/webtoon_read_like@2x.png">
              </div>

          </div>

          <div class="mobile-apps-container">
              <div style="max-width: 30%;">
                  <a (click)="bannerClick('iphone')" href="https://apps.apple.com/us/app/graphite-comics-get-drawn-in/id1367730938" target="_blank">
                    <img  src="./assets/images/appstore_small.png">
                  </a>
              </div>
              <div style="margin-left: 15px; margin-right: 15px; max-width: 33%;    height: 30px;">
                  <a routerLink="/" target="_blank">
                    <img  src="./assets/images/graphite_logo.png">
                  </a>
              </div>
              <div style="max-width: 30%;">
                  <a (click)="bannerClick('android')" href="https://play.google.com/store/apps/details?id=com.graphite.graphitecomics&hl=en" target="_blank">
                    <img  src="./assets/images/goolgeplay_small.png">
                  </a>
              </div>

          </div>
          <!-- <button mat-button class="pink-mat small-round menu-button-blue">
            {{ 'GET GRAPHITE PREMIUM' | translate }}
          </button> -->
        </div>
      </div>
      </ng-template>
    </div>
      <go-top-button [animate]="true" [speed]="50" [acceleration]="2" [scrollDistance]="300"
        [classNames]="'scroll-custom-class'">
        <i class='fa fa-arrow-up'></i>
      </go-top-button>

      <app-ownads *ngIf="!(currentUser?.subscription?.status==='active')" class="bottom"  style="text-align:center;"></app-ownads>



      
  </div>
  <div  *ngIf="!(currentUser?.subscription?.status==='active')"   [style.opacity]="showLeftAd===true?1:0" id="amznLeft" >
    <div style="text-align: center;">
      <span class="material-icons link-pointer closead" (click)="closeLeftAd()">
      highlight_off
      </span></div>
    </div>
    <div  *ngIf="!(currentUser?.subscription?.status==='active')"   [style.opacity]="showRightAd===true?1:0" id="amznRight" >
      <div  style="text-align: center;">
        <span class="material-icons link-pointer closead"  (click)="closeRightAd()">
        highlight_off
        </span></div>
        <app-ownads *ngIf="!(currentUser?.subscription?.status==='active')" [which]="'google'"></app-ownads>
    </div>
</div>
