<div  class="webtoons-reader">
    <div class="slides-container">
        <div #slides_container style="width:100%"></div>
        <ng-container *ngIf="isLoaded">
            <div *ngFor="let page of issue.pages; let i=index">
                <div class="div-page" [style.width.px]="pageWidth" [style.height.px]="page.height" [ngStyle]="{'background-image': i > firstLoaded ? 'url('+ page?.thumb +')' : '' }">
                    <img *ngIf="i <= firstLoaded" (load)="pagePreLoaded($event, i)" class="page" [src]="page?.src" [ngClass]="{'blur-page':page?.isThumb===true}">
                    <img *ngIf="i > firstLoaded" (load)="pagePreLoaded($event, i)" lazyImage class="page" [attr.data-src]="page?.src" [ngClass]="{'blur-page':page?.isThumb===true}">
                </div>
            </div>
        </ng-container >
    </div>
</div>