import { Component, OnInit, Input, HostListener, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { UserService, AuthenticationService, WINDOW } from '../../../_services';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';
declare var postscribe;

@Component({
    selector: 'app-webtoon-reader',
    templateUrl: './webtoon-reader.component.html',
    styleUrls: ['./webtoon-reader.component.scss']
})
export class WebtoonReaderComponent implements OnInit, AfterViewInit {
    winHeight: number;
    showRightAd   = false;
    showLeftAd    = false;
    showHeader:   boolean;
    showFirst:    boolean;
    countLoaded:  any;
    allpages:     any;
    defaultImage: any;
    index:        number;
    showNav       = true;
    calculationsCompleted = false;
    lasti                 = 0;
    lastTop               = 0;
    currentPage           = 0;
    y                     = 0;
    firstLoaded           = 7;
    loadBefore            = 7;
    firstLoadedThumb      = 20;
    loadBeforeThumb       = 15;
    scroll                = false;

    max:             any;
    pageCount:       any;
    active_subscription_or_preview: boolean;
    pageWidth        = 800;
    pageHeight;
    isWindow         = false;
    currentUser:     any;

    constructor(
        @Inject(WINDOW) private window: Window,
        private userService: UserService,
        private authService: AuthenticationService,
        private router:      Router,
    ) {
        if(this.authService.isWindow()){
            this.authService.currentUser.subscribe((value) => {
                this.currentUser = value;
            });
            fromEvent(this.window, 'scroll').pipe(
                tap(() => this.scroll = true),
                debounceTime(100)
            ).subscribe((event) => {
                this.scroll = false;
                this.y = this.window.scrollY;
                this.findImageByScroll();
            });
            fromEvent(this.window, 'resize').pipe(
                debounceTime(100)
            ).subscribe((event) => {
                this.reinitAll();
            });
        }

    }

    @Input() issue;
    @Input() navnext;
    @Input() navprev;
    @Input() prevIssue;
    @Input() nextIssue;
    @Input() neighbours;
    @ViewChild('slides_container', {static: true}) container : ElementRef;
    @HostListener('window:scroll', ['$event']) onScroll(e) {

        const doch = document.documentElement.scrollHeight;
        if (this.winHeight !== doch) {
            this.winHeight = doch;
            return;
        }

        this.showHeader = false;
        this.showFirst = false;
        const element = document.getElementById('header');
        try {
            element.classList.add('sticky');
        } catch (err) {
            console.error(err);
        }
    }

    // Event on click > show/hide Header
    @HostListener('click', ['$event']) onMouseDown(e) {
        e.stopPropagation();

        if ( e.toElement ) {
            if (e.toElement.className === 'swiper-button-prev' || e.toElement.className === 'swiper-button-next') {
                return;
            }
            if (e.toElement.className.indexOf('mat-slider-thumb') >= 0) {
                this.hideHeader();
                return;
            }
        }

        try {
            this.showHeader = !this.showHeader;
            this.showFirst = false;
            const element = document.getElementById('header');

            element.classList.add('sticky');
        } catch (err) {
            console.log(err);
        }
    }

    @HostListener('window:orientationchange', ['$event']) onOrientationChange(event) {
        this.reinitAll();
    }

    reinitAll(){
        this.calculationsCompleted=false;
        this.lasti= 0;
        this.initViewer();
    }

    pageLoaded(page, evt) {
        if (evt === true) {
            page.loaded = true;
        }
        if (page.displayed) { return; }

        this.userService.pageWasDisplayed(page.objectId, this.issue.objectId, this.issue.title.objectId, this.issue.readerType, this.userService.isSubscribed(this.currentUser), page.aspectRatio).subscribe(result => {
            page.displayed = true;
        });
    }

    displayHeader() {
        this.showHeader = true;
        this.showFirst = false;
    }

    hideHeader() {
        this.showHeader = false;
    }

  pagePreLoaded(evt, index) {
    // const page = this.issue.pages[index];
    // page.height = evt.path[0].height;
    // page.height = evt.target.height;
    // this.calculateImages();
  }


  getPageImageVertical(page, index) {
        const url = this.userService.getPageImageJPG(page.objectId);
        return url;
  }


  getVerticalDefaultImage(page, index) {
    if (index <= this.firstLoaded) {
        return this.userService.getPageImageJPG(page.objectId);
    } else {
        return this.userService.getThumbnail(page.objectId);
    }
  }
  getHeight() {
    return this.window.innerHeight + 100;
  }


  // navigate(issue) {
  //   this.showHeader = true;
  //   this.router.navigate(['/issue', issue.publisher_slug, issue.title_slug, issue.slug]) .then(() => {
  //     this.window.location.reload();
  //   });
  // }

  navigate(issue) {
    this.showHeader = true;
    // this.router.navigate(['/issue', issue.publisher_slug, issue.title_slug, issue.slug]) .then(() => {
    //   //this.window.location.reload();
    // });

    this.window.location.href=`/issue/${issue.publisher_slug}/${issue.title_slug}/${issue.slug}`;
  }


  navigateOld(id) {
    this.showHeader = true;
    this.router.navigate(['/issue', id]) .then(() => {
      this.window.location.reload();
    });
  }


  ngOnInit() {

    if(this.authService.isWindow()){

      this.initViewer();
    }

  }

  ngAfterViewInit() {
    this.pageWidth=this.container.nativeElement.offsetWidth;
    if(this.authService.isWindow()){
      this.isWindow=true;
      this.initViewer();
    }

     
  setTimeout(() => {
    this.loadScriptsLeft();
}, 1000);
  }

  bannerClick( clickType ) {
      console.log('bannerClick', clickType);

  }
  
  canView(){

    if( this.issue.accessRule === 'active_subscription'){
      this.issue['lockSubscription']=true;
    }
    
    if(this.currentUser){
     
          if(this.userService.ageToNumber(this.issue.title.age_rating)<=this.userService.ageToNumber(this.currentUser.max_age_rating)){
                //unlock all age restrictions
                this.issue['lockedMature']=false;
                this.issue['lockedAge']=false;
          }
          else{
            this.issue['hideAge']=true;
              if(this.issue.title.age_rating==='MA'){
                this.issue['lockedMature']=true;
               }
          }
          if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status==='active'){
            this.issue['lockSubscription']=false;
          }

    }
    else{
      if(this.userService.ageToNumber(this.issue.title.age_rating)>this.userService.ageToNumber('MA')){
        this.issue['lockedAge']=true;
      }
      else{
        this.issue['lockedAge']=false;
      }
        if(this.issue.title.age_rating==='MA'){
          this.issue['lockedMature']=true;
        }
    }

}


  neighbourCanView(accessRule){
    let hasAccess=true;
    if(accessRule === 'active_subscription'){
            hasAccess=false;
            if(this.currentUser){
                  if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status==='active'){
                        hasAccess=true;
                  }
            }
            else{
                hasAccess=false;
            }
      } else{
        hasAccess=true;
      }
      return hasAccess;
}







  initViewer(){
    
        // if(this.window.innerWidth<1200){
        //   this.pageWidth = this.window.innerWidth;
        // }
        // else{
        //   this.pageWidth = 800;
        // }
        
        // 

        this.pageWidth=this.container.nativeElement.offsetWidth;
if(this.pageWidth>800){
  this.pageWidth=800;
}
      // ACTIVE SUBSCRIPTION
      if(this.currentUser && this.currentUser.subscription.status==='active'){

      }
      else{
            if (this.issue.accessRule === 'active_subscription_or_preview' && typeof this.issue.accessRuleValue === 'number') {
              this.active_subscription_or_preview = true;
              this.issue.pages = this.issue.pages.slice(0, this.issue.accessRuleValue);
              this.max = this.pageCount = this.issue.accessRuleValue;
            }        
      }

      for (let i = 0 ; i < this.issue.pages.length; i++) {
        this.issue.pages[i].height =Math.floor( (this.pageWidth) * this.issue.pages[i].aspectRatio);
      }
      this.calculateImages();
      for (let i = 0 ; i < this.issue.pages.length; i++) {
          if(i <= this.firstLoaded) {
              this.issue.pages[i].src = this.userService.getPageImageJPG(this.issue.pages[i].objectId);
              this.issue.pages[i].isThumb = false;
              this.issue.pages[i].full = true;
          } else if (i<this.firstLoadedThumb) {
              this.issue.pages[i].src = this.userService.getThumbnail(this.issue.pages[i].objectId);
              this.issue.pages[i].isThumb = true;
          }

      }    
  }



  calculateImages() {
    if (this.calculationsCompleted === true) {
      return;
    }
      if (this.lasti === 0) {
        this.issue.pages[0].top = 0;
        this.lasti = 1;
      }
      for (let i = this.lasti; i < this.issue.pages.length; i++) {
          this.issue.pages[i].top = this.issue.pages[i - 1].top + this.issue.pages[i - 1].height;
          this.lastTop = this.issue.pages[i].top;
          if (!this.issue.pages[i].height) {
            break;
          }
          this.lasti = i;
          if (i === (this.issue.pages.length - 1)) {
            this.calculationsCompleted = true;
            this.lastTop = this.issue.pages[this.lasti].top +  this.issue.pages[this.lasti].height;

            if (this.y === 0 && this.window.scrollY > 0) {
              this.y = this.window.scrollY;
            }
            this.findImageByScroll();
          }
      }



}

imageLoaded(i) {

}

loadAround(index) {
  if(this.authService.isWindow()){
    this.loadAroundThumbs();
    let start, end;
    if (index === 0) {
        start = 0;
        end = this.firstLoaded;
    } else {
      start = index - this.loadBefore;
      if (start < 0) {
        start = 0;
      }
      end = index + this.loadBefore;
    }

    if (end >= this.issue.pages.length) {
        end = this.issue.pages.length - 1;
    }

      for (let i = start; i <= end; i++) {
          this.issue.pages[i].img = new Image();
          this.issue.pages[i].img.src = this.userService.getPageImageJPG(this.issue.pages[i].objectId);
          this.issue.pages[i].img.onload = () => {
            this.issue.pages[i].full = true;
            this.issue.pages[i].isThumb = false;
            this.issue.pages[i].src = this.userService.getPageImageJPG(this.issue.pages[i].objectId);
          };
      }
     
  }

}

loadAroundThumbs(){
  const index= this.currentPage;
  let start, end;
  if (index === 0) {
      start = 0;
      end = this.firstLoadedThumb;
  } else {
    start = index - this.loadBeforeThumb;
    if (start < 0) {
      start = 0;
    }
    end = index + this.loadBeforeThumb;
  }

  if (end >= this.issue.pages.length) {
      end = this.issue.pages.length - 1;
  }

    for (let i = start; i <= end; i++) {
        if (this.issue.pages[i].isThumb===true || this.issue.pages[i].full){
            continue;
        }
            this.issue.pages[i].src = this.userService.getThumbnail(this.issue.pages[i].objectId);
            this.issue.pages[i].isThumb = true;


    }

}

findImageByScroll() {
    if (this.y < this.lastTop) {
        // search for image in that position and load x images after it
        // find position
        const index = 0;
        for (let i = 0; i < this.issue.pages.length; i++) {
          if (this.issue.pages[i].top < this.y && (this.issue.pages[i].top + this.issue.pages[i].height) > this.y) {
            this.currentPage = i;
            this.pageLoaded(this.issue.pages[this.currentPage], true);
            this.loadAround(this.currentPage);
            break;
          }

        }

    }
}

onSwipe(ev){
    if(ev.offsetDirection){
      if(ev.offsetDirection===2){
        //next
        if(this.nextIssue){
            this.navigate(this.nextIssue);
        }
      }
      if(ev.offsetDirection===4){
        //prec
        if(this.prevIssue){
          this.navigate(this.prevIssue);
        }
      }

    }
}



    loadScriptsLeft(){
        if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription==='active'){
            return;
        }
        try {
            postscribe('#amznLeft', `
            <div id="amzn-assoc-ad-cc72a99a-606b-4d7f-8ac5-5ff8c5678b1d"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=cc72a99a-606b-4d7f-8ac5-5ff8c5678b1d"></script>
            `);
        } catch (error) {

        }
    }

    loadScriptsRight(){
        if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription==='active'){
            return;
        }
        return;
        postscribe('#amznRight', `
      <div id="amzn-assoc-ad-cc72a99a-606b-4d7f-8ac5-5ff8c5678b1d"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=cc72a99a-606b-4d7f-8ac5-5ff8c5678b1d"></script>
    `);
    }

    closeLeftAd(){
        this.showLeftAd = false;
        let rand = Math.round(Math.random() * (15000 - 500)) + 5500;
        setTimeout(()=>{
            this.showLeftAd=true;
            // this.loadScriptsLeft();
        },rand)
    }

    closeRightAd(){
        this.showRightAd = false;
        let rand = Math.round(Math.random() * (15000 - 500)) + 5500;
        setTimeout(()=>{
            this.showRightAd=true;
            // this.loadScriptsRight();
        },rand)
    }
}
