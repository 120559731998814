<script type="application/ld+json">{
  "@context": "http://schema.org",
  "@type": "WebPage",
  "name": "Comics, Manga, Webtoon - Popular Titles",
  "description": "Read the latest of over 30,000 FREE to read comics from top shelf publishers and creators.",
  "publisher": {
    "@type": "Organization",
    "name": "GraphiteComics"
  }
}</script>
<app-topslider [isPlaceholder]="isPlaceholder"></app-topslider>
<div class="popular-titles">
    <div class="main-container">
      <h1>Popular Titles</h1>
      <!-- <h1>{{ 'Popular Titles' | translate }}</h1> -->
            <app-titlesrow [slider]="false" [isPlaceholder]="isPlaceholder" [rowTitle]="'Trending Titles'" [titles]="titles" [all]="true"></app-titlesrow>
    </div>
</div>
