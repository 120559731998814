import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService, LocalService } from '../_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private localStorage: LocalService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        //  let currentUser = this.authenticationService.currentUserValue;
        if(this.authenticationService.isWindow) {
            let currentUser;
            if (this.authenticationService.isJSON(this.localStorage.getItem('currentUser'))) {
                currentUser = JSON.parse(this.localStorage.getItem('currentUser'));
            }

            if (currentUser && currentUser.sessionToken) {
                request = request.clone({
                    setHeaders: {
                        'session-token': `${currentUser.sessionToken}`
                    }
                });
            }
        }
        return next.handle(request);
    }
}
