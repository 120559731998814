 
  <div  *ngIf="isPlaceholder===false" class="top-slider-container mobile-only" [ngClass]="{'initialclass':initial===true}">
          <ngx-hm-carousel
          [(ngModel)]="index"
          (ngModelChange)="indexChanged($event)"
          [autoplay-speed]="speed"
          [autoplay]="autoplay"
          [autoplay-direction]="direction"
          [infinite]="infinite"
          [between-delay]="2000"
          [data]="slides"
          [aniTime]="200"
          class="carousel">
  
          <section ngx-hm-carousel-container class="content"  #slidesection>
            <article   class="item cursor-pointer visible_important x" (click)="goToTitle(avatar)" [ngStyle]="{'max-height.px': maxheight}"
              ngx-hm-carousel-item
              *ngFor="let avatar of slides; let i = index;">
              <a>
                  <div class="container-main-image">
                      <img routerLink="{{getLinkG(avatar)}}"   [alt]="avatar.title" [title]="avatar.title" draggable="false" #imgslide class="img-slide main-image"  [src]="getImageG(avatar)">
  
                  </div>
            </a>
            <div class="div-text" *ngIf="avatar.action!=='goToPublisher' && avatar.action!=='goToURL' && avatar.action!=='doNothing'">
              <div class="text-container">
                <div class="text-block">
                    <h1>{{avatar.title}}</h1>
                    <h2>by {{avatar.publisher}}</h2>
                </div>
              </div>
            </div>
            </article>
            <ng-template #infiniteContainer></ng-template>
          </section>
  
          <ng-template #carouselContent let-avatar let-i="index">
            <article class="item cursor-pointer visible_important" (click)="goToTitle(avatar)">
              <div class="img"
                [style.backgroundImage]="'url('+(avatar?avatar.url:'')+')'">
              </div>
              <h1></h1>
            </article>
          </ng-template>
  
          <ng-template #carouselPrev>
            <div class="click-area">
              <i class="material-icons"></i>
            </div>
          </ng-template>
          <ng-template #carouselNext>
            <div class="click-area">
              <i class="material-icons"></i>
            </div>
          </ng-template>
        </ngx-hm-carousel>
  </div>
  