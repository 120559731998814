import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'feedFormat'
})
export class FeedFormatPipe implements PipeTransform {

    urls: any = /(\b(https?|http|ftp|ftps|Https|rtsp|Rtsp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim; // Find/Replace URL's in text
    hashtags: any = /(^|\s)(#)([a-z\d][\w-]*)/ig; // Find/Replace #hashtags in text
    mentions: any = /(^|[.]|\s)(@)([a-z\d][\w-]*)/ig; // Find/Replace @Handle/Mentions in text
    emails: any = /(\S+@\S+\.\S+)/gim; // Find/Replace email addresses in text

    constructor(protected sanitizer: DomSanitizer) {}

    transform(text: string) {
        return this.sanitizer.bypassSecurityTrustHtml( this.parseUrl(text) );
    }

    private parseUrl(text: string) {
        // Find/Replace URL's in text
        if (text.match(this.urls)) {
            text = text.replace(this.urls, function replacer($1, $2, $3) {
                let url: any = $1;
                let urlClean: any = url.replace("" + $3 + "://", "");

                return " <a href=\"" + url + "\" target=\"_blank\">" + urlClean + "</a> ";
            });
        }

        // Find/Replace @Handle/Mentions in text
        if (text.match(this.hashtags)) {
            text = text.replace(this.hashtags, "$1<a href=\"/hashtag/$3\" class=\"link-primary\">$2$3</a>");
        }

        // Find/Replace #hashtags in text
        if (text.match(this.mentions)) {
            text = text.replace(this.mentions, "$1<a href=\"/feed/$3\" class=\"link-primary\">$2$3</a>");
        }

        return text;
    }
}