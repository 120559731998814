<div class="status px-xl-2">
    <div class="border-xl border-light p-xl-3 status-card">
        <div class="status-header">
            <div class="d-flex flex-row">
                <div class="first"><a routerLink="/feed/{{ username }}"><img [src]="photo" class="rounded-circle" style="width: 64px;" alt="{{ username }}" /></a></div>
                <div class="p-2 my-auto h-100 publisher">
                    <span class="name"><a routerLink="/feed/{{ username }}">{{ name }}</a></span>
                    <span class="px-1">&bull;</span>
                    <span class="date-added"><a routerLink="{{ permalink }}">{{ status.statusCreatedAt.iso | timeAgo }}</a></span>
                </div>
                <div class="ms-auto my-auto ">
                    <app-feed-dropdown></app-feed-dropdown>
                </div>
            </div><!--/.row -->
        </div><!--/.status-header -->

<!--
    <div *ngIf="issue.readerType === 'comic'; then comicView; else issueView"></div>
    <ng-template #comicView>
        <div  routerLink="/view/{{issue.objectId}}"  *ngIf="issue.lockSubscription!==true && issue.lockedAge!==true; else readAgeLocked" class="premium">
            <span class="text">
                <span >{{ 'READ NOW' | translate }}</span>
            </span>
        </div>
    </ng-template>
    <ng-template #issueView>
        <div  routerLink="/issue/{{issue.publisher_slug}}/{{issue.title_slug}}/{{issue.slug}}"  *ngIf="issue.lockSubscription!==true && issue.lockedAge!==true; else readAgeLocked" class="premium">
            <span class="text">
                <span >{{ 'READ NOW' | translate }}</span>
            </span>
        </div>
    </ng-template>

-->
        <div>
            <div class="status-body" *ngIf="status.statusBody">
                <div [innerHtml]="status.statusBody | feedFormat"></div>
            </div>
            <div *ngIf="status.images">
                <app-feed-gallery [images]="status.images" (messageEvent)="launchImageViewer($event)"></app-feed-gallery>
            </div>
            <div *ngIf="status.card" class="status-card">
                <mat-card>
                    <div *ngIf="status.readerType==='comic' || status.readerType==='webcomic'">
                        <a (click)="openContent()">
                            <img mat-card-image src="{{ status.card.image.url }}" alt="{{ status.card.title }}" />
                        </a>
                    </div>
                    <div *ngIf="status.readerType==='strip'">
                        <a (click)="openContent()">
                            <img mat-card-image [src]="getStrip()" alt="{{ status.card.title }}" />
                        </a>
                    </div>
                    <div *ngIf="status.readerType!=='comic' && status.readerType!=='webcomic' && status.readerType!=='strip'">
                        <a *ngIf="status.card.image" routerLink="{{ permalink }}">
                            <img mat-card-image src="{{ status.card.image.url }}" alt="{{status.card.title}}" />
                        </a>
                    </div>
                    <mat-card-content>
                        <mat-card-title *ngIf="status.readerType==='comic'"><a routerLink="{{ permalink }}">{{status.card.title}}</a></mat-card-title>
                        <mat-card-title *ngIf="status.readerType!=='comic'"><a routerLink="{{ permalink }}">{{status.card.title}}</a></mat-card-title>
                        <p *ngIf="status.card.body">
                            {{ status.card.body }}
                        </p>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="d-flex flex-row h-100">
                <div id="likes">
                    <button type="button" (click)="onButtonClick('like')" mat-mini-fab [ngClass]="liked ? 'like selected' : 'like'"><mat-icon svgIcon="heart"></mat-icon></button>
                </div>
                <div class="ps-1 pe-2 count">{{likesCount}}</div>
                <div id="comments">
                    <button type="button" (click)="onButtonClick('reply')" mat-mini-fab class="reply"><mat-icon svgIcon="message-circle"></mat-icon></button>
                </div>
                <div class="ps-1 pe-2 count">{{commentCount}}</div>
                <div id="reposts">
                    <button type="button" (click)="onButtonClick('repost')" mat-mini-fab class="repost"><mat-icon svgIcon="repeat"></mat-icon></button>
                </div>
                <div class="ps-1 pe-2 count">{{repostsCount}}</div>
                <!--
                <div id="share">
                    <button type="button" (click)="onButtonClick('share')" mat-mini-fab class="share"><mat-icon svgIcon="send"></mat-icon></button>
                </div>
                <div class="ps-1 pe-2 count">{{likesCount}}</div>
                -->
                <div class="ms-auto"></div>
                <div id="bookmark">
                    <button type="button" [disabled]="bookmarkAction" (click)="onButtonClick('bookmark')" mat-mini-fab [ngClass]="bookmarked ? 'bookmark selected' : 'bookmark'"><mat-icon svgIcon="bookmark"></mat-icon></button>
                </div>
                <div class="ps-1 count">{{bookmarkCount}}</div>
            </div>
        </div><!--/.status-body -->
    </div>
    <mat-divider class="d-inline-block d-none d-xs-block d-lg-block d-xl-none"></mat-divider>
</div><!--/.status -->

