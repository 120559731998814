<header>
    <nav class="navbar navbar-expand-md fixed-top py-3 mb-4 shadow">
        <div class="container-fluid">
            <div class="logo">
                <div><a routerLink="/" class="navbar-brand">
                    <!--<img class="logo-svg" src="https://zable-files.s3.amazonaws.com/assets/zable-logo.svg" />-->
                    <svg class="logo-svg"><use href="/assets/images/zable-logo.svg#logo" /></svg>
                </a></div>
                <!--
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                -->
                <div><small class="text-muted">Version: {{ version }} </small></div>
            </div>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 menu-icons-container">
                    <li class="nav-item">
                        <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="nav-link">
                            <mat-icon class="icon">home</mat-icon>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/hotnew/popular" routerLinkActive="active" class="menu-icon trending">
                            <mat-icon class="icon">trending_up</mat-icon>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/publishers/publishers" routerLinkActive="active" class="menu-icon publishers">
                            <mat-icon class="icon">people</mat-icon>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/feed" routerLinkActive="active" class="menu-icon publishers">
                            <mat-icon class="icon">rss_feed</mat-icon>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/feed/hashtag" routerLinkActive="active" class="menu-icon publishers">
                            <mat-icon class="icon">hash</mat-icon>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/search" routerLinkActive="active" class="menu-icon font search">Search </a>
                    </li>
                </ul>
                <form class="d-flex">
                    <input type="text" class="search" placeholder="{{ 'Search' | translate }}" id="searchInput" name="Search" [(ngModel)]="term" (keyup.enter)="doSearch(term)" />
                </form>
                <div class="d-flex">
                    <a routerLink="/register" class="menu-icon upload"></a>
                    <a *ngIf="!currentUser" routerLink="/login" routerLinkActive="active" class="menu-icon font sign-in"></a>
                    <a *ngIf="!currentUser" routerLink="/register" class="btn">Sign up for FREE</a>
                    <div *ngIf="currentUser" class="menu-icon profile-picture-container" [ngStyle]="{ 'background-image': 'url(' + getProfileImage() + ')'}" (click)="gotoprofile()">
                        <img class="age-rating" src="{{getAgeRating()}}" alt="Age rating">
                    </div>
                   <!-- <p *ngIf="currentUser" (click)="gotoprofile()" style="cursor: pointer;">{{ currentUser?.username }}</p> -->
                </div>
            </div>
        </div>
    </nav>
</header>