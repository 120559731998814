<div class="publisher-item">
    <div class="logo">
        <a routerLink="/publisher/{{publisher.slug}}">
            <img [alt]="publisher.name"  title="{{publisher.name}}" [src]="publisher.logo?publisher.logo.url:''">
        </a>
        <div class="publisher-details">
            <div class="publisher-description">
                {{publisher.description}}
            </div>
            <div class="titles-count" href="" [ngPlural]="publisher?.titleCount">
                <ng-template ngPluralCase="=0">no titles yet</ng-template>
                <ng-template ngPluralCase="=1">one title</ng-template>
                <ng-template ngPluralCase="other">{{publisher?.titleCount}} titles</ng-template>
            </div>
        </div>
    </div>
    <a routerLink="/publisher/{{ publisher.slug }}"><h3 class="publisher-name">{{ publisher.name }}</h3></a>
</div>
