<ngx-json-ld [json]="schema"></ngx-json-ld>
<div class="hot-new">
        <div class="main-container" >
                <mat-tab-group  animationDuration="150ms" #hotnew mat-align-tabs="center" (selectedTabChange)="onLinkClick($event)" [selectedIndex]="selectedIndex">
                        <mat-tab label="{{ 'TOP 50' | translate }}" >
                                <ng-template matTabContent>
                                      <app-hottitles [isPlaceholder]="isPlaceholderTop" [titles]="top50"></app-hottitles>  
                                </ng-template>
                                
                        </mat-tab>
                        <mat-tab label="{{ 'NEW' | translate }}" >
                                <ng-template matTabContent>
                                        <app-hottitles [isPlaceholder]="isPlaceholderNew" [titles]="newtitles"></app-hottitles>
                                </ng-template>
                                
                        </mat-tab>
                        <mat-tab label="{{ 'RIGHT NOW' | translate }}" >
                                <ng-template matTabContent>
                                        <app-hottitles [isPlaceholder]="isPlaceholderNow" [titles]="nowtitles"></app-hottitles>
                                </ng-template>
                                
                        </mat-tab>
                        <mat-tab label="{{ 'ALL TIME' | translate }}" >
                                <ng-template matTabContent>
                                        <app-hottitles [isPlaceholder]="isPlaceholderAll" [titles]="alltitles"></app-hottitles>
                                </ng-template>
                                
                        </mat-tab>
                </mat-tab-group>
        </div>
</div>
