
    <div  *ngIf="isPlaceholder===false" class="top-slider-container desktop-only" [ngClass]="{'initialclass':initial===true}">
      <ngx-hm-carousel
      [(ngModel)]="index"
      (ngModelChange)="indexChanged($event)"
      [autoplay-speed]="speed"
      [autoplay]="autoplay"
      [autoplay-direction]="direction"
      [infinite]="infinite"
      [between-delay]="2000"
      [data]="slides"
      [aniTime]="200"
      class="carousel">
  
      <section ngx-hm-carousel-container class="content"  #slidesection>
        <article   class="item cursor-pointer visible_important" [ngStyle]="{'max-height.px': maxheight}"
          ngx-hm-carousel-item
          *ngFor="let avatar of slides; let i = index;">
          <a>
              <div class="container-main-image">
                  <img routerLink="{{getLinkG(avatar[0])}}"   [alt]="avatar[0]?.title" [title]="avatar[0]?.title" draggable="false" #imgslide class="img-slide main-image"  [src]="getImageG(avatar[0])">
                <div *ngIf="!genreId" class="small-images-container">
                    <div routerLink="{{getLinkG(avatar[1])}}">
                      <img  [alt]="avatar[1]?.title" [title]="avatar[1]?.title" draggable="false" 
                       [src]="getImageG(avatar[1])">
                       <div class="div-text-small" *ngIf="avatar[1]?.action!=='goToPublisher' && avatar[1]?.action!=='goToURL' && avatar[1]?.action!=='doNothing'">
                        <div class="text-container">
                          <div class="text-block">
                            <h1>{{getTitleG(avatar[1])}}</h1>
                            <h2>by {{getPublisherG(avatar[1])}}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="second-image"  style="margin-top: 2px;"  routerLink="{{getLinkG(avatar[2])}}">
                      <img  [alt]="avatar[2]?.title" [title]="avatar[2]?.title" draggable="false" [src]="getImageG(avatar[2])">
                      <div class="div-text-small" *ngIf="avatar[2]?.action!=='goToPublisher' && avatar[2]?.action!=='goToURL' && avatar[2]?.action!=='doNothing'">
                        <div class="text-container">
                          <div class="text-block">
                              <h1>{{getTitleG(avatar[2])}}</h1>
                              <h2> by {{getPublisherG(avatar[2])}}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div *ngIf="genreId" class="small-genre-container">
                  <div>
                        <h3>{{genre?.genreName}} Comics</h3>
                        <div *ngIf="genre?.description" class="genre-description">
      
                                  {{genre?.description}}  
      
                        </div>                    
                  </div>

                    <div  routerLink="{{getLinkG(avatar[1])}}">
                      <img style="vertical-align: bottom;"  [alt]="avatar[1]?.title" [title]="avatar[1]?.title" draggable="false" 
                       [src]="getImageG(avatar[1])">
                       <div class="div-text-small" *ngIf="avatar[1]?.action!=='goToPublisher' && avatar[1]?.action!=='goToURL' && avatar[1]?.action!=='doNothing'">
                        <div class="text-container">
                          <div class="text-block">
                            <h1>{{getTitleG(avatar[1])}}</h1>
                            <h2>by {{getPublisherG(avatar[1])}}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
  
                  
              </div>
        </a>
        <div class="div-text" *ngIf="avatar[0]?.action!=='goToPublisher' && avatar[0]?.action!=='goToURL' && avatar[0]?.action!=='doNothing'">
          <div class="text-container">
            <div class="text-block">
                <h1>{{avatar[0]?.title}}</h1>
                <h2>by {{avatar[0]?.publisher}}</h2>
            </div>
          </div>
        </div>
        </article>
        <ng-template #infiniteContainer></ng-template>
      </section>
  
      <ng-template #carouselContent let-avatar let-i="index">
        <article class="item cursor-pointer visible_important" (click)="goToTitle(avatar)">
          <div class="img"
            [style.backgroundImage]="'url('+(avatar?avatar.url:'')+')'">
          </div>
          <h1></h1>
        </article>
      </ng-template>
  
      <ng-template #carouselPrev>
        <div class="click-area">
          <i class="material-icons"></i>
        </div>
      </ng-template>
      <ng-template #carouselNext>
        <div class="click-area">
          <i class="material-icons"></i>
        </div>
      </ng-template>
    </ngx-hm-carousel>
  </div>

  
  
  
  
  