import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-publishers',
    templateUrl: './publishers.component.html',
    styleUrls: ['./publishers.component.scss']
})
export class PublishersComponent implements OnInit {
    schema;

    constructor(
        private titleService: Title,
        private metaTagService: Meta
    ) { }

    ngOnInit() {
        this.titleService.setTitle('Publishers - Graphite Comics');
        this.metaTagService.updateTag({name: 'description', content: 'Kodansha, Dark Horse, TOKYOPOP, IDW, Top Cow, Dynamite and many more great publishers and creators.'});

        this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
        this.metaTagService.updateTag({ property: 'og:url', content: 'https://graphitecomics.com/publishers/publishers' });
        this.metaTagService.updateTag({ property: 'og:title', content: 'Publishers - Graphite Comics' });
        this.metaTagService.updateTag({ property: 'og:description', content: 'Kodansha, Dark Horse, TOKYOPOP, IDW, Top Cow, Dynamite and many more great publishers and creators.' });
        this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.graphitecomics.com/assets/images/site_icon_512.jpg' });

        // Twitter metadata
        this.metaTagService.addTag({name: 'twitter:card', content: 'summary'});
        this.metaTagService.addTag({name: 'twitter:site', content: 'GraphiteComics'});
        this.metaTagService.addTag({name: 'twitter:title', content:  'Publishers - Graphite Comics'});
        this.metaTagService.addTag({name: 'twitter:description',
        content: 'Kodansha, Dark Horse, TOKYOPOP, IDW, Top Cow, Dynamite and many more great publishers and creators.' });
        this.metaTagService.addTag({name: 'twitter:text:description',
        content: 'Kodansha, Dark Horse, TOKYOPOP, IDW, Top Cow, Dynamite and many more great publishers and creators.'});
        this.metaTagService.addTag({name: 'twitter:image', content: 'https://www.graphitecomics.com/assets/images/site_icon_512.jpg'});

        this.populateSchema();
    }


    populateSchema() {
            const schema =       {
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                'name': 'Publishers - Graphite Comics',
                'description': 'Kodansha, Dark Horse, TOKYOPOP, IDW, Top Cow, Dynamite and many more great publishers and creators.',
                'publisher': {
                '@type': 'Organization',
                'name': 'GraphiteComics'
            }
    };
    this.schema = schema;
    }
}
