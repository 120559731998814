<div class="comment-component" >
    <div>
        <h6>Comments <span class="comment-count">{{commentCount}}</span></h6>
        <form [formGroup]="commentForm" (ngSubmit)="postComment()" class="comment-form">
            <div class="form-group">
                <mat-form-field appearance='outline'>
                    <mat-label>Comment</mat-label>
                    <textarea id="message" matInput placeholder="Message" formControlName="message" rows="2"></textarea>
                </mat-form-field>
                <button mat-stroked-button type="submit">Post</button>
            </div>
        </form>

    </div>
    <div class="comments-container" *ngIf="pinned">
        <app-comment [comment]="pinned"></app-comment>
    </div>
    <div class="comment-nav" *ngIf="comments && comments.length>0">
        <mat-tab-group>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="volume-title">LATEST</div>
                </ng-template>
                <div class="comments-container">
                    <ng-template #commentsContainer></ng-template>
                    <div *ngFor="let comment of comments; let i=index">
                        <app-comment [comment]="comment"></app-comment>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="volume-title">TRENDING</div>
                </ng-template>
                <div class="comments-container">
                    <ng-template #commentsContainer></ng-template>
                    <div *ngFor="let comment of trending; let i=index">
                        <app-comment [comment]="comment"></app-comment>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
