
<div class="graphite-viewer">
    <div class="swiper-viewer">
        <swiper-container *ngIf="issue"
            [config]="config"
            [(index)]="index"
            (indexChange)="onIndexChange($event)"
        >
            <div *ngFor="let slide of issue.pages">
                <img [src]="getPageImage(slide)" loading="lazy" />
            </div>
        </swiper-container>
    </div>
    <div class="page-number">
        Page {{index+1}}/{{pageCount}}
    </div>
    <div class="close-button">
        <i class="material-icons" (click)="close()">
            close
        </i>
    </div>
</div>
