import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import { Issue } from '../_models';
import { Account } from '../_models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }
    titleFields = `name
    slug
    publisher_slug
    language
    type
    featuredDate{
      iso
    }
    publisher {
      name
      slug
      objectId
    }
    logo {
      url
    }
    featuredBanner {
      url
    }
    featuredBannerSpecial {
      url
    }
    featuredBannerWide {
      url
    }
    featuredActionValue
    featuredAction
    totalPageViews
    age_rating
    genres {
      objectId
      orderStandard
      genreName
    }`;

    minTitleFields = `
    name
    slug
    publisher_slug
    language
    publisher {
      name
      slug
      objectId
    }
    logo {
      url
    }
    totalPageViews
    age_rating
`;

    loadSessionData( ) {
      return this.http.get<any>(`${environment.apiUrl}/session`);
    }

    loadBookmarks() {
      return this.http.get<any>(`${environment.apiUrl}/bookmarks`);
    }

    saveBookmark( statusId:any ) {
      return this.http.post<any>(`${environment.apiUrl}/bookmarks/add/${statusId}`, statusId);
    }

    removeBookmark( statusId ) {
      return this.http.post<any>(`${environment.apiUrl}/bookmarks/remove/${statusId}`, statusId);
    }

    getPublisher() {
      return this.http.get<any>(`${environment.apiUrl}/user/publisher`);
    }

    getFeed() {
      return this.http.get<any>(`${environment.apiUrl}/feed`);
    }

    getUserFeed( username ) {
      return this.http.get<any>(`${environment.apiUrl}/feed/${username}`);
    }

    getUserMentionsFeed( username ) {
      return this.http.get<any>(`${environment.apiUrl}/feed/${username}/mentions`);
    }

    getHashtagFeed( hashtag ) {
      return this.http.get<any>(`${environment.apiUrl}/feed/i/hashtag/${hashtag}`);
    }

    getStatus(username,statusId) {
      return this.http.get<any>(`${environment.apiUrl}/feed/${username}/status/${statusId}`);
    }

    getStatusReplies(username,statusId) {
      return this.http.get<any>(`${environment.apiUrl}/feed/${username}/status/${statusId}/replies`);
    }

    postStatus( status, headers ) {
        return this.http.post<any>( `${environment.apiUrl}/feed/i/create`, status, headers );
    }

    getRecommendedUsers( username ) {
        return this.http.get<any>( `${environment.apiUrl}/feed/i/recommended` );
    }

    getPublishers(limit= '1000') {
        const type = 'publisher,featured_publisher,creator,public_domain,featured_creator,ugc';
        const clas = 'publisher,creator';

        let params = new  HttpParams().set('limit', limit);
            params = params.append('type', type);
            params = params.append('class', clas);

        return this.http.get<any>(`${environment.apiUrl}/publisher/all`, {params});
    }

    getPublishersUGC(limit= '1000') {
        const type = 'ugc';
        const clas = 'publisher,creator';

        let params = new  HttpParams().set('limit', limit);
            params = params.append('type', type);
            params = params.append('class', clas);

        return this.http.get<any>(`${environment.apiUrl}/publisher/all`, {params});
    }

    getPublisherById(publisherId) {
        return this.http.get<any>(`${environment.apiUrl}/publisher/find/${publisherId}`);
    }
    getPublisherBySlug(publisherSlug) {
        return this.http.get<any>(`${environment.apiUrl}/publisher/find/null/?slug=${publisherSlug}`);
    }
    getPublisherTitles(publisherId, limit= '1000') {
        const params = new  HttpParams().set('limit', limit);
        return this.http.get<any>(`${environment.apiUrl}/publisher/titles/${publisherId}`, {params});
    }
    // getPopularTitles(limit) {
    //     const params = new  HttpParams().set('limit', limit);
    //     return this.http.get<any>(`${environment.apiUrl}/title/popular`, {params});
    // }
    getRecommended(issueId, limit) {
        console.log("####",'getRecommended',"####");
        const params = new  HttpParams().set('limit', limit);
        return this.http.get<any>(`${environment.apiUrl}/issue/recommendations/${issueId}`, {params});
    }
    // getFeaturedTitles(limit) {
    //     const params = new  HttpParams().set('limit', limit);
    //     return this.http.get<any>(`${environment.apiUrl}/title/featured`, {params});
    // }
    // getLiveTitles(limit) {
    //     const params = new  HttpParams().set('limit', limit);
    //     return this.http.get<any>(`${environment.apiUrl}/title/live`, {params});
    // }
    // getNewTitles(limit) {
    //     const params = new  HttpParams().set('limit', limit);
    //     return this.http.get<any>(`${environment.apiUrl}/title/new`, {params});
    // }
    // getTopTitles(limit) {
    //     const params = new  HttpParams().set('limit', limit);
    //     return this.http.get<any>(`${environment.apiUrl}/title/top`, {params});
    // }
    // getGenres(limit) {
    //   const params = new  HttpParams().set('limit', limit);
    //   return this.http.get<any>(`${environment.apiUrl}/title/genres/list`, {params});
    // }
    // getTitlesGenre(genreId, limit) {
    //   const params = new  HttpParams().set('limit', limit);
    //   return this.http.get<any>(`${environment.apiUrl}/title/genres/genre/${genreId}`, {params});
    // }
    getIssues(titleId) {
      return this.http.get<any>(`${environment.apiUrl}/title/issues/${titleId}`);
    }


    getIssue(issueId) {
      return this.http.get<Issue>(`${environment.apiUrl}/issue/find/${issueId}`);
    }
    getIssueBySlug(publisherSlug, titleSlug, issueSlug): Observable<any> {
      return this.http.get<Issue>(`${environment.apiUrl}/issue/find/null/?publisher_slug=${publisherSlug}&title_slug=${titleSlug}&slug=${issueSlug}`);
    }
    getTitle(titleId) {
      return this.http.get<any>(`${environment.apiUrl}/title/find/${titleId}`);
    }


    getIssueURL(issue) {
        let type = '/comic/';
        switch (issue.readerType) {
            case 'webtoon':
              type = '/webtoon/';
              break;
            case 'comic':
              type = '/comic/';
              break;
            case 'manga':
              type = '/manga/';
              break;
            case 'jmanga':
              type = '/manga/';
              break;
            case 'strip':
              type = '/strip/';
              break;
        }
        return `${type}${issue.publisher_slug}/${issue.title_slug}/${issue.slug}`;
    }

    getTitleBySlug(publisherSlug, titleSlug) {
      return this.http.get<any>(`${environment.apiUrl}/title/find/null/?publisher_slug=${publisherSlug}&slug=${titleSlug}`);
    }

    getById(id: number) {
        return this.http.get(`${environment.apiUrl}/users/${id}`);
    }

    registerUser(user: User) {
        return this.http.post(`${environment.apiUrl}/user/signup`, user);
    }

    requestReset(user: string) {
      return this.http.post(`${environment.apiUrl}/user/requestpasswordreset`, {usernameOrEmail: user});
  }

  validateReset(email: string, token: string) {
      return this.http.get(`${environment.apiUrl}/user/validatepasswordresetrequest/${email}/${token}`);
  }
  resetPassword(email: string, token: string, password: string) {
      return this.http.put(`${environment.apiUrl}/user/resetpassword/`, {email: email, token: token, password: password});
  }

    getUser() {
        return this.http.get<Account>(`${environment.apiUrl}/user/current`);
    }

    getBase64(image: string)  {
      return this.http.get<any>(`${environment.apiUrl}/user/base64/${ encodeURIComponent(image)}`);
    }


    setProfileImage() {
      return `${environment.apiUrl}/user/setprofileimage`;
    }

    cropProfileImage(picture) {
      return this.http.post(`${environment.apiUrl}/user/setprofileimage`, {picture:picture});
    }
    uploadProfileImage() {
      return `${environment.apiUrl}/user/uploadprofileimage`;
    }

    updateUser(max_age, languages) {
        return this.http.put(`${environment.apiUrl}/user/update`, {'max_age_rating' : max_age, 'languages' : languages});
    }

    updatingPassword(oldpassword, newpassword, username) {
      return this.http.put(`${environment.apiUrl}/user/changepassword`, {'old_password': oldpassword, 'new_password': newpassword, 'username': username});
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`);
    }


    getThumbnail(pageId): string {
      return `${environment.apiUrl}/page/thumbnail/${pageId}`;
    }
    getPageImage(pageId): string {
      return `${environment.apiUrl}/page/image/${pageId}`;
    }

    getPageImageJPG(pageId): string {
      const img = `${environment.apiUrl}/page/image/${pageId}`;
      return img.replace('.jpf', '.jpg').replace('.jp2', '.jpg');
    }

    /*
        Comment functions
    */
    getPinnedComment(issueId) {
        return this.http.get<any>(`${environment.apiUrl}/issue/pinnedcomment/${issueId}`);
    }

    getComment(commentId) {
        return this.http.get<any>(`${environment.apiUrl}/comment/find/${commentId}`);
    }

    getReplies(commentId) {
        return this.http.get<any>(`${environment.apiUrl}/comment/replies/${commentId}`);
    }

    getComments(issueId) {
        return this.http.get<any>(`${environment.apiUrl}/comment/issue/${issueId}`);
    }

    postComment( issueId, message ) {
        return this.http.post(`${environment.apiUrl}/comment/post/`, { issueId: issueId, message: message });
    }

    postReply( issueId, message, parentId ) {
        return this.http.post(`${environment.apiUrl}/comment/post/`, { issueId: issueId, message: message, parentId:  parentId});
    }

   isSubscribed(user){
        if(user && user.subscription && user.subscription.status==='active'){
            return true;
        }
        return false;
   }

    pageWasDisplayed(pageId, issueId, titleId, readerType, isSubscribed= false, aspectRatio= 0) {
        console.log("####","pageWasDisplayed","####");
        let params = new  HttpParams().set('pageId', pageId);
            params = params.append('titleId', titleId);
            params = params.append('issueId', issueId);
            params = params.append('isSubscribed', isSubscribed.toString());
            params = params.append('source', 'web');
            params = params.append('readerType', readerType);
            if (aspectRatio && aspectRatio > 0) { params = params.append('aspectRatio', aspectRatio.toString()); }
            return this.http.get<any>(`${environment.apiUrl}/page/wasdisplayed/`, {params});
    }

    issueWasOpened(issueId, titleId, readerType, isSubscribed= false) {
        console.log("####","issueWasOpened","####");
        let params = new  HttpParams().set('issueId', issueId);
            params = params.append('titleId', titleId);
            params = params.append('isSubscribed', isSubscribed.toString());
            params = params.append('source', 'web');
            params = params.append('readerType', readerType);
        return this.http.get<any>(`${environment.apiUrl}/issue/wasopened`, {params});
    }

    issueWasClosed( issueId, titleId, readerType, isSubscribed= false ) {
        console.log("####","issueWasClosed","####");
        let params = new  HttpParams().set('issueId', issueId);
            params = params.append('titleId', titleId);
            params = params.append('isSubscribed', isSubscribed.toString());
            params = params.append('source', 'web');
            params = params.append('readerType', readerType);
        return this.http.get<any>(`${environment.apiUrl}/issue/wasclosed`, {params});
    }

  neighbours(titleId, volumeNumber, issueNumber) {
    return this.http.get<any>(`${environment.apiUrl}/issue/neighbors/${titleId}/${volumeNumber}/${issueNumber}`);
  }

  searchTitle(search_term, limit= '100') {
    const params = new  HttpParams().set('limit', limit);
    return this.http.get<any>(`${environment.apiUrl}/title/search/${search_term}`, {params});
  }

  shortLink(uri) {
    const name = encodeURIComponent(uri.replace('/', ''));
    return this.http.get<any>(`${environment.apiUrl}/shortlink/name/${name}`);
  }




  cancelSubscription() {
    return this.http.get(`${environment.apiUrl}/payment/cancel-subscription`);
  }

  reactivateSubscription() {
    return this.http.get(`${environment.apiUrl}/payment/reactivate-subscription`);
  }

  getUserSubscription() {
    return this.http.get(`${environment.apiUrl}/payment/user-subscription`);
  }


  getPrices() {
    return this.http.get(`${environment.apiUrl}/payment/prices`);
  }

  getProducts() {
    return this.http.get(`${environment.apiUrl}/payment/products`);
  }

  getCustomerId() {
    return this.http.get(`${environment.apiUrl}/payment/customer`);
  }




  createSubscription({ customerId, paymentMethodId, priceId }) {
    return this.http.post(`${environment.apiUrl}/payment/create-subscription`, { customerId, paymentMethodId, priceId });
  }


ratings<Array>() {
  return [
    {id: 'A', name: 'All ages', image: './assets/images/icon_age_rating_A@2x.png'},
    {id: 'T', name: 'Teens', image: './assets/images/icon_age_rating_T@2x.png'},
    {id: 'T+', name: 'Teens Plus', image: './assets/images/icon_age_rating_Tplus@2x.png'},
    {id: 'PA', name: 'Parental Advisory', image: './assets/images/icon_age_rating_PG@2x.png'},
    {id: 'MA', name: 'Mature', image: './assets/images/icon_age_rating_MA@2x.png'},
  ];
}

oldRatings() {
    return     [
         {id: 'A', name: 'All ages', image: './assets/images/title_age_rating_A@2x.png'},
         {id: 'T', name: 'Teens', image: './assets/images/title_age_rating_T@2x.png'},
        {id: 'T+', name: 'Teens Plus', image: './assets/images/title_age_rating_Tplus@2x.png'},
         {id: 'PA', name: 'Parental Advisory', image: './assets/images/title_age_rating_PG@2x.png'},
         {id: 'MA', name: 'Mature', image: './assets/images/title_age_rating_MA@2x.png'},
       ];
}


getReaderTypes() {
  return [
      {name: 'Webtoon (VERTICAL scroll)', type: 'webtoon'},
      {name: 'Comic & Graphic Novel', type: 'comic'},
      {name: 'Webcomic', type: 'webcomic'},
      {name: 'Comic Strip', type: 'strip'},
      {name: 'Manga (Read LEFT to RIGHT)', type: 'manga'},
      {name: 'Manga (Read RIGHT to LEFT)', type: 'jmanga'},
    ];
}

getReaderName(type) {
  let name;
  switch (type) {
      case 'webtoon':
        name = 'Webtoon (VERTICAL scroll)';
        break;
      case 'comic':
        name = 'Comic & Graphic Novel';
        break;
      case 'webcomic':
        name = 'Webcomic';
        break;
      case 'strip':
        name = 'Comic Strip';
        break;
      case 'manga':
        name = 'Manga (Read LEFT to RIGHT)';
        break;
      case 'jmanga':
        name = 'Manga (Read RIGHT to LEFT)';
        break;
        default:
          name = '';
    }
    return name;
  }



  popular(limit=500) {
    const data = {
        query:
        `query GetPopularTitles {
            popularTitles(limit:${limit}) {
              ${this.titleFields}
            }
        }`
    };
    return this.http.post<any>(`${environment.graphUrl}`, data);
}

  newTitles(limit=100) {
    const data = {
        query:
        `query GetNewTitles {
            newTitles(limit:${limit}) {
              ${this.titleFields}
            }
        }`
    };
    return this.http.post<any>(`${environment.graphUrl}`, data);
}


  liveTitles(limit=100) {
    const data = {
        query:
        `query GetNewTitles {
            liveTitles(limit:${limit}) {
                ${this.titleFields}
            }
        }`
    };
    return this.http.post<any>(`${environment.graphUrl}`, data);
}


  topTitles(limit=100) {
    const data = {
        query:
        `query GetNewTitles {
            topTitles(limit:${limit}) {
                ${this.titleFields}
            }
        }`
    };
    return this.http.post<any>(`${environment.graphUrl}`, data);
}

    featuredTitles(limit=100) {
        const data = {
            query:
            `query GetFeaturedTitles {
                featuredTitles(limit:${limit}) {
                    ${this.titleFields}
                }
            }`
        };
        return this.http.post<any>(`${environment.graphUrl}`, data);
    }

  featuredTitlesGenre(limit=100, genreId) {
    const data = {
        query:
        `query GetFeaturedTitlesGenre {
            featuredTitlesGenre(limit:${limit}, genreId: "${genreId}") {
                ${this.titleFields}
            }
            genre(id: "${genreId}") {
              genreName
              description
              objectId
            }
        }`
    };
    return this.http.post<any>(`${environment.graphUrl}`, data);
}



    getHomePage(limit=10) {
        const data = {
            query:
                `query HomePage ($limit: Int){
                    getLatestTitles(limit:$limit) {
                        ${this.minTitleFields}
                    }
                    getTrendingTitles(limit:$limit) {
                        ${this.minTitleFields}
                    }
                }`,
            variables: {limit}
        };
        return this.http.post<any>(`${environment.graphUrl}`, data);
    }




genreTitles(id, limit=100, sortBy='') {
    const data = {
        query:
        `query GetGenreTitles {
          genreTitles(id: "${id}", limit:${limit}, sort:"${sortBy}") {
            ${this.minTitleFields}
          }

        }
        `
    };
    return this.http.post<any>(`${environment.graphUrl}`, data);
}


    genreTitlesArray(genres, limit=15, sortBy='') {
        let genreQ = '';
        genres.forEach((id)=>{
            genreQ=genreQ +   `
                genre${id}: genreTitles(id: "${id}", limit:${limit}, sort:"${sortBy}") {
                    ${this.minTitleFields}
                }`;
        })
        const data = { query:
            `query GetGenreTitles {
                ${genreQ}
            }`
        };
        return this.http.post<any>(`${environment.graphUrl}`, data);
    }

genreTitlesMulti(genres, limit=100, sortBy='') {
    const data = {
        query:
        `query GetGenreTitles {
          genreTitlesMulti(id: "${genres}", limit:${limit}, sort:"${sortBy}") {
            ${this.minTitleFields}
          }

        }
        `
    };
    return this.http.post<any>(`${environment.graphUrl}`, data);
}






  genre(id) {
    const data = {
        query:
        `query GetGenre {
          genre(id: "${id}") {
            genreName
            description
          }
        }
        `
    };
    return this.http.post<any>(`${environment.graphUrl}`, data);
}

  genreByName(genreName) {
    const data = {
        query:
        `query GetGenre {
          genreByName(name: "${genreName}") {
            genreName
            description
            objectId
          }
        }
        `
    };
    return this.http.post<any>(`${environment.graphUrl}`, data);
}

    genres() {
        const data = {
            query:
                `query GetGenreS {
                    genres {
                        objectId
                        genreName
                        description
                    }
                }`
        };
        return this.http.post<any>(`${environment.graphUrl}`, data);
    }


listCountry: Array<{name: string, code: string}> =  [
  {'code': 'all', 'name': 'All languages'},
  {'code': 'ar', 'name': 'Arabic'},
  {'code': 'az', 'name': 'Azerbaijani'},
  {'code': 'be', 'name': 'Belarusian'},
  {'code': 'bg', 'name': 'Bulgarian'},
  {'code': 'bn', 'name': 'Bengali'},
  {'code': 'ca', 'name': 'Catalan'},
  {'code': 'cs', 'name': 'Czech'},
  {'code': 'cy', 'name': 'Welsh'},
  {'code': 'da', 'name': 'Danish'},
  {'code': 'de', 'name': 'German'},
  {'code': 'el', 'name': 'Greek'},
  {'code': 'en', 'name': 'English'},
  {'code': 'eo', 'name': 'Esperanto'},
  {'code': 'es', 'name': 'Spanish'},
  {'code': 'et', 'name': 'Estonian'},
  {'code': 'eu', 'name': 'Basque'},
  {'code': 'fa', 'name': 'Persian'},
  {'code': 'fi', 'name': 'Finnish'},
  {'code': 'fr', 'name': 'French'},
  {'code': 'ga', 'name': 'Irish'},
  {'code': 'gl', 'name': 'Galician'},
  {'code': 'gu', 'name': 'Gujarati'},
  {'code': 'hi', 'name': 'Hindi'},
  {'code': 'hr', 'name': 'Croatian'},
  {'code': 'ht', 'name': 'Haitian Creole'},
  {'code': 'hu', 'name': 'Hungarian'},
  {'code': 'id', 'name': 'Indonesian'},
  {'code': 'is', 'name': 'Icelandic'},
  {'code': 'it', 'name': 'Italian'},
  {'code': 'iw', 'name': 'Hebrew'},
  {'code': 'ja', 'name': 'Japanese'},
  {'code': 'ka', 'name': 'Georgian'},
  {'code': 'kn', 'name': 'Kannada'},
  {'code': 'ko', 'name': 'Korean'},
  {'code': 'la', 'name': 'Latin'},
  {'code': 'lt', 'name': 'Lithuanian'},
  {'code': 'lv', 'name': 'Latvian'},
  {'code': 'mk', 'name': 'Macedonian'},
  {'code': 'ms', 'name': 'Malay'},
  {'code': 'mt', 'name': 'Maltese'},
  {'code': 'nl', 'name': 'Dutch'},
  {'code': 'no', 'name': 'Norwegian'},
  {'code': 'none', 'name': 'No language'},
  {'code': 'pl', 'name': 'Polish'},
  {'code': 'pt', 'name': 'Portuguese'},
  {'code': 'ro', 'name': 'Romanian'},
  {'code': 'ru', 'name': 'Russian'},
  {'code': 'sk', 'name': 'Slovak'},
  {'code': 'sl', 'name': 'Slovenian'},
  {'code': 'sq', 'name': 'Albanian'},
  {'code': 'sr', 'name': 'Serbian'},
  {'code': 'sv', 'name': 'Swedish'},
  {'code': 'sw', 'name': 'Swahili'},
  {'code': 'ta', 'name': 'Tamil'},
  {'code': 'te', 'name': 'Telugu'},
  {'code': 'th', 'name': 'Thai'},
  {'code': 'tl', 'name': 'Filipino'},
  {'code': 'tr', 'name': 'Turkish'},
  {'code': 'uk', 'name': 'Ukrainian'},
  {'code': 'ur', 'name': 'Urdu'},
  {'code': 'vi', 'name': 'Vietnamese'},
  {'code': 'yi', 'name': 'Yiddish'},
  {'code': 'zh-Hans', 'name': 'Chinese Simplified'},
  {'code': 'zh-Hant', 'name': 'Chinese Traditional'}
];




numberToAge(number) {
  switch (number) {
    case 0:
      return 'A';
    break;
    case 1:
      return 'T';
    break;
    case 2:
      return 'T+';
    break;
    case 3:
      return 'PA';
    break;
    case 4:
      return 'MA';
    break;
  }
}

ageToNumber(value) {
  switch (value) {
    case 'A':
      return 0;
    break;
    case 'T':
      return 1;
    break;
    case 'T+':
      return 2;
    break;
    case 'PA':
      return 3;
    break;
    case 'MA':
      return 4;
    break;
  }
}


listAge:  Array<{label: string, value: string, number: number, img: string}> = [
  {'label': 'all', 'value': 'A', 'number': 0, 'img': 'A'},
  {'label': '10', 'value': 'T', 'number': 1, 'img': 'T'},
  {'label': '13', 'value': 'T+', 'number': 2, 'img': 'Tplus'},
  {'label': '16', 'value': 'PA', 'number': 3, 'img': 'PG'},
  {'label': '18', 'value': 'MA', 'number': 4, 'img': 'MA'},
];




titleCanView(title, user){
  if(!title){
    return;
  }
  if(!title.age_rating){
    return;
  }
  if(user){
        if(this.ageToNumber(title.age_rating)<=this.ageToNumber(user.max_age_rating)){
              //unlock all age restrictions
              title.lockedMature=false;
              title.lockedAge=false;
        }
        else{
            title.hideAge=true;
            if(title.age_rating==='MA'){
              title.lockedMature=true;
             }
        }
        if(user.subscription && user.subscription.status==='active'){
              title.lockSubscription=false;
        }
  }
  else{
    if(this.ageToNumber(title.age_rating)<=this.ageToNumber('MA')){
      //unlock all age restrictions
      title.lockedMature=false;
      title.lockedAge=false;
    } else{
       title.lockedAge=false;
       title.hideAge=true;
    }
    title.lockedAge=false;
      if(title.age_rating==='MA'){
          title.lockedMature=true;
          title.lockSubscription=true;
      }
  }

}

}
