import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, ActivatedRoute } from '@angular/router';
import {  AuthenticationService } from '../../_services';
@Component({
  selector: 'app-feed-layout',
  templateUrl: './feed-layout.component.html',
  styleUrls: ['./feed-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FeedLayoutComponent implements OnInit {
term:String;
currentUser;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  constructor(        private route: ActivatedRoute,

    private router: Router,
    private authService: AuthenticationService,
    ) {
      if(authService.isWindow()){
      authService.currentUser.subscribe((value) => {
        this.currentUser = value;
        });
      }

    }
    reason = '';

doSearch(term){
    this.router.navigate(['/s',term]);
    this.term='';
    this.sidenav.close();
}

  close(reason: string) {
    this.reason = reason;
    this.sidenav.close();
  }
  ngOnInit() {
  }

  doLogout(){
    this.authService.logout(true);
  }

}
