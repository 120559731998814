import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mature-popup',
  templateUrl: './mature-popup.component.html',
  styleUrls: ['./mature-popup.component.scss']
})
export class MaturePopupComponent implements OnInit {
  @Input() type:string;
  @Output() onclose = new EventEmitter<boolean>();
  constructor() { }
  close(){
      this.onclose.emit(true);
  }
  ngOnInit() {

  }


}
