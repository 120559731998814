<div class="row h-100">
        <main class="col-7 d-flex align-items-center">

        </main>
        <aside class="col-5 d-flex align-items-center">

                <div class="login-container row">

                        <mat-card class="login-card">
                                <mat-card-header>
                                        <mat-card-title>
                                                Log into {{ WEBSITE_NAME }}
                                        </mat-card-title>
                                </mat-card-header>
                                <mat-card-content>

                                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">

                                                <div class="form-group">
                                                        <mat-form-field appearance="outline">
                                                                <mat-icon matPrefix>person</mat-icon>
                                                                <mat-label>Username</mat-label>
                                                                <input matInput placeholder="Username" formControlName="username">
                                                                <mat-error *ngIf="f.username.errors && f.username.errors.required">Username is required</mat-error>
                                                        </mat-form-field>
                                                </div>
                                                <div class="form-group">
                                                        <mat-form-field appearance="outline">
                                                                <mat-icon matPrefix>lock</mat-icon>
                                                                <mat-label>Password</mat-label>
                                                                <input matInput placeholder="Password" formControlName="password" [type]="hide ? 'password' : 'text'">
                                                                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                                <mat-error *ngIf="f.password.errors && f.password.errors.required">Password is required</mat-error>
                                                        </mat-form-field>

                                                </div>
                                                <div class="form-group">

                                                        <img *ngIf="loading" class="pl-3"
                                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                                                </div>
                                                <div class="clear-both">
                                                        <mat-checkbox class="pull-left">Remember me</mat-checkbox>
                                                        <div *ngIf="!previewMode" routerLink="/forgotpassword" class="pull-right forget">Forgot password?</div>
                                                </div>


                                                <mat-card-actions>
                                                        <button [disabled]="f.password.errors || f.username.errors" mat-flat-button class="login">Login</button>
                                                </mat-card-actions>
                                        </form>

                                        <mat-spinner [style.display]="showSpinner ? 'block' : 'none'"></mat-spinner>

                                        <div *ngIf="!previewMode">
                                                <a routerLink="/register" class="btn btn-link">No account? Signup.</a>
                                        </div>

                                </mat-card-content>

                        </mat-card>
                </div>
        </aside>
</div>