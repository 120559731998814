<div class="mature-alert mobile">
    <i class="material-icons close-icon" (click)="close()">
        close
      </i>
  <img class="banner" (click)="close()" src="/assets/images/banner_graphite_premium_mobileweb.jpg">
  <div class="mature-text">
    <p [innerHTML]="'premium' | translate"></p>
    <p [innerHTML]="'downloadmobile' | translate"></p>
    <p>
      <a href="https://apps.apple.com/us/app/graphite-comics-get-drawn-in/id1367730938" target="_blank" style="margin-left:25px; margin-right:10px;">
        <img  src="./assets/images/appleappstore.png" style="max-width: 110px;">
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.graphite.graphitecomics&hl=en" target="_blank">
        <img  src="./assets/images/googleplay.png" style="max-width: 110px;">
      </a>
    </p>
    <button mat-button class="pink-mat small-round menu-button-blue">
      {{ 'GET GRAPHITE PREMIUM' | translate }}
    </button>
  </div>
</div>
<div class="mature-alert desktop">
    <i class="material-icons close-icon" (click)="close()">
        close
      </i>
  <img class="banner" src="/assets/images/banner_graphite_premium_desktop.jpg">
  <div class="mature-text">
    <span  *ngIf="type==='mature'">
      <p [innerHTML]="'premium' | translate"></p>
          <p>
            {{ 'Download our mobile app to get started!' | translate }}
          </p>

    </span>
    <span  *ngIf="type==='active_subscription'">
      <p [innerHTML]="'premium' | translate"></p>
      <p>
        {{ 'Download Graphite on iOS or Android' | translate }}
      </p>

    </span>


  <p style="margin-top:30px;">
      <a href="https://apps.apple.com/us/app/graphite-comics-get-drawn-in/id1367730938" target="_blank" style="margin-left:25px; margin-right:10px;">
        <img  src="./assets/images/appleappstore.png" style="max-width: 110px;">
  </a>
  <a href="https://play.google.com/store/apps/details?id=com.graphite.graphitecomics&hl=en" target="_blank">
        <img  src="./assets/images/googleplay.png" style="max-width: 110px;">
  </a>
  </p>

  <button mat-button class="pink-mat small-round menu-button-blue">
    {{ 'GET GRAPHITE PREMIUM' | translate }}
  </button>
    </div>
</div>
