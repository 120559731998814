import { isPlatformBrowser } from '@angular/common';
import { Component, NgZone, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Location } from '@angular/common';
import { AlertService, UserService } from '../../_services';
import { Title, Meta } from '@angular/platform-browser';

import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MatDialogConfig,  MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-feed-profile',
  templateUrl: './feed-profile.component.html',
  styleUrls: ['./feed-profile.component.scss']
})
export class FeedProfileComponent implements OnInit {
  feed;
  user: any;
  username: string;
  dateJoined: any;
  profile;
  schema;
  feedLoaded = false;
  isBrowser;
  error: any;
  permalink;
  platforms: Array<any>;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private ngZone: NgZone,
    private alert: AlertService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { console.log( 'constructor' ); }

    ngOnInit() {
        console.log( 'ngOnInit' );
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.route.params.subscribe(routeParams => {
            this.username = routeParams.username;
            this.loadFeed();
        });
    }

    loadFeed() {
        console.log( 'loadFeed', this.username );
        this.titleService.setTitle('Loading...');
        this.userService.getUserFeed( this.username ).pipe().subscribe(result => {
            if ( result.redirect ) {
                this.ngZone.run(() => this.router.navigateByUrl('/feed/' + result.redirect));
            } else {
                if ( result.error ) {
                    this.error = result.error;
                } else {
                    this.user       = result.user;
                    this.username   = this.user.username;
                    this.profile    = this.user.profile ? this.user.profile : {};

                    this.dateJoined = this.user.createdAt;
                    this.feed       = result.status;
                    this.permalink  = '/feed/' + this.username;
                    if ( this.profile && this.profile.platforms && this.profile.platforms.length > 0 ) {
                        this.platforms = this.profile.platforms.map( platform => {
                            return {
                                name: platform.platform.name,
                                type: platform.platform.name.toLowerCase(),
                                link: platform.platform.url + '/' + platform.handle,
                            };
                        });
                    }

                    var title = this.profile.name ? this.profile.name + ' (@'+ this.username + ')' : '@' + this.username;
                    this.titleService.setTitle( title );
                    this.feedLoaded = true;
                }
            }
        });

    }
    receiveMessage( $event ) {


    }
    openLink(url: string){
        window.open(url, "_blank");
    }
}