
<div *ngIf="isPlaceholder===true" class="top-banner-container">
    <div class="top-banner-max" >

        <div *ngIf="title" class="top-texts">
            <a ><h3>&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</h3></a>
            <h1>&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</h1>
            <p class="genres">
                    <a >&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;  &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</a>
            </p>

            <div *ngIf="rating" class="rating-line">
              <div style="display:inline-block;">

                  <span>
                    &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;
                  </span>
              </div>
            <div class="views-comments-likes">
                <div class="views">
                    <img  src="/assets/images/titledisplay_icon_eye_white@3x.png"><span > &#x2584;&#x2584; </span>
                    <img  src="/assets/images/titledisplay_icon_comment_white@3x.png"><span > &#x2584;&#x2584; </span>
                    <img  src="/assets/images/titledisplay_icon_heart_white@3x.png"><span > &#x2584;&#x2584; </span>
              </div>
          </div>
          <span>
            <button mat-button class="yellow-mat small-round rate-button not-implemented-hide">
                    <i class="fa fa-star"></i> <i class="star-filled default-star-filled-icon"></i> Rate
            </button>
          </span>
          <div class="bottom-text">
              <div *ngIf="itemAgeRating" class="age-rating-container">
                  <div class="age-rating-value">
                    <div style="width: 24px; height:24px; border-radius:100%; background-color: white; margin-top:5px;"></div>

          
                  </div>
                  <div class="readertype">
                    &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;
                  </div>
                  <div class="language">

                  </div>
              </div>
              <div class="subscribe-container not-implemented">

              </div>
          </div>
        </div>

    </div>
  <!--  </div>-->
</div>
</div>


<div *ngIf="isPlaceholder===false" class="top-banner-container">
    <div class="top-banner-max" >
      <svg class="image43" viewBox="0 0 800 600" preserveAspectRatio="xMidYMin slice">
        <rect width="100%" height="100%" fill="red" />
      </svg>
        <div *ngIf="title" class="top-texts">
            <a routerLink="/publisher/{{title.publisher_slug}}"><h3>{{publisher}}</h3></a>
            <h1>{{name}}</h1>
            <p class="genres">
                    <a *ngFor="let genre of genres; let i=index" routerLink="/genre/{{genre.genreName}}">{{i>0?' | ':''}} {{genre.genreName | translate}}</a>
            </p>

            <div  class="rating-line">
              <div  *ngIf="rating && isBrowser" style="display:flex;align-items: center;">
                  <star-rating [value]="rating" totalstars="5" checkedcolor="#f4cf06" uncheckedcolor="transparent" size="15px" readonly="true" (rate)="onRate($event)" ></star-rating>
                  <div style="line-height: 22px;    margin-left: 7px;">
                    <span class="rating-number"> {{rating||0}} </span>
                    <span>
                        ({{ratingCount}} {{ 'ratings' | translate }})
                    </span>                    
                  </div>

              </div>
            <div class="views-comments-likes">
                <div class="views">
                    <img  src="/assets/images/titledisplay_icon_eye_white@3x.png"><span > {{title.totalPageViews | viewsFormat}} </span>
                    <img  src="/assets/images/titledisplay_icon_comment_white@3x.png"><span > {{title.totalComments | viewsFormat}} </span>
                    <img  src="/assets/images/titledisplay_icon_heart_white@3x.png"><span > {{title.titleFavoriteCount | viewsFormat}} </span>
              </div>
          </div>
          <span>
            <button mat-button class="yellow-mat small-round rate-button not-implemented-hide">
                    <i class="fa fa-star"></i> <i class="star-filled default-star-filled-icon"></i> Rate
            </button>
          </span> 
          <div class="bottom-text"> 
              <div class="age-rating-container">
                  <div class="age-rating-value">
                      <img  [src]="itemAgeRating.image">
                  </div>
                  <div class="readertype">
                    {{readerName | translate}}
                  </div>
                  <div class="language">
                      <img *ngIf="language && language.image" [src]="language.image">
                  </div>
              </div>
              <div class="subscribe-container not-implemented">
                  <div>
                      <button mat-button class="circle-mat"  style="display:none;">
                              <i class="material-icons">
                                      share
                              </i>
                      </button>
                      <button  mat-button class="green-mat small-round">
                              <i class="material-icons">notifications_active</i> SUBSCRIBED
                          </button>
                      <button  mat-button class="circle-mat" style="display:none;">
                              <i class="material-icons">add</i>
                          </button>
                  </div>
              </div>
          </div>
        </div>

    </div>
  <!--  </div>-->
</div>
</div>