
<!-- main app container -->
<div class="main-app">
    <div class="container">
        <div class="row">
                <app-alert></app-alert>
                <router-outlet></router-outlet>
        </div>
    </div>
</div>

