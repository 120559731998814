import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from '../../_services';

@Component({
    selector: 'app-publisherslist',
    templateUrl: './publisherslist.component.html',
    styleUrls: ['./publisherslist.component.scss']
})
export class PublisherslistComponent implements OnInit {
    publishers: any;
    featuredPublishers: any;
    ugc: any;
    defaultImage: String='./assets/images/preloading_image_placeholder.png';
    isBrowser = false;
    isPlaceholder = true;
    isOld=false;
    constructor(
        private userService: UserService,
        private authService: AuthenticationService,
    ) { }

    ngOnInit() {
        this.isBrowser = this.authService.isWindow();
        if(this.isBrowser){
            this.loadPublishers();
        }
    }

    loadPublishers(){
        this.userService.getPublishers().pipe().subscribe(result=>{
            this.featuredPublishers=[];
            this.publishers=[];
            this.ugc=[];

            for ( let publisher of result ) {
                if ( publisher.name && publisher.logo ) {
                // if ( publisher.name && publisher.logo && publisher.coverImage ) {
                    if ( publisher.type == 'featured_publisher' ) {
                        this.featuredPublishers.push(publisher);
                    }
                    if ( publisher.type == "publisher" ) {
                        this.publishers.push(publisher);
                    }
                    if ( publisher.type == "ugc" ) {
                        this.ugc.push(publisher);
                    }
                }
            }
            this.isPlaceholder = false;
        });
    }
}
