<ul *ngIf="pager.pages && pager.pages.length" class="pagination">
  <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item first-item nav-item">
      <a (click)="setPage(1)" class="page-link" >{{ 'First' | translate}}</a>
  </li>
  <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item nav-item">
      <a (click)="setPage(pager.currentPage - 1)" class="page-link" >{{ 'Previous' | translate}}</a>
  </li>
  <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}" class="page-item number-item">
      <a (click)="setPage(page)" class="page-link number">{{page}}</a>
  </li>
  <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item nav-item">
      <a (click)="setPage(pager.currentPage + 1)" class="page-link" >{{ 'Next' | translate}}</a>
  </li>
  <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item last-item nav-item">
      <a (click)="setPage(pager.totalPages)" class="page-link" >{{ 'Last' | translate}}</a>
  </li>
</ul>
