import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from '../../_services';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  isBrowser = false;
  isPlaceholder = true;
  publishers: any;
  featuredPublishers: any;
  defaultImage: String = './assets/images/preloading_image_placeholder.png';
  constructor(private userService: UserService, private authService: AuthenticationService,) { }

  ngOnInit() {
    this.isBrowser = this.authService.isWindow();
    if(this.isBrowser){
      this.loadUploaders();
    }
    

  }

  loadUploaders(){
    this.userService.getPublishersUGC('500').pipe().subscribe(result => {

      this.publishers = [];
      for (const publisher of result) {
        if (publisher.name && publisher.logo && publisher.coverImage) {
            if (publisher.type === 'ugc') {
              this.publishers.push(publisher);
            }
        }

      }

      this.isPlaceholder = false;
    });
  }
}
