import { Component, OnInit, Input  } from '@angular/core';
import { UserService } from '../../_services';
//import { SwiperConfigInterface} from 'ngx-swiper-wrapper';


@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss']
})

export class RecommendedComponent implements OnInit {
  @Input() titleId: string;
  @Input() vertical: boolean = false;
  @Input() rowTitle: string = 'Other comics you might like';


    titles: any;
    private ratings = this.userService.ratings();
   config = { //: SwiperConfigInterface = {
    initialSlide: 0,
    direction: 'horizontal',
    slidesPerView: 5,
    keyboard: true,
    mousewheel: false,
    autoplay: false,
    navigation: true,
    preloadImages: false,
    lazy: true,
    centeredSlides: false,
    zoom: false,
    setWrapperSize: true,
  };
  all = false;
  index = 1;

  defaultImage: String = './assets/images/preloading_image_placeholder.png';
  constructor( private userService: UserService) { }

  ngOnInit() {
    this.userService.getRecommended(this.titleId, 30).subscribe(result => {
      this.titles = result;

    });
  }


getLink(title) {
      return '/title/' + title.publisher_slug + '/' + title.slug;
}

indexChange(ev) {

}
onInit() {


}

imagesReady() {
  console.log('imagesReady');
}

lazyImageReady() {
  console.log('lazyImageReady');
}


getAgeRating(title){
  for (let r of this.ratings){
    if (r.id === title.age_rating){
          return r.image;
    }
  }
  return '';
}


}
