
<div [ngClass]="layout + ' d-flex flex-row my-auto'">
    <div class="avatar px-2"><img (click)="gotoProfile()" [src]="photo" class="rounded-circle img-fluid" alt="{{ user.username }}" /></div>
    <div class="flex-grow-1 my-auto">
        <div class="name lh-sm"><a [routerLink]="permalink">{{ name }}</a></div>
        <div class="username lh-sm"><a [routerLink]="permalink">@{{ username }}</a></div>
    </div>
    <div class="px-2">
        <button (click)="follow()" type="button" mat-mini-fab aria-label="Follow">
            <mat-icon svgIcon="user-plus"></mat-icon>
        </button>
    </div>
</div>
