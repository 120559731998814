import { Component, OnInit, Input, HostListener, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { UserService, AuthenticationService, WINDOW } from '../../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogPlanComponent } from '../../dialog-plan/dialog-plan.component';
// import Panzoom, { PanzoomObject } from '@panzoom/panzoom';

declare var postscribe;

@Component({
    selector: 'app-comic-reader',
    templateUrl: './comic-reader.component.html',
    styleUrls: ['./comic-reader.component.scss']
})
export class ComicReaderComponent implements OnInit, AfterViewInit {
    winHeight     : number;
    showRightAd   = false;
    showLeftAd    = false;
    showHeader    : boolean;
    showFirst     : boolean;
    countLoaded   : any;
    allpages      : any;
    defaultImage  : any;
    index         : number;
    showNav       = true;
    calculationsCompleted = false;
    lasti = 0;
    lastTop = 0;
    currentPage = 0;
    y = 0;
    firstLoaded = 1;
    loadBefore = 1;
    firstLoadedThumb = 5;
    loadBeforeThumb = 7;
    scroll = false;
    min=1;
    max: any;
    pageCount: any;
    active_subscription_or_preview: boolean;
    next_active_subscription_or_preview: boolean;
    pageWidth = 800;
    pageHeight;
    isWindow=false;
    currentUser:any;
    pageMargin=30;
    pagenr=1;
    readerType: string;
    showSlider: boolean;
    disabled = false;
    invert;
    step;
    thumbLabel;
    tickInterval;
    vertical;
    canstop=false;
    worker: Worker;
    keepPage;
    startTime: Date;
    zoomLevel= 1;
    hasAccess = false;
// panzoom: PanzoomObject;
    constructor(
        @Inject(WINDOW) private window: Window,
        private userService: UserService,
        private authService: AuthenticationService,
        private router: Router,
        private location: Location,
        private route: ActivatedRoute,
        private dialog: MatDialog,
    ) {
        if ( this.authService.isWindow() ) {
            this.authService.currentUser.subscribe((value) => {
                this.currentUser = value;
            });
            fromEvent(this.window, 'scroll').pipe(
                tap(() => this.scroll = true),
                debounceTime(100)
            ).subscribe((event) => {
                this.scroll = false;
                this.y = this.window.scrollY;
                this.findImageByScroll();
            });
            fromEvent(this.window, 'resize').pipe(
                debounceTime(100)
            ).subscribe((event) => {
                this.keepPage=true;
                let page=this.currentPage+1;
                this.reinitAll();
                this.gotopage(page);
            });
        }
    }
    @Input() issue;
    @Input() navnext;
    @Input() navprev;
    @Input() prevIssue;
    @Input() nextIssue;
    @Input() neighbours;
    @ViewChild('slides_container', {static: true}) container : ElementRef;
    @HostListener('window:scroll', ['$event']) onScroll(e) {

        const doch = document.documentElement.scrollHeight;
        if (this.winHeight !== doch) {
            this.winHeight = doch;
            return;
        }

        this.showHeader = false;
        //this.showSlider = false;
        this.showFirst = false;
        const element = document.getElementById('header');
        try {
            element.classList.add('sticky');
        } catch (err) {
            console.error(err);
        }
    }

    // Event on click > show/hide Header
    @HostListener('click', ['$event']) onMouseDown(e) {
        e.stopPropagation();
        if(e && e.toElement) {
            if (e.toElement.className === 'swiper-button-prev' || e.toElement.className === 'swiper-button-next') {
                return;
            }
            if (e && e.toElement  && e.toElement.className.indexOf('mat-slider-thumb') >= 0) {
                this.hideHeader();
                return;
            }
        }

        try {
            this.showHeader = !this.showHeader;
            this.showSlider = this.showHeader;
            this.showFirst = false;
            const element = document.getElementById('header');

            element.classList.add('sticky');
        } catch (err) {
            console.log(err);
        }
    }

    @HostListener('window:orientationchange', ['$event']) onOrientationChange(event) {
        this.keepPage=true;
        let page=this.currentPage+1;
        this.reinitAll();
        this.gotopage(page);
    }

    moveSlide(evt) {
        this.pagenr= evt.value;
    }

    movePage(evt){
        this.gotopage(this.pagenr);
    }

    reinitAll(){
        this.calculationsCompleted=false;
        this.lasti= 0;
        this.initViewer();
        this.keepPage=false;
    }

    pageLoaded(page, index=null) {
        if ( page.displayed ) { return; }
        if ( this.currentPage!==index ) {
            return;
        }
        if ( page.full===true || this.issue.readerType==='strip' ) {
            page.displayed = true;
            this.userService.pageWasDisplayed(page.objectId, this.issue.objectId, this.issue.title.objectId, this.issue.readerType, this.userService.isSubscribed(this.currentUser)).subscribe(result => {
                page.displayed = true;
            });
        }
    }

    displayHeader() {
        this.showHeader = true;
        this.showSlider = this.showHeader;
        this.showFirst = false;
    }

    hideHeader() {
        this.showHeader = false;
        this.showSlider = this.showHeader;
    }



  pagePreLoaded(evt, index) {
    // console.log("dsadsadasdsadsa", this.issue.pages)
    // const page = this.issue.pages[index];
    // page.height = evt.path[0].height;
    // page.height = evt.target.height;
    // this.calculateImages();
  }



  showAlertIssue(): void {
    const dialogRef = this.dialog.open(DialogPlanComponent, {
      // opening dialog here which will give us back stripeToken
      width: '100%',
      height: '100%',
      panelClass: 'payment-modalbox',

  });
 
    dialogRef.afterClosed().subscribe(result => {
      this.canView();
    });
  }


  getPageImageVertical(page, index) {
        const url = this.userService.getPageImageJPG(page.objectId);
        return url;
  }


  getVerticalDefaultImage(page, index) {
    if (index <= this.firstLoaded) {
        return this.userService.getPageImageJPG(page.objectId);
    } else {
        return this.userService.getThumbnail(page.objectId);
    }
  }
  getHeight() {

    return this.window.innerHeight + 100;
  }


  navigate(issue) {
    this.showHeader = true;
    this.showSlider = this.showHeader;
    // this.router.navigate(['/issue', issue.publisher_slug, issue.title_slug, issue.slug]) .then(() => {
    //   //this.window.location.reload();
    // });

    this.window.location.href=`/issue/${issue.publisher_slug}/${issue.title_slug}/${issue.slug}`;
  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

  navigateOld(id) {
    this.showHeader = true;
    this.showSlider = this.showHeader;
    this.router.navigate(['/issue', id]) .then(() => {
      this.window.location.reload();
    });
  }


  ngOnInit() {
    this.startTime=new Date();
    if(this.authService.isWindow()){
      this.initViewer();
    }
    if(this.issue && this.issue.readerType==="strip"){

      this.pageLoaded(this.issue.pages[0],0);
    }
  }


  initPages(){
    
    if (this.issue.readerType === 'webtoon') {
      this.readerType = 'webtoon';
  
    } 
    else if (this.issue.readerType === 'jmanga') {
        this.readerType = 'jmanga';
   
    } 
    else {
      this.readerType = 'comic';
    }  
  
  }




  ngAfterViewInit() {
    //this.pageWidth=this.container.nativeElement.offsetWidth;

    if(this.authService.isWindow()){
      this.isWindow=true;

      setTimeout(() => {
       this.initViewer();
       
      },0);
      //this.initViewer();
    }

     
    setTimeout(() => {
        this.loadScriptsLeft();
        //this.loadScriptsRight();
        this.pagenr =Number(this.route.snapshot.paramMap.get('page')) ;
        this.gotopage(this.pagenr);

    }, 1000);
     






  }

  
    gotopage(pagenr){
        console.log('gotopage',pagenr);
        if ( !pagenr || pagenr===0 ) {
            pagenr=1;
        }

        if ( !this.issue ) {
            return;
        }
        if ( !this.issue.pages ) {
            return;
        }
        if ( this.issue.pages.length===0 ) {
            return;
        }
        if(!this.issue.pages[pagenr-1]){
            return;
        }

        this.pagenr = pagenr;
        let y = 0;
            y = this.issue.pages[pagenr-1].top;
        this.window.scrollTo(0,y);

        this.loadAround(pagenr-1);
        this.location.replaceState('/issue/'  + this.issue.publisher_slug + '/' + this.issue.title_slug + '/' + this.issue.slug + '/' + this.pagenr);

        const elem = document.getElementById('main-reader-container');

        //  this.panzoom = Panzoom(elem, {contain: 'outside',
        //   maxScale: 5, startScale: this.zoomLevel,
        //   disableYAxis:true,
        //   origin:'50% 0',
        //   //panOnlyWhenZoomed:true,

        // })

        // elem.addEventListener('panzoomend', (event) => {
        // console.log('Event: ', event['detail']); // => { x: 0, y: 0, scale: 1 }
        // console.log('Pan: ',this.panzoom.getPan());
        // let x=event['detail'].x;
        // let yr=event['detail'].y;
        // let y=this.window.scrollY-yr;
        // this.window.scrollTo(x,y);

        //this.panzoom.pan(0, 0, { relative: true });
        //this.panzoom.pan(x, 0);
        //   })



        // if(this.readerType==='jmanga'){
        //   setTimeout(() => {
        //     this.index=Number(this.pageCount)-Number(this.pagenr)+1;
        //   });

        // }
        // else{
        //     setTimeout(() => {
        //       this.index=this.pagenr-1;
        //     });
        // }
    }

    dozoom(){
        return;
        // this.panzoom.zoomIn();
    }

    canView() {

    if( this.issue.accessRule === 'active_subscription'){
      this.issue['lockSubscription']=true;
    }
    
    if(this.currentUser){
     
          if(this.userService.ageToNumber(this.issue.title.age_rating)<=this.userService.ageToNumber(this.currentUser.max_age_rating)){
                //unlock all age restrictions
                this.issue['lockedMature']=false;
                this.issue['lockedAge']=false;
          }
          else{
            this.issue['hideAge']=true;
              if(this.issue.title.age_rating==='MA'){
                this.issue['lockedMature']=true;
               }
          }
          if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status==='active'){
            this.issue['lockSubscription']=false;
          }

    }
    else{
      if(this.userService.ageToNumber(this.issue.title.age_rating)>this.userService.ageToNumber('MA')){
        this.issue['lockedAge']=true;
      }
      else{
        this.issue['lockedAge']=false;
      }
        if(this.issue.title.age_rating==='MA'){
          this.issue['lockedMature']=true;
        }
    }

}


  neighbourCanView(accessRule){
    let hasAccess=true;
    if(accessRule === 'active_subscription'){
            hasAccess=false;
            if(this.currentUser){
                  if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status==='active'){
                        hasAccess=true;
                  }
            }
            else{
                hasAccess=false;
            }
      } else{
        hasAccess=true;
      }
      return hasAccess;
}

    initViewer() {
        setTimeout(()=> {
            this.pageWidth = this.window.innerWidth;
            this.pageWidth=this.container.nativeElement.offsetWidth;

            if (!this.issue) {
                return;
            }
            // ACTIVE SUBSCRIPTION
            if ( this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status==='active' ) {

            } else {
                if (this.issue.accessRule === 'active_subscription_or_preview' && typeof this.issue.accessRuleValue === 'number') {
                    this.active_subscription_or_preview = true;
                    this.issue.pages = this.issue.pages.slice(0, this.issue.accessRuleValue);
                    this.max = this.pageCount = this.issue.pages.length;
                }
            }

            for ( let i = 0 ; i < this.issue.pages.length; i++ ) {
                if( !this.issue.pages[i].aspectRatio ) {
                    this.issue.pages[i].aspectRatio=800/600;
                }
                this.issue.pages[i].height = Math.floor( (this.pageWidth) * this.issue.pages[i].aspectRatio);
            }
            this.calculateImages();
            for ( let i = 0 ; i < this.issue.pages.length; i++ ) {
                if ( i <= this.firstLoaded ) {
                    this.issue.pages[i].src = this.userService.getPageImageJPG(this.issue.pages[i].objectId);
                    this.issue.pages[i].isThumb = false;
                    this.issue.pages[i].full = true;
                } else if ( i<this.firstLoadedThumb ) {
                    this.issue.pages[i].src = this.userService.getThumbnail(this.issue.pages[i].objectId);
                    this.issue.pages[i].isThumb = true;
                }
            }

            this.pageCount = this.issue.pages.length;
            this.max=this.pageCount;
        })
    }

    calculateImages() {
        if ( !this.issue ) {
            return;
        }
        if ( !this.issue.pages ) {
            return;
        }
        if ( this.issue.pages.length===0 ){
            return;
        }
        if ( this.calculationsCompleted === true ) {
            return;
        }

        if ( this.lasti === 0 ) {
            this.issue.pages[0].top = 0;
            this.lasti = 1;
        }

      for (let i = this.lasti; i < this.issue.pages.length; i++) {
          this.issue.pages[i].top = this.issue.pages[i - 1].top + this.issue.pages[i - 1].height * this.zoomLevel + this.pageMargin * this.zoomLevel;
          this.lastTop = this.issue.pages[i].top;
          if (!this.issue.pages[i].height) {
            break;
          }
          this.lasti = i;
          if (i === (this.issue.pages.length - 1)) {
            this.calculationsCompleted = true;
            this.lastTop = this.issue.pages[this.lasti].top +  this.issue.pages[this.lasti].height * this.zoomLevel + this.pageMargin * this.zoomLevel;

            if (this.y === 0 && this.window.scrollY > 0) {
              this.y = this.window.scrollY;
            }
            this.findImageByScroll();
          }
      }



}

      imageLoaded(i) {

      }






      cancelOther(index){
        
        // if (typeof Worker !== 'undefined') {
        //   this.worker = new Worker('../../../app.worker', { type: 'module' });;
        //   this.worker.onmessage = ({ data }) => {
        //       console.log(data);
        //   };
        // } else {
        //   console.log("Web Workers are not supported by your browser");
        // }
        // this.worker.postMessage("start looping...");
        if(this.authService.isWindow()){

            let dif=new Date().getTime()  - this.startTime.getTime();

            if(dif>90000){
              //window.stop();
            }
            // 

          
          let start, end;
          if (index === 0) {
              start = 0;
              end = this.firstLoaded;
          } else {
            start = index - this.loadBefore;
            if (start < 0) {
              start = 0;
            }
            end = index + this.loadBefore;
          }

          if (end >= this.issue.pages.length) {
              end = this.issue.pages.length - 1;
          }

            for (let i = 0; i < this.issue.pages.length; i++) {
              //if(i!==index){
                    if(this.issue.pages[i].thumbloading===true){
                      this.issue.pages[i].isThumb=false;
                      this.issue.pages[i].thumbloading=false;
                      this.issue.pages[i].thumbimg.src="";
                      //this.issue.pages[i].src="";
                      // delete this.issue.pages[i].thumbimg;
                    }
                    if(this.issue.pages[i].loading===true){
                        this.issue.pages[i].full=false;
                        this.issue.pages[i].loading=false;
                        this.issue.pages[i].img.src="";
                        //this.issue.pages[i].src="";
                        //delete this.issue.pages[i].img;
                    }

            // }         

            }
          
        }
      }

      loadAround(index) {

        this.cancelOther(index);

        if(this.authService.isWindow()){
          this.loadCurrentThumb(index);
          if(index<this.issue.pages.length-1){
            this.loadCurrentThumb(index+1);
          }
          if(index>0){
            this.loadCurrentThumb(index-1);
          }
          if(index<this.issue.pages.length-2){
            this.loadCurrentThumb(index+2);
          }
          if(index>1){
            this.loadCurrentThumb(index-2);
          }
          this.loadCurrentPage(index);


          if(index<this.issue.pages.length-3){
            this.loadCurrentThumb(index+3);
          }

          if(index>2){
            this.loadCurrentThumb(index-3);
          }
          if(index<this.issue.pages.length-4){
            this.loadCurrentThumb(index+4);
          }
          if(index>3){
            this.loadCurrentThumb(index-4);
          }


          this.loadAroundThumbs();
          let start, end;
          if (index === 0) {
              start = 0;
              end = this.firstLoaded;
          } else {
            start = index - this.loadBefore;
            if (start < 0) {
              start = 0;
            }
            end = index + this.loadBefore;
          }

          if (end >= this.issue.pages.length) {
              end = this.issue.pages.length - 1;
          }

            for (let i = start; i <= end; i++) {
                this.issue.pages[i].img = new Image();
                this.issue.pages[i].img.src = this.userService.getPageImageJPG(this.issue.pages[i].objectId);
                this.issue.pages[i].loading=true;
                this.issue.pages[i].img.onload = () => {
                  this.issue.pages[i].loading=false;
                  this.issue.pages[i].full = true;
                  this.issue.pages[i].isThumb = false;
                  this.issue.pages[i].src = this.userService.getPageImageJPG(this.issue.pages[i].objectId);
                  this.pageLoaded(this.issue.pages[i], i);
                };
            }
          
        }

      }



      loadCurrentPage(index){
        if (!(this.issue.pages[index].loading===true || this.issue.pages[index].full)){
          let i=index;
          this.issue.pages[index].img = new Image();
          this.issue.pages[index].img.src = this.userService.getPageImageJPG(this.issue.pages[index].objectId);
          this.issue.pages[index].loading=true;
          this.issue.pages[index].img.onload = () => {
            this.issue.pages[index].loading=false;
            this.issue.pages[index].full = true;
            this.issue.pages[index].isThumb = false;
            this.issue.pages[index].src = this.userService.getPageImageJPG(this.issue.pages[index].objectId);
            this.pageLoaded(this.issue.pages[index], index);
          };
        }
      }



      loadCurrentThumb(index){

          if (!(this.issue.pages[index].thumbloading===true || this.issue.pages[index].isThumb===true || this.issue.pages[index].full)){
            let i=index;
            this.issue.pages[i].thumbimg = new Image();
            this.issue.pages[i].thumbimg.src = this.userService.getThumbnail(this.issue.pages[i].objectId);
            this.issue.pages[i].thumbloading=true;
            this.issue.pages[i].thumbimg.onload = () => {
                  this.issue.pages[i].thumbloading=false;
                  this.issue.pages[i].isThumb===true
                  this.issue.pages[i].src = this.userService.getThumbnail(this.issue.pages[i].objectId);

            };
          }
      }


      loadAroundThumbs(){
        const index= this.currentPage;
        let startedToLoad=0;

        //loading exact position for furrent

        if (!(this.issue.pages[index].thumbloading===true || this.issue.pages[index].isThumb===true || this.issue.pages[index].full)){
          let i=index;
          this.issue.pages[i].thumbimg = new Image();
          this.issue.pages[i].thumbimg.src = this.userService.getThumbnail(this.issue.pages[i].objectId);
          this.issue.pages[i].thumbloading=true;
          this.issue.pages[i].thumbimg.onload = () => {
                this.issue.pages[i].thumbloading=false;
                this.issue.pages[i].isThumb===true
                this.issue.pages[i].src = this.userService.getThumbnail(this.issue.pages[i].objectId);
          };
        }

        if ((index+1)<this.issue.pages.length){
          let i=index+1;
        if(this.issue.pages[i].thumbloading===true || this.issue.pages[i].isThumb===true || this.issue.pages[i].full){

        }
        else{
              this.issue.pages[i].thumbimg = new Image();
              this.issue.pages[i].thumbimg.src = this.userService.getThumbnail(this.issue.pages[i].objectId);
              this.issue.pages[i].thumbloading=true;
              this.issue.pages[i].thumbimg.onload = () => {
                    this.issue.pages[i].thumbloading=false;
                    this.issue.pages[i].isThumb===true
                    this.issue.pages[i].src = this.userService.getThumbnail(this.issue.pages[i].objectId);
              };
        }
          

        }




      //end current


        let start, end;
        if (index === 0) {
            start = 0;
            end = this.firstLoadedThumb;
        } else {
          start = index - this.loadBeforeThumb;
          if (start < 0) {
            start = 0;
          }
          end = index + this.loadBeforeThumb;
        }

        if (end >= this.issue.pages.length) {
            end = this.issue.pages.length - 1;
        }

          for (let i = start; i <= end; i++) {
              if (this.issue.pages[i].thumbloading===true || this.issue.pages[i].isThumb===true || this.issue.pages[i].full===true){
                  continue;
              }

              this.issue.pages[i].thumbimg = new Image();
              this.issue.pages[i].thumbimg.src = this.userService.getThumbnail(this.issue.pages[i].objectId);
              this.issue.pages[i].thumbloading=true;
              this.issue.pages[i].thumbimg.onload = () => {
                    this.issue.pages[i].thumbloading=false;
                    this.issue.pages[i].isThumb===true
                    this.issue.pages[i].src = this.userService.getThumbnail(this.issue.pages[i].objectId);
              };

          }

      }

      findImageByScroll() {

          if (this.y < this.lastTop) {
              // search for image in that position and load x images after it
              // find position
              
              const index = 0;
              for (let i = 0; i < this.issue.pages.length; i++) {
                if(this.y< (this.issue.pages[0].height * this.zoomLevel)){
                  this.currentPage = 0;
                  this.pagenr=this.currentPage+1;
                  this.location.replaceState('/issue/'  + this.issue.publisher_slug + '/' + this.issue.title_slug + '/' + this.issue.slug + '/' + this.pagenr);
                  this.pageLoaded(this.issue.pages[this.currentPage], i);

                  this.loadAround(this.currentPage);
                  break;            
                } else{
                    if (this.issue.pages[i].top < this.y && (this.issue.pages[i].top + (this.issue.pages[i].height * this.zoomLevel+this.pageMargin * this.zoomLevel)) > this.y) {
                      this.currentPage = i;
                      this.pagenr=this.currentPage+1;
                      this.location.replaceState('/issue/'  + this.issue.publisher_slug + '/' + this.issue.title_slug + '/' + this.issue.slug + '/' + this.pagenr);
                      this.pageLoaded(this.issue.pages[this.currentPage], i);

                      this.loadAround(this.currentPage);
                      break;
                    }            
                }



              }

          }
      }

      onSwipe(ev){
          if(ev.offsetDirection){
            if(ev.offsetDirection===2){
              //next
              if(this.nextIssue){
                  this.navigate(this.nextIssue);
              }
            }
            if(ev.offsetDirection===4){
              //prec
              if(this.prevIssue){
                this.navigate(this.prevIssue);
              }
            }

          }
      }



      loadScriptsLeft(){
        if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription==='active'){
          return;
        }
        try {
              postscribe('#amznLeft', `
              <div id="amzn-assoc-ad-cc72a99a-606b-4d7f-8ac5-5ff8c5678b1d"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=cc72a99a-606b-4d7f-8ac5-5ff8c5678b1d"></script>
            `);    
        } catch (error) {
          
        }

      }


      loadScriptsRight(){
        if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription==='active'){
          return;
        }
      return;
        postscribe('#amznRight', `
        <div id="amzn-assoc-ad-cc72a99a-606b-4d7f-8ac5-5ff8c5678b1d"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=cc72a99a-606b-4d7f-8ac5-5ff8c5678b1d"></script>
      `);
      }

      closeLeftAd(){
            this.showLeftAd=false;
            let rand = Math.round(Math.random() * (15000 - 500)) + 5500;
            setTimeout(()=>{
              this.showLeftAd=true;
              // this.loadScriptsLeft();
            },rand)

      }
      closeRightAd(){

            this.showRightAd=false;
            let rand = Math.round(Math.random() * (15000 - 500)) + 5500;
            setTimeout(()=>{
            this.showRightAd=true;
            // this.loadScriptsRight();
            },rand)
      }
}
