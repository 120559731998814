<a *ngIf="isOnlyUrl === true; else morethanurl"
[ngStyle]="{'background-color': website.bgColor,'color':website.textColor}"
class="external-link"
href="{{protocolFix(externalLink.url)}}"
target="_blank">
  {{website.text | translate }}
  <img style="float:right;" src=".{{website.icon}}" />
</a>
<ng-template #morethanurl>
<a
  class="external-link"
  [ngStyle]="{'background-color':externalLink.backgroundColor,'color':externalLink.textColor}"
  href="{{protocolFix(externalLink.url)}}"
  target="_blank"
    >
    {{externalLink?.displayText}}
    <img [src]="externalLink.linkImg? externalLink?.linkImg:'./assets/images/external_link/external_link_icon_chevron.png'" />
  </a>
</ng-template>
