<div *ngIf="isPlaceholder===false" class="publishers-list">
    <div class="featured-container">
        <h2>{{ title | translate }}</h2>
        <div class="gray-divider"></div>
        <div class="container">
            <div *ngFor="let publisher of publishers">
                <app-publisher-element [publisher]="publisher"></app-publisher-element>
            </div>
        </div>
    </div>
</div>

<div  *ngIf="isPlaceholder===true" class="publishers-list">
    <div class="featured-container">
        <div class="orange-divider"></div>
        <div class="container">
            <a class="publisher-item"
                *ngFor="let dummy of ' '.repeat(15).split(''), let i = index" >
                <img src="./assets/images/publisher_listing_placeholder.png">
            </a>
        </div>
    </div>
</div>




