<div class="main row h-100">
    <div *ngIf="error || isPlaceholder===false" class="d-inline-block d-none d-xl-block">
        <app-feed-nav navLayout="horizontal"></app-feed-nav>
    </div>
    <div *ngIf="error" class="error col-6 col-xl-4 col-xxl-3 mx-auto mt-4">
        <h2>{{ error.label }}</h2>
        <div class="message">{{ error.message }}</div>
    </div>
    <div *ngIf="isPlaceholder===false" class="d-inline-block d-block col-sm-8 col-xl-12 h-100 feed overflow-scroll d-flex flex-wrap">
        <div #feed *ngFor="let status of feed; let even = even; let odd = odd"  class="col-12 col-xl-4 col-xxl-3">
            <app-feed-card [status]="status" (messageEvent)="receiveMessage($event)"></app-feed-card>
        </div>
    </div>
    <div *ngIf="error || isPlaceholder===false" class="d-inline-block d-none d-sm-block col-sm-4 d-xl-none h-100">
        <app-feed-nav navLayout="vertical"></app-feed-nav>
    </div>
</div>