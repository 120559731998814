import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { PopupServiceService } from '@app/_services/popup-service.service';
import { UserService, AlertService } from '../../_services';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MustMatch } from '../../_helpers/must-match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  token: string;
  email: string;
  forgotForm: UntypedFormGroup;
  loading = true;
  submitted = false;
  isValid= false;
  didReset= false;
  hide:boolean;
  showSpinner = false;
  constructor(private route: ActivatedRoute, private userService: UserService,       private formBuilder: UntypedFormBuilder,
    private router: Router, private alertService: AlertService
    ) { }
    get f() { return this.forgotForm.controls; }
  ngOnInit() {
    this.hide=true;

    this.email = this.route.snapshot.paramMap.get("email");
    this.token = this.route.snapshot.paramMap.get("token");
    this.userService.validateReset(this.email, this.token).subscribe(result=>{
        this.loading=false;

        if (result['isValid']===true){
          this.isValid=true;
        }

    });
    this.forgotForm = this.formBuilder.group({
      password: ['', [Validators.required, 
        Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/)]],
        passwordAgain: ['', [Validators.required]],
    },{
      validator: MustMatch('password', 'passwordAgain')
  });
  }



  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotForm.invalid) {
        this.alertService.error('Please check the values');
        return;
    }

    this.loading = true;
    this.userService.resetPassword(this.email, this.token, this.f.password.value)
        .subscribe(
            data => {

                this.alertService.snacksuccess('Password changed');
                if(data['didReset']===true){
                    this.didReset=true;
                }
            },
            error => {

                this.alertService.error(error.message);

            });
}


}
