import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from '../../_services';
import {ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-publisher-page',
  templateUrl: './publisher-page.component.html',
  styleUrls: ['./publisher-page.component.scss']
})
export class PublisherPageComponent implements OnInit {
  schema;
  titles: any;
  publisher: any;
  error: Boolean = false;
  defaultImage: String = './assets/images/preloading_image_placeholder.png';
  isPlaceholder = true;
  isBrowser = false;
  isPublisherLoaded = false;
  description: String;

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta
    ) { }

  ngOnInit() {
    this.isBrowser=this.authService.isWindow();
    this.titles = [];
    this.publisher = {};
    const publisherId = this.route.snapshot.paramMap.get('publisherId');
    const publisherSlug = this.route.snapshot.paramMap.get('publisherSlug');
    if (publisherId) {
      this.userService.getPublisherById(publisherId).subscribe(result => {
              this.publisher = result;
              this.publisherLoaded();
          },
          error => {
            this.error = true;
          }

      );

      this.loadTitles(publisherId);
    } else if (publisherSlug) {
        this.userService.getPublisherBySlug(publisherSlug).subscribe(result => {
          this.publisher = result;
          this.publisherLoaded();
          this.loadTitles(this.publisher.objectId);
        },
        error => {
          this.error = true;
        });
    }


  }

  publisherLoaded() {
    this.description = this.publisher.description;
    this.populateSchema();
    const logoImage = this.publisher.logo && this.publisher.logo.url ? this.publisher.logo.url : this.defaultImage;
    this.titleService.setTitle('Graphite Comics - ' + this.publisher.name);
    this.metaTagService.updateTag({name: 'description', content: this.publisher.description ||  'Read titles from ' + this.publisher.name + ' free on Graphite Comics!'});
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://graphitecomics.com/publisher/' + this.publisher.slug });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Graphite Comics: Free manga, webtoons, graphic novels and webcomics.' });
    this.metaTagService.updateTag({ property: 'og:description', content: this.publisher.description || 'Read titles from ' + this.publisher.name + ' free on Graphite Comics!' });
    this.metaTagService.updateTag({ property: 'og:image', content: logoImage });


    // Twitter metadata
    this.metaTagService.addTag({name: 'twitter:card', content: 'summary'});
    this.metaTagService.addTag({name: 'twitter:site', content: 'GraphiteComics'});
    this.metaTagService.addTag({name: 'twitter:title', content: 'Graphite Comics: Free manga, webtoons, graphic novels and webcomics.' });
    this.metaTagService.addTag({name: 'twitter:description', content: this.publisher.description || 'Read titles from ' + this.publisher.name + ' free on Graphite Comics!' });
    this.metaTagService.addTag({name: 'twitter:text:description', content: this.publisher.description || 'Read titles from ' + this.publisher.name + ' free on Graphite Comics!' });
    this.metaTagService.addTag({name: 'twitter:image', content: logoImage});

    this.isPublisherLoaded=true;
  }

    loadTitles(publisherId) {

        if(!this.isBrowser){
            return;
        }
        this.userService.getPublisherTitles(publisherId, '100').subscribe(
            titles => {
                this.titles = titles;

                this.isPlaceholder = false;
            },
            error => {
                this.titles = null;
            }
        );
    }


  populateSchema() {
    const schema = {
      '@context': 'http://schema.org/',
      '@type' : 'Organization',
      'name': this.publisher.name,
      'url' : `https://graphitecomics.com/publisher/${this.publisher.slug}`,
      'description': this.publisher.description ? this.publisher.description : '',
      'logo': this.publisher && this.publisher.logo  && this.publisher.logo.url ? this.publisher.logo.url : '',
      'image': this.publisher && this.publisher.coverImage  && this.publisher.coverImage.url ?  this.publisher.coverImage.url : ''
  };
  this.schema = schema;



  }

}
