import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;
    constructor(private router: Router, private _snackBar: MatSnackBar) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next(null); // TODO this variable was added and I don't know what it does
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
    }

    snackerror(message: string, duration = 10000) {
        let config = new MatSnackBarConfig();
        config.duration = duration;
        config.panelClass = ['errorsnack'];
        this._snackBar.open(message, 'Dismiss', config);
    }
    snacksuccess(message: string, duration = 10000) {
        let config = new MatSnackBarConfig();
        config.duration = duration;
        config.panelClass = ['successsnack'];
        this._snackBar.open(message, 'Dismiss', config);
    }
    snackmessage(message: string, duration = 10000) {
        let config = new MatSnackBarConfig();
        config.duration = duration;
        config.panelClass = ['messagesnack'];
        this._snackBar.open(message, 'Dismiss', config);
    }


    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
    }

    httperror(message: any, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}