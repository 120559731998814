import { Component, OnInit, AfterViewInit, Input, HostListener, ElementRef,
  ViewChild, Renderer2, ViewChildren, QueryList, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, AuthenticationService, WINDOW } from '../../_services';

@Component({
  selector: 'app-topslider-mobile',
  templateUrl: './topslider-mobile.component.html',
  styleUrls: ['./topslider-mobile.component.scss']
})
export class TopsliderMobileComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private userService: UserService,
    private authService: AuthenticationService,
    private renderer: Renderer2) { 

      this.authService.currentUser.subscribe((value) => {
        this.currentUser = value;
      });

    }

  maxheight = 548;
  margintop = 0;
  initial = false;
  isBrowser = false;
  currentUser;

  @Input() isPlaceholder: Boolean;
  @Input() genreId: String;
  @Input() genre: any;
  @Input() slides: [];
  @ViewChildren('imgslide') imgslides: QueryList<any>;
  @ViewChild('slidesection', { static: true }) container: ElementRef;

  index = 0;
  speed = 8000;
  infinite = true;
  direction = 'right';
  directionToggle = true;
  autoplay = true;

  ngAfterViewInit() {
        const innerHeight = (this.window.innerHeight);
        //this.maxheight = innerHeight / 2;
        this.initial = false;
  }

  ngOnInit() {
    this.isBrowser = this.authService.isWindow();

  }

  

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {
      const innerHeight = (this.window.innerHeight);
      // this.maxheight = innerHeight / 2;
    }
    @HostListener('window:resize', ['$event'])
    onResize() {
          this.centerImage();
    }


    centerImage() {
      if (!this.imgslides) {
        // return;
      }
      const innerHeight = (this.window.innerHeight);
      // this.maxheight = innerHeight / 2;
      if (this.imgslides && this.imgslides.first && this.container && this.container.nativeElement) {
            const h = this.container.nativeElement.offsetHeight;
            const imgh = this.imgslides.first.nativeElement.offsetHeight;
            if (h < imgh) {
                // this.margintop=(h-imgh)+20;
                this.margintop = 0;
            } else {
              this.margintop = 0;
            }

            this.imgslides.forEach(imgel => {
              this.renderer.setStyle(imgel.nativeElement, 'margin-top', this.margintop + 'px');
            });
            this.isPlaceholder=false;
      }

    }



    getLinkG(title) { 
      if(!title){
        return '';
      }
      if (title && title.action && title.action!==null) {
        switch (title.action) {
          case 'goToPublisher': {
            return '/publisher/' + title.publisher_slug;
            break;
          }
          case 'openURL': {
            return title.featuredActionValue;
            break;
          }
          case 'doNothing': {
            return '/';
            break;
          }
          default: {
            if(title && title.publisher_slug){
              return '/title/' + title.publisher_slug + '/' + title.slug;
            }
            
            break;
          }
        }
      } else {
        return '/title/' + '/' + title.publisher_slug + '/' + title.slug;
      }
  
    }

getImageG(slide) {
    if (!slide) {
      return;
    }
    if (slide.special) {
        return slide.special.url;
    }
    return slide.url;
}
getTitleG(slide) {
    if(slide && slide!==false){
        return slide.title;
    }
    return'';
}
getPublisherG(slide) {
    if(slide && slide!==false){
        return slide.publisher;
    }
    return'';
}


getBgImage(slide) {
  if (!slide) {
    return;
  }
  if (slide.wide) {
      return slide.wide.url;
  }
  return slide.url;
}


  goToTitle(title) {
    let link = '';
    if (title.action && title.action!==null) {
      switch (title.action) {
        case 'goToPublisher': {
          link = '/publisher/' + title.publisher_slug;
          break;
        }
        case 'openURL': {
          link = title.featuredActionValue;
          break;
        }
        case 'doNothing': {
          link = '/';
          break;
        }
        default: {
          link = '/title/'  + title.publisher_slug + '/' + title.slug;
          break;
        }
      }
    } else {
      link = '/title/' + title.publisher_slug + '/' + title.slug;
    }
    if (link && link.length > 0) {
      this.router.navigate([link]);
    }
    if (title) {
      if (title.link && title.link.length > 0) {
        this.router.navigate([link]);
      }
    }

  }


  indexChanged(index) {

  }


  indexChangedG(index) {

  }

  toggleDirection($event) {
    this.direction = this.directionToggle ? 'right' : 'left';
  }



}

