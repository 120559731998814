<div class="main-app">
    <app-header></app-header>
    <!-- main app container -->
    <!-- <div class="container-fluid pb-3 flex-grow-1 d-flex flex-column flex-sm-row"> -->
    <div class="container-fluid pb-3">
        <div class="row">
            <app-alert></app-alert>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<mat-divider class="w-100"></mat-divider>
<app-footer></app-footer>
<go-top-button   [animate]="true"
    [speed]="50"
    [acceleration]="2"
    [scrollDistance]="300"
    [classNames]="'scroll-custom-class'"
    [styles]="{
    'border-radius': '20px 20px 20px 20px',
    'color': '#ff00de',
    'border': '5px solid',
    'line-height': '20px',
    'right': '20px',
    'position': 'fixed',
    'bottom': '20px',
    'cursor':'pointer'
}">
<i class='fa fa-arrow-up'></i>
</go-top-button>
