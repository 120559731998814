import { AfterViewInit, Component, ElementRef, Inject, ViewChild, VERSION } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UserService, AuthenticationService, WINDOW } from '../../_services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Location } from '@angular/common';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types/swiper-options';

/*
https://medium.com/@fbanyai/swiper-10-angular-16-uncovered-1721bf32dfd6
*/
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements AfterViewInit {
    @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
    @ViewChild('swiperThumbs') swiperThumbs!: ElementRef<SwiperContainer>;
    currentUser:any;
    deviceInfo: any = null;
    isMobile = false;
    isiOS=false;
    images: Array<any>;
    index = 0;
    imageCount = 0;
    pageWidth = 800;
    pageHeight;
    isWindow=false;
    ready=false;

    public imagesConfig: SwiperOptions = {
        
        direction: 'horizontal',
        slidesPerView: 1,
        keyboard: true,
        mousewheel: true,
        autoplay:false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        centeredSlides: true,
        observer: true,
        autoHeight: false,
        setWrapperSize: true,
        watchSlidesProgress: true,
        preventClicks: true,
    }

    public thumbsConfig: SwiperOptions = {
        direction: 'horizontal',
        mousewheel: false,
        keyboard: false,
        watchSlidesProgress: true,
        autoHeight: false,
        centeredSlides: false,
        preventClicks: true,
        setWrapperSize: true,
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<ImageViewerComponent>,
        @Inject(WINDOW) private window: Window,
        private route: ActivatedRoute,
        private userService: UserService,
        private titleService: Title,
        private metaTagService: Meta,
        private authService: AuthenticationService,
        private deviceService: DeviceDetectorService,
        private location: Location
    ) {
        if ( data ) {
            this.images = data.images;
            this.index  = data.index;
            this.imageCount = this.images.length;
            console.log('index', this.index);
            console.log('imageCount', this.imageCount);
        }
        this.dialogRef.updateSize('300vw','300vw');
        this.authService.currentUser.subscribe((value) => {
            this.currentUser = value;
        });
        this.deviceInfo = this.deviceService.getDeviceInfo();
        if (this.deviceService.isMobile()) {
            this.isMobile = true;
            this.isiOS=true
        } else if (this.deviceInfo.os === 'IOS') {
            this.isMobile = true;
            this.isiOS=true;
        } else if (this.deviceInfo.os === 'ANDROID') {
            this.isMobile = true;
            this.isiOS=true
        } else {

        }

    }

    ngAfterViewInit() {
        console.log('ngAfterViewInit');
        if(this.authService.isWindow()){
            this.isWindow = true;
        }
        this.ready = true;
        
        this.swiper.nativeElement.swiper.activeIndex = this.index;
        this.swiperThumbs.nativeElement.swiper.activeIndex = this.index;
        
    }

    close() {
        this.dialogRef.close();
    }

    slideChange(swiper: any) {
        this.index = swiper.detail[0].activeIndex;
    }

}
