<div class="feed-post">
    <div class="my-2">
        <div (click)="close()"> <svg class="feather"> <use xlink:href="/assets/images/feather-sprite.svg#x-circle"></use> </svg></div>
    </div>
    <div class="d-flex flex-row">
        <div class="first"><img [src]="thumbnail" class="rounded-circle" class="avatar" alt="{{ username }}" /></div>
        <div class="flex-fill mx-2">
            <form [formGroup]="statusForm" (ngSubmit)="postStatus()" enctype="multipart/form-data" class="comment-form">
                <div class="form-group">
                    <textarea
                        id="status"
                        matInput
                        placeholder="What is happening?"
                        formControlName="status"
                        (keyup)="onKey($event)"
                    ></textarea>
                    <mat-error *ngIf="error"></mat-error>
                </div>
                <input type="file" formControlName="images" multiple class="file-input" accept="image/jpeg" (change)="onFileSelected( $event )" #fileUpload>
            </form>
            <div class="thumbnails"><ng-template #statusImages></ng-template></div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="ui">
        <div class="d-flex flex-row mx-1">


            <span class="mx-1">

                <button class="upload-btn" (click)="fileUpload.click()">
                    <svg class="feather"> <use xlink:href="/assets/images/feather-sprite.svg#image"></use> </svg>
                </button>
            </span>
            <span class="mx-1 ms-auto"><button mat-flat-button [disabled]="disabled" color="primary" (click)="postStatus()">Post</button></span>
        </div>
    </div>
</div>