import { Component, OnInit, Input, Inject, PLATFORM_ID, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-feed-gallery',
  templateUrl: './feed-gallery.component.html',
  styleUrls: ['./feed-gallery.component.scss']
})
export class FeedGalleryComponent implements OnInit {
    @Input() images: Array<any>;
    @Output() messageEvent = new EventEmitter<string>();
    image: any;
    constructor(
         @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    ngOnInit() {
        console.log( 'images', this.images.length );
        if ( this.images.length == 1 ) {
            this.image = this.images[0];
        }
    }

    launchImageViewer( index ) {
        console.log('launchViewer', index );
        this.messageEvent.emit( index );
    }
}
