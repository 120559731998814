import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Location } from '@angular/common';
import { AuthenticationService, UserService, WINDOW } from '../../_services';
import { Title, Meta } from '@angular/platform-browser';

import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComicViewerComponent } from '../comic-viewer/comic-viewer.component';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {
  feed;
  schema;
  isPlaceholder = true;
  isBrowser: boolean = false;
  username!: string;
  hashtag!: string;
  feedType: string = 'feed';
  error: any;
  currentUser: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
    private authService: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
  ) {
    console.log('Loading Feed');
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.authService.currentUser.subscribe((value) => {
        this.currentUser = value;
      });
    }
  }

    ngOnInit() {

        this.isBrowser = isPlatformBrowser(this.platformId);
        this.route.url.subscribe(([url]) => {
            const { path, parameters } = url;
            console.log('path', '=>', path); // e.g. /products
            console.log('path', '=>', parameters); // e.g. { id: 'x8klP0' }
          });
        this.route.params.subscribe(routeParams => {
            if ( routeParams.username ) {
                this.username = routeParams.username;
                this.feedType = 'username';
                console.log( 'username', this.username );
            }

            if ( routeParams.hashtag ) {
                this.hashtag  = routeParams.hashtag;
                this.feedType = 'hashtag';
                console.log( 'hashtag', this.hashtag );
            }
        });

        this.loadFeed();
        this.titleService.setTitle('Feed');
    }

    loadFeed() {
        console.log( 'loadFeed', this.feedType );
        switch ( this.feedType ) {
            case 'hashtag':
                console.log( this.hashtag );
                this.userService.getHashtagFeed( this.hashtag ).pipe().subscribe(result => {
                    if ( result.error ) {
                        this.error = result.error;
                    } else {
                        this.feed = result.status;
                        this.isPlaceholder = false;
                    }
                });
                break;
            case 'username':

                this.userService.getUserMentionsFeed( this.username ).pipe().subscribe(result => {
                  this.feed = result.status;;
                  this.isPlaceholder = false;
                });


            default:
                this.userService.getFeed().pipe().subscribe(result => {
                  this.feed = result.status;;
                  this.isPlaceholder = false;
                });
        }
    }

    receiveMessage( $event ) {
        const status = JSON.parse( $event );
        switch ( status.event ) {
          case 'launchImageViewer':
            this.launchImageViewer( status );
            break;
          case 'removeBookmark':
            console.log( 'Remove Bookmark' );
            break;
          case 'openContent':
            this.openContent( status );
            break;
        }
    }

    openContent( status ) {
        const dialogRef = this.dialog.open( ComicViewerComponent, {
            panelClass : 'feed-overlay',
            autoFocus: true,
            hasBackdrop: true,
            backdropClass: 'backdropBackground',
            width:'100%',
            height:'100%',
            //ariaModal: true,
            position: { 'top': '0' },
            maxWidth: '100vw',
            maxHeight: '100vw',
            data: status.issueId,
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    launchImageViewer( status ) {
        const dialogConfig = new MatDialogConfig();
            dialogConfig.panelClass = 'overlay';
            dialogConfig.autoFocus = true;
            dialogConfig.hasBackdrop = true;
            dialogConfig.backdropClass = 'backdropBackground';
            dialogConfig.position = { 'top': '0' };
            dialogConfig.width = 'calc(100%)';
            dialogConfig.height = 'calc(100%)';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
            dialogConfig.data = { images: status.images, index: status.index };

        const dialogRef = this.dialog.open( ImageViewerComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');

        });
    }
}