import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../_services';
import * as _  from 'lodash';

@Component({
  selector: 'app-comment-reply',
  templateUrl: './comment-reply.component.html',
  styleUrls: ['./comment-reply.component.scss']
})
export class CommentReplyComponent implements OnInit {
    @Input() reply:any;

    constructor() { }

    ngOnInit() {
    }

    getImage(user){
        if ( user.profileImage ) {
            return 'url(' +  user.profileImage.url + ')';
        }

        return "url('assets/images/graphy.png')";
    }
}
