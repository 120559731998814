import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { UserService, AuthenticationService, WINDOW } from '../../_services';
import {ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
declare var amplitude: any;
declare const postscribe;
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { DialogPlanComponent } from '../dialog-plan/dialog-plan.component';




@Component({
  selector: 'app-issue-details',
  templateUrl: './issue-details.component.html',
  styleUrls: ['./issue-details.component.scss']
})
export class IssueDetailsComponent implements OnInit, AfterViewInit {
  public isBrowser;

  public schema;

  public isWindow = true;
  artist;
  writer;
  itemAgeRating: any;
  language: any;
  issues: [];
  orderedIssues: Array<any>;
  pageOfIssues: Array<any>;
  initialPage = 1;
  volumeIssues: Array<any>;
  firstLoad = true;
  issue: any;
  issueId: any;
  title: any;
  titleId: any;
  genres: any;
  name: String;
  image: String;
  publisher: String;
  bannerImage: String;
  defaultImage: String = './assets/images/preloading_image_placeholder.png';
  readerType: String;
  description: String;
  error = false;
  displayAlert = false;
  isMature = false;
  issueLabel;
  volumes = [];
  sortasc = true;
  showPagination = true;
  showInfo = false;
  creators = '';
  isVolume = false;
  alertType = 'mature';
  issueSlug: any;
  titleSlug: any;
  publisherSlug: any;
  isIssueLoaded=false;
  isPlaceholder=true;
  currentUser:any;
  constructor(
      @Inject(PLATFORM_ID) private platformId: any,
      @Inject(WINDOW) private window: Window,
      private authService: AuthenticationService,
      private userService: UserService,
      private titleService: Title,
      private metaTagService: Meta,
      private route: ActivatedRoute,
      private router: Router,
      private location: Location,
      private translate: TranslateService,
      private dialog: MatDialog,

  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if(this.isBrowser){
      this.authService.currentUser.subscribe((value) => {
        this.currentUser = value;
      });
     }

  }


  getFullIssueURL(issue) {
    return 'https://graphitecomics.com' + this.userService.getIssueURL(issue);
  }


  getIssueURL(issue) {
    return  this.userService.getIssueURL(issue);
  }

  isLocked(issue) {
    if (this.isMature || issue.accessRule === 'active_subscription' || issue.accessRule === 'mobile') {
      return true;
    }
    return false;
  }
  showAlertIssue(issue): void {
    const dialogRef = this.dialog.open(DialogPlanComponent, {
      // opening dialog here which will give us back stripeToken
      width: '100%',
      height: '100%',
      panelClass: 'payment-modalbox',

    });
    

    dialogRef.afterClosed().subscribe(result => {
      this.refreshIssue();
      

    });
  }
  refreshIssue(){
        this.canView(this.issue);
        for (const issue of this.issues) {
          this.canView(issue);
        }
  }

  getUnlocPreview(issue) {
    if (this.isLocked(issue)) {
        if (this.isMature && !(issue.accessRule === 'active_subscription')) {
          return 'readwithpremium';
          // return 'readwithgraphite';
        }
        if (issue.accessRule === 'active_subscription') {
            return 'readwithpremium';
        }
    }
    if (issue.accessRule === 'active_subscription_or_preview') {
      return 'exclusivepreview';
    }

  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.loadScripts();
    },3000);
    

  }


  ngOnInit() {
    this.route.params.subscribe(routeParams => {
        this.issueId = routeParams.issueId;
        this.issueSlug = routeParams.slug;
        this.titleSlug = routeParams.title_slug;
        this.publisherSlug = routeParams.publisher_slug;

        this.volumes = [];

        if (routeParams.pageNumber) {
          this.initialPage = Number(routeParams.pageNumber);
        } else {
          this.initialPage = 1;
        }




        this.loadIssue().subscribe(issue=>{
          this.canView(issue);
          this.issue = issue;
          if(this.isBrowser){
                    
                      if (amplitude) {
                        try {
                          amplitude.getInstance().logEvent('title_opened', {'issueId' : this.issueId});
                        } catch (error) {
                        }
                      }
          }



            
            if (this.issue.title.age_rating === 'MA') {
              this.isMature = true;
            }

            if (this.issue.title.featuredBanner && this.issue.title.featuredBanner.url) {
              this.bannerImage = this.issue.title.featuredBanner.url;
            } else if (this.issue.title.featuredBannerWide && this.issue.title.featuredBannerWide.url) {
              this.bannerImage = this.issue.title.featuredBannerWide.url;
            }

            this.name = this.issue.name;
            const cover = this.fixExtension();
            const thumb = this.issue.thumb.url;
            const logoImage = cover ? cover : thumb ? thumb : this.defaultImage;
            this.titleService.setTitle('Read ' + this.issue.title.name + ' from ' + this.issue.title.publisher.name + ' free on Graphite Comics!');
            this.metaTagService.addTag({name: 'description', content: this.issue.description || 'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators'});
            this.metaTagService.addTag({ property: 'og:type', content: 'website' });
            this.metaTagService.addTag({ property: 'og:url', content: this.getFullIssueURL(this.issue)});
            this.metaTagService.addTag({ property: 'og:title', content:  'Read ' + this.issue.name + ' from ' + this.issue.title.publisher.name + ' free on Graphite Comics!'});
            this.metaTagService.addTag({ property: 'og:description', content: this.issue.description ||  'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators' });
            this.metaTagService.addTag({ property: 'og:image', content: logoImage });


                  // Twitter metadata
      this.metaTagService.addTag({name: 'twitter:card', content: 'summary_large_image'});
      this.metaTagService.addTag({name: 'twitter:site', content: 'GraphiteComics'});
      this.metaTagService.addTag({name: 'twitter:title', content: 'Read ' + this.issue.title.name + ' from ' + this.issue.title.publisher.name + ' free on Graphite Comics!'});
      this.metaTagService.addTag({name: 'twitter:description', content: this.issue.description ||  'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators'});
      this.metaTagService.addTag({name: 'twitter:text:description', content: this.issue.description ||  'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators'});
      this.metaTagService.addTag({name: 'twitter:image', content: logoImage});


            this.readerType = this.issue.readerType;
            this.artist = this.getCredit('artist');
            this.writer = this.getCredit('writer');

            this.loadTitle(this.issue.title.objectId);


            /* Populate JSON-ld
            */
           let language = 'EN';
           if (this.title && this.title.languages && this.title.languages.length > 0) {
               language = this.title.languages[0].language.toUpperCase();
           }
           const schema = {
             '@context': 'http://schema.org/',
             '@type': 'ComicIssue',
             'description': this.issue.description,
             'name': this.issue.name,
             'issueNumber': this.issue.number,
             'commentCount': this.issue['commentCount'],
             'datePublished': this.issue.live_date.iso,
             'inLanguage': language,
             'isAccessibleForFree': true,
             'thumbnailUrl': this.issue.thumb.url,
             'publisher': {
                   '@type': 'Organization',
                   'url': 'https://graphitecomics.com/publisher/' + this.issue.publisher_slug 
             },
             'isPartOf': 'https://graphitecomics.com/title/' + this.issue.title.publisher_slug + '/' + this.issue.title.slug,
             'url': this.getFullIssueURL(this.issue),
       
         };
       
             const writers = [];
             let persons = this.getCreditArray('writer');
             for (const writer of persons) {
                 writers.push({
                   '@type': 'Person',
                   'name': writer
                 });
             }
             if (writers && writers.length > 0) {
               schema['author'] = writers;
             }
       
             const artists = [];
             persons = this.getCreditArray('artist');
             for (const artist of persons) {
                 artists.push({
                   '@type': 'Person',
                   'name': artist
                 });
             }
             if (artists && artists.length > 0) {
               schema['artist'] = artists;
             }
       
       
         const pages = [];
         if (this.issue.pages) {
             for (const page of this.issue.pages) {
                 pages.push(page.url);
             }
             schema['image'] = pages;
       
         }
         this.schema = schema;


   /* END JSON-ld
   */

         this.isIssueLoaded=true;


        });
    });
  }



  loadIssue() {
    this.genres = [];
    this.firstLoad = true;
    if (this.issueId) {
        return  this.userService.getIssue(this.issueId);
    } else if (this.issueSlug) {
        return this.userService.getIssueBySlug(this.publisherSlug, this.titleSlug, this.issueSlug);
    }

  }


  loadTitle(titleId) {
        if(!this.isBrowser){
          return;
        }
        this.userService.getTitle(titleId).subscribe(result => {
          this.title = result;
          this.loadIssues(titleId);

          this.ratingsRow();
        });
  }


  ratingsRow() {

            const ratings = [
              {id: 'A', name: 'All ages', image: './assets/images/icon_age_rating_A@2x.png'},
              {id: 'T', name: 'Teens', image: './assets/images/icon_age_rating_T@2x.png'},
              {id: 'T+', name: 'Teens Plus', image: './assets/images/icon_age_rating_Tplus@2x.png'},
              {id: 'PA', name: 'Parental Advisory', image: './assets/images/icon_age_rating_PG@2x.png'},
              {id: 'MA', name: 'Mature', image: './assets/images/icon_age_rating_MA@2x.png'},
            ];

            for (const r of ratings) {
                if (r.id === this.title.age_rating) {
                      this.itemAgeRating = r;
                }


            }
            if (this.title && this.title.languages) {
              if (this.title.languages.length > 0) {
                this.language = this.title.languages[0];

                if (this.language.language !== 'en') {
                  this.language['image'] = 'assets/languages/icon_flag_' + this.language.language + '@2x.png';
                }

              }

            }
  }

  getCredit(type) {
    if (!this.issue.credits || this.issue.credits.length === 0) {
      return null;
    }
    const person = [];
    for (const credit of this.issue.credits) {
        if (credit.type === type) {
          person.push(credit.person.name);
        }
    }
    if (person.length > 0) {
      return person.join(', ');
    }
    return null;
  }

  getCreditArray(type) {
    if (!this.issue.credits || this.issue.credits.length === 0) {
      return [];
    }
    const person = [];
    for (const credit of this.issue.credits) {
        if (credit['type'] === type) {
          person.push(credit['person']['name']);
        }
    }

    return person;
  }

  fixExtension() {
      if (!this.issue.cover_image || this.issue.lockSubscription===true) {
        if (this.issue.thumb) {
          return this.issue.thumb.url.replace('.jp2', '.jpg').replace('.jpf', '.jpg');
        }
        return null;
      }
      const src = this.issue.cover_image;
      return src.replace('.jp2', '.jpg').replace('.jpf', '.jpg');
  }
  getMobileBanner() {
        if (this.issue.title.thumb) {
          return this.issue.title.thumb.url.replace('.jp2', '.jpg').replace('.jpf', '.jpg');
        }

      
      const src = this.issue.cover_image;
      return src.replace('.jp2', '.jpg').replace('.jpf', '.jpg');
  }

  get readerName() {
    return this.userService.getReaderName(this.readerType);
  }
  loadIssues(titleId) {
    if(!this.isBrowser){
      return;
    }
    this.userService.getIssues(titleId).subscribe(issues => {
      for (const issue of issues) {
        this.canView(issue);
      }
      this.issues = issues;
      this.orderedIssues = issues;


      if (this.isWebToonOrWebComic(this.readerType) === true) {
          this.description = this.orderedIssues['0']['description'];
      }
      if (this.issues.length <= 10) {
        this.pageOfIssues = this.issues;
      }
      this.splitInVolumes();
      const creatorsArr = [];
      if (this.title && this.title.creator) {
            for (const creator of this.title.creator) {
              creatorsArr.push(creator.name);
            }
            this.creators = creatorsArr.join(', ');
      }
        this.isPlaceholder=false;
    });
  }

  isWebToonOrWebComic(type) {
      if (type === 'webtoon' || type === 'webcomic') {
        return true;
      }
      return false;
  }

  splitInVolumes() {
    let volumeLabel;
    this.issueLabel = 'issues';
    if (this.orderedIssues && this.orderedIssues.length > 0 && this.isWebToonOrWebComic(this.orderedIssues['0']['readerType']) === true) {
          volumeLabel = this.translate.instant('season');
          this.issueLabel = 'episodes';
    } else {
      volumeLabel = this.translate.instant('volume');
      this.issueLabel = 'issues';
    }

    this.volumes[0] = {title: this.translate.instant('all'), issues: this.orderedIssues.length};
    for (const issue of this.orderedIssues) {
        const volumes = this.orderedIssues.filter(t => t['volume_number'] === issue['volume_number']);
        this.volumes[issue['volume_number']] = {title: volumeLabel + ' ' + issue['volume_number'], issues: volumes.length};
    }
    this.volumes.push({title: this.translate.instant('info')});

  }

 changeSort(val) {
     this.sortasc = val;
     if (this.sortasc === true) {
       this.orderedIssues = _.orderBy(this.orderedIssues, ['volume', 'number'], ['asc', 'asc']);
       this.volumeIssues = _.orderBy(this.volumeIssues, ['volume', 'number'], ['asc', 'asc']);
     } else {
       this.orderedIssues = _.orderBy(this.orderedIssues, ['volume', 'number'], ['desc', 'desc']);
       this.volumeIssues = _.orderBy(this.volumeIssues, ['volume', 'number'], ['desc', 'desc']);
     }

 }

  volumeChanged(evt) {
      if (evt.index === 0) {
        // is all
        this.showInfo = false;
        this.showPagination = true;
        this.isVolume = false;
        return;
      }
      if (evt.index === this.volumes.length - 1) {
        // is info
        this.showInfo = true;

        return;
      }


      // is volume
      this.showPagination = false;
      this.showInfo = false;
      this.isVolume = true;
      this.volumeIssues = _.filter(this.orderedIssues, function(o) {
        return o['volume_number'] === evt.index ;
      });
  }

  onclose() {
    this.displayAlert = false;
  }



  viewPages(issue) {
      this.router.navigate(['/reader/' + issue.objectId]);
  }


  populateSchema() {



  }


  openIssue(issue){
    if(this.isLocked(issue) ){
        this.showAlertIssue(issue);
    }
    else{
      this.router.navigate(['/issue/',  issue.publisher_slug, issue.title_slug, issue.slug]);
    }
}


  onChangePage(pageOfIssues) {
    const scrollToTop = setInterval(() => {
        const pos = this.window.pageYOffset;
        if (pos > 0) {
            this.window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            clearInterval(scrollToTop);
        }
    }, 4);
    const p = pageOfIssues.pageNumber;
    // update current page of items
    this.pageOfIssues = pageOfIssues.pageOfIssues;

    if (this.firstLoad === false) {
        this.location.go(this.userService.getIssueURL(this.issue)  + '/' + p, '', 1);
    }
    this.firstLoad = false;
  }



  canView(issue){
    if(!issue){
      return;
    }
      if( issue.accessRule === 'active_subscription'){
        issue.lockSubscription=true;
      }
      
      if(this.currentUser){
       
            if(this.userService.ageToNumber(issue.title.age_rating)<=this.userService.ageToNumber(this.currentUser.max_age_rating)){
                  //unlock all age restrictions
                  issue.lockedMature=false;
                  issue.lockedAge=false;
            }
            else{
                issue.hideAge=true;
                if(issue.title.age_rating==='MA'){
                  issue.lockedMature=true;
                 }
            }
            if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status==='active'){
                  issue.lockSubscription=false;
            }

      }
      else{
        if(this.userService.ageToNumber(issue.title.age_rating)>this.userService.ageToNumber('MA')){
          issue.lockedAge=true;
        }
        else{
          issue.lockedAge=false;
        }
        issue.lockedAge=false;
          if(issue.title.age_rating==='MA'){
              issue.lockedMature=true;
              issue.lockSubscription=true;
          }
      }

}


      loadScripts(){
          if(this.authService.isWindow()){
            try {
              postscribe('#amzntitle_issue', `<div id="amzn-assoc-ad-dce8ec42-4a7c-4c05-ae27-212f630cd433" style="background:white;"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=dce8ec42-4a7c-4c05-ae27-212f630cd433"></script>
              `);
            } catch (error) {
              
            }
                
            
          }
      }
}
