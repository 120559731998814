<div class="placeholder" *ngIf="isPlaceholder"
style="width:100%; overflow:hidden; opacity: .5; box-sizing: border-box;">

  <div class="titlesrow" [ngClass]="{'all':all}" *ngIf="!slider">
    <h2 style="opacity:1;" class="row-title" >{{rowTitle | translate}}</h2>
    <div class="title-row" style="    margin-right: 10px;
    overflow: hidden;">
      <div  [ngClass]="{'in-home':!all}" class="title-container" *ngFor="let dummy of ' '.repeat(15).split(''), let i = index" >
        <a>
            <div class="image-container">
              <svg viewBox="0 0 500 500" preserveAspectRatio="xMidYMin slice">
                <rect width="100%" height="100%" fill="silver" />
              </svg>
  
            </div>
            <div style="display: flex;height:86px; flex-direction: column;    justify-content: space-between; padding-top:10px; padding-bottom:10px; box-sizing: border-box;">
              <div>
                <div style="height:5px; width:50px; background-color:#646464; border-radius:10px;opacity:.3; margin-bottom:10px;"></div>            
                <div style="height:12px; width:100px; background-color:#000000; opacity:.3;"></div>
              </div>
              <div style="height:5px; width:50px; background-color:#646464; border-radius:10px;opacity:.3;"></div>            
            </div>
        </a>
  
      </div>

    </div>
  </div>




  <div class="titlesrow" [ngClass]="{'all':all}" style="width:100%; overflow:hidden;"  *ngIf="slider">

    <div style="height:26px; display:flex;align-items: center;padding-left: 10px; margin-top: 10px;     padding-top: 4px;">
    </div>
    <div class="title-row inslider" style="display:flex; ">
        <div *ngFor="let dummy of ' '.repeat(8).split(''), let x = index" style="width:150px; margin-right:5px;">
              <img src="assets/images/title-placeholder.jpg" style="min-width:150px;">
        </div>
  
    </div>
  
  </div>
</div>

<div  *ngIf="isPlaceholder!==true" class="h-100">
    <div class="titlesrow" [ngClass]="{'all':all}" *ngIf="!slider">
        <h2 class="row-title" >{{rowTitle | translate}}</h2>
        <div class="title-row">
            <div [ngClass]="{'in-home':!all}" class="title-container" *ngFor="let title of titles" routerLink="{{getLink(title)}}" >
                <a routerLink="{{getLink(title)}}">
                    <div class="image-container">

                        <img *ngIf="isWindow" [alt]="title.name" [title]="title.name" [defaultImage]="defaultImage" [lazyLoad]="title.logo?title.logo.url:''" [offset]="'100'">
                        <img *ngIf="!isWindow"  [alt]="title.name" [title]="title.name" [src]="title.logo?title.logo.url:''">

                    </div>
                    <div class="text-container">
                        <h5>{{title.publisher.name}}</h5>
                        <h3>{{title.name}}</h3>
                    </div>
                </a>
            </div>
            <a class="seeall" routerLink="{{getExploreLink()}}">
                <div *ngIf="titles && titles.length>0" class="see-icon"><img src="./assets/images/icon_eye_see_all_blue_2x.png"></div>
                <div class="see-space"></div>
            </a>
        </div>
    </div>
  
    <div class="titlesrow" [ngClass]="{'all':all}" *ngIf="slider && titles">
        <h2  [routerLink]="getExploreLink()" class="row-title" >{{rowTitle | translate}} <span class="title-link">{{ 'Explore All' | translate }}</span></h2>
        <div class="swiper-row inslider">
          <swiper-container 
            appSwiper 
              [config]="config"
              init="false"
              (indexChange)="indexChange($event)"
              class="swiper-container"
              >
            
            <swiper-slide *ngFor="let title of titles; index as i" class="swiper-slide" lazy="true">
              
                <div class="title-container">
                  <a routerLink="{{getLink(title)}}">
                    <img *ngIf="title.language  == 'French'"  style="position:absolute; top:3px; left:3px; " src="./assets/languages/icon_flag_fr.png" />
                    <div class="image-container">
                        <img [alt]="title.name" [title]="title.name" [defaultImage]="defaultImage" [lazyLoad]="title.logo?title.logo.url:''"  class="swiper-lazy">
                    </div>
                    <div class="text-container">
                        <h5>{{title.publisher.name}}</h5>
                        <h3>{{title.name}}</h3>
                        <div class="views">
                            <i class="material-icons">
                                remove_red_eye
                            </i>
                            <span style=" font-size: 10px; line-height: 16px;">
                                {{title.totalPageViews | viewsFormat}}
                            </span>
                        </div>
                    </div>
                  </a>
                
            </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              
                <div  class="title-container" style="text-align: center;  width:250px!important; margin-right:100px;">
                  <a style="width:100%;" class="seeall inslider" routerLink="{{getExploreLink()}}">
                    <div *ngIf="titles && titles.length>0" class="see-icon">
                      <img src="./assets/images/icon_eye_see_all_blue_2x.png" >
                      <div class="explore-all">EXPLORE<br>ALL</div>
                    </div>
                    <div class="see-space"></div>
                  </a>
                </div>
              
            </swiper-slide>
            
            
          </swiper-container>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        
        </div>
    </div>
</div>

 