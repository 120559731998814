<h1 mat-dialog-title style="display:flex; justify-content:space-between;">
  <div>
    <b>{{title}}</b>
  </div>
  <div>
      <i class="material-icons" (click)="onNoClick()" style="cursor:pointer;">
          close
          </i>
  </div>
</h1>
<div mat-dialog-content *ngIf="!loading" style="overflow:visible;">
      <p>{{question}}</p>
      <div mat-dialog-actions style="justify-content: flex-end;">
          <button *ngIf="showCancel" type="button" mat-button (click)="onNoClick()">{{cancel}}</button>
          <button *ngIf="showOk"  class="pink-mat" mat-button (click)="onYesClick()">{{ok}}</button>
      </div>
</div>


