<!--
<div   *ngIf="!isPlaceholder" class="hot-titles">
    <div *ngFor="let title of titles; let i=index;" class="hot-items">
        <app-series-element [title]="title" [i]="i"></app-series-element>
    </div>
</div>
-->


<div   *ngIf="!isPlaceholder" class="hot-titles">
    <div *ngFor="let title of titles; let i=index;" class="hot-items">
      <span class="rank-number">{{i+1}}.</span>
      <div class="hot-left">
        <div class="image-container">
          <a  routerLink="/title/{{title.publisher_slug}}/{{title.slug}}">
              <img [alt]="title.name"  [title]="title.name"  [defaultImage]="defaultImage" [lazyLoad]="title.logo?title.logo.url:''" [offset]="'100'">
          </a>


        </div>

      </div>
          <div  class="hot-right" [ngClass]="{'nopublisher': nopublisher}">
            <div class="top-texts">
                <h5 class="link" routerLink="/publisher/{{title.publisher.slug}}">{{title.publisher.name}}</h5>
                <div routerLink="/title/{{title.publisher_slug}}/{{title.slug}}" class="name-row">{{title.name}}</div>
                <div class="genres-row">
                    <a *ngFor="let genre of title.genres; let i=index" routerLink="/genre/{{genre.genreName}}">{{i>0?' | ':''}} {{genre.genreName | translate}}</a>
                </div>
                <div class="views">
                  <i class="material-icons">
                    remove_red_eye
                  </i>
                  <span>
                    {{title.totalPageViews | viewsFormat}}
                  </span>

              </div>
              <div class="age-rating-value"  >
                  <img [src]="getAgeRating(title)">
              </div>
            </div>
            <div class="button-row">
                <a type="button" routerLink="/title/{{title.publisher_slug}}/{{title.slug}}" mat-button>
                 <img src="./assets/images/titledisplay_icon_eye_pink@3x.png"> {{ 'READ NOW' | translate }}</a>
              </div>
          </div>
          <img class="item-menu"  style="display:none;" src="./assets/images/button_more_blue@2x.png">
    </div>

</div>


<div *ngIf="isPlaceholder" class="hot-titles" style="opacity:.5;">
  <div *ngFor="let dummy of ' '.repeat(30).split(''), let i = index" class="hot-items">
    <span class="rank-number">{{i+1}}.</span>
    <div class="hot-left">
      <div class="image-container">
        <a>
          <svg viewBox="0 0 150 150" preserveAspectRatio="xMidYMin slice">
            <rect width="100%" height="100%" fill="silver" />
          </svg>
        </a>
      </div>
    </div>
        <div  class="hot-right" [ngClass]="{'nopublisher': nopublisher}">
          <div class="top-texts">
              <h5>&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</h5>
              <div class="name-row">&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</div>
              <div class="genres-row">
                  <a>&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;  </a>
                  <a>&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;  </a>
              </div>
              <div class="views"></div>
            <div class="age-rating-value"  >
              <div style="width: 24px; height:24px; border-radius:100%; background-color: #969696; margin-top:5px;"></div>

            </div>
          </div>
          <div class="button-row">
              <a type="button"  mat-button>
                &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;</a>
            </div>
        </div>

  </div>

</div>