<div class="profile-main-container" *ngIf="user">
    <div class="image-side">

                        <div class="image-container">
                          <div *ngIf="user.profileImage" (click)="openDialog(true, true)" style="display: inline-block;">
                                <img    [src]="user.profileImage" (load)="imageLoaded($event)" style=" border-radius: 100%;"/>
                          </div>
                          <div class="no-user-image"  *ngIf="!user.profileImage"   (click)="openDialog(false, true, true)" style="display: inline-block;">
                                <img src="./assets/images/user_profile_placeholder.png" (load)="imageLoaded($event)"  style=" border-radius: 100%;"/>
                          </div>
                          
                          <h2 style="    margin-bottom: 0; font-size: 20px; font-weight: 400; margin-top: 20px;">
                              {{user?.username}}
                          </h2>
                          <span style="    font-size: 13px;
                          font-weight: 500;">{{user?.email}}</span>
                          <p>
                              
                          </p>
                          <p>
                              
                          </p>
                          <p>
                            <a (click)="showPassword = true" style="text-decoration: underline;
                            color: black;
                            font-weight: 400;
                            font-size: 14px;
                        ">Change password</a>
                          </p>
                          
                          <p>
                          </p>
                          
                          <p>
                          <a style="text-decoration: underline;
                          color: black;
                          font-weight: 400;
                          font-size: 14px;
                      " (click)="doLogout()">Log out</a>
                          </p>

                        </div>
    </div>
    <div style=" padding: 10px 20px;box-sizing: border-box;flex:1;padding-top: 0;    position: relative;">
    <h2>SETTINGS</h2>
    <a routerLink="/"><img src="/assets/images/icon_settings_close.png" style="position: absolute; top: 0; right: 18px;"></a>
    <mat-divider></mat-divider>
    <h4>SUBSCRIPTION</h4>
  
            <!-- Did I subscribe -->
            <div *ngIf="subscription; else nosubscription">
              <div *ngIf="subscription.cancel_at_period_end!==true">

                    <div>Status: {{subscription.status}}</div>
                    <!-- <div *ngIf="subscription.status==='trialing'">
                        <div>Trial start: {{subscription.trial_start * 1000 | date:'MMM d, yyyy'}}</div>
                        <div>Trial end: {{subscription.trial_end * 1000 | date:'MMM d, yyyy'}}</div>
                    </div> -->
                    <div>Renewal date: {{user.subscription.validUntil | date:'MMM d, yyyy'}}</div>
                    <div>
                      Username: {{user.username}}
                    </div>
                    <div>
                      Email: {{user.email}}
                    </div>
                    <button (click)="cancelSubscription()"  type="button" mat-button class="pink-mat" style="margin-top:20px;margin-bottom: 20px;border-radius:10px;">
                      Cancel Subscription
                    </button>
              </div>
              <div *ngIf="subscription.cancel_at_period_end===true">

                    <div>Status: Deactivated. Subscription will cancel at  {{subscription.current_period_end *1000  | date:'MMM d, yyyy'}}</div>
                    <div>
                      Username: {{user.username}}
                    </div>
                    <div>
                      Email: {{user.email}}
                    </div>
                    <button (click)="reactivateSubscription()"  type="button" mat-button class="pink-mat" style="margin-top:20px;margin-bottom: 20px;border-radius:10px;">
                      Reactivate Subscription
                    </button>
              </div>

              </div>
            <!-- Else -->
              <!-- Subscribe link -->
              <ng-template #nosubscription>
                <div *ngIf="user.subscription?.status==='active'">
                  <h3>Current Subscription</h3>
                  <div>Status: {{user.subscription.status}}</div>
                  <div>Renewal date: {{user.subscription.validUntil | date:'MMM d, yyyy'}}</div>
                  <div>
                    Username: {{user.username}}
                  </div>
                  <div>
                    Email: {{user.email}}
                  </div>                    
                </div>
                <div *ngIf="user.subscription?.status!=='active'" >
                  <p style="    color: black;
                  font-size: 13px;
                  font-weight: 500;
              ">Subscribe to get access to premium content, no ads and more on web and using Graphite Comics Android and iOS app.</p>
                  <a  (click)="checkout()"  class="pink-mat" style="max-width:400px; width:80%;display:block; margin: auto;    max-width: 300px;" mat-button >GET GRAPHITE PREMIUM</a>

                </div>

                <mat-divider></mat-divider>
              </ng-template>

              <div>
                  <h4>CONTENT FILTER</h4>
                  <p>Set the age rating for your content</p>
              </div>

                <form [formGroup]="userUpdate" (ngSubmit)="uploadProfile(userUpdate.value)">
                  
                    <div class="slider-container">
 <ul>
                        <li *ngFor="let age of listAge" class="numbers">
                          <img (click)="(changeSliderValue(age.number))" [src]="'./assets/images/icon_age_rating_'+age.img+'@2x.png'" alt="{{age.label}}" title="{{age.label}}"/>
                        </li>
                      </ul>
                    <mat-slider
                      min="0"
                      max="4"
                      step="1"
                      [value]="sliderValue"
                      formControlName="ageRating" (input)="onInputChange($event)"
                    ></mat-slider>                        
                    </div>
                    <mat-divider></mat-divider>
                    <h4>{{ 'Languages' | translate }}</h4>
                    <mat-select (selectionChange)="langSelected($event)" multiple  id="country" name="country"  formControlName="languages" [(ngModel)]="languagesList">
                      <mat-option *ngFor="let country of listCountry" [value]="country.code">{{country.name}}</mat-option>
                    </mat-select>

                  <mat-divider></mat-divider>


                </form>

      

        
                <!-- password -->
                <div *ngIf="showPassword===true">
                  <h3>{{ 'Password' | translate}}</h3>
                  <div style="padding-bottom:15px; "*ngIf="!showPassword; else showPasswordForm">
                    {{ 'Change your account password' | translate  }}
                    <button style="float:right;" class="textbutton" (click)="showPassword = true">{{ 'Update password' | translate }}</button>
                  </div>
                  <ng-template #showPasswordForm>
                    <form [formGroup]="pwChangeForm" (ngSubmit)="changePasswordSubmit(pwChangeForm.value)">
                    <mat-form-field appearance="outline" class="">
                      <mat-label>{{ 'Current password' | translate }}</mat-label>
                      <input type="password"  matInput formControlName="current"  placeholder="{{ 'Current passsword' | translate }}" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="">
                      <mat-label>{{ 'New password (min 8 char)' | translate }}</mat-label>
                      <input type="password"  matInput formControlName="password" placeholder="{{ 'New password (min 8 char)' | translate }}" (input)="onPasswordInput()"><mat-error *ngIf="password.hasError('required')">
                        Password is required
                      </mat-error>
                      <mat-error *ngIf="password2.hasError('minlength')">
                        Password must have at least {{minPw}} characters
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="">
                      <mat-label>{{ 'Repeat new password' | translate }}</mat-label>
                      <input type="password" matInput formControlName="password2" placeholder="{{ 'Repeat new password' | translate }}">
                      <mat-error *ngIf="password2.hasError('required')">
                        Passwords don't match
                      </mat-error>
                      <mat-error *ngIf="password2.invalid && !password2.hasError('required')">
                        Passwords don't match
                      </mat-error>
                    </mat-form-field>
                    <button (click)="showPassword = false" mat-flat-button class="mat-button">{{ 'Cancel' | translate }}</button>
                    <button submit mat-flat-button class="mat-button pink-mat" [disabled]="!pwChangeForm.valid">{{ 'Save' | translate }}</button>
                    </form>
                  </ng-template>
                <!-- Desactivate account -->
                <mat-divider></mat-divider>
              <!--    <h3>{{ 'profile.deactivate' | translate }}</h3>
                  <div *ngIf="showDeactivate == false; else showDeactivateForm">
                    {{ 'profile.passwordDescription' | translate  }}
                    <button class="textbutton">{{ 'profile.deactivateaccount' | translate }}</button>
                  </div>-->
                  </div>


    </div>
</div>
