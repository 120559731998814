<ngx-json-ld [json]="schema"></ngx-json-ld>
<div class="search-page">
  <div class="main-search">
    <mat-form-field>
        <input matInput placeholder="Search term" [(ngModel)]="term" (keyup.enter)="doSearch(term)" (change)="termChanged()">
        <button mat-button  matSuffix mat-icon-button   (click)="doSearch(term)" >
                <mat-icon>search</mat-icon>
        </button>
    </mat-form-field>
  </div>


<div *ngIf="!loading && term &&!holdSearch">
        <h3>{{ 'SEARCH.RESULT' | translate }} <span *ngIf="termResults && termResults.length>0" style="font-weight:normal;">{{ 'SEARCH.FOR' | translate }} "{{termResults}}"</span></h3>
        <mat-divider></mat-divider>

        <div class="titles-list">
            <div *ngIf="titles.length===0">
              <h2>{{ 'SEARCH.NORESULT' | translate }}</h2>
              <h3>{{ 'SEARCH.TRYANOTHER' | translate }}</h3>
            </div>
            <app-hottitles [titles]="titles"></app-hottitles>
        </div>
</div>
<div class="spinner-container">
    <mat-spinner [style.display]="loading ? 'block' : 'none'"></mat-spinner>
</div>

</div>
