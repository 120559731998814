import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-feed-image-preview',
  templateUrl: './feed-image-preview.component.html',
  styleUrls: ['./feed-image-preview.component.scss']
})
export class FeedImagePreviewComponent implements OnInit {
    @Input() file:any;
    url: any;
    constructor(
        private domSanitizer: DomSanitizer
    ) {

    }

    ngOnInit() {
        this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.file));
    }

}
