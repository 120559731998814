import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { UserService, AuthenticationService, WINDOW } from '../../_services';
import {ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
declare var amplitude: any;
declare const postscribe;

import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { SelectPlanComponent } from '../select-plan/select-plan.component';
import { DialogPlanComponent } from '../dialog-plan/dialog-plan.component';



@Component({
  selector: 'app-title-details',
  templateUrl: './title-details.component.html',
  styleUrls: ['./title-details.component.scss']
})
export class TitleDetailsComponent implements OnInit, AfterViewInit {
  isBrowser = false;
  isPlaceholder = true;
  isTitleLoaded = false;
  public schema;

  issues: [];
  orderedIssues: Array<any>;
  pageOfIssues: Array<any>;
  volumeIssues: Array<any>;
  initialPage: Number;
  firstLoad = true;
  title: any;
  titleId: any;
  genres: any;
  name: String;
  image: String;
  publisher: String;
  bannerImage: String;
  mBannerImage: String;
  defaultImage: String = './assets/images/preloading_image_placeholder.png';
  readerType: String;
  description: String;
  error = false;
  displayAlert = false;
  isMature = false;
  issueLabel;
  volumes = [];
  sortasc = true;
  showPagination = true;
  showInfo = false;
  creators = '';
  isVolume = false;
  alertType = 'mature';
  titleSlug: string;
  publisherSlug: string;
  currentUser:any;
  perPage=20;
  isWindow = false;
  constructor(
      @Inject(PLATFORM_ID) private platformId: any,
      @Inject(WINDOW) private window: Window,
      private authService: AuthenticationService,
      private userService: UserService,
      private titleService: Title,
      private metaTagService: Meta,
      private route: ActivatedRoute,
      private router: Router,
      private location: Location,
      private dialog: MatDialog,
      private translate: TranslateService
  ) {
      this.isBrowser = isPlatformBrowser(this.platformId);
      if(this.isBrowser){
        this.authService.currentUser.subscribe((value) => {
          this.currentUser = value;
        });
       }
  }

  issueURL(issue) {
      return this.userService.getIssueURL(issue);
  }

  isLocked(issue) {
    if (this.isMature || issue.accessRule === 'active_subscription' || issue.accessRule === 'mobile') {
      return true;
    }
    return false;
  }


  showAlertIssue(issue): void {
    const dialogRef = this.dialog.open(DialogPlanComponent, {
      // opening dialog here which will give us back stripeToken
      width: '100%',
      height: '100%',
      panelClass: 'payment-modalbox',

  });
    


    dialogRef.afterClosed().subscribe(result => {
      this.refreshIssue();
      

    });
  }
  refreshIssue(){
    for (const issue of this.issues) {
      this.canView(issue);
    }
    this.orderedIssues=this.issues;
  }
  
  showAlertIssueOld(issue) {
        if (this.isMature) {
            this.alertType = 'mature';
        }
        if (issue.accessRule === 'active_subscription') {
            this.alertType = 'active_subscription';
        }
        if (issue.accessRule === 'mobile') {
          this.alertType = 'mobile';
        }
        this.displayAlert = true;
  }


  getUnlockPreview(issue) {
    if (this.isLocked(issue)) {
        if (this.isMature && !(issue.accessRule === 'active_subscription')) {
          return 'readwithpremium';
          // return 'readwithgraphite';
        }
        if (issue.accessRule === 'active_subscription') {
            return 'readwithpremium';
        }
    }
    if (issue.accessRule === 'active_subscription_or_preview') {
      return 'exclusivepreview';
    }
    return 'normal';

  }

  ngOnInit() {
    this.isWindow = this.authService.isWindow();
    this.route.params.subscribe(routeParams => {
      this.titleId = routeParams.titleId;
      this.titleSlug = routeParams.title_slug;
      this.publisherSlug = routeParams.publisher_slug;

      this.volumes = [];

      if (routeParams.pageNumber) {
        this.initialPage = Number(routeParams.pageNumber);
      } else {
        this.initialPage = 1;
      }


      this.loadTitle();
  });



  }

  loadTitle() {
    console.log('loadTitle');
          // this.issues=[];
          this.genres = [];
          this.firstLoad = true;
          this.titleId = this.route.snapshot.paramMap.get('titleId');
          this.titleSlug = this.route.snapshot.paramMap.get('titleSlug');
          this.publisherSlug = this.route.snapshot.paramMap.get('publisherSlug');
          if (this.titleId) {
                  // sending to amplitude
                  this.userService.getTitle(this.titleId).subscribe(
                      result => {
                        this.title = result;
                        this.userService.titleCanView(this.title, this.currentUser);
                        this.isTitleLoaded = true;
                        this.loadIssues();
                        this.afterLoadTitle();
                      }
                      
                  );

          } else if (this.titleSlug) {
                this.userService.getTitleBySlug(this.publisherSlug, this.titleSlug).subscribe(
                  result => {
                    this.title = result;
                    this.titleId = result.objectId;
                    this.userService.titleCanView(this.title, this.currentUser);
                    this.afterLoadTitle();
                    this.loadIssues();
                    this.isTitleLoaded = true;

                  }
                );

          }

  }


  afterLoadTitle() {
    
if(this.isBrowser){
    if (amplitude) {
      try {
        amplitude.getInstance().logEvent('title_opened', {'issueId' : this.titleId});
      } catch (error) {

      }
    }
  }
    this.populateSchema();
    if (this.title.age_rating === 'MA') {
      this.isMature = true;
    }


    if(this.title.featuredBanner && this.title.featuredBanner.url){
      this.bannerImage=this.title.featuredBanner.url;
    }
    else{
      if (this.title.featuredBannerWide && this.title.featuredBannerWide.url) {
        this.bannerImage = this.title.featuredBannerWide.url;
      }
    }
    this.mBannerImage=this.bannerImage;


    if (this.title.featuredBannerWide && this.title.featuredBannerWide.url) {
      this.bannerImage = this.title.featuredBannerWide.url;
    } else if (this.title.featuredBanner && this.title.featuredBanner.url) {
      this.bannerImage = this.title.featuredBanner.url;
    }

    this.name = this.title.name;
    this.publisher = this.title.publisher.name;
    this.genres = this.title.genres;
    const logoImage = this.title.logo.url ? this.title.logo.url : this.defaultImage;

    this.titleService.setTitle('Read ' + this.name + ' from ' + this.publisher + ' - Free, Legally, Online, High Quality on Graphite Comics');
    this.metaTagService.updateTag({name: 'description', content: this.title.description ||  'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators'});

    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `https://graphitecomics.com/title/${this.title.publisher_slug}/${this.title.slug}`});
    this.metaTagService.updateTag({ property: 'og:title', content:  'Read ' + this.name + ' from ' + this.publisher + ' - Free, Legally, Online, High Quality on Graphite Comics'});
    this.metaTagService.updateTag({ property: 'og:description', content: this.title.description || 'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators' });
    this.metaTagService.updateTag({ property: 'og:image', content: logoImage });

    // Twitter metadata
    this.metaTagService.addTag({name: 'twitter:card', content: 'summary_large_image'});
    this.metaTagService.addTag({name: 'twitter:site', content: 'GraphiteComics'});
    this.metaTagService.addTag({name: 'twitter:title', content:  'Read ' + this.name + ' from ' + this.publisher + ' - Free, Legally, Online, High Quality on Graphite Comics'});
    this.metaTagService.addTag({name: 'twitter:description', content: this.title.description || 'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators' });
    this.metaTagService.addTag({name: 'twitter:text:description', content: this.title.description || 'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators' });
    this.metaTagService.addTag({name: 'twitter:image', content: logoImage});

  }
  loadIssues() {
    if(!this.isBrowser){
      return;
    }
    this.userService.getIssues(this.titleId).subscribe(issues => {
      for (const issue of issues) {
            this.canView(issue);
      }
      this.issues = issues;
      
      this.orderedIssues = issues;
      if(this.issues.length===0){
        this.isPlaceholder=false;
        return '';

      } 
      this.readerType = this.orderedIssues['0']['readerType'];
      if (this.isWebToonOrWebComic(this.readerType) === true) {
          this.description = this.orderedIssues['0']['description'];
      }
      if (this.issues.length <= this.perPage) {
        this.pageOfIssues = this.issues;
      }
      this.splitInVolumes();
      const creatorsArr = [];
      if (this.title && this.title.creator) {
            for (const creator of this.title.creator) {
              creatorsArr.push(creator.name);
            }
            this.creators = creatorsArr.join(', ');
      }
     

      this.isPlaceholder = false;
    });
  }

  isWebToonOrWebComic(type) {
      if (type === 'webtoon' || type === 'webcomic') {
        return true;
      }
      return false;
  }

  splitInVolumes() {
    let volumeLabel;
    this.issueLabel = 'issues';
    if (this.orderedIssues && this.orderedIssues.length > 0 && this.isWebToonOrWebComic(this.orderedIssues['0']['readerType']) === true) {
          volumeLabel = this.translate.instant('season');
          this.issueLabel = 'episodes';
    } else {
      volumeLabel = this.translate.instant('volume');
      this.issueLabel = 'issues';
    }

    this.volumes[0] = {title: this.translate.instant('all'), issues: this.orderedIssues.length};
    for (const issue of this.orderedIssues) {
        const volumes = this.orderedIssues.filter(t => t['volume_number'] === issue['volume_number']);
        this.volumes[issue['volume_number']] = {title: volumeLabel + ' ' + issue['volume_number'], issues: volumes.length};
    }
    this.volumes.push({title: this.translate.instant('info')});

  }

  populateSchema() {
    const schema = {
      '@context': 'http://schema.org/',
      '@type': 'ComicSeries',
      'description': this.title.description,
      'name': this.title.name,
      'startDate':  this.title.year_start ? this.title.year_start.toString() : undefined,
      'inLanguage': this.title.languages[0].language.toUpperCase(),
      'keywords': this.title.keywords,
      'isAccessibleForFree': true,
      'thumbnailUrl': (this.title.logo && this.title.logo.url) ? this.title.logo.url : undefined,
      'image': (this.title.featuredBanner && this.title.featuredBanner.url) ? this.title.featuredBanner.url : undefined,
      'publisher': {
            '@type': 'Organization',
            'url': 'https://graphitecomics.com/publisher/' + this.title.publisher_slug
      },
      'isPartOf': 'https://graphitecomics.com/publisher/' + this.title.publisher_slug,
      'url': 'https://graphitecomics.com/title/' + this.title.publisher_slug + '/' + this.title.slug,
  };
  const creators = [];
  if (this.title.creator) {
      for (const creator of this.title.creator) {
          creators.push({
            '@type': 'Person',
            'name': creator.name
          });
      }
      schema['creator'] = creators;

  }
  const genres = [];
  if (this.title.genres) {
      for (const genre of this.title.genres) {
          genres.push(
            'https://graphitecomics.com/genre/' + genre.genreName,

          );
      }
      schema['genre'] = genres;
  }

  if (this.title.rating_total && this.title.rating_count && this.title.rating_total > 0 && this.title.rating_count > 0) {
    const ratingValue = this.title.rating_total / this.title.rating_count;
    if (ratingValue > 0 && ratingValue <= 5) {
        schema['aggregateRating'] = {
          '@type': 'AggregateRating',
          'ratingValue': this.title.rating_total / this.title.rating_count,
          'ratingCount': this.title.rating_count,
          'bestRating': '5',
          'worstRating': '1'
        };
    }

  }

 this.schema = schema;

  }

 changeSort(val) {
     this.sortasc = val;
     if (this.sortasc === true) {
       this.orderedIssues = _.orderBy(this.orderedIssues, ['volume', 'number'], ['asc', 'asc']);
       this.volumeIssues = _.orderBy(this.volumeIssues, ['volume', 'number'], ['asc', 'asc']);
     } else {
       this.orderedIssues = _.orderBy(this.orderedIssues, ['volume', 'number'], ['desc', 'desc']);
       this.volumeIssues = _.orderBy(this.volumeIssues, ['volume', 'number'], ['desc', 'desc']);
     }

 }


 openIssue(issue){
      if(this.isLocked(issue) ){
          this.showAlertIssue(issue);
      }
      else{
        this.router.navigate(['/issue/',  issue.publisher_slug, issue.title_slug, issue.slug]);
      }
}
  volumeChanged(evt) {
      if (evt.index === 0) {
        // is all
        this.showInfo = false;
        this.showPagination = true;
        this.isVolume = false;
        return;
      }
      if (evt.index === this.volumes.length - 1) {
        // is info
        this.showInfo = true;

        return;
      }


      // is volume
      this.showPagination = false;
      this.showInfo = false;
      this.isVolume = true;
      this.volumeIssues = _.filter(this.orderedIssues, function(o) {
        return o['volume_number'] === evt.index ;
      });
  }

  onclose() {
    this.displayAlert = false;
  }

 


  viewPages(issue) {
      this.router.navigate(['/issue/',  issue.publisher_slug, issue.title_slug, issue.slug]);
  }

  onChangePage(pageOfIssues) {
    if(this.isBrowser){
      const scrollToTop = setInterval(() => {
          const pos = this.window.pageYOffset;
          if (pos > 0) {
              this.window.scrollTo(0, pos - 20); // how far to scroll on each step
          } else {
              clearInterval(scrollToTop);
          }
      }, 4);
    }
      const p = pageOfIssues.pageNumber;
      // update current page of items
      this.pageOfIssues = pageOfIssues.pageOfIssues;
      if (this.firstLoad === false) {
        this.location.go('/title/' + this.title.publisher_slug + '/' + this.title.slug + '/' + p, '', 1);
      }


      this.firstLoad = false;
    }


ngAfterViewInit(){
  if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status==='active'){
    return;
  }
  this.loadScripts();
  
}



canView(issue){
    if(!issue){
      return;
    }
      if( issue.accessRule === 'active_subscription'){
        issue.lockSubscription=true;
      }
      
      if(this.currentUser){
       
            if(this.userService.ageToNumber(issue.title.age_rating)<=this.userService.ageToNumber(this.currentUser.max_age_rating)){
                  //unlock all age restrictions
                  issue.lockedMature=false;
                  issue.lockedAge=false;
            }
            else{
                issue.hideAge=true;
                if(issue.title.age_rating==='MA'){
                  issue.lockedMature=true;
                 }
            }
            if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status==='active'){
                  issue.lockSubscription=false;
            }

      }
      else{
        if(this.userService.ageToNumber(issue.title.age_rating)>this.userService.ageToNumber('MA')){
          issue.lockedAge=true;
        }
        else{
          issue.lockedAge=false;
        }
        issue.lockedAge=false;
          if(issue.title.age_rating==='MA'){
              issue.lockedMature=true;
              issue.lockSubscription=true;
          }
      }

}


    loadScripts(){
      if(this.authService.isWindow()){
            postscribe('#amzntitle', `<div id="amzn-assoc-ad-dce8ec42-4a7c-4c05-ae27-212f630cd433" style="background:white;"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=dce8ec42-4a7c-4c05-ae27-212f630cd433"></script>
          `);
        
    }
  }
}
