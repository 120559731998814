/*
export const environment = {
  production: true,
  apiUrl: 'https://graphitecomics.com/api',
  parseUrl: 'https://graphitecomics.com/api',
  graphUrl: 'https://graphitecomics.com/graphql'
};
*/
export const environment = {
  production: true,
  apiUrl: 'api',
  parseUrl: 'api',
  graphUrl: 'graphql'
};

export const BASE_URL      = '/';
export const BUSINESS_NAME = 'My Awesome Comic Site, Inc.'
export const WEBSITE_NAME  = 'Awesome Comic Site';
export const WEBSITE_TITLE = 'Everything Comics – Read 30,000 Manga, Webtoons, Comics, Strips and Webcomics on ' + WEBSITE_NAME;
export const WEBSITE_DESC  = 'For comic, manga and webtoon fans, parents, creators or just want to check out the latest in comics – Free, legally and on any platform. On Graphite you have it all. Socialize with other comic fans and creators or publish your own comics in minutes with Graphite Upload.';
export const WEBSITE_IMAGE = BASE_URL + '/assets/images/site_icon_512.jpg';
export const COPYRIGHT     = BUSINESS_NAME;
export const TWITTER      = 'getdrawnin';
export const INSTAGRAM    = 'graphitecomics';
export const FACEBOOK     = 'graphitecomics';
export const LOGO         = {
    sm: './assets/images/logo_graphite_comcis_small.png',
    md: './assets/images/logo_graphite_comcis_small_2x.png'
};
