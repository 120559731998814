<div class="dropdown">
  <button mat-mini-fab type="button" aria-label="Example icon button with a menu icon">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#">Action</a>
    <a class="dropdown-item" href="#">Another action</a>
    <a class="dropdown-item" href="#">Something else here</a>
  </div>
</div>
