import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService, AuthenticationService, LocalService } from '../../_services';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';


declare var stripe:any;
declare var elements:any;

@Component({
    selector: 'app-stripe-payment',
    templateUrl: './stripe-payment.component.html',
    styleUrls: ['./stripe-payment.component.scss'],
})
export class StripePaymentComponent implements OnDestroy, AfterViewInit, OnInit {
    @ViewChild('cardInfo') cardInfo: ElementRef;
    @ViewChild('cardExpiry') cardExpiry: ElementRef;
    @ViewChild('cardCvc') cardCvc: ElementRef;

    card: any;
    expiry: any;
    cvc: any;
    cardHandler = this.onChange.bind(this);
    cardError: string;
    currentUser: any;
    priceId = 1;
    isProcessing=false;
    showThankYou=false;
    showError=false;
    subscription:any;
    plan;
    plan_name;


    loginForm: UntypedFormGroup;
    registerForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    hide: boolean = true;
    showSpinner: boolean = false;
    isOptional = false;
    loggedin=false;
    showLogin=false;
    showRegister=true;
constructor(
    private formBuilder: UntypedFormBuilder,
        private cd: ChangeDetectorRef,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<StripePaymentComponent>,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private localStorage: LocalService,
    ) {

        this.authenticationService.currentUser.subscribe((value) => {
            this.currentUser = value;
            if(value){
                this.loggedin=true;
            }
            else{
                this.loggedin=false;
            }
            console.log(this.loggedin);
          });
          this.plan=data.plan;
          this.plan_name=data.plan_name;

    }

    get f() { return this.loginForm.controls; }
    get fr() { return this.registerForm.controls; }

    changeRegister(){
        this.showRegister=true;
        this.showLogin=false;
    }

    changeLogin(){
        this.showRegister=false;
        this.showLogin=true;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.loading = false;
                    this.loggedin=true;
                },
                error => {
                   // this.alertService.error(error);
                    this.loading = false;
                });
    }



    onSubmitRegister() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }

        this.loading = true;
        this.userService.registerUser(this.registerForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    //login
                    this.authenticationService.login(this.fr.username.value, this.fr.password.value)
                    .pipe(first())
                    .subscribe(
                        data => {
                            this.loading = false;
                            this.loggedin=true;
                        },
                        error => {
                            this.loading = false;
                        });
                },
                error => {
                    this.loading = false;
                });
    }

ngOnDestroy() {
        if (this.card) {
            this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
            this.expiry.destroy();
            this.cvc.destroy();
            this.cardHandler=null;
        }
    }
    ngOnInit(){
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.registerForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            username: ['', Validators.required],
            password: ['', [Validators.required,
                Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\D]{8,20}$/)]],
            iAgree: [false, Validators.pattern('true')]
        });
    }
ngAfterViewInit() {

        this.initiateCardElement();

        this.userService.getProducts().subscribe(result=>{

        })

    }

    changeUser(){
        this.authenticationService.logout();
        this.loggedin=false;
    }
initiateCardElement() {
        // Giving a base style here, but most of the style is in scss file
        const cardStyle = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        };

        this.card = elements.create('cardNumber', {cardStyle});
        this.card.mount(this.cardInfo.nativeElement);
        this.expiry = elements.create('cardExpiry', {cardStyle});
        this.expiry.mount(this.cardExpiry.nativeElement);
        this.cvc = elements.create('cardCvc', {cardStyle});
        this.cvc.mount(this.cardCvc.nativeElement);
this.card.addEventListener('change', this.cardHandler);
    }
onChange({error}) {
        if (error) {
            this.cardError = error.message;
        } else {
            this.cardError = null;
        }
        this.cd.detectChanges();
    }


doSubscription(){
    this.isProcessing=true;
    this.userService.getCustomerId().subscribe(
        result => {
            if(result){
                let customerId;
                if(result[0]){
                    customerId=result[0].customerId;
                } else{
                    customerId=result['customerId'];
                }

                this.createPaymentMethod(this.card, customerId, this.priceId);

            }
                //
        },
        error=>{
            //showing error essage

            this.isProcessing=false;
            this.showError=true;
        }

    );
}

createPaymentMethod(cardElement, customerId, priceId) {
        return stripe
          .createPaymentMethod({
            type: 'card',
            card: cardElement,
          })
          .then((result) => {


            if (result.error) {
              //displayError(error);
              this.showError=true;
              this.isProcessing=false;

            } else {
              this.userService.createSubscription({
                customerId: customerId,
                paymentMethodId: result.paymentMethod.id,
                priceId: this.plan,
              }).subscribe(subscription=>{
                  this.isProcessing=false;
                  this.showThankYou=true;
                  this.subscription=subscription;
              },
              error=>{
                  this.isProcessing=false;
                  this.showError=true;
              });
            }
          });
}






async createStripeToken() {
 const {token, error} = await stripe.createToken(this.card);
        if (token) {
            this.onSuccess(token);
        } else {
            this.onError(error);
        }
    }

    closeDialog(){
        this.dialogRef.close();
        if(this.subscription){
            this.router.navigate(['/']);
        }


    }
    closeDialogAndRead(){
        this.dialogRef.close();
        // if(this.subscription){
        //     this.router.navigate(['/']);
        // }
    }

onSuccess(token) {
        this.dialogRef.close({token});
        this.router.navigate(['/']);
    }
onError(error) {
        if (error.message) {
            this.cardError = error.message;
        }
    }
}