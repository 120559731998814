import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { UserService, WINDOW } from '../../_services';

// import { Vibrant } from 'node-vibrant';
// import { Palette } from 'node-vibrant/lib/color';


@Component({
  selector: 'app-topbanner',
  templateUrl: './topbanner.component.html',
  styleUrls: ['./topbanner.component.scss']
})
export class TopbannerComponent implements OnInit {
  isBrowser = false;
  title: any;
  rating = 0;
  ratingCount = 0;
  totalPageViews = 0;
  favorites = 0;
  commentCount = 0;
  ageRating = '';
  ratingp = 0;
  itemAgeRating: any;
  language: any;
  ratingStr;
  defaultImage = './assets/images/preloading_image_placeholder.png';
  maxheight: Number;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
    private userService: UserService,

    ) {
      this.isBrowser = isPlatformBrowser(this.platformId);

  }
  @Input() isPlaceholder: Boolean;
  @Input() image: String;
  @Input() name: String;
  @Input() publisher: String;
  @Input() readerType: String;
  @Input() genres: Object;
  @Input() set objTitle(value) {
    const innerHeight = (this.window.innerHeight);
    this.maxheight = innerHeight * 0.6;

    this.title = value;
    if (value) {
      this.rating = value.rating_total / value.rating_count;
      this.rating = parseFloat(this.rating.toFixed(1));

      this.ratingCount = value.rating_count;
      this.ageRating = value.age_rating;
      this.ageRating = value.age_rating;
      this.totalPageViews = value.totalPageViews;
      this.favorites = value.titleFavoriteCount;
      this.commentCount = value.commentCount;

    const ratings = this.userService.ratings();
    for (const r of ratings) {
        if (r.id === this.ageRating) {
              this.itemAgeRating = r;

        }


    }

if (this.title && this.title.languages) {
  if (this.title.languages.length > 0) {
    this.language = this.title.languages[0];
    if (this.language.language !== 'en') {
      this.language['image'] = 'assets/languages/icon_flag_' + this.language.language + '@2x.png';
    }

  }

}


  }

}
  get objTitle() {
      if (typeof this.title !== 'undefined') {
          return this.title;
      } else {
        return {
          url: ''
        };
      }
  }

  get readerName(){
      return this.userService.getReaderName(this.readerType);
  }

  ngOnInit() {

  }
  onRate( $event ) {
    console.log('onRate');

  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    const innerHeight = (this.window.innerHeight);
    this.maxheight = innerHeight * 0.6;
  }



}
