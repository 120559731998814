import { Injectable, Input } from '@angular/core';
// https://medium.com/front-end-weekly/how-to-optimize-image-loading-on-your-website-855020fb41ae
@Injectable({
  providedIn: 'root'
})
export class ImagesLazyloadService {
  @Input() debounceTime = 0;
  @Input() threshold = 1;
  private observer: IntersectionObserver;

  constructor() {
    this.init();
  }

  private init() {    
    const options = {
      rootMargin: '5000px', // How far from viewable area before the image is loaded
      threshold: this.threshold,
    };
    this.observer = new IntersectionObserver((entries, imgObserver) => {
      entries.forEach(entry => {
        // Not viewable yet
        
        if (!entry.isIntersecting) {
          return;
        }
        const lazyImage = entry.target as HTMLImageElement;
        const src = lazyImage.dataset.src;
        if (!src) {
          return;
        }
        // create a new image with the src
        // Once loaded replace img src with loaded src
        const img = new Image();
              img.src = src;
              img.onload = () => {
                lazyImage.classList.remove("blur-page");
                lazyImage.tagName.toLowerCase() === 'img'
                  ? lazyImage.src = src
                  : lazyImage.style.backgroundImage = 'url(\'' + src + '\')';
        
                lazyImage.removeAttribute('lazyImage');
        
                imgObserver.unobserve(lazyImage);
        
              };
      });
    }, options);
  }

  observe(target: Element) {
    this.observer.observe(target);
  }
}