import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { AuthenticationService, UserService, WINDOW } from '../_services';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WEBSITE_NAME, WEBSITE_TITLE, WEBSITE_DESC, WEBSITE_IMAGE, BASE_URL, FACEBOOK, TWITTER, INSTAGRAM } from '../../environments/environment';

import * as _ from 'lodash';
declare var amplitude: any;

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit {
    titles: Array<any>;
    slides: Array<any>;
    genres: Array<any>;
    allGenres: Array<any>;
    schema;
    isBrowser;
    isPlaceholder = true;
    loading=false;
    isSlider = true;
    latest: unknown[];

    constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private titleService: Title,
        private metaTagService: Meta
    ) {
      this.isBrowser = isPlatformBrowser(this.platformId);

      if (!this.authenticationService.currentUserValue) {
        this.router.navigate(['/login']);
      }

    }

    ngOnInit() {
        this.titleService.setTitle( WEBSITE_TITLE );
        this.metaTagService.updateTag({
            name: 'description',
            content: WEBSITE_DESC
        });

        this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
        this.metaTagService.updateTag({ property: 'og:url', content: BASE_URL });
        this.metaTagService.updateTag({ property: 'og:title', content: WEBSITE_TITLE });
        this.metaTagService.updateTag({
            property: 'og:description',
            content: WEBSITE_DESC
        });
        this.metaTagService.updateTag({ property: 'og:image', content: WEBSITE_IMAGE });

    // Twitter metadata
    this.metaTagService.addTag({name: 'twitter:card', content: 'summary'});
    this.metaTagService.addTag({name: 'twitter:site', content: WEBSITE_NAME});
    this.metaTagService.addTag({name: 'twitter:title', content:  WEBSITE_TITLE});
    this.metaTagService.addTag({
        name: 'twitter:description',
        content: WEBSITE_DESC
    });
    this.metaTagService.addTag({
        name: 'twitter:text:description',
        content: WEBSITE_DESC
    });
    this.metaTagService.addTag({name: 'twitter:image', content: WEBSITE_IMAGE});
        this.populateSchema();

        this.titles = [];
        this.slides = [];
        this.genres = [];
        if (this.isBrowser) {
          this.loadTitles();
        }
    }


    loadTitles(){
        this.userService.getHomePage(20).subscribe(result=>{
            this.latest=result.data.getLatestTitles;
            this.titles=result.data.getTrendingTitles;
        })
        const userLang = this.window.navigator.language || this.window.navigator['userLanguage'];
        this.isPlaceholder = false;
        this.userService.genres().subscribe(result => {
            this.allGenres = result.data.genres;
            this.onScroll(5);
        });
    }



    bannerClick(device) {
      if (this.isBrowser) {
        amplitude.getInstance().logEvent('banner_click', {'device' : device});
      }
    }

    populateSchema() {
      const schema = {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        'name': WEBSITE_NAME,
        'url': BASE_URL,
        'sameAs': ['https://www.facebook.com/' + FACEBOOK,
                   'https://twitter.com/' + TWITTER,
                   'https://www.instagram.com/' + INSTAGRAM],
                   'potentialAction': {
                    '@type': 'SearchAction',
                    'target': '{{BASE_URL}}/s/{query}',
                    'query': 'required',
                    'query-input': 'name=query'
                  }
        };


   this.schema = schema;

    }


    onScroll(numberGenres=5){
        if(!this.allGenres) {
            return;
        }
        if(this.allGenres.length < 1) {
            return;
        }
        if(this.loading === true) {
            return;
        }
        this.loading=true;
        let genres = this.allGenres.slice(0,numberGenres);

        this.userService.genreTitlesArray(genres.map(genre=>genre.objectId), 15).subscribe(result=>{
            this.allGenres.splice(0,numberGenres);
            genres.forEach((genre)=>{
                this.loading = false;
                //if(result.data['genre'+genre.objectId].length>5){
                if(result.data['genre'+genre.objectId].length>0){
                    this.genres.push({
                        genreName: genre.genreName,
                        objectId: genre.objectId,
                        titles:result.data['genre'+genre.objectId],
                    });
                }
            })
        },
        error=>{
            this.loading=false;
        })
    }

    onScrollSingle(){
      if(!this.allGenres){
        return;
      }
      if(this.allGenres.length<1){
        return;
      }
      if(this.loading===true){
        return;
      }
      this.loading=true;
      let genre = this.allGenres[0];
      this.userService.genreTitles(genre.objectId, 10).subscribe(result=>{
        this.allGenres.shift();
        this.loading = false;
        if(result.data.genreTitles.length>5){
            this.genres.push({
              genreName: genre.genreName,
              objectId: genre.objectId,
              titles:result.data.genreTitles,
            });
        } else{
            this.onScroll();
        }

      }, error=>{
          this.loading=false;
      })
  }


}
