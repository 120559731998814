<!--
<div class="footer">
  <div class="social" >
    <a href="https://facebook.com/{{facebook}}"><i class="fa fa-facebook-square" style="color: #646464"></i></a>
    <a href="https://twitter.com/{{twitter}}"><i class="fa fa-twitter-square" style="color: #646464"></i> </a>
    <a href="https://instagram.com/{{instagram}}"><i class="fa fa-instagram" style="color: #646464"></i></a>
  </div>
  <div class="footer-menu">
    <ul>
      <li><a href="{{baseUrl}}/" target="_blank">{{ 'About' | translate }}</a> | </li>
      <li><a href="{{baseUrl}}/contact/" target="_blank">{{ 'Contact' | translate }}</a> | </li>

      <li><a href="{{baseUrl}}/terms" target="_blank">{{ 'Terms' | translate }}</a> | </li>
      <li><a href="{{baseUrl}}/privacy-policy" target="_blank">{{ 'Privacy' | translate }}</a></li>
    </ul>
  </div>
  <div class="footer-copyright">
    &copy; {{ copyright }} {{ currentYear }}
    <small class="text-muted">Version: {{ version }} </small>
  </div>
</div>
-->
<footer class="bg-body-tertiary text-center text-lg-start">
  <!-- Grid container -->
  <div class="container p-4">
    <!--Grid row-->
    <div class="row">
      <!--Grid column-->
      <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase">Links</h5>

        <ul class="list-unstyled mb-0">
          <li>
            <a href="#!" class="text-body">Link 1</a>
          </li>
          <li>
            <a href="#!" class="text-body">Link 2</a>
          </li>
          <li>
            <a href="#!" class="text-body">Link 3</a>
          </li>
          <li>
            <a href="#!" class="text-body">Link 4</a>
          </li>
        </ul>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase mb-0">Links</h5>

        <ul class="list-unstyled">
          <li>
            <a href="#!" class="text-body">Link 1</a>
          </li>
          <li>
            <a href="#!" class="text-body">Link 2</a>
          </li>
          <li>
            <a href="#!" class="text-body">Link 3</a>
          </li>
          <li>
            <a href="#!" class="text-body">Link 4</a>
          </li>
        </ul>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase">Links</h5>

        <ul class="list-unstyled mb-0">
          <li>
            <a href="#!" class="text-body">Link 1</a>
          </li>
          <li>
            <a href="#!" class="text-body">Link 2</a>
          </li>
          <li>
            <a href="#!" class="text-body">Link 3</a>
          </li>
          <li>
            <a href="#!" class="text-body">Link 4</a>
          </li>
        </ul>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase mb-0">Feed</h5>

        <ul class="list-unstyled">
          <li>
            <a routerLink="feed" class="text-body">Feed</a>
          </li>
          <li>
            <a routerLink="hashtag" class="text-body">Hashtags</a>
          </li>
          <li>
            <a routerLink="bookmarks" class="text-body">Bookmarks</a>
          </li>
          <li>
            <a [routerLink]="mentions" class="text-body">Mentions</a>
          </li>
        </ul>
      </div>
      <!--Grid column-->
    </div>
    <!--Grid row-->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.05);">
    &copy; {{ currentYear }} Copyright:
    <a class="text-body" href="/">{{ copyright }}</a>
    <small class="text-muted">Version: {{ version }} </small>
  </div>
  <!-- Copyright -->
</footer>