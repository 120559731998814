import { Injector, AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ImagesLazyloadService } from './lazyload.service';
import { Subject, delay, filter } from 'rxjs';
// https://giancarlobuomprisco.com/angular/intersection-observer-with-angular
// https://stackblitz.com/edit/angular-lazy-load-images-module?file=src%2Fapp%2Fapp.component.ts
@Directive({
    // Take advantage of the "loading" attribute as a directive selector
  selector: '[lazyImage]',
})
export class ImagesLazyloadDirective implements OnInit, AfterViewInit {
    @Input() debounceTime = 0;
    @Input() threshold = 1;
    @Output() visible = new EventEmitter<HTMLElement>();

    private observer: IntersectionObserver | undefined;
    private subject$ = new Subject<{
        entry: IntersectionObserverEntry;
        observer: IntersectionObserver;
    }>();


    constructor(
        private injector: Injector,
        private element: ElementRef,
    ) {}

    ngOnInit() {
        const img = this.element.nativeElement;    
        const lazyService = this.injector.get(ImagesLazyloadService);
              lazyService.observe( img );
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {

    }

}