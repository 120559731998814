<div class="main row h-100">
    <div class="h-100 overflow-scroll">
        <section *ngIf="error" class="row">
            <div class="error col-6 col-xl-4 col-xxl-3 mx-auto mt-4">
                <h2>{{ error.label }}</h2>
                <div class="message">{{ error.message }}</div>
            </div>
            <div class="d-inline-block d-none d-sm-block col-sm-4 h-100">
                <app-feed-nav [data]="'@'+username" navLayout="vertical"></app-feed-nav>
            </div>
        </section>
        <ng-container *ngIf="feedLoaded">
            <section class="profile d-flex row">
                <div class="profile-header d-inline-block d-block col-lg-12 col-xl-9">
                    <div>
                        <img [src]="profile.banner?profile.banner.url:''" class="w-100">
                    </div>
                    <div class="d-flex flex-row mx-1">
                        <div class="avatar mx-2 bg-white"><img class="profile-logo" *ngIf="profile.photo?.url" [src]="profile.photo.url" class="rounded-circle img-fluid"></div>
                        <div class="mx-1 mt-2">
                            <button mat-mini-fab color="primary" aria-label="Tip Jar">
                                <mat-icon svgIcon="tip-jar" class="btn-icon"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="platforms" class="ms-auto mt-2">
                            <button *ngFor="let platform of platforms" type="button" (click)="openLink( platform.link )" mat-mini-fab color="primary" [attr.aria-label]="profile.name" class="mx-1">
                                <mat-icon [svgIcon]="platform.type"></mat-icon>
                            </button>

                        </div>
                    </div>
                    <div class="body">
                        <div class="d-flex flex-row">
                            <div class="py-3">
                                <h1>{{ profile.name }}</h1>
                                <div class="username"><a routerLink="permalink">@{{ username }}</a></div>
                            </div>

                            <div class="ms-auto my-auto">
                                <span id="views" class="ps-2">
                                    <span><svg class="feather"><use href="/assets/images/feather-sprite.svg#eye" /></svg></span>
                                    <span class="mx-1">X</span>
                                </span>
                                <span id="comments" class="ps-2">
                                    <span><svg class="feather"><use href="/assets/images/feather-sprite.svg#message-circle" /></svg></span>
                                    <span class="mx-1">Y</span>
                                </span>
                                <span id="likes" class="ps-2">
                                    <span><svg class="feather"><use href="/assets/images/feather-sprite.svg#heart" /></svg></span>
                                    <span class="mx-1">Z</span>
                                </span>
                            </div>
                        </div>
                        <div class="bio">{{profile.description}}</div>
                        <div class="my-2">
                            <span class="feather-text me-2"><mat-icon>calendar_today</mat-icon></span>
                            <span class="feather-text me-2">Joined {{ dateJoined | date:'longDate' }}</span>
                            <span *ngIf="profile.link">
                                <span class="feather-text me-2"><a href="{{profile.link}}" target="_blank" class="profile-link"><svg class="feather"><use href="/assets/images/feather-sprite.svg#external-link" /></svg></a></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-inline-block d-none d-xl-block col-xl-3 h-100">
                    <app-feed-nav navLayout="vertical"></app-feed-nav>
                </div>
            </section>
            <mat-divider></mat-divider>
            <section class="feed row">
                <div class="d-inline-block d-block col-sm-8 col-xl-12 d-flex flex-wrap">
                    <div *ngFor="let status of feed; let even = even; let odd = odd"  class="col-12 col-xl-4 col-xxl-3">
                        <app-feed-card [status]="status" (messageEvent)="receiveMessage($event)"></app-feed-card>
                    </div>
                </div>
                <div class="d-inline-block d-none d-sm-block col-sm-4 d-xl-none">
                    <app-feed-nav [data]="'@'+username" navLayout="vertical" alignButton="top"></app-feed-nav>
                </div>
            </section>
        </ng-container>
    </div>
</div>

