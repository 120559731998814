import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from '../../../_services/user.service';

import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MatDialogConfig,  MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FeedPostComponent } from '../feed-post/feed-post.component';

@Component({
  selector: 'app-feed-nav',
  templateUrl: './feed-nav.component.html',
  styleUrls: ['./feed-nav.component.scss']
})
export class FeedNavComponent implements OnInit {
    @Input() navLayout: string;
    @Input() alignButton: string = 'bottom';
    @Input() data: string;
    horizontal = true;
    dataLoaded = false;
    username: any;
    recommended: any;
    defaultImage: String='assets/images/graphy.png'; // TODO move to constants
    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        public dialog: MatDialog,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
    }

    ngOnInit() {
        this.horizontal = this.navLayout == 'horizontal';
        console.log('data',this.data);
        this.loadRecommended();
    }

    loadRecommended() {
        this.userService.getRecommendedUsers( null ).pipe().subscribe(result => {
            this.recommended = result;
            this.dataLoaded = true;
        });
    }

    postStatus() {
        const dialogRef = this.dialog.open( FeedPostComponent, {
            panelClass : 'post-overlay',
            autoFocus: true,
            hasBackdrop: true,
            backdropClass: 'post-background',
            width:'600px',
            position: { top: '100px' },
            data: this.data ? this.data : '',
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');

        });

    }
    getImage(user){
        if ( user.profile && user.profile.photo ) {
            return user.profile.photo.url;
        }

        return this.defaultImage;
    }

    gotoProfile( username ) {
        console.log('gotoProfile',username );
    }
}
