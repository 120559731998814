<ngx-json-ld [json]="schema"></ngx-json-ld>



<div style="
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 789px;
    width: 100%;
    overflow: hidden;
    margin-top:69px;
    z-index:-1;
" [ngClass]="{'faded': !isIssueLoaded}">
<div *ngIf="isIssueLoaded" class="bg-blur" [style.backgroundImage]="'url('+(bannerImage)+')'"></div>
<div  *ngIf="!isIssueLoaded" class="bg-blur" style="background-color: silver;"></div>
</div>
<div class="background-pic" [ngClass]="{'faded': !isIssueLoaded}">
  <div class="gradient-back"></div>
  <img *ngIf="isIssueLoaded" [src]="(bannerImage?bannerImage:'')" style="width:1050px;"/>
  <!-- <img *ngIf="isIssueLoaded"  [src]="getMobileBanner()" style="width:1050px;"  class="mobile-banner"/> -->
  <svg *ngIf="!isIssueLoaded" viewBox="0 0 800 600" preserveAspectRatio="xMidYMin slice"  style="width:1050px;">
    <rect width="100%" height="100%" fill="darkgrey" />
  </svg>
</div>



<div class="top-issue-container" [ngClass]="{'faded': !isIssueLoaded}">
      <div class="top-banner-max" >
        <svg class="image43" viewBox="0 0 800 600" preserveAspectRatio="xMidYMin slice">
          <rect width="100%" height="100%" fill="red" />
        </svg>
          <div *ngIf="title" class="top-texts">
            <div class="top-flex-container">
                <div>
                    <div style="display: flex;">
                          <div class="cover-issue">
                            <a  (click)="showAlertIssue(issue)" *ngIf="isIssueLoaded && (issue.lockSubscription && getUnlocPreview(issue)==='readwithpremium')" class="wrapper-cover" 
                            >
                                <img class="image-issue-cover" src="{{fixExtension()}}">
                            </a>
                            <a  *ngIf="isIssueLoaded && !(issue.lockSubscription && getUnlocPreview(issue)==='readwithpremium')" class="wrapper-cover" 
                            routerLink="/issue/{{issue?.publisher_slug}}/{{issue.title_slug}}/{{issue.slug}}">
                                <img class="image-issue-cover" src="{{fixExtension()}}">
                            </a>
                            <a  *ngIf="!isIssueLoaded">
                              <svg class="image-issue-cover" viewBox="0 0 246 300" preserveAspectRatio="xMidYMin slice">
                                <rect width="100%" height="100%" fill="silver" />
                              </svg>
                            </a>
                            

                           <div (click)="openIssue(issue)"  *ngIf="!(issue.lockSubscription && getUnlocPreview(issue)==='readwithpremium')"  style="text-align: center;width:100%;margin-top:10px;">
                           <a routerLink="/issue/{{issue?.publisher_slug}}/{{issue?.title_slug}}/{{issue?.slug}}"><button style="width:100%;" class="pink-mat small-round menu-button-orange" mat-button>READ NOW</button></a>  
                          </div>

                          <div  (click)="showAlertIssue(issue)" *ngIf="issue.lockSubscription && getUnlocPreview(issue)==='readwithpremium'" class="premium">
                            <span class="text">
                              <a  style="position: relative; min-height: 24px; display: block;">
                                <button style="width: 100%;top: 10px;" class="pink-mat small-round menu-button-orange" mat-button><span class="graphite-pre">GRAPHITE </span>PREMIUM</button>
                              </a>  
                            </span>
                          </div>




 
 

                           <div  *ngIf="!isIssueLoaded"  style="text-align: center;width:100%;margin-top:10px;">
                           <a><button style="width:100%;" class="small-round" mat-button>&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</button></a>  
                          </div> 
 </div>
               <div  *ngIf="isIssueLoaded" class="right-banner">
                            <a routerLink="/publisher/{{issue.title.publisher_slug}}"><h3>{{issue.title.name}} <span style="text-transform: lowercase;">by</span> {{issue.title.publisher.name}}</h3></a>
                            <h1 style="margin-bottom:0;">{{issue.number}}.{{issue.name}}</h1>
              
                            <h3 *ngIf="isWebToonOrWebComic(issue.readerType)!==true">VOLUME {{issue.volume_number}}, ISSUE {{issue.number}}</h3>
                            <h3 *ngIf="isWebToonOrWebComic(issue.readerType)">SEASON {{issue.volume_number}}, EPISODE {{issue.number}}</h3>
                            <div class="views-comments-likes">
                              <div class="views">
                                  <img  src="/assets/images/titledisplay_icon_eye_white@3x.png"><span > {{issue?.totalPageViews | viewsFormat}} </span>
                                  <img  src="/assets/images/titledisplay_icon_comment_white@3x.png"><span > {{issue?.commentCount | viewsFormat}} </span>
                                  <img  src="/assets/images/titledisplay_icon_heart_white@3x.png"><span > {{issue?.issueLikeCount | viewsFormat}} </span>
                              </div>
                            </div>
                            <div class="mobile-genre">
                              <div  *ngIf="isIssueLoaded" class="bottom-text">
                                <p class="genres" style="text-align: left;">
                                  <a *ngFor="let genre of title?.genres; let i=index" routerLink="/genre/{{genre.genreName}}">{{i>0?' | ':''}} {{genre.genreName | translate}}</a>
                                </p>
                                  <div *ngIf="itemAgeRating" class="age-rating-container">
                                      <div class="age-rating-value">
                                          <img *ngIf="itemAgeRating && itemAgeRating.image" [src]="itemAgeRating.image">
                                      </div>
                                      <div class="readertype">
                                        {{readerName | translate}}
                                      </div>
                                      <div class="language">
                                          <img *ngIf="language && language.image" [src]="language.image">
                                      </div>
                                  </div>
                              </div>
                            
                            
                              <div  *ngIf="!isIssueLoaded" class="bottom-text">
                                <p class="genres" style="text-align: left;">
                                  <a>&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</a>
                                </p>
                                  <div *ngIf="itemAgeRating" class="age-rating-container">
                                      <div class="age-rating-value">
                                          <div style="width:30px; height:30px; border-radius:100%; background-color: white;"></div>
                                      </div>
                                      <div class="readertype">
                                        &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;
                                      </div>
                                      <div class="language">
                                          <img *ngIf="language && language.image" [src]="language.image">
                                      </div>
                                  </div>
                              </div>
                          </div>  
                          </div>
                          <div  *ngIf="!isIssueLoaded" style="padding-left:20px;">
                            <a routerLink="/publisher/{{title.publisher_slug}}"><h3>&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584; <span style="text-transform: lowercase;">&#x2584;&#x2584;</span> &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</h3></a>
                            <h1 style="margin-bottom:0;">&#x2584;&#x2584; &#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</h1>
              
                            <h3>&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</h3>
                            
                            <div class="views-comments-likes">
                              <div class="views">
                                  <span > &#x2584;&#x2584;&#x2584; &#x2584;&#x2584; </span>
                                  <span > &#x2584;&#x2584;&#x2584; &#x2584;&#x2584; </span>
                                 <span > &#x2584;&#x2584;&#x2584; &#x2584;&#x2584; </span>
                              </div>
                            </div>
                          </div>
                      </div>  



                      <div class="desktop-genre">
                        <div  *ngIf="isIssueLoaded" class="bottom-text">
                          <p class="genres" style="text-align: left;">
                            <a *ngFor="let genre of title?.genres; let i=index" routerLink="/genre/{{genre.genreName}}">{{i>0?' | ':''}} {{genre.genreName | translate}}</a>
                          </p>
                            <div *ngIf="itemAgeRating" class="age-rating-container">
                                <div class="age-rating-value">
                                    <img *ngIf="itemAgeRating && itemAgeRating.image" [src]="itemAgeRating.image">
                                </div>
                                <div class="readertype">
                                  {{readerName | translate}}
                                </div>
                                <div class="language">
                                    <img *ngIf="language && language.image" [src]="language.image">
                                </div>
                            </div>
                        </div>
                      
                      
                        <div  *ngIf="!isIssueLoaded" class="bottom-text">
                          <p class="genres" style="text-align: left;">
                            <a>&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;</a>
                          </p>
                            <div *ngIf="itemAgeRating" class="age-rating-container">
                                <div class="age-rating-value">
                                    <div style="width:30px; height:30px; border-radius:100%; background-color: white;"></div>
                                </div>
                                <div class="readertype">
                                  &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;
                                </div>
                                <div class="language">
                                    <img *ngIf="language && language.image" [src]="language.image">
                                </div>
                            </div>
                        </div>
                      
                      
                      
                       </div>


                </div>
         
                  <div   *ngIf="isIssueLoaded" class="top-issue-description">
                        <div *ngIf="issue.description?.length>0" class="top-description-container for-desktop" >
                        <p>
                          {{issue.description}}
                        </p>
                            <div *ngIf="artist">
                              Artist: {{artist}}
                            </div>
                            <div *ngIf="writer">
                              Writer: {{writer}}
                            </div>
                        </div>

                  </div>
         
                  <div *ngIf="!isIssueLoaded" class="top-issue-description">
                        <div class="top-description-container" >
                        <p>
                          &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;
                        </p>
                            <div>
                              &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;
                            </div>
                            <div>
                              &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584;&#x2584;&#x2584;
                            </div>
                        </div>

                  </div>
            

            </div>


      </div>
    <!--  </div>-->




   
  </div>
  
  </div>






    <div *ngIf="issue" class="issue-details">
      
      <div *ngIf="!error" class="main-container">
        <div   *ngIf="isIssueLoaded" class="top-issue-description for-mobile">
          <div *ngIf="issue.description?.length>0" class="top-description-container" >
          <p>
            {{issue.description}}
          </p>
              <div *ngIf="artist">
                Artist: {{artist}}
              </div>
              <div *ngIf="writer">
                Writer: {{writer}}
              </div>
          </div>

    </div>
          <a routerLink="/title/{{issue.title.publisher_slug}}/{{issue.title_slug}}" class="discover-title mobile-only" >Discover more about this title
            <img  src="./assets/images/external_link/external_link_icon_chevron.png" />
          </a>
          <div  style="display:inline-block; width:100%;">
            <div *ngIf="!isPlaceholder" class="left-side">
              <div class="other-comics">Other comics of {{issue.title.name}} <span style="text-transform: lowercase;">by</span> {{issue.title.publisher.name}}</div> 
              <!-- Top tabs -->
              <div class="volume-nav">
                <mat-tab-group (selectedTabChange)="volumeChanged($event)">
                  <mat-tab *ngFor="let volume of volumes">
                    <ng-template mat-tab-label>
                      <div class="volume-title">{{volume.title}}</div>
                      <div class="issues-count" *ngIf="volume.issues">{{volume.issues}} {{issueLabel | translate}}</div>
                    </ng-template>
                  </mat-tab>
                </mat-tab-group>
                <img (click)="changeSort(false)" *ngIf="sortasc && !showInfo && !showPagination" class="sort-ico" src="assets/images/button_sort_ascending@3x.png">
                <img (click)="changeSort(true)" *ngIf="!sortasc && !showInfo  && !showPagination" class="sort-ico" src="assets/images/button_sort_descending@3x.png">
              </div>
              <div *ngIf="!showInfo && !isVolume"  style="padding-bottom: 30px;">
                  <div class="issue-row" [class.webtoon-row]="isWebToonOrWebComic(issue.readerType)"  *ngFor="let issue of pageOfIssues">
                      <a *ngIf="isLocked(issue)" class="image-wrapper pointer-link" (click)="showAlertIssue(issue)">
                          <img *ngIf="isWindow" [defaultImage]="defaultImage" [lazyLoad]="issue.thumb?issue.thumb.url:defaultImage" [offset]="'100'">
                          <img *ngIf="!isWindow" [src]="issue.thumb?issue.thumb.url:defaultImage" >
                          <img *ngIf="isMature"  class="ma-overlay" src="./assets/images/icon_age_rating_MA@3x.png">
                      </a>
                      <a  *ngIf="!isLocked(issue)" class="image-wrapper pointer-link"  routerLink="{{getIssueURL(issue)}}">
                          <img *ngIf="isWindow" [defaultImage]="defaultImage" [lazyLoad]="issue.thumb?issue.thumb.url:defaultImage" [offset]="'100'">
                          <img *ngIf="!isWindow" [src]="issue.thumb?issue.thumb.url:defaultImage" >
                      </a>
                      <div  class="hot-right">
                        <div class="top-texts">
                        <!--    <h4 *ngIf="issue.readerType!='webtoon'">{{ 'Volume' | translate }} {{issue.volume_number}} {{ 'Issue' | translate }} {{issue.number}}</h4>-->
                          <!--  <h4 *ngIf="issue.readerType=='webtoon'">{{ 'season' | translate }} {{issue.volume_number}} {{'Episode' | translate}} {{issue.number}}</h4>-->
                            <h2 class="name-row">
                              <span *ngIf="isLocked(issue)" class="locked"><img src="/assets/images/icon_locked_small_grey_2x.png"></span>
                              <a *ngIf="isLocked(issue)"  (click)="showAlertIssue(issue)" class="issue-title">{{issue.number}}.{{issue.name}}</a>
                              <a *ngIf="!isLocked(issue)" routerLink="{{getIssueURL(issue)}}" class="issue-title">{{issue.number}}.{{issue.name}}</a>
                              <span style="display:none; float:right; color:#828282; font-size:15px; font-weight:400;">{{issue.createdAt | date}}</span>
                            </h2>
                            <div class="views">
                              <!--<span>
                                {{issue.pages.length}} pages
                              </span>-->
                              <div>
                                <span>
                                  <img  class="icon" src="assets/images/icon_like_small_grey.png">
                                  
                                </span>
  
                                <span>
                                  {{issue.issueLikeCount | viewsFormat}}
                                </span>
                                <span style="margin-left: 30px;">
                                  <img class="icon" style="margin-left:-5px;" src="assets/images/icon_comments_small_grey.png">
                                </span>
  
                                <span>
                                  {{(issue.commentCount?issue.commentCount:0) | viewsFormat}}
                                </span>
                              </div>
                              <div  (click)="showAlertIssue(issue)" *ngIf="issue.lockSubscription && getUnlocPreview(issue)==='readwithpremium'" class="premium">
                                <span class="text">
                                  <span >{{ 'Unlock With' | translate }}</span>
                                  <img src="assets/images/logo_graphite_premium_small_2x.png">
                                </span>
                              </div>
                              <div (click)="openIssue(issue)"  *ngIf="!(issue.lockSubscription && getUnlocPreview(issue)==='readwithpremium')" class="premium link" >
                                <span class="text">
                                  <span >{{ 'READ NOW' | translate }}</span>
                                </span>
                              </div>

                          </div>
                          <h5 (click)="issue.showMore=true" *ngIf="!issue.showMore&&issue.description" class="description">
                              {{issue.description | truncate:120}} <a *ngIf="issue.description.length>=120">{{ 'read more' | translate }}</a>
                          </h5>
                          <h5 *ngIf="issue.showMore" class="description">
                              {{issue.description| translate}} <a (click)="issue.showMore=false">{{ 'read less' | translate }}</a>
                          </h5>
                        </div>
                      </div>
                    <div class="right-points">
                      <a routerLink="/issue/{{issue.publisher_slug}}/{{issue.title_slug}}/{{issue.slug}}">
                        <mat-icon>more_vert</mat-icon>
                      </a>
                      
                    </div>
                  </div>
                  <app-paginator  *ngIf="issues?.length > 10 && showPagination" [items]="issues" [initialPage]="initialPage" (changePage)="onChangePage($event)"></app-paginator>
              </div>
              <div *ngIf="!showInfo && isVolume"  style="padding-bottom: 30px;">
                      <div class="issue-row" *ngFor="let issue of volumeIssues">
                          <a *ngIf="isLocked(issue)" class="image-wrapper pointer-link" (click)="showAlertIssue(issue)">
                              <img *ngIf="isWindow" [defaultImage]="defaultImage" [lazyLoad]="issue.thumb?issue.thumb.url:defaultImage" [offset]="'100'">
                              <img *ngIf="!isWindow" [src]="issue.thumb?issue.thumb.url:defaultImage" >
                          </a>
                          <a  *ngIf="!isLocked(issue)" class="image-wrapper pointer-link"  routerLink="{{getIssueURL(issue)}}">
                              <img *ngIf="isWindow" [defaultImage]="defaultImage" [lazyLoad]="issue.thumb?issue.thumb.url:defaultImage" [offset]="'100'">
                              <img *ngIf="!isWindow" [src]="issue.thumb?issue.thumb.url:defaultImage" >
                          </a>
                          <div  class="hot-right">
                            <div class="top-texts">
                                <h4 *ngIf="!isWebToonOrWebComic(issue.readerType)">{{ 'volume' | translate }}  {{issue.volume_number}} {{ 'issue' | translate }}  {{issue.number}}</h4>
                                <h4 *ngIf="isWebToonOrWebComic(issue.readerType)">{{ 'season' | translate }} {{issue.volume_number}} {{'episode' | translate }}  {{issue.number}}</h4>
                                <h2 class="name-row">
                                  <span *ngIf="isLocked(issue)" class="locked">
                                    <img src="/assets/images/icon_locked_small_grey_2x.png">
                                  </span>
                                  <a *ngIf="isLocked(issue)" (click)="showAlertIssue(issue)" class="issue-title">{{issue.number}}.{{issue.name}}</a>
                                  <a *ngIf="!isLocked(issue)" routerLink="{{getIssueURL(issue)}}" class="issue-title">{{issue.number}}.{{issue.name}}</a>

                                  <span style="display:none; float:right; color:#cecece; font-size:15px;">{{issue.createdAt | date}}</span>
                                </h2>
                                <div class="views">
                                  <span style="    margin-right: 15px;">
                                    {{issue.pages.length}} pages
                                  </span>
                                  <div>
                                    <span>
                                      <img  class="icon" src="assets/images/icon_like_small_grey.png">
                                      
                                    </span>
      
                                    <span>
                                      {{issue.issueLikeCount | viewsFormat}}
                                    </span>
                                    <span style="margin-left: 30px;">
                                      <img class="icon" style="margin-left:-5px;" src="assets/images/icon_comments_small_grey.png">
                                    </span>
      
                                    <span>
                                      {{(issue.commentCount?issue.commentCount:0) | viewsFormat}}
                                    </span>
                                  </div>
                                  <div  (click)="showAlertIssue(issue)" *ngIf="issue.lockSubscription && getUnlocPreview(issue)==='readwithpremium'" class="premium">
                                    <span class="text">
                                      <span >{{ 'Unlock With' | translate }}</span>
                                      <img src="assets/images/logo_graphite_premium_small_2x.png">
                                    </span>
                                  </div>
                                  <div (click)="openIssue(issue)"  *ngIf="!(issue.lockSubscription && getUnlocPreview(issue)==='readwithpremium')" class="premium link" >
                                    <span class="text">
                                      <span >{{ 'Read Now' | translate }}</span>
                                    </span>
                                  </div>
    
                              </div>
                              <h5 (click)="issue.showMore=true" *ngIf="!issue.showMore&&issue.description" class="description">
                                  {{issue.description | truncate:120}} <a>read more</a>
                              </h5>
                              <h5 *ngIf="issue.showMore" class="description">
                                  {{issue.description| translate}} <a (click)="issue.showMore=false">read less</a>
                              </h5>

                            </div>


                            
                            


                          </div>
                        <div class="right-points">
                          <a routerLink="/issue/{{issue.publisher_slug}}/{{issue.title_slug}}/{{issue.slug}}">
                            <mat-icon>more_vert</mat-icon>
                          </a>
                          
                          
                        </div>

                      </div>
              </div>

              <div *ngIf="showInfo" class="info-div">
                <p>
                  {{title?.description}}
                </p>

                  <div class="info-row">
                  <div class="info-label">{{ 'created by' | translate }}</div>
                  <div  class="info-value">{{creators}}</div>
                  </div>
                  <div class="info-row">
                  <div class="info-label">{{ 'publisher' | translate }}</div>
                  <div  class="info-value">{{title?.publisher?.name}}</div>
                  </div>

              </div>


            </div>



            <div class="left-side" *ngIf="isPlaceholder">
              <!-- Top tabs -->

              <div style="opacity: .5;;">
                  <div class="issue-row"   *ngFor="let dummy of ' '.repeat(20).split(''), let i = index">
                      <a class="image-wrapper pointer-link">
                        <svg viewBox="0 0 160 151" preserveAspectRatio="xMidYMin slice" style="width:80px;">
                          <rect width="100%" height="100%" fill="silver" />
                        </svg>
                      </a>
                      <div  class="hot-right">
                        <div class="top-texts">
                            <h2 class="name-row">
                              <a  class="issue-title">&#x2584;  &#x2584;&#x2584;&#x2584; &#x2584;&#x2584;</a>
                              <span style="display:none; float:right; color:#828282; font-size:15px; font-weight:400;">&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;</span>
                            </h2>
                            <div class="views">

                              <div>
  
                                <span>
                                  &#x2584;&#x2584;
                                </span>  
                                <span>
                                  &#x2584;&#x2584;
                                </span>
                              </div>
                              <div  class="premium">
                                <span class="text">
                                  <span >&#x2584;&#x2584;&#x2584; &#x2584;&#x2584;&#x2584; </span>
                                </span>
                              </div>
                          </div>
                          <h5 class="description">
                             <span >&#x2583;&#x2583;&#x2583;&#x2583; &#x2583;&#x2583; &#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583; &#x2583;&#x2583; &#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583; &#x2583;&#x2583;&#x2583;</span>  
                          </h5>

                        </div>
                      </div>

                  </div>
                 
              </div>
            </div>









            <div class="right-side"  *ngIf="!isPlaceholder">
              <a routerLink="/title/{{issue.title.publisher_slug}}/{{issue.title.slug}}" class="discover-title desktop-only" style="margin:0px 0 0 20px; display:block">Discover more about this title
                <img src="./assets/images/external_link/external_link_icon_chevron.png" />
              </a>


              <div *ngIf="!(currentUser?.subscription?.status==='active')" id="amzntitle_issue" style="padding-left: 20px; padding-top: 0px; box-sizing: border-box;"></div>
            </div>
            <div class="right-side"  *ngIf="isPlaceholder">
              <a class="discover-title desktop-only" style="margin:0px 0 0 20px; display:block">&#x2583;&#x2583;&#x2583;&#x2583; &#x2583;&#x2583; &#x2583;&#x2583; &#x2583;&#x2583;&#x2583;
                
              </a>
            </div>
<!-- add ad -->



            </div>
           
</div>
    <div *ngIf="error" class="error-found">
        <h2>Title not found!</h2>
    </div>

</div>
<app-mature-popup  (onclose)="onclose()" *ngIf="displayAlert" [type]="alertType"></app-mature-popup>
