<div>
        <h3 mat-dialog-title   cdkDragHandle cdkDrag cdkDragRootElement=".cdk-overlay-pane">{{dialogTitle}}
                <mat-icon (click)="closeDialog()">close</mat-icon>
        </h3>
        
        <div mat-dialog-content class="dialog-content">
                <mat-progress-bar *ngIf="isCrop" mode="query" [style.display]="cropping ? 'block' : 'none'"></mat-progress-bar>
                <div *ngIf="isCrop && imageBase64">
                        <image-cropper style="background-color: rgba(0,0,0,.4); box-sizing: border-box; display: block;"
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="cropconf.maintainAspect"
                        [aspectRatio]="cropconf.aspect"
                        [imageBase64]="imageBase64"
                        [resizeToWidth]="cropconf.resizeToWidth"
                        format="jpg"
                        roundCropper="true"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"
                        [imageQuality]="cropconf.quality"
                        [cropperMinWidth]="cropconf.minwidth"
                        [cropperMinHeight]="cropconf.minheight"
                        >
                        </image-cropper>   
      

                        

                </div>
                <div  *ngIf="!isCrop">
                        <div class="thumbnail-container" *ngIf="uploader" [ngClass]="{'file-over': hasDropZoneOver}"
                        (fileOver)="fileOver($event)"  
                        ng2FileDrop [uploader]="uploader"
                        (onFileDrop)="dragFilesDropped($event)"

                        (click)="uploader.isUploading!==true && fileInput.click()">  
                                <div class="image">
                                        <div class="page-upload">
                                                <div>                                                
                                                        <input  (change)="fileChangeEvent($event)"   type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none"/>
                                                </div>       
                                        </div>                                       
                                </div>
                                <div class="upload-zone" >
                                        <div class="text-hint">
                                                <div *ngIf="uploader.isUploading===true">
                                                        <button style="margin-top:5px; margin-bottom: 10px; max-width: 200px;" type="button" class="rounded red-mat" mat-button (click)="stopAllConfirm()">CANCEL UPLOAD</button>
                                                </div>
                                                <span  *ngIf="!uploader.isUploading===true" style="font-weight:bold;">+ Click to upload an image</span>
                                                <!-- <mat-progress-bar [style.display]="uploader.queue[0]&&uploader.queue[0].progress>0 ? 'block' : 'none'" mode="determinate" value="{{imgProgress}}"></mat-progress-bar> -->
                                                <br>
                                                <span></span>
                                                <!-- <mat-spinner [style.display]="uploader.queue[0]&&uploader.queue[0].progress>0 ? 'block' : 'none'"  style="margin:auto;"></mat-spinner>                                        -->
                                        </div>
                                </div>                               
                        </div>        
                </div>
        </div>

        <div mat-dialog-actions   *ngIf="isCrop">
                <!-- <mat-spinner class="cropping"  [style.display]="cropping ? 'block' : 'none'"  [style.margin]="'auto'"></mat-spinner>    -->
                <div style="    text-align: right;
                width: 100%;">
                        <button mat-button (click)="cancelCrop()">Cancel</button>
                        <span><button mat-button class="orange-mat"(click)="doCrop()">Save</button></span>        
                </div>                    
        </div>   
        <div class="uploader-images"  *ngIf="!isCrop &&  hasImage()">
                <span  class="not-implemented">
                        Fetch From Issue Cover Image
                </span>
                
                <div class="list-images-container">
                        <div class="list-images" >
                                        <img [src]="getImage()">

                        </div>
                        <div class="list-images not-implemented" *ngFor="let issue of issues; let i = index" (click)="fetchCover(issue)">
                                <img *ngIf="issue.cover_image" [src]="issue.cover_image">
                        </div>
                </div>

        </div>
</div>

  

