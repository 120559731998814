import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
declare var amplitude: any;

@Component({
  selector: 'app-dialog-plan',
  templateUrl: './dialog-plan.component.html',
  styleUrls: ['./dialog-plan.component.scss']
})
export class DialogPlanComponent implements OnInit {
  isBrowser;
  constructor(       
    @Inject(PLATFORM_ID) private platformId: any,   
    public dialogRef: MatDialogRef<DialogPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.isBrowser = isPlatformBrowser(this.platformId)
    }

  ngOnInit() {
    if(this.isBrowser){
      if (amplitude) {
        try {
          amplitude.getInstance().logEvent('viewed_subscription_offer', {});
        } catch (error) {
  
        }
      }
    }

  }
closeDialogEvent(){
  this.dialogRef.close();
}

}
