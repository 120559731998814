import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from '../../_services';


@Component({
  selector: 'app-creators',
  templateUrl: './creators.component.html',
  styleUrls: ['./creators.component.scss']
})
export class CreatorsComponent implements OnInit {
  public isWindow = true;
  isPlaceholder = true;
  creators: any;
  defaultImage: String = './assets/images/preloading_image_placeholder.png';
  featuredCreators: any;
  constructor(private userService: UserService, private authenticationService: AuthenticationService) { }

  ngOnInit() {
      this.isWindow = this.authenticationService.isWindow();
      if(this.isWindow){
        this.loadCreators();
      }
  }


  loadCreators(){
    this.userService.getPublishers().pipe().subscribe(result => {
      this.featuredCreators = [];
      this.creators = [];
      for (const publisher of result) {
        if (publisher.name && publisher.logo && publisher.coverImage) {
            if (publisher.type === 'creator' || publisher.type === 'featured_creator') {
              this.creators.push(publisher);
            }
            if (publisher.type === 'featured_creator') {
              this.featuredCreators.push(publisher);
            }
        }

      }
        this.isPlaceholder = false;
    });    

  }
}
