<div class="main-app">
    <app-header></app-header>
    <app-alert></app-alert>
    <div class="container-fluid container-lg container-xxl h-100">
        <div id="feed" class="h-100">
            <router-outlet></router-outlet>
        </div>
    </div>
    <go-top-button
        [animate]="true"
        [speed]="100"
        [acceleration]="5"
        [scrollDistance]="300"
        [classNames]="'scroll-custom-class'"
        [styles]="{
            'border-radius': '20px 20px 20px 20px',
            'color': '#ff00de',
            'border': '5px solid',
            'line-height': '20px',
            'right': '20px',
            'position': 'fixed',
            'bottom': '20px',
            'cursor':'pointer'
        }">
        
        <mat-icon svgIcon="arrow-up-circle"></mat-icon>
    </go-top-button>
</div>
<!--
<div class="main-app">
    <app-header></app-header>
    <div id="feed" class="container-fluid pb-3 flex-grow-1 d-flex flex-column flex-sm-row overflow-hidden">
        <app-alert></app-alert>
        <router-outlet></router-outlet>
    </div>
-->
