import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ViewContainerRef,
    NgModule,
    ComponentRef
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../_services';
import { CommentReplyComponent } from '../comment-reply/comment-reply.component';
import * as _  from 'lodash';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
    @Input() comment:any;
    @ViewChild('repliesContainer', { read: ViewContainerRef }) target;
    componentRef: ComponentRef<any>;

    commentForm: UntypedFormGroup;
    user: any;
    issue: any;
    title: any;
    publisher: any;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private userService: UserService,
    ) { }

    get f() { return this.commentForm.controls; }

    ngOnInit() {
        this.commentForm = this.formBuilder.group({
              message: '',
        });
        this.user = this.comment.user;
        this.issue = this.comment.issue;
    }

    getImage(user){
        if ( user.profileImage ) {
            return 'url(' +  user.profileImage.url + ')';
        }

        return "url('assets/images/graphy.png')";
    }

    loadReplies(){
        this.comment['loadingReplies'] = true;
        this.userService.getReplies(this.comment.objectId).subscribe(replies=> {
            this.comment['replies'] = _.orderBy(replies, ['createdAt'],['desc']);
            this.comment['repliesVisible'] = true;
        })
    }

    showReplies(){
        this.comment['repliesVisible']=true;
    }

    hideReplies(){
        this.comment['repliesVisible']=false;
    }

    repliesText(nr){
        if(nr==1){
            return 'reply';
        }
        return 'replies';
    }

    postReply() {
        const result = Object.assign({}, this.commentForm.value);

        this.userService.postReply(this.issue.objectId, result.message, this.comment.objectId).subscribe(response=> {
            if ( response ) {
                this.commentForm.reset();
                this.componentRef = this.target.createComponent( CommentReplyComponent );
                let instance = this.componentRef.instance;
                    instance.reply = response;
                    instance.reply.user.profileImage = this.user.profileImage;
            }
        });
    }
}
