<button class="close" mat-button (click)="onNoClick()">X</button>
<h1 mat-dialog-title>{{ 'Profile Parameters' | translate }}</h1>
<div mat-dialog-content>
  <mat-tab-group mat-strech-tabs animationDuration="0ms" class="dialog-tabs">
    <mat-tab label="{{ 'Profile' | translate }}">
        <!-- photo -->
        <div style="
        padding: 20px 18px;
        border-radius: 3px;
        border: 1px solid #aaa;
        margin-bottom: 30px;">
          <img  style="width: 50px;  position: absolute;  top: 35px;" [src]="currentUser.profileImage" />
          <input class="avatar-input"  type="file" (change)="onFileChanged($event)" #fileInput>
          <button  class="file-btn" mat-flat-button (click)="fileInput.click()">{{ "select file" | translate }}</button>
          <button  class="file-btn" mat-flat-button (click)="uploadPic()">Upload!</button>
        </div>

        <form [formGroup]="userUpdate" (ngSubmit)="uploadProfile(userUpdate.value)">
          <mat-form-field appearance="outline" class="">
            <mat-label> {{ 'Email address' | translate }}</mat-label>
            <input matInput placeholder="{{ 'Email address' | translate }}" disabled formControlName="email" [value]="currentUser?.email">
          </mat-form-field>
          <mat-divider></mat-divider>
            <h3>{{ 'Language' | translate }}</h3>
            <mat-select multiple id="country" name="country"  formControlName="languages" [(ngModel)]="languagesList">
              <mat-option *ngFor="let country of listCountry" [value]="country.code">{{country.name}}</mat-option>
            </mat-select>
          <mat-divider></mat-divider>
            <h3>{{ 'Age Filter' | translate }}</h3>
            <mat-slider
              min="0"
              max="4"
              step="1"
              [value]="sliderValue"
              formControlName="ageRating"
            ></mat-slider>
            <ul>
              <li *ngFor="let age of listAge">
                <img (click)="(changeSliderValue(age.number))" [src]="'./assets/images/icon_age_rating_'+age.value+'.png'" alt="{{age.label}}" title="{{age.label}}"/>
              </li>
            </ul>
            <mat-divider></mat-divider>
            <div class="validation">
            <button mat-flat-button class="mat-button blue-mat" submit>{{ 'Save' | translate }}</button>
          </div>
        </form>
    </mat-tab>
  <!--  <mat-tab label="{{ 'profile.notifications' | translate }}">
      <mat-checkbox>{{ 'profile.newsletter' | translate }}</mat-checkbox>
      <mat-checkbox>{{ 'profile.weeklyrecomandations' | translate }}</mat-checkbox>
      <mat-checkbox>{{ 'profile.commentsanswers' | translate }}</mat-checkbox>
    </mat-tab>-->
    <mat-tab label="{{ 'Subscription' | translate }}">
      <!-- Did I subscribe -->
        <div *ngIf="currentUser?.subscription.length === 0; else nosubscription">
          You are currently a premium subscriber
          Subscription started the ....
          Next subscription will be the ...

          <button mat-button  style="display:block;" class="cancelsubscription file-btn">Cancel subscription</button>
        </div>
      <!-- Else -->
        <!-- Subscribe link -->
        <ng-template #nosubscription>
          Subscribe to graphite Premium and get access to all book and an addless experience
          <a routerLink="/subscription"  class="file-btn" style="display:block;" mat-button >Subscribe</a>
        </ng-template>
    </mat-tab>
    <mat-tab label="{{ 'Account' | translate}}">

        <!-- password -->
        <div>
          <h3>{{ 'Password' | translate}}</h3>
          <div style="padding-bottom:15px; "*ngIf="showPassword === false; else showPasswordForm">
            {{ 'Change your account password' | translate  }}
            <button style="float:right;" class="textbutton" (click)="showPassword = true">{{ 'Update password' | translate }}</button>
          </div>
          <ng-template #showPasswordForm>
            <form [formGroup]="pwChangeForm" (ngSubmit)="changePasswordSubmit(pwChangeForm.value)">
            <mat-form-field appearance="outline" class="">
              <mat-label>{{ 'Actual password' | translate }}</mat-label>
              <input type="password"  matInput formControlName="current"  placeholder="{{ 'Actual passsword' | translate }}" required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="">
              <mat-label>{{ 'New Password' | translate }}</mat-label>
              <input type="password"  matInput formControlName="password" placeholder="{{ 'New Password' | translate }}" (input)="onPasswordInput()"><mat-error *ngIf="password.hasError('required')">
                Password is required
              </mat-error>
              <mat-error *ngIf="password2.hasError('minlength')">
                Password must have at least {{minPw}} characters
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="">
              <mat-label>{{ 'New password confirmation' | translate }}</mat-label>
              <input type="password" matInput formControlName="password2" placeholder="{{ 'New password confirmation' | translate }}">
              <mat-error *ngIf="password2.hasError('required')">
                Passwords don't match
              </mat-error>
              <mat-error *ngIf="password2.invalid && !password2.hasError('required')">
                Passwords don't match
              </mat-error>
            </mat-form-field>
            <button (click)="showPassword = false" mat-flat-button class="mat-button blue-mat">{{ 'Cancel' | translate }}</button>
            <button submit mat-flat-button class="mat-button blue-mat" [disabled]="!pwChangeForm.valid">{{ 'Save' | translate }}</button>
            </form>
          </ng-template>
        <!-- Desactivate account -->
        <mat-divider></mat-divider>
      <!--    <h3>{{ 'profile.deactivate' | translate }}</h3>
          <div *ngIf="showDeactivate == false; else showDeactivateForm">
            {{ 'profile.passwordDescription' | translate  }}
            <button class="textbutton">{{ 'profile.deactivateaccount' | translate }}</button>
          </div>-->
          </div>
    </mat-tab>
    </mat-tab-group>
</div>
