<div *ngIf="!horizontal" class="h-100 border-lg-left border-sm-left ms-3 ps-3 vertical">
    <div class="feed-nav mt-3">
        <ng-container *ngIf="dataLoaded">
            <h5>People to follow</h5>
            <div *ngFor="let user of recommended" style="container border">
                <app-feed-recommendation [user]="user" layout="vertical"></app-feed-recommendation>
            </div>
        </ng-container>
    </div>
    <div>
        <button mat-flat-button color="primary" (click)="postStatus()">Post</button>
    </div>
</div>
<div *ngIf="horizontal" class="horizontal">
    <div class="row">
        <div *ngIf="alignButton === 'top'" class="col-3 my-auto">
            <button mat-flat-button color="primary" (click)="postStatus()">Post</button>
        </div>
        <ng-container *ngIf="dataLoaded">
            <div *ngFor="let user of recommended" class="col-3">
                <app-feed-recommendation [user]="user" layout="horizontal"></app-feed-recommendation>
            </div>
        </ng-container>
        <div *ngIf="alignButton === 'bottom'" class="col-3 my-auto">
            <button mat-flat-button color="primary" (click)="postStatus()">Post</button>
        </div>

    </div>
</div>