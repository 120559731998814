

<a *ngIf="activeAd==='own'" href="https://gpht.co/getdrawnin" target="_blank">
  <img src="assets/ads/graphite_app_ad_728_x_90.jpg" class="ad-img">
</a>

<ng-adsense  *ngIf="activeAd==='google'"></ng-adsense>

<div *ngIf="activeAd==='amazon'" id="{{adId||'amazonContainer'}}">

</div>


  
  
  
  
  
