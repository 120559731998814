import { Component, OnInit, Input, Inject, PLATFORM_ID, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AlertService, AuthenticationService,  SessionService, UserService } from '../../../_services';

@Component({
    selector: 'app-feed-card',
    templateUrl: './feed-card.component.html',
    styleUrls: ['./feed-card.component.scss']
})
export class FeedCardComponent implements OnInit {
    @Input() status: any;
    @Output() messageEvent: EventEmitter<string> = new EventEmitter();
    defaultImage: String = 'assets/images/graphy.png';
    statusId!: string;
    isBrowser:boolean = false;
    thumb: any;
    live_date: any;
    isOpen = false;
    user: any;
    username: string;
    profile: any;
    photo: string;
    name: string;
    permalink: any;
    bookmarked: boolean = false;
    liked: boolean = false;
    likesCount: number = 0;
    commentCount: number = 0;
    bookmarkCount: number = 0;
    repostsCount: number = 0;
    currentUser!: any;
    bookmarkAction: boolean = false;
    bookmarks:Array<string> = [];

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private alert: AlertService,
        private authService: AuthenticationService,
        private sessionService: SessionService,
        private userService: UserService,
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (this.isBrowser) {
            this.authService.currentUser.subscribe((value) => {
                this.currentUser = value;
                this.bookmarks = this.sessionService.getData('bookmarks');

            });
        }
    }

    ngOnInit() {
        this.statusId = this.status.objectId;
        this.user = this.status.user;
        this.username = this.user.username;
        this.profile = this.user.profile ? this.user.profile : {};
        this.name = this.profile.name ? this.profile.name : this.username;
        this.photo = this.profile.photo ? this.profile.photo.url : this.defaultImage;
        this.bookmarkCount = this.status.bookmarks ? this.status.bookmarks : 0;
        this.bookmarked = this.bookmarks.includes( this.statusId );
        this.likesCount = this.status.likes ? this.status.likes : 0;
        this.commentCount = this.status.comments ? this.status.comments : 0;
        this.repostsCount = this.status.reposts ? this.status.reposts : 0;

        if (this.status.permalink) {
            this.permalink = this.status.permalink;
        } else {
            this.permalink = '/feed/' + this.status.user.username + '/status/' + this.statusId;
        }
    }

    openContent() {
        console.log("openContent");
        this.messageEvent.emit(JSON.stringify({ event: 'openContent', issueId: this.status.issue.objectId }));
    }

    launchImageViewer(index) {
        console.log('launchViewer', index);
        this.messageEvent.emit(JSON.stringify({ event: 'launchImageViewer', index: index, images: this.status.images }));
    }

    getStrip() {
        return this.status.card.image.url;
    }

    onButtonClick( action:string ) {
        console.log('onButtonClick', action, action == 'bookmark');
        switch ( action ) {
            case 'bookmark':
                this.bookmarkAction = true;
                if ( this.bookmarked ) {
                    this.userService.removeBookmark(this.statusId).subscribe(response => {
                        this.bookmarkCount = this.bookmarkCount - 1 > 0 ? this.bookmarkCount - 1 : 0;
                        this.bookmarked = false;
                        this.bookmarkAction = false;
                        this.messageEvent.emit(JSON.stringify({ event: 'removeBookmark', statusId: this.statusId }));
                        this.bookmarks = this.bookmarks.filter( statusId => statusId !== this.statusId );

                        this.saveBookmarks();
                        this.alert.snacksuccess('Bookmark removed', 5000);
                    });
                } else {
                    this.userService.saveBookmark(this.statusId).subscribe(response => {
                        this.bookmarkCount += 1;
                        this.bookmarked = true;
                        this.bookmarkAction = false;
                        this.bookmarks.push( this.statusId );
                        this.saveBookmarks();
                        this.alert.snacksuccess('Bookmark added', 5000);
                    });
                }
                break;
        }
    }

    saveBookmarks() {
        this.sessionService.saveData('bookmarks', this.bookmarks );
    }
}
