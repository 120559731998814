import { Component, OnInit, ViewEncapsulation, Inject, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { UserService, AuthenticationService, WINDOW } from '../../_services';
import { Title, Meta } from '@angular/platform-browser';
// import { SwiperConfigInterface, SwiperComponent} from 'ngx-swiper-wrapper';
import { DeviceDetectorService } from 'ngx-device-detector';
declare var amplitude: any;
declare const postscribe;
import {Location} from '@angular/common';
import { Issue } from '../../_models';
// import Panzoom, { PanzoomObject } from '@panzoom/panzoom';

@Component({
  selector: 'app-reader',
  templateUrl: './reader.component.html',
  styleUrls: ['./reader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  /*
  host: {
    '(contextmenu)': 'disableClick($event)'
  }
  */
})
export class ReaderComponent implements OnInit, AfterViewInit {
  schema;
  issueId: string;
  issue: Issue;
  title: any;
  publisher: any;
  index;
  pageCount = 0;
  offset = 10;
  horizontal: Boolean = false;
  loaded: Boolean = false;
  imgw: number;
  readerType: string;
  canLoadPages = false;
  minHeight: number;
  isMature: Boolean = false;
  deviceInfo: any = null;
  isMobile = false;
  navprev;
  navnext;
  nextIssue;
  prevIssue;
  showHeader = false;
  showNav = false;
  showFirst = false;
  winHeight = 0;
  step = 1;
  min = 1;
  max: number;
  jmanga: Boolean = false;
  active_subscription_or_preview: Boolean = false;
  sliderValue;
  thumbLabel: false;
  countLoaded = 0;
  allpages: any[];
  first;
  pagenr;
  invert=false;
  loadarr = [];
  currentUser: any;
  isBrowser;
  showAd= true;
  showRightAd = true;
  showLeftAd = true;
  isiOS=false;
  neighbours;
  newIndex;
  startPage;
  isios=false;
  zoomLevel= 1;
  // panzoom: PanzoomObject;
  isZoom=false;
  currentImage;
  currentPage = 1;
  disabled = false;
  tickInterval;
  vertical = false;
  
  public config = { //: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 1,
        keyboard: true,
        mousewheel: true,
        autoplay: false,
        // threshold:0,
        preloadImages: false,
        observer: false,
        lazy: true,
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
        },
        zoom: {
        maxRatio: 3,
        toggle: true,

        },
    //zoom:false,
  };
  height;
  defaultImage: String = './assets/images/preloading_image_placeholder.png';
  issueSlug: string;
  titleSlug: string;
  publisherSlug: string;
  constructor(
    @Inject(WINDOW) private window: Window,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private titleService: Title,
    private metaTagService: Meta,
    private authenticationService: AuthenticationService,
    private deviceService: DeviceDetectorService,
    private location: Location

  ) {
          this.authenticationService.currentUser.subscribe((value) => {
          this.currentUser = value;
      });
     


    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceService.isMobile()) {
      this.isMobile = true;
      this.isiOS=true
    } else if (this.deviceInfo.os === 'IOS') {
      this.isMobile = true;
      this.isiOS=true;
    } else if (this.deviceInfo.os === 'ANDROID') {
      this.isMobile = true;
      this.isiOS=true
    } else {

    }

    //this.isiOS=true;
  }

  //@ViewChild('comicSwiper') comicSwiper: SwiperComponent;
  @ViewChild('navContainers') navContainers: ElementRef;
  @HostListener('window:scroll', ['$event']) onScroll(e) {
      const doch = document.documentElement.scrollHeight;
      if (this.winHeight !== doch) {
            this.winHeight = doch;
            return;
      }
      setTimeout(()=>{this.showHeader = false;});
      
      this.showFirst = false;
      const element = document.getElementById('header');
      try {
        element.classList.add('sticky');
      } catch (err) {}
  }

  // Event on click > show/hide Header
  @HostListener('click', ['$event']) onMouseDown(e) {
    e.stopPropagation();
    if (e.toElement.className === 'swiper-button-prev' || e.toElement.className === 'swiper-button-next') {
          return;
    }
    if (e.toElement.className.indexOf('mat-slider-thumb') >= 0) {
          this.hideHeader();
          return;
    }
          try {
            setTimeout(()=>{this.showHeader = !this.showHeader;});
              this.showFirst = false;
              const element = document.getElementById('header');

                element.classList.add('sticky');
              } catch (err) {}
  }

  imagesReady() {
    console.log('imagesReady');
  }

  lazyImageReady() {

    console.log('lazyImageReady');
  }

  displayHeader() {
    setTimeout(()=>{this.showHeader = true;});
    this.showFirst = false;
  }
  hideHeader() {
    setTimeout(()=>{this.showHeader = false;});
  }

  isWebtoon(){
    if(this.horizontal===false && this.issue && this.isMature===false){
      return true;
    }
    return false;
  }
reloadIssue(){
        
      if (this.issueId) {
        this.userService.getIssue(this.issueId).subscribe(result => {
                this.issue = result;
                this.title = this.issue.title;
                this.publisher = this.title.publisher;
                this.pageCount = result.pages.length+1;
                this.afterLoadIssue();
                
      });
    } else if(this.issueSlug){
        this.userService.getIssueBySlug(this.publisherSlug, this.titleSlug, this.issueSlug).subscribe(result => {
            this.issue = result;
            this.issueId = result.objectId;
            this.title = this.issue.title;
            this.publisher = this.title.publisher;
            this.pageCount = result.pages.length+1;
            this.afterLoadIssue();
        });
    }

}
ngAfterViewInit(){

  if(this.issue && this.issue.pages && this.issue.pages.length===0){
        this.reloadIssue();
  }

    if(this.isWebtoon()===false && !this.isiOS===true){
      this.height = this.window.innerHeight + 50 + 'px';
      setTimeout(() => {
        this.loadScriptsLeft();
        this.loadScriptsRight();
        this.pagenr =Number(this.route.snapshot.paramMap.get('page')) ;
        this.gotopage(this.pagenr);
      }, 1000);
    }




}


zoomOut(){
  return;
    this.isZoom=!this.isZoom;
    if(this.isZoom===true){
        this.zoomActive();
    }
}


zoomIn(){
  return;
    this.isZoom=!this.isZoom;
    if(this.isZoom===true){
        this.zoomActive();
    }
}

zoomActive(){
  return;
  // setTimeout(() => {
  //         let elem = document.getElementById('zoomedimage') as HTMLElement;
  //         this.panzoom = Panzoom(elem, {
  //           //contain: 'outside',
  //           contain: 'outside',
  //           maxScale: 5, startScale: this.zoomLevel,
  //           disableYAxis:false,
  //           focal: {clientX:0, clientY:0},
  //           //origin:'0 0',
  //           //panOnlyWhenZoomed:true,

  //         });


  //       },1000);  
}

  ngOnInit() {
   
    this.showNav = false;
    this.issueId = this.route.snapshot.paramMap.get('issueId');
    this.issueSlug = this.route.snapshot.paramMap.get('slug');
    this.titleSlug = this.route.snapshot.paramMap.get('title_slug');
    this.publisherSlug = this.route.snapshot.paramMap.get('publisher_slug');
    this.pagenr =Number(this.route.snapshot.paramMap.get('page')) ;
    



    
  if (this.issueId) {
    this.userService.getIssue(this.issueId).subscribe(result => {
            this.issue = result;
            this.title = this.issue.title;
            this.publisher = this.title.publisher;
            this.pageCount = result.pages.length+1;
            this.afterLoadIssue();
            
  });
  
} else if(this.issueSlug){
    this.userService.getIssueBySlug(this.publisherSlug, this.titleSlug, this.issueSlug).subscribe(result => {
        this.issue = result;
        this.issueId = result.objectId;
        this.title = this.issue.title;
        this.publisher = this.title.publisher;
        this.pageCount = result.pages.length+1;
        this.afterLoadIssue();
    });
    
}



  }

    gotopage( pagenr ) {
        if ( !pagenr || pagenr===0 ) {
            pagenr=1;
        }
        this.pagenr = pagenr;

        if ( this.issue.readerType && this.issue.readerType === 'jmanga' ) {
            setTimeout(() => {
                this.index=Number(this.pageCount)-Number(this.pagenr)+1;
                console.log(this.index)
            });
        } else {
            setTimeout(() => {
                this.index=this.pagenr-1;
            });
        }
    }

  // navigate(issue) {
  //   this.showHeader = true;
  //   this.router.navigate(['/issue', issue.publisher_slug, issue.title_slug, issue.slug]) .then(() => {
  //     this.window.location.reload();
  //   });
  // }


  navigate(issue) {
    this.showHeader = true;
    this.window.location.href=`/issue/${issue.publisher_slug}/${issue.title_slug}/${issue.slug}`;
    
  }

  issueLoaded() {
    
      let realIndex=this.getRealIndex(this.index);
      
      if(this.issue.readerType==='strip'){
        realIndex=0;
      }
      if(realIndex<this.issue.pages.length || this.issue.readerType==='strip'){
            this.pageLoaded(this.issue.pages[realIndex], true);
      }
      this.userService.issueWasOpened(this.issueId, this.issue.title.objectId, this.issue.readerType, false).subscribe(result => {

      });      
      if(this.pagenr){
        this.gotopage(this.pagenr);
      }
    
  }
  pageLoaded(page, evt) {

      if(!page){
        return;
      }
      if (evt === true) {
          page.loaded = true;
      }

      if (page.displayed) { return; }

        this.userService.pageWasDisplayed(page.objectId, this.issue.objectId, this.issue.title.objectId, this.issue.readerType, this.userService.isSubscribed(this.currentUser)).subscribe(result => {
            console.log(result);
            if ( result ) {

            } else {

            }
            page.displayed = true;
        });

  }

  slideLoaded(index, evt) {
    this.loadarr[index] = true;
  }


  pagePreLoaded(page, evt) {
      if (evt === true) {
        page.loaded = true;
      }
      this.countLoaded++;
      page.displayed = true;
      if (this.countLoaded >= 3) {
            this.allpages = this.issue.pages;
      }
  }

  moveSlide(val){

    this.newIndex=val.value;
  }



  movePage(value) {

    this.newIndex=undefined;
    if(this.readerType==='jmanga'){
        
        setTimeout(() => {
          this.index=this.issue.pages.length-value.value+1;
        });        
        return;
    }

      setTimeout(() => {
        this.index = value.value;
      }); 
  }


  formatLabelComic(value: number) {
    return (this.index+1) +'';
  }
  

  getCurrentPageImage(){
      if(this.index){
          return this.getPageImage(this.issue.pages[this.index], this.index);
      }
      return '';
  }

  getPageImage(page, index) {
    const preload = 5;
    
    if(!this.index){
      if(this.invert){
        this.index=1;
      }
      else{
        this.index=0;
      }
      
    }
    if (page && (index >= this.index - preload) && index <= (this.index + preload)) {
        const url = this.userService.getPageImageJPG(page.objectId);
        page.loaded = true;
        return url;
    } else {
       if (page && !page.loaded) {
            return this.getDefaultImage(page, index);
       }
    }
    return this.getDefaultImage(page, index);
  }
  getPageImageVertical(page, index) {
        const url = this.userService.getPageImageJPG(page.objectId);
        return url;
  }

  getDefaultImage(page, index) {
    const preload = 10;
    if(!this.index){
      if(this.invert){
        this.index=1;
      }
      else{
        this.index=0;
      }
      
    }
    if (page && (index >= this.index - preload) && index <= (this.index + preload)) {
          return this.userService.getThumbnail(page.objectId);
    } else {
        return this.defaultImage;
    }

  }
  getVerticalDefaultImage(page, index) {
        return this.userService.getThumbnail(page.objectId);
  }
  getHeight() {
    return this.window.innerHeight + 100;
  }

  private disableClick(e) {
      e.preventDefault();
  }

  firstPageLoaded(e) {
        this.minHeight = 0;
        this.canLoadPages = true;
        this.showHeader = true;
        this.first = true;
  }
  onclose() {
    this.router.navigate(['/title', this.title.publisher_slug, this.title.slug]);
  }

  // Track amplitude
  bannerClick(mobile) {
    if (this.authenticationService.isWindow()) {
      amplitude.getInstance().logEvent('banner_click', {'device' : mobile});
    }

  }
  getCreditArray(type) {
    if (!this.issue.credits || this.issue.credits.length === 0) {
      return [];
    }
    const person = [];
    for (const credit of this.issue.credits) {
        if (credit['type'] === type) {
          person.push(credit['person']['name']);
        }
    }

    return person;
  }

  populateSchema() {
    let language = 'EN';
    if (this.issue.title && this.issue.title.languages && this.issue.title.languages.length > 0) {
      language = this.issue.title.languages[0].language.toUpperCase();
    }
    const schema = {
      '@context': 'http://schema.org/',
      '@type': 'ComicIssue',
      'description': this.issue.description,
      'name': this.issue.name,
      'issueNumber': this.issue.number,
      'commentCount': this.issue['commentCount'],
      'datePublished': this.issue.live_date.iso,
      'inLanguage': language,
      'isAccessibleForFree': true,
      'thumbnailUrl': this.issue.thumb.url,
      'publisher': {
            '@type': 'Organization',
            'url': 'https://graphitecomics.com/publisher/' + this.issue.title.publisher_slug
      },
      'isPartOf': 'https://graphitecomics.com/title/' + this.issue.title.publisher_slug + '/' + this.issue.title.slug,
      'url': 'https://graphitecomics.com/issue/'  + this.issue.publisher_slug + '/' + this.issue.title_slug + '/' + this.issue.slug,

  };
  const writers = [];
  let persons = this.getCreditArray('writer');
  for (const writer of persons) {
      writers.push({
        '@type': 'Person',
        'name': writer
      });
  }
  if (writers && writers.length > 0) {
    schema['author'] = writers;
  }

  const artists = [];
  persons = this.getCreditArray('artist');
  for (const artist of persons) {
      artists.push({
        '@type': 'Person',
        'name': artist
      });
  }
  if (artists && artists.length > 0) {
    schema['artist'] = artists;
  }

  const pages = [];
  if (this.issue.pages) {
      for (const page of this.issue.pages) {
          pages.push(page.url);
      }
      schema['image'] = pages;

  }
  this.schema = schema;



  }



  loadScriptsLeft(){
    if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription==='active'){
      return;
    }
    try {
      postscribe('#amznLeft', `
          <div id="amzn-assoc-ad-dce8ec42-4a7c-4c05-ae27-212f630cd433" style="background:white;"></div>
          <script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=dce8ec42-4a7c-4c05-ae27-212f630cd433"></script>
        `);
    } catch (error) {
      //nothing
    }
          
  }


  loadScriptsRight(){
    if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription==='active'){
      return;
    }
return;
          postscribe('#amznRight', `
          <div id="amzn-assoc-ad-cc72a99a-606b-4d7f-8ac5-5ff8c5678b1d"></div><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=cc72a99a-606b-4d7f-8ac5-5ff8c5678b1d"></script>
        `);
  }

  closeLeftAd(){
        this.showLeftAd=false;
        let rand = Math.round(Math.random() * (15000 - 500)) + 5500;
        setTimeout(()=>{
          this.showLeftAd=true;
          // this.loadScriptsLeft();
        },rand)
        
  }
  closeRightAd(){

      this.showRightAd=false;
      let rand = Math.round(Math.random() * (15000 - 500)) + 5500;
      setTimeout(()=>{
        this.showRightAd=true;
       // this.loadScriptsRight();
      },rand)
  }





  afterLoadIssue(){
          this.canView();
          // ACTIVE SUBSCRIPTION
          if(this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status==='active'){

          }
          else{

                if (this.issue.accessRule === 'active_subscription_or_preview' && typeof this.issue.accessRuleValue === 'number') {
                  this.active_subscription_or_preview = true;
                  this.issue.pages = this.issue.pages.slice(0, this.issue.accessRuleValue);
                  this.max = this.pageCount = this.issue.accessRuleValue;
                }   
                if(this.issue.accessRule==='active_subscription'){
                  this.active_subscription_or_preview = true;
                  this.issue.pages = [];
                  this.max = this.pageCount = 1;
                }     
          }



    // Redirect if Reader > MobileOnly or PREMIUM
    
    if (this.authenticationService.isWindow()) {
      amplitude.getInstance().logEvent('issue_opened', {'issueId' : this.issue.objectId});
    }
    if (this.issue['lockSubscription']===true || this.issue['lockedMature']===true || this.issue['lockedAge']===true) {
      this.router.navigate(['/title', this.issue.title.publisher_slug, this.issue.title.slug]) .then(() => {
        //this.window.location.reload();
      });
    }

    this.max = this.issue.pages.length;

    this.userService.neighbours(this.issue.title.objectId, this.issue.volume_number, this.issue.number).subscribe(neighbour => {
        this.neighbours=neighbour;
        if (neighbour.previous) {
            if (this.neighbourCanView(neighbour.previousAccessRules['accessRule']) === true) {
              this.navprev = neighbour.previous;
              this.prevIssue=neighbour.previousIssue;
            }
              this.showNav = true;
        }
        if (neighbour.next) {
            if (this.neighbourCanView(neighbour.nextAccessRules['accessRule'])===true) {
              this.navnext = neighbour.next;
              this.nextIssue=neighbour.nextIssue;

            }
              this.showNav = true;
        }
    });


      if (this.issue.title.age_rating === 'MA') {
            this.isMature = true;
      }



      this.populateSchema();

      
      
      const thumbImage = this.issue.thumb.url ? this.issue.thumb.url : this.defaultImage;

      this.titleService.setTitle('Read ' + this.issue.name + ' from ' + this.publisher.name + ' free on Graphite Comics!');
      this.metaTagService.updateTag({name: 'description', content: this.issue.description || 'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators'});

      this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
      this.metaTagService.updateTag({ property: 'og:url', content: `https://graphitecomics.com/issue/${this.issue.publisher_slug}/${this.issue.title_slug}/${this.issue.slug}` });
      this.metaTagService.updateTag({ property: 'og:title', content:  'Read ' + this.issue.name + ' from ' + this.publisher.name + ' free on Graphite Comics!'});
      this.metaTagService.updateTag({ property: 'og:description', content: this.issue.description || 'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators' });
      this.metaTagService.updateTag({ property: 'og:image', content: thumbImage });


                        // Twitter metadata
this.metaTagService.addTag({name: 'twitter:card', content: 'summary'});
this.metaTagService.addTag({name: 'twitter:site', content: 'GraphiteComics'});
this.metaTagService.addTag({name: 'twitter:title', content: 'Read ' + this.issue.title.name + ' from ' + this.issue.title.publisher.name + ' free on Graphite Comics!'});
this.metaTagService.addTag({name: 'twitter:description', content: this.issue.description ||  'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators'});
this.metaTagService.addTag({name: 'twitter:text:description', content: this.issue.description ||  'Graphite Comics is the free, streaming comics service built by readers and creators, for readers and creators'});
this.metaTagService.addTag({name: 'twitter:image', content: thumbImage});
this.issueLoaded();
this.initPages();
}

initPages(){
    if ( this.isMobile === false && this.issue.readerType === 'jmanga' ) {
        this.issue.readerType = "comic";
        this.readerType = "comic";
    }
    if ( this.isiOS === false && this.issue.readerType === 'jmanga' ) {
        this.issue.readerType = "comic";
        this.readerType = "comic";
    }

    if (this.issue.readerType === 'webtoon') {
        this.readerType = 'webtoon';
        this.horizontal = false;
    } else if (this.issue.readerType === 'jmanga') {
        this.jmanga = true;
        this.invert=true;
        this.issue.pages = this.issue.pages.reverse();
        this.readerType = 'jmanga';
        this.horizontal = true;
        this.loaded = true;
        console.log("INIT: ", this.pagenr)
        if(this.pagenr===0){
            this.pagenr=1;
        }
        this.pagenr=this.pagenr || 1;

        // this.sliderValue=this.pagenr;
        // this.index =this.pageCount-1;
    } else {
        this.invert=false;
        this.readerType = 'comic';
        this.horizontal = true;
        this.loaded = true;
        this.sliderValue = this.pagenr || 1;
    }
}

public onIndexChange(index: number) {

  this.newIndex=undefined;
  if(index<0){
    index=0;
  }
  if(!this.index){
    if(this.invert){
      this.index=1;
    }
    else{
      this.index=0;
    }
    
  }

    if ( this.issue.readerType === 'jmanga' ) {
        let realIndex  =this.getRealIndex(index) || 0;

        this.sliderValue = realIndex+1;
        setTimeout(()=>{ this.showHeader = false; });
        if (index !== 0) {
            this.showFirst = false;
        }
        this.pagenr = this.sliderValue;
        if ( this.pagenr === 0 ) {
            this.pagenr = 1;
        }

        this.location.replaceState('/issue/'  + this.issue.publisher_slug + '/' + this.issue.title_slug + '/' + this.issue.slug + '/' + this.pagenr);
        this.currentImage= this.getCurrentPageImage();
        if ( realIndex<this.issue.pages.length ) {
            if (this.issue.pages[realIndex] && this.issue.pages[realIndex].displayed) { return; }

            this.userService.pageWasDisplayed(this.issue.pages[realIndex].objectId, this.issue.objectId, this.issue.title.objectId, this.issue.readerType, this.userService.isSubscribed(this.currentUser)).subscribe(result => {
                this.issue.pages[realIndex].displayed = true;
            });
        }
    } else {

    let realIndex=index||0;
    // this.index=index||0;
    this.sliderValue=index+1;
    setTimeout(()=>{this.showHeader = false;});
    if (index !== 0) {
      this.showFirst = false;
    }
    
    this.pagenr=index+1;

    this.location.replaceState('/issue/'  + this.issue.publisher_slug + '/' + this.issue.title_slug + '/' + this.issue.slug + '/' + this.pagenr);
    this.currentImage= this.getCurrentPageImage();
    if (index >= this.issue.pages.length) {
      return;
    }
    if(realIndex<this.issue.pages.length){
          if (!this.issue.pages[realIndex] || this.issue.pages[realIndex].displayed) { 
            return; 
          }

          this.userService.pageWasDisplayed(this.issue.pages[realIndex].objectId, this.issue.objectId, this.issue.title.objectId, this.issue.readerType, this.userService.isSubscribed(this.currentUser)).subscribe(result => {
            this.issue.pages[realIndex].displayed = true;
          });
    }
  }



}

    getRealIndex(index) {
        if ( this.issue.readerType === 'jmanga' ) {
            index = index || 0;
            return this.issue.pages.length - index;
        } else {
            index = index || 0;
            return index;
        }
    }

canView(){
    if( this.issue.accessRule === 'active_subscription' || this.issue.accessRule === 'active_subscription_preview'){
        this.issue['lockSubscription']=true;
    }
  
    if ( this.currentUser ) {

        if( this.userService.ageToNumber(this.issue.title.age_rating) <= this.userService.ageToNumber(this.currentUser.max_age_rating ) ) {
            //unlock all age restrictions
            this.issue['lockedMature']=false;
            this.issue['lockedAge']=false;
        } else{
            this.issue['hideAge']=true;
            this.issue['lockedAge']=true;
            if ( this.issue.title.age_rating==='MA' ) {
                this.issue['lockedMature']=true;
            }
        }
        if ( this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status==='active' ) {
            this.issue['lockSubscription']=false;
        }

    } else {
        if ( this.userService.ageToNumber(this.issue.title.age_rating)>this.userService.ageToNumber('MA') ) {
            this.issue['lockedAge']=true;
        } else {
            this.issue['lockedAge']=false;
        }
        this.issue['lockedAge']=false;
        if(this.issue.title.age_rating==='MA'){
            this.issue['lockedMature']=true;
            this.issue['lockSubscription']=true;
        }
    }

}

    neighbourCanView(accessRule){
        let hasAccess=true;
        if ( accessRule === 'active_subscription' ) {
            hasAccess = false;
            if ( this.currentUser ) {
                if ( this.currentUser && this.currentUser.subscription && this.currentUser.subscription.status === 'active' ) {
                    hasAccess=true;
                }
            } else {
                hasAccess = false;
            }
        } else {
            hasAccess = true;
        }
        return hasAccess;
    }

    onSwipe( ev ) {
        if ( ev.offsetDirection ) {
            if ( ev.offsetDirection === 2 ) {
                //next
                if ( this.nextIssue ) {
                    this.navigate(this.nextIssue);
                }
            }
            if ( ev.offsetDirection === 4 ) {
                //prev
                if ( this.prevIssue ) {
                    this.navigate(this.prevIssue);
                }
            }
        }
    }
}