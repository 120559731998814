<div class="container row flex-grow-sm-1 flex-grow-0 mx-auto">
    <main class="col overflow-scroll h-100 main">
        <div *ngIf="isLoaded">
            <div class="status">
                <div class="status-header">
                    <div class="d-flex flex-row">
                        <div class="first"><a routerLink="/feed/{{ username }}"><img [src]="profileImage.url" class="rounded-circle" style="width: 64px;" alt="Avatar" /></a></div>
                        <div class="p-2 my-auto h-100 publisher">
                            <div class="name"><a routerLink="/feed/{{ username }}">{{ name }}</a></div>
                            <div class="px-1">@{{ username }}</div>
                        </div>
                        <div class="ms-auto my-auto ">
                            <app-feed-dropdown></app-feed-dropdown>
                        </div>
                    </div><!--/.row -->
                </div><!--/.status-header -->

                <div *ngIf="status.readerType!=='webtoon'">
                    <div class="status-body" *ngIf="status.statusBody">
                        <div [innerHtml]="status.statusBody | feedFormat"></div>
                    </div>
                    <div *ngIf="status.images">
                        <app-feed-gallery [images]="status.images" (messageEvent)="launchImageViewer($event)"></app-feed-gallery>
                    </div>

                    <div class="mb-2"></div>
                    <div *ngIf="status.card">
                        <mat-card>

                            <div *ngIf="status.readerType==='comic' || status.readerType==='strip' || status.readerType==='webcomic'; else reader">
                                <a (click)="openContent()">
                                    <img mat-card-image src="{{status.card.image.url}}" alt="{{status.card.title}}" />
                                </a>
                            </div>
                            <ng-template #reader>
                                <a *ngIf="status.card.image" routerLink="{{ permalink }}">
                                    <img mat-card-image src="{{ status.card.image.url }}" alt="{{status.card.title}}" />
                                </a>
                            </ng-template>
                            <mat-card-content>
                                <mat-card-title *ngIf="status.readerType==='comic'"><a routerLink="{{ permalink }}">{{status.card.title}}</a></mat-card-title>
                                <mat-card-title *ngIf="status.readerType!=='comic'"><a routerLink="{{ permalink }}">{{status.card.title}}</a></mat-card-title>
                                <p *ngIf="status.card.body">
                                    {{ status.card.body }}
                                </p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div><!--/.card-body -->
                <div *ngIf="status.readerType==='webtoon'" clas="w-100">
                    <app-webtoons-viewer [issueId]="status.issue.objectId"></app-webtoons-viewer>
                </div>
                <div class="mb-2"></div>
                <div class="status-footer">
                    <div class="date-added">
                        <span><a routerLink="{{ permalink }}">{{ status.statusCreatedAt.iso | date:'h:mm a &bull; MMMM d, y':'UTC -4' }}</a></span>
                        <span>&bull;</span>
                        <span><span class="view-count">X</span>views</span>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="d-flex flex-row mb-2">
                        <span id="likes">
                            <svg class="feather"><use href="/assets/images/feather-sprite.svg#heart" /></svg>
                        </span>
                        <span class="p-2">{{ status.replies }}</span>
                        <span id="comments">
                            <svg class="feather"><use href="/assets/images/feather-sprite.svg#message-circle" /></svg>
                        </span>
                        <span class="p-2">{{ status.replies }}</span>
                        <span id="views">
                            <svg class="feather"><use href="/assets/images/feather-sprite.svg#send" /></svg>
                        </span>
                        <span class="p-2">{{ status.replies }}</span>
                        <span id="bookmark" class="pe-2 ms-auto">
                            <svg class="feather"><use href="/assets/images/feather-sprite.svg#bookmark" /></svg>
                        </span>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="reply-form">
                        <form [formGroup]="replyForm" (ngSubmit)="postReply()" class="d-flex">
                            <div class="profile" [style.backgroundImage]="getBackgroundImage( currentUser )"></div>
                            <div class="flex-fill"><textarea id="message" matInput placeholder="Post your reply" formControlName="message"></textarea></div>
                            <div class="ml-auto"><button mat-flat-button color="primary" type="button">Reply</button></div>
                        </form>
                    </div>
                </div>
            </div><!--/.card -->

            <div *ngIf="repliesLoaded===true" class="replies">
                <div *ngFor="let reply of replies; let even = even; let odd = odd">
                    <div class="reply">
                        <div class="user">
                            <div class="profile" [style.backgroundImage]="getBackgroundImage( reply.user )"></div>
                            <div style="margin-left: 32px;">
                                <span class="name">{{reply.user.username}}</span>
                                <span> &bull; </span>
                                <span class="date" title="{{reply.statusCreatedAt.iso | date: 'short'}}"><a routerLink="{{ reply.permalink }}">{{reply.statusCreatedAt.iso | timeAgo}}</a></span>
                            </div>
                        </div>
                        <div style="margin-left: 32px;">
                            <div class="body">
                                {{reply.statusBody}}
                            </div>
                            <div class="mb-2"></div>
                            <div class="d-flex flex-row mb-2">
                                <span id="comments" class="pe-2">
                                    <svg class="feather"><use href="/assets/images/feather-sprite.svg#message-circle" /></svg>
                                </span>
                                <span id="likes" class="pe-2">
                                    <svg class="feather"><use href="/assets/images/feather-sprite.svg#heart" /></svg>
                                </span>
                                <span id="bookmark" class="pe-2 ms-auto">
                                    <svg class="feather"><use href="/assets/images/feather-sprite.svg#bookmark" /></svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </div>
            </div>

        </div>

    </main>

    <aside *ngIf="isLoaded && !theaterMode" class="col-sm-4 flex-grow-sm-1 flex-shrink-1 flex-grow-0 h-100 sticky-top pb-sm-0 pb-3 d-sm-none d-md-none d-lg-block h-100">
        <div class="toolbar d-flex flex-row h-100">
            <div><button type="button" (click)="onButtonClick('defaultView')" mat-mini-fab class="reply"><mat-icon svgIcon="columns"></mat-icon></button></div>
            <div><button type="button" (click)="onButtonClick('theaterMode')" mat-mini-fab class="reply"><mat-icon svgIcon="square"></mat-icon></button></div>
        </div>
        <app-feed-nav></app-feed-nav>
    </aside>

</div>


