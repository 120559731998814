import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ViewContainerRef,
    ComponentRef
} from '@angular/core';
//import {Component, NgModule, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService, AuthenticationService } from '../../_services';
import { CommentComponent } from '../comment/comment.component';
import * as _  from 'lodash';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})

export class CommentsComponent implements OnInit {
    @Input() issue:string;
    @ViewChild('commentsContainer', { read: ViewContainerRef }) target:any;
    componentRef: ComponentRef<any>;

    commentForm: UntypedFormGroup;
    trending:any;
    comments:any;
    pinned:any;
    user: any;
    title: any;
    publisher: any;
    commentCount = 0;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private userService: UserService,
        private authenticationService: AuthenticationService,
    ) { }

    get f() { return this.commentForm.controls; }

    ngOnInit() {
        this.userService.getComments(this.issue).subscribe(comments=>{
            this.comments=_.orderBy(comments, ['createdAt'],['desc']);
            this.trending=_.orderBy(comments, ['voteTotal', 'createdAt'],['desc','desc']);
            this.commentCount = this.comments.length;
        })

        this.userService.getPinnedComment(this.issue).subscribe(comment=>{
            this.pinned=comment;
        })
        this.commentForm = this.formBuilder.group({
              message: '',
        });
    }

    postComment() {
        const result = Object.assign({}, this.commentForm.value);
        this.userService.postComment(this.issue, result.message).subscribe(response=> {
            if ( response ) {
                this.commentForm.reset();
                this.componentRef = this.target.createComponent( CommentComponent );
                let instance = this.componentRef.instance;
                    instance.comment = response;
                    instance.comment.user.profileImage = this.user.profileImage;
            }
        });
    }

}
