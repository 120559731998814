import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { StripePaymentComponent } from '../stripe-payment/stripe-payment.component';
import { UserService, AuthenticationService } from '../../_services';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit {
  @Output() public closeDialogEvent = new EventEmitter();
  @Input() public isDialog: boolean=false;

currentUser: any;
  constructor(    
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    //@Inject(MAT_DIALOG_DATA) private data: any,
    //private dialogRef: MatDialogRef<StripePaymentComponent>,
    private router: Router,) {
      // if(data){
      //   this.isDialog=true;
      // }
      this.authenticationService.currentUser.subscribe((value) => {
        this.currentUser = value;
      });

     }
    subscription: any;
    user;
  ngOnInit() {

    if(!this.currentUser){
      return;
    }
    this.userService.getUser().subscribe(user=>{
      this.user=user;
      if(user && user['subscription'] && user['subscription']['source']==='stripe'){
          this.userService.getUserSubscription().subscribe(
            subscription => {
              this.subscription=subscription;
              if(subscription){
                if(!this.isDialog===false){
                  this.router.navigate(['/profile']);
                }
                
              }
            }
          );
      }
    })
  
  }




  getTotal(){
    return 1000;
  }
  createOrder(id){
  
  }
  
  closeDialog(){
    //this.dialogRef.close();
    this.closeDialogEvent.emit();
  }
    checkout(plan, plan_name) {
      const dialogRef = this.dialog.open(StripePaymentComponent, {
          // opening dialog here which will give us back stripeToken
          width: '100%',
          height: '100%',
          panelClass: 'payment-modalbox',
          data: {plan:plan, plan_name:plan_name},
      });


  dialogRef.afterClosed()
          // waiting for stripe token that will be given back       
          .subscribe((result: any) => {
             this.authenticationService.refresh().subscribe(r=>{
                  this.closeDialog();
             });
          });
  }

}
