import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from '../../_services';
import {ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { ignoreElements } from 'rxjs/operators';

@Component({
  selector: 'app-title-genres',
  templateUrl: './title-genres.component.html',
  styleUrls: ['./title-genres.component.scss']
})
export class TitleGenresComponent implements OnInit {
  isBrowser = false;
  isPlaceholder = true;
  isTitlePlaceholder = false;
  titles: any;
  slides: any;
  genreName: String;
  genreId;
  genre;
  sortBy= 'trending';
  schema: { '@context': string; '@type': string; name: string; description: string; publisher: { '@type': string; name: string; }; };
  constructor(
      private authService: AuthenticationService,
      private userService: UserService,
      private route: ActivatedRoute,
      private titleService: Title,
      private metaTagService: Meta,
  ) {


  }

  sort(sortType){
      this.sortBy=sortType;
      this.loadTitles();
  }

  description(){
    if(this.genre.description){
      return this.genre.description;
    }
    return 'For comic, manga and webtoon fans, parents, creators or just want to check out the latest in comics – Free, legally and on any platform. On Graphite you have it all. Socialize with other comic fans and creators or publish your own comics in minutes with Graphite Upload.';
  }

  ngOnInit() {
      this.isBrowser = this.authService.isWindow();
      this.loadGenre();
      if (this.isBrowser) {
        if(this.genreId){
          this.loadTitles();
        }
        else{
          this.loadGenre(true);
        }
        
      }


  }


loadGenre(loadTitles=false){
      this.genreId = this.route.snapshot.paramMap.get('genreId');
      if(this.genreId){
            this.userService.genre(this.genreId).subscribe(result => {
              this.genre = result.data.genre;
                  this.populateMeta();
                  this.populateSchema();
            });
      }
      else{
        this.genreName = this.route.snapshot.paramMap.get('genreName');
        this.userService.genreByName(this.genreName).subscribe(result => {
              this.genre = result.data.genreByName;
              this.genreId=this.genre.objectId;
              this.populateMeta();
              this.populateSchema();
              if(loadTitles===true){
                  this.loadTitles();
              }
  
        });
      }

}


  loadTitles() {
    this.isTitlePlaceholder=true;
    this.titles = [];
    this.slides = [];
    this.userService.genreTitles(this.genreId, 1000, this.sortBy).subscribe(result => {
        this.titles = result.data.genreTitles;
        this.isTitlePlaceholder = false;
        this.loadSlides();
        this.isPlaceholder=false;
    });
  }


  loadSlides(){
    const now = new Date();
    let titles=this.titles.slice(0,10);
    for (const title of titles) {
      if (title.featuredDate !== undefined && new Date(title.featuredDate.iso) < now) {
        const slide = {
           url: title.featuredBanner.url,
           title: title.name,
           publisher: title.publisher.name,
           link: title.objectId,
           action: title.featuredAction,
           special: title.featuredBannerSpecial,
           wide: title.featuredBannerWide,
           publisherId: title.publisher.objectId,
           featuredActionValue: title.featuredActionValue,
           publisher_slug : title.publisher_slug,
           slug : title.slug,

        };

        this.slides.push(slide);
      }
    }
  }
populateMeta(){
  this.genreName = this.genre.genreName;
        
        this.titleService.setTitle(`Graphite Comics - Titles in genre ${this.genreName}`);
        this.metaTagService.updateTag({name: 'description', 
        content: this.description()});
    
        this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
        this.metaTagService.updateTag({ property: 'og:url', content: 'https://graphitecomics.com/publishers/publishers' });
        this.metaTagService.updateTag({ property: 'og:title', content: `Graphite Comics - Titles in genre ${this.genreName}` });
        this.metaTagService.updateTag({ property: 'og:description', 
        content: this.description() });
        this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.graphitecomics.com/assets/images/site_icon_512.jpg' });
    
    
            // Twitter metadata
            this.metaTagService.addTag({name: 'twitter:card', content: 'summary'});
            this.metaTagService.addTag({name: 'twitter:site', content: 'GraphiteComics'});
            this.metaTagService.addTag({name: 'twitter:title', content:  `Graphite Comics - Titles in genre ${this.genreName}`});
            this.metaTagService.addTag({name: 'twitter:description',
            content: this.description() });
            this.metaTagService.addTag({name: 'twitter:text:description',
            content: this.description()});
            this.metaTagService.addTag({name: 'twitter:image', content: 'https://www.graphitecomics.com/assets/images/site_icon_512.jpg'});
}
  populateSchema() {
    const schema =       {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      'name': 'Graphite Comics - Titles in genre ${this.genreName}',
      'description': `${this.description()}`,
      'publisher': {
          '@type': 'Organization',
          'name': 'GraphiteComics'
      }
  };
  this.schema = schema;

  }

}
