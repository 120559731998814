import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-publisher-element',
  templateUrl: './publisher-element.component.html',
  styleUrls: ['./publisher-element.component.scss']
})
export class PublisherElementComponent implements OnInit {

    defaultImage: String='./assets/images/preloading_image_placeholder.png';
    isBrowser = false;
    @Input() publisher: any;

    showDetails = false;

    constructor() { }

    ngOnInit() {
    }

}
