import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, UserService, WINDOW } from '../../_services';
import { MatDialog } from '@angular/material/dialog';
import { ProfileDialog } from '../../dialogs/profile/profile-dialog';
import { WEBSITE_NAME, BASE_URL, FACEBOOK, TWITTER, INSTAGRAM, LOGO } from '../../../environments/environment';

import packageInfo from '../../../../package.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  version: string = packageInfo.version;
  term: String;
  device = '';
  currentUser: any;
  isBrowser = false;
  isUserLoaded = false;
  showAgeRating = false;
  websiteName = WEBSITE_NAME;
  baseUrl = BASE_URL;
  currentYear = new Date().getFullYear();
  facebook = FACEBOOK;
  twitter = TWITTER;
  instagram = INSTAGRAM;

  logo_small = LOGO.sm;
  logo_medium = LOGO.md;

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    public dialog: MatDialog,
  ) { }

  //  @Input() currentUser: string;
  @Output() openSidenav = new EventEmitter<boolean>();

  ngOnInit() {
    this.isBrowser = this.authenticationService.isWindow();
    this.device = this.getMobileOperatingSystem();
    if (this.currentUser && this.currentUser.profileImage === '') {
      this.currentUser.profileImage = './assets/images/avatar.png';
    }

    if (this.isBrowser) {
      this.authenticationService.currentUserSubject.subscribe((value) => {
        this.currentUser = value;
      });
      this.authenticationService.currentUser.subscribe((value) => {
        this.currentUser = value;
        this.isUserLoaded = true;
      });
    }
  }

  onMobileClick() {
    this.openSidenav.emit(true);
  }

  gotoprofile() {
    this.router.navigate(['/profile']);
  }

  doSearch(term) {
    this.router.navigate(['/s', term]);
  }

  // Open profile popup
  openProfile(): void {
    const dialogRef = this.dialog.open(ProfileDialog, {
      width: '800px',
      panelClass: 'profile-dialog',
      data: { user: this.currentUser }
    });
  }

  // Find the mobile type
  getMobileOperatingSystem() {
    const userAgent = this.window.navigator.userAgent || this.window.navigator.vendor || this.window['opera'];

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !this.window['MSStream']) {
      return 'iOS';
    }

    return 'unknown';
  }

  DoLogout() {
    this.authenticationService.logout();
    location.reload();
  }

  getAgeRating() {
    let rating = 4;
    if (this.currentUser && this.currentUser.max_age_rating) {
      rating = this.userService.ageToNumber(this.currentUser.max_age_rating);
    }
    const age = this.userService.listAge[rating];
    return './assets/images/icon_age_rating_' + age.img + '@2x.png'
  }

  getProfileImage() {
    // TODO this is getting called more than once, why?

    if (this.currentUser.profileImage) {
      return this.currentUser.profileImage;
    };
    return './assets/images/user_default_small.png';
  }

}
