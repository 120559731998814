import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, UserService, AlertService } from '../../_services';




@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  forgotForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  showSpinner = false;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private router: Router,
      private authenticationService: AuthenticationService,
      private userService: UserService,
      private alertService: AlertService

  ) {
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) {
          this.router.navigate(['/']);
      }
  }

  ngOnInit() {
      this.forgotForm = this.formBuilder.group({
          UGCusername: ['', Validators.required],
      });

  }



  get f() { return this.forgotForm.controls; }

  onSubmit() {
      
      if (this.forgotForm.invalid) {
          return;
      }
if(this.f.UGCusername.value==='' || this.f.UGCusername.value.length<=3){
    return;
}

this.submitted = true;
      this.userService.requestReset(this.f.UGCusername.value).subscribe(
          result=>{
              if(result['status']==='ok'){
                  this.submitted= true;
              }  
          },
          error=>{
                    this.alertService.snackerror(error);
          }
        

      );
    

      this.loading = true;

  }

}
