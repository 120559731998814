import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { UserService, AuthenticationService } from '../../_services';
import {ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
declare var amplitude: any;
declare const postscribe;
import * as _ from 'lodash';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ComicViewerComponent } from '../comic-viewer/comic-viewer.component';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';

@Component({
  selector: 'app-feed-page',
  templateUrl: './feed-page.component.html',
  styleUrls: ['./feed-page.component.scss']
})
export class FeedPageComponent implements OnInit {
    public isBrowser;
    public schema;
    public isWindow = true;

    statusId:any;
    user: any;
    username: string;
    profile: any;
    name: string;
    status:any;
    isLoaded: boolean = false;
    theaterMode: boolean = false;
    repliesLoaded: boolean = false;
    replies:any;
    replyCount = 0;
    replyForm: UntypedFormGroup;
    currentUser: any;
    error: any;
    profileImage: any;
    userProfileImage: any;

    permalink:any;
    defaultImage = 'assets/images/graphy.png';

  constructor(
      @Inject(PLATFORM_ID) private platformId: any,

      private authService: AuthenticationService,
      private userService: UserService,
      private titleService: Title,
      private metaTagService: Meta,
      private route: ActivatedRoute,
      private router: Router,
      private location: Location,
      private translate: TranslateService,
      private dialog: MatDialog,
      private formBuilder: UntypedFormBuilder,

    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if(this.isBrowser){
            this.authService.currentUser.subscribe((value) => {
                this.currentUser = value;
                console.log(this.currentUser);
            });
        }

    }

    get f() { return this.replyForm.controls; }

    ngOnInit() {
        this.replyForm = this.formBuilder.group({
              message: '',
        });
        this.route.params.subscribe(routeParams => {
            this.username = routeParams.username;
            this.statusId = routeParams.statusId;
        });
        if ( this.statusId ) {
            this.loadItem();
        } else {
            // 404 error
        }
    }

    onButtonClick( event:string ) {
        console.log('buttonclick', event);
        switch ( event ) {
            case 'theaterMode':
                this.theaterMode = true;
                break;
            case 'defaultMode':
                this.theaterMode = false;
                break;
        }
    }

    loadItem() {
        this.userService.getStatus(this.username, this.statusId).subscribe(result => {
            if ( result.error ) {
                this.error = result.error;
            }
            this.status   = result.status;
            this.user     = this.status.user;
            this.username = this.user.username;
            this.profile  = this.user.profile;
            this.name     = this.profile.name;
            if ( this.status.permalink ) {
                this.permalink = this.status.permalink;
            } else {
                this.permalink = '/feed/' + this.username + '/status/' + this.status.objectId;
            }
            this.profileImage = this.profile.photo ? this.profile.photo : this.defaultImage;
            this.userProfileImage = this.currentUser.profileImage ? this.currentUser.profileImage : this.defaultImage;

            this.isLoaded = true;
            this.loadReplies();
        },
        error => {
          this.error = error;
        });
    }

    loadReplies() {
        this.userService.getStatusReplies( this.username, this.status.objectId ).subscribe(replies=>{
            this.replies=_.orderBy(replies.status, ['statusCreatedAt'],['desc']);
            this.replyCount = this.replies.length;
            this.repliesLoaded = true;
        })
    }

    postReply() {
        console.log('postReply');

    }

    getBackgroundImage( user ) {
        if ( user.profile && user.profile.photo ) {
            return 'url('+ user.profile.photo.url +')';
        } else if ( user.profileImage ) {
            return 'url('+ user.profileImage +')';
        }
        return 'url(' +  this.defaultImage + ')';

    }

    openContent() {
        const dialogRef = this.dialog.open( ComicViewerComponent, {
            panelClass : 'feed-overlay',
            autoFocus: true,
            hasBackdrop: true,
            backdropClass: 'backdropBackground',
            width:'100%',
            height:'100%',
            //ariaModal: true,
            position: { 'top': '0' },
            maxWidth: '100vw',
            maxHeight: '100vw',
            data: this.status.issue.objectId,
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    launchImageViewer( $event ) {
        console.log('receiveMessage', $event);
        const dialogConfig = new MatDialogConfig();
            dialogConfig.panelClass = 'overlay';
            dialogConfig.autoFocus = true;
            dialogConfig.hasBackdrop = true;
            dialogConfig.backdropClass = 'backdropBackground';
            dialogConfig.position = { 'top': '0' };
            dialogConfig.width = 'calc(100%)';
            dialogConfig.height = 'calc(100%)';
            dialogConfig.maxWidth = '100%';
            dialogConfig.maxHeight = '100%';
            dialogConfig.data = { images: this.status.images, index: $event };

        const dialogRef = this.dialog.open( ImageViewerComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');

        });
    }
}
