import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {Location} from '@angular/common';
import { UserService } from '../../_services';
import { Title, Meta } from '@angular/platform-browser';

import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-hotnew',
  templateUrl: './hotnew.component.html',
  styleUrls: ['./hotnew.component.scss']
})
export class HotnewComponent implements OnInit {
  schema;
  top50: any;
  newtitles: any;
  nowtitles: any;
  alltitles: any;
  selected = 0;
  selectedIndex = 0;
  isPlaceholder = true;
  isBrowser;

  isPlaceholderTop = true;
  isPlaceholderNew = true;
  isPlaceholderNow = true;
  isPlaceholderAll = true;


  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { 
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.isPlaceholder = true;
  }

  ngOnInit() {
    this.getIndexByUrl();
    this.titleService.setTitle('Trending Hot New Top50 Comic Titles');
    this.metaTagService.updateTag({name: 'description', content: 'See all the latest and most popular comics and what is being read right now.'});

    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: 'https://graphitecomics.com/hotnew' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Trending Hot New Top50 Comic Titles' });
    this.metaTagService.updateTag({ property: 'og:description', content: 'See all the latest and most popular comics and what is being read right now.' });
    this.metaTagService.updateTag({ property: 'og:image', content: 'https://www.graphitecomics.com/assets/images/site_icon_512.jpg' });


            // Twitter metadata
            this.metaTagService.addTag({name: 'twitter:card', content: 'summary'});
            this.metaTagService.addTag({name: 'twitter:site', content: 'GraphiteComics'});
            this.metaTagService.addTag({name: 'twitter:title', content:  'Trending Hot New Top50 Comic Titles'});
            this.metaTagService.addTag({name: 'twitter:description',
            content: 'See all the latest and most popular comics and what is being read right now.' });
            this.metaTagService.addTag({name: 'twitter:text:description',
            content: 'See all the latest and most popular comics and what is being read right now.'});
            this.metaTagService.addTag({name: 'twitter:image', content: 'https://www.graphitecomics.com/assets/images/site_icon_512.jpg'});
            this.populateSchema();
      if (this.isBrowser === true){
              this.gotab(this.selectedIndex);
      }
  }

  getIndexByUrl(){
      const url = this.router.url;
      switch (this.route.snapshot.paramMap.get('term')) {
        default:
        case 'popular':
          this.selectedIndex = 0;
          break;
        case 'top50':
          this.selectedIndex = 0;
        break;
        case 'new':
          this.selectedIndex = 1;
        break;
        case 'rightnow':
          this.selectedIndex = 2;
        break;
        case 'top':
          this.selectedIndex = 3;
        break;
      }
  }



  onLinkClick(event) {
    if (event.index === 0) {
          this.location.go('hotnew/popular', '', true);
          this.loadTop50();
    }
    if (event.index === 1) {
          this.location.go('hotnew/new', '', true);
          this.loadNew();
    }
    if (event.index === 2) {
        this.location.go('hotnew/rightnow', '', true);
        this.loadNowTitles();
    }
    if (event.index === 3) {
        this.location.go('hotnew/top', '', true);
        this.loadAllTitles();

    }

  }

  gotab(index) {
    if (index === 0) {
        this.router.navigate(['/hotnew/popular']);
        if (!this.top50 || this.top50.length === 0) {
          this.loadTop50();
        }
    }
    if (index === 1) {
      this.router.navigate(['/hotnew/newtitles']);
        if (!this.newtitles || this.newtitles.length === 0) {
          this.loadNew();
        }
    }
    if (index === 2) {
      this.router.navigate(['/hotnew/nowtitles']);
      if (!this.nowtitles || this.nowtitles.length === 0) {
            this.loadNowTitles();
        }
    }
    if (index === 3) {
      this.router.navigate(['/hotnew/alltitles']);
      if (!this.alltitles || this.alltitles.length === 0) {
            this.loadAllTitles();
        }
    }

  }

  loadTop50() {
    if(this.isPlaceholderTop===false){
      return;
    }
    this.userService.popular(100).subscribe(result => {
      this.top50 = result.data.popularTitles;
      this.isPlaceholderTop = false;
    });
  }

  loadNew() {
    if(this.isPlaceholderNew===false){
      return;
    }
    this.userService.newTitles(100).subscribe(result => {
      this.newtitles = result.data.newTitles;
      this.isPlaceholderNew = false;

    });
  }

  loadNowTitles() {
    if(this.isPlaceholderNow===false){
      return;
    }
    this.userService.liveTitles(100).subscribe(result => {
      this.nowtitles = result.data.liveTitles;
      this.isPlaceholderNow = false;
    });
  }

  loadAllTitles() {
    if(this.isPlaceholderAll===false){
      return;
    }
    this.userService.topTitles(100).subscribe(result => {
      this.alltitles = result.data.topTitles;
      this.isPlaceholderAll = false;
    });
  }

  populateSchema() {
    const schema =       {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      'name': 'Trending Hot New Top50 Comic Titles',
      'description': 'See all the latest and most popular comics and what is being read right now.',
      'publisher': {
          '@type': 'Organization',
          'name': 'GraphiteComics'
      }
  };
  this.schema = schema;

  }
}
