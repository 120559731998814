import {Component, Inject, ViewEncapsulation, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../_models/index';
import {  UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, ValidationErrors} from '@angular/forms';
import { UserService, AuthenticationService, LocalService } from '../../_services';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: 'profile-dialog.html',
  styleUrls: ['profile-dialog.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ProfileDialog implements OnInit{
  listCountry: Array<{name: string, code: string}> =  [
    {'code': 'all', 'name': 'All languages'},
    {'code': 'ar', 'name': 'Arabic'},
    {'code': 'az', 'name': 'Azerbaijani'},
    {'code': 'be', 'name': 'Belarusian'},
    {'code': 'bg', 'name': 'Bulgarian'},
    {'code': 'bn', 'name': 'Bengali'},
    {'code': 'ca', 'name': 'Catalan'},
    {'code': 'cs', 'name': 'Czech'},
    {'code': 'cy', 'name': 'Welsh'},
    {'code': 'da', 'name': 'Danish'},
    {'code': 'de', 'name': 'German'},
    {'code': 'el', 'name': 'Greek'},
    {'code': 'en', 'name': 'English'},
    {'code': 'eo', 'name': 'Esperanto'},
    {'code': 'es', 'name': 'Spanish'},
    {'code': 'et', 'name': 'Estonian'},
    {'code': 'eu', 'name': 'Basque'},
    {'code': 'fa', 'name': 'Persian'},
    {'code': 'fi', 'name': 'Finnish'},
    {'code': 'fr', 'name': 'French'},
    {'code': 'ga', 'name': 'Irish'},
    {'code': 'gl', 'name': 'Galician'},
    {'code': 'gu', 'name': 'Gujarati'},
    {'code': 'hi', 'name': 'Hindi'},
    {'code': 'hr', 'name': 'Croatian'},
    {'code': 'ht', 'name': 'Haitian Creole'},
    {'code': 'hu', 'name': 'Hungarian'},
    {'code': 'id', 'name': 'Indonesian'},
    {'code': 'is', 'name': 'Icelandic'},
    {'code': 'it', 'name': 'Italian'},
    {'code': 'iw', 'name': 'Hebrew'},
    {'code': 'ja', 'name': 'Japanese'},
    {'code': 'ka', 'name': 'Georgian'},
    {'code': 'kn', 'name': 'Kannada'},
    {'code': 'ko', 'name': 'Korean'},
    {'code': 'la', 'name': 'Latin'},
    {'code': 'lt', 'name': 'Lithuanian'},
    {'code': 'lv', 'name': 'Latvian'},
    {'code': 'mk', 'name': 'Macedonian'},
    {'code': 'ms', 'name': 'Malay'},
    {'code': 'mt', 'name': 'Maltese'},
    {'code': 'nl', 'name': 'Dutch'},
    {'code': 'no', 'name': 'Norwegian'},
    {'code': 'none', 'name': 'No language'},
    {'code': 'pl', 'name': 'Polish'},
    {'code': 'pt', 'name': 'Portuguese'},
    {'code': 'ro', 'name': 'Romanian'},
    {'code': 'ru', 'name': 'Russian'},
    {'code': 'sk', 'name': 'Slovak'},
    {'code': 'sl', 'name': 'Slovenian'},
    {'code': 'sq', 'name': 'Albanian'},
    {'code': 'sr', 'name': 'Serbian'},
    {'code': 'sv', 'name': 'Swedish'},
    {'code': 'sw', 'name': 'Swahili'},
    {'code': 'ta', 'name': 'Tamil'},
    {'code': 'te', 'name': 'Telugu'},
    {'code': 'th', 'name': 'Thai'},
    {'code': 'tl', 'name': 'Filipino'},
    {'code': 'tr', 'name': 'Turkish'},
    {'code': 'uk', 'name': 'Ukrainian'},
    {'code': 'ur', 'name': 'Urdu'},
    {'code': 'vi', 'name': 'Vietnamese'},
    {'code': 'yi', 'name': 'Yiddish'},
    {'code': 'zh-Hans', 'name': 'Chinese Simplified'},
    {'code': 'zh-Hant', 'name': 'Chinese Traditional'}
  ];
  listAge:  Array<{label: string, value: string, number: number}> = [
    {'label': 'all', 'value': 'A', 'number': 0},
    {'label': '10', 'value': 'T', 'number': 1},
    {'label': '13', 'value': 'Tplus', 'number': 2},
    {'label': '16', 'value': 'PG', 'number': 3},
    {'label': '18', 'value': 'MA', 'number': 4},
  ];
  sliderValue = 0;
  selected: string[];
  showPassword = false;
  selectedFile: File;
  user: any;
  languagesList: string[];
  minPw = 8;
  pwChangeForm: UntypedFormGroup;
  userUpdate: UntypedFormGroup;
  validationMessage: '';
  errorMessage: '';
  currentUser: any;
  constructor(
    public dialogRef: MatDialogRef<ProfileDialog>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private localStorage: LocalService,
    ) {
        // this.currentUser = this.authenticationService.currentUserValue;
        this.authenticationService.currentUser.subscribe((value) => {
          this.currentUser = value;
        });
    }


  ngOnInit() {

    this.pwChangeForm = this.formBuilder.group({
      current: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(this.minPw)]],
      password2: ['', Validators.required],
      }, {
      validator: passwordMatchValidator
    });


    this.sliderValue = this.ageToNumber(this.currentUser.max_age_rating);
    const list = [];
    this.currentUser.languages.forEach(function(value) {
      list.push(value.language);
    });
    this.languagesList = list;


    this.userUpdate = this.formBuilder.group({
      email: [{value: null, disabled: true}],
      ageRating: [this.sliderValue],
      languages: ['']
    });

}


  onNoClick() {
    this.dialogRef.close();
  }

  changeSliderValue(value) {
    this.sliderValue = value;
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  // Uploading picture
  uploadPic() {
    // this.currentUser.profileImage = './assets/images/loading-gif.gif';
    // const formPicData = new FormData();
    // formPicData.append('profileImage', this.selectedFile, this.selectedFile.name);
    // this.userService.uploadProfileImage(formPicData).subscribe(
    //   (result: any) => {
    //       if (result.profileImage) {
    //         this.currentUser.profileImage = result.profileImage;
    //         this.authenticationService.currentUserSubject.next(this.currentUser);
    //       }
    //   },
    //   error => {

    //   }
    // );
  }

  uploadProfile(form) {
    // const age = this.numberToAge(form.ageRating);
    // const langues = form.languages.join();
    // this.userService.updateUser(age, langues).subscribe(
    //   (result: User) => {
    //     this.authenticationService.currentUserSubject.next(result);
    //     this.dialogRef.close();
    //   },
    //   error => {

    //   }
    // );
  }

  numberToAge(number) {
    switch (number) {
      case 0:
        return 'A';
      break;
      case 1:
        return 'T';
      break;
      case 2:
        return 'T+';
      break;
      case 3:
        return 'PA';
      break;
      case 4:
        return 'MA';
      break;
    }
  }

  ageToNumber(value) {
    switch (value) {
      case 'A':
        return 0;
      break;
      case 'T':
        return 1;
      break;
      case 'T+':
        return 2;
      break;
      case 'PA':
        return 3;
      break;
      case 'MA':
        return 4;
      break;
    }
  }

  changePasswordSubmit(form) {
    // this.userService.updatingPassword(form.current, form.password, this.user.username).subscribe(
    //   result => {
    //     this.localStorage.setItem('currentUser', JSON.stringify(result));
    //   }
    //   ,
    //   error => {

    //   }
    // );
  }

  get password() { return this.pwChangeForm.get('password'); }
  get password2() { return this.pwChangeForm.get('password2'); }

  onPasswordInput() {
    if (this.pwChangeForm.hasError('passwordMismatch')) {
      this.password2.setErrors([{'passwordMismatch': true}]);
    } else {
      this.password2.setErrors(null);
    }
  }


}

export const passwordMatchValidator: ValidatorFn = (pwChangeForm: UntypedFormGroup): ValidationErrors | null => {
  if (pwChangeForm.get('password').value === pwChangeForm.get('password2').value) {
    return null;
  } else {
    return {passwordMismatch: true};
  }
};
