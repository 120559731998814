import { Component, HostListener, OnInit , Inject, AfterViewInit} from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, RoutesRecognized, NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, SessionService, UserService, WINDOW } from './_services';
import { User } from './_models';

import packageJson from '../../package.json';
import { BinaryOperatorToken } from 'typescript';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit, AfterViewInit {
    public version: string = packageJson.version;
    title = 'Awesome Comic Site';
    currentUser: User;
    supportedLanguages = ['en', 'fr'];

    constructor(
        @Inject(WINDOW) private window: Window,
        private router: Router,
        private authenticationService: AuthenticationService,
        private sessionService: SessionService,
        private userService: UserService,
        private translate: TranslateService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        console.log( 'version', this.version );
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.matIconRegistry.addSvgIconSet(
            this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/feather-sprite.svg")
        );

        this.matIconRegistry.addSvgIconSet(
            this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/astropop.svg")
        );

        this.matIconRegistry.addSvgIconSet(
            this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/images/reader-sprite.svg")
        );

        translate.addLangs(this.supportedLanguages);
        translate.setDefaultLang(this.supportedLanguages[1]);

        // If user language pref is not english.
        //translate.setDefaultLang('en');
        //translate.use('en');

        // var userLang = this.window.navigator.language || this.window.navigator['userLanguage'];
        // if(userLang == 'fr-FR')
        // {
        //   translate.use('fr');
        // }
        // Load bookmarks into memory
        var reset: boolean = false;
        var bookmarks = this.sessionService.getData('bookmarks');
        if ( !bookmarks || reset ) {
            console.log( 'No bookmarks' );
            this.userService.loadSessionData().subscribe(response => {
                bookmarks = response.bookmarks;
                this.sessionService.saveData('bookmarks', bookmarks);
            });
        }
    }

    @HostListener('window:unload', [ '$event' ])
    unloadHandler( event ) {
        console.log('unloadHandler');
    }
    @HostListener('window:beforeunload', [ '$event' ])
    beforeUnloadHandler( event ) {
        console.log('beforeUnloadHandler');
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
    ngAfterViewInit() {
        this.translate.setDefaultLang('en');
        const userLang = this.window.navigator.language || this.window.navigator['userLanguage'];
        if (userLang === 'fr-FR') {
          this.translate.use('fr');
        }
    }

    ngOnInit() {

        this.router.events.subscribe(e => {
            // Redirection if facebook?
            if (e['url']) {
                    const eurl = e['url'];
                    if (eurl) {
                        const fbpos = eurl.indexOf('?fbcl');
                        if (fbpos > 0) {
                            this.router.navigate([eurl.substr(0, fbpos)]);

                        }
                    }
            }
            if (e instanceof RoutesRecognized) {
                if (this.authenticationService.isWindow() === true) {
                    this.window.scroll(0, 0);
                }

              if (e.urlAfterRedirects === '/shortlinks' && e.urlAfterRedirects !== e.url) {

                  this.userService.shortLink(e.url).subscribe(
                      result => {
                                if (result.error) {
                                    this.router.navigate(['/']);
                                } else {
                                    if (result.publisher) {
                                        this.router.navigate(['/p', result.publisher.objectId]);
                                    }
                                    if (result.title) {
                                        this.router.navigate(['/t', result.title.objectId]);
                                    }
                                    if (result.issue) {
                                        this.router.navigate(['/i', result.issue.objectId]);
                                    }
                                    if (result.searchTerm) {
                                        this.router.navigate(['/s', result.searchTerm]);
                                    }
                                }
                      },
                      error => {
                        const navigationExtras: NavigationExtras = {
                            queryParams: {
                                'redirected': 'true'
                            }
                          };
                        this.router.navigate(['/s', e.url.replace('/', '')], navigationExtras);
                      }
                  );
              } else if (e.url === '/shortlinks') {
                    this.router.navigate(['/']);
              }
            }
          });
    }
}

