import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ShortlinksComponent } from './_components/shortlinks/shortlinks.component';
import { PopularTitlesComponent } from './_components/popular-titles/popular-titles.component';
import { TitleDetailsComponent } from './_components/title-details/title-details.component';
import { PublishersComponent } from './_components/publishers/publishers.component';
import { TitleGenresComponent } from './_components/title-genres/title-genres.component';
import { ReaderComponent } from './_components/reader/reader.component';
import { HotnewComponent } from './_components/hotnew/hotnew.component';
import { PublisherslistComponent } from './_components/publisherslist/publisherslist.component';
import { PublisherPageComponent } from './_components/publisher-page/publisher-page.component';
import { GraphiteViewerComponent } from './_components/graphite-viewer/graphite-viewer.component';
import { CreatorsComponent } from './_components/creators/creators.component';
import { SearchComponent } from './_components/search/search.component';
import { FeedLayoutComponent } from './layouts/feed-layout/feed-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { NoheaderLayoutComponent } from './layouts/noheader-layout/noheader-layout.component';
import { ViewerLayoutComponent } from './layouts/viewer-layout/viewer-layout.component';
import { ShortlinksGuard } from './_guards';
import { AppStoreRedirectComponent } from './_components/appstore-redirect/appstore-redirect.component';
import { MatureComponent } from './_components/mature/mature.component';
import { UploadComponent } from './_components/upload/upload.component';
import { IssueDetailsComponent } from './_components/issue-details/issue-details.component';
import { ProfilePageComponent } from './_components/profile-page/profile-page.component';
import { ForgotpasswordComponent } from './_components/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './_components/reset-password/reset-password.component';
import { SelectPlanComponent } from './_components/select-plan/select-plan.component';
import { FeedComponent } from './_components/feed/feed.component';
import { FeedPageComponent } from './_components/feed-page/feed-page.component';
import { FeedProfileComponent } from './_components/feed-profile/feed-profile.component';
import { ComicsComponent } from './_components/comics/comics.component';
import { ComicDetailComponent } from './_components/comic-detail/comic-detail.component';
import { TranslateModule } from '@ngx-translate/core';
import { FeedBookmarksComponent } from './_components/feed-bookmarks/feed-bookmarks.component';
import { DiscoverComponent } from './_components/discover/discover.component';
import { FeedNotFoundComponent } from './_components/feed-not-found/feed-not-found.component';
const appRoutes: Routes = [
    { path: '', component: MainLayoutComponent, children:[
            { path: '', component: HomeComponent },
            { path: 'profile', component: ProfilePageComponent },
            { path: 'home', component: HomeComponent},
            { path: 'plans', component: SelectPlanComponent},
            { path: 'shortlinks', component: ShortlinksComponent, canActivate: [ShortlinksGuard] },
            { path: 'hotnew', component: HotnewComponent },
            { path: 'hotnew/:term', component: HotnewComponent },
            { path: 'titles/popular', component: PopularTitlesComponent },
            { path: 't/:titleId/:publisherSlug/:slug', component: TitleDetailsComponent },
            { path: 't/:titleId/:publisherSlug/:slug/p/:pageNumber', component: TitleDetailsComponent },
            { path: 't/:titleId/:publisherSlug/:slug/v/:volumeNumber', component: TitleDetailsComponent },
            { path: 't/:titleId', component: TitleDetailsComponent },
            { path: 't/:titleId/p/:pageNumber', component: TitleDetailsComponent },
            { path: 't/:titleId/v/:volumeNumber', component: TitleDetailsComponent },
            { path: 'genre/:genreName', component: TitleGenresComponent },
            { path: 't/g/:genreId', component: TitleGenresComponent },
            { path: 'title/:publisherSlug/:titleSlug/:pageNumber', component: TitleDetailsComponent },
            { path: 'title/:publisherSlug/:titleSlug', component: TitleDetailsComponent },
            { path: 's', component: SearchComponent },
            { path: 's/:term', component: SearchComponent },
            { path: 'p/:publisherId/:slug', component: PublisherPageComponent },
            { path: 'p/:publisherId', component: PublisherPageComponent },
            { path: 'publisher/:publisherSlug', component: PublisherPageComponent },
            { path: 'publishers', component: PublishersComponent, children:[
                { path: 'publishers', component: PublisherslistComponent },
                { path: 'creators', component: CreatorsComponent },
                { path: 'upload', component: UploadComponent },
            ] },

            { path: 'getdrawnin', component: AppStoreRedirectComponent },
            { path: 'mature', component: MatureComponent },
            { path: 'comics', component: ComicsComponent },
            { path: 'comics/:publisher_slug', component: ComicsComponent },
            { path: 'comic/:publisher_slug/:title_slug/:slug', component: ComicDetailComponent },
            { path: 'webcomic/:publisher_slug/:title_slug/:slug', component: IssueDetailsComponent },
            { path: 'manga/:publisher_slug/:title_slug/:slug', component: IssueDetailsComponent },
            { path: 'webtoon/:publisher_slug/:title_slug/:slug', component: IssueDetailsComponent },
            { path: 'strip/:publisher_slug/:title_slug/:slug', component: IssueDetailsComponent },
            { path: 'comic/:publisher_slug/:title_slug/:slug/:pageNumber', component: IssueDetailsComponent },
            { path: 'webcomic/:publisher_slug/:title_slug/:slug/:pageNumber', component: IssueDetailsComponent },
            { path: 'manga/:publisher_slug/:title_slug/:slug/:pageNumber', component: IssueDetailsComponent },
            { path: 'webtoon/:publisher_slug/:title_slug/:slug/:pageNumber', component: IssueDetailsComponent },
            { path: 'strip/:publisher_slug/:title_slug/:slug/:pageNumber', component: IssueDetailsComponent },
            { path: 'c/:issueId/:publisher_slug/:title_slug/:slug', component: IssueDetailsComponent },
            { path: 'c/:issueId/:publisher_slug/:title_slug/:slug/:pageNumber', component: IssueDetailsComponent },
            { path: 'c/:issueId', component: IssueDetailsComponent },
            { path: 'c/:issueId/:page', component: IssueDetailsComponent },
    ] },
    { path: '', component: NoheaderLayoutComponent, children:[
            { path: 'login', component: LoginComponent },
            { path: 'register', component: RegisterComponent },
            { path: 'forgotpassword', component: ForgotpasswordComponent },
            { path: 'reset_password/:email/:token', component: ResetPasswordComponent },
    ] },
    { path: '', component: ViewerLayoutComponent, children:[
            { path: 'i/:issueId/:publisher_slug/:title_slug/:slug', component: ReaderComponent },
            { path: 'i/:issueId/:publisher_slug/:title_slug/:slug/:page', component: ReaderComponent },
            { path: 'i/:issueId', component: ReaderComponent },
            { path: 'i/:issueId/:page', component: ReaderComponent },
            { path: 'issue/:publisher_slug/:title_slug/:slug', component: ReaderComponent },
            { path: 'issue/:publisher_slug/:title_slug/:slug/:page', component: ReaderComponent },
            { path: 'view/:issueId', component: GraphiteViewerComponent },

    ] },
    { path: '', component: FeedLayoutComponent, children:[
        { path: 'feed', component: FeedComponent },
        { path: 'discover', component: DiscoverComponent },
        { path: 'hashtag', component: DiscoverComponent },
        { path: 'hashtag/:hashtag', component: FeedComponent },
        { path: 'bookmarks', component: FeedBookmarksComponent },
        { path: 'mentions', component: DiscoverComponent },
        { path: 'mentions/:username', component: FeedComponent },
        { path: 'feed/:username', component: FeedProfileComponent },
        { path: 'feed/:username/status', component: FeedProfileComponent },
        { path: 'feed/:username/status/:statusId', component: FeedPageComponent },
        { path: '**', component: FeedNotFoundComponent },
    ] },
    // otherwise redirect to home
    { path: ' ', redirectTo: 'home' },
    { path: '**', redirectTo: 'shortlinks' }
];

export const routing = RouterModule.forRoot(appRoutes);
